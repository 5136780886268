import React, { useContext, useEffect } from "react";
import riskCategoryJson from "./../../../data/Risk/riskCategories.json";
import SearchContext from "../../../services/context/Search/searchContext";
import BreadcrumbContext from "../../../services/context/Breadcrumb/breadcrumbContext";


export const RiskCategoryComponent = () => {
    window.scrollTo(0, 0);

    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)

    useEffect(() => {
        setBreadcrumbMenu(
            [
                { slug: '', menuName: 'Risk Monitor', isActive: true, hasSubMenu: true },
                { slug: '/risk/risk-categories', menuName: 'Risk Category', isActive: false, hasSubMenu: true }
            ]
        )
    }, [])

    const getRiskNewsCategoryBg = (category) => {
        if (category === 'Legal') {
            return 'badge-primary'
        }
        else if (category === 'Human Capital') {
            return 'badge-info'
        }
        else if (category === 'Operational') {
            return 'badge-success'
        }
        else if (category === 'Reputation') {
            return 'badge-light'
        }
        else if (category === 'Financial') {
            return 'badge-dark'
        }
        else if (category === 'ESG') {
            return 'badge-secondary'
        }
        else if (category === 'Compliance') {
            return 'badge-warning'
        }
        else {
            return 'badge-danger'
        }
    }

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <section className="mb-3 d-flex justify-content-between">
                    <div>
                        <h6>Risk Categories</h6>
                    </div>
                    <div>
                        <button className="btn btn-primary btn-sm mr-2"><i className="fa fa-fw fa-download" aria-hidden="true"></i>Export</button>
                        <button className="btn btn-primary btn-sm mr-2"><i className="fa fa-fw fa-plus" aria-hidden="true"></i>{'Add Custom Category'}</button>
                        <button className="btn btn-primary btn-sm mr-2"><i className="fa fa-fw fa-edit" aria-hidden="true"></i></button>
                        <button className="btn btn-primary btn-sm mr-2"><i className="fa fa-fw fa-trash" aria-hidden="true"></i></button>
                        <button className="btn btn-primary btn-sm"><i className="fa fa-fw fa-filter" aria-hidden="true"></i></button>
                    </div>
                </section>
                <section className="mb-3">
                    <div className="row">
                        <div className="col-md-12 col-lg-12 col-12">
                            <div className="card-deck bl-card-deck">
                                {
                                    riskCategoryJson.map((cat, i) => {
                                        return (
                                            <div key={`risk-cat-${i}`} className="card">
                                                <div className="card-header"><h6 className={`mb-0 badge badge-pill ${getRiskNewsCategoryBg(cat.riskCategory)}`}>{cat.riskCategory}</h6></div>
                                                <div className="card-body p-0">
                                                    <ul className="list-group list-group-flush">
                                                        {
                                                            cat.items.map((item, j) => {
                                                                return (
                                                                    <li key={`risk-cat-item-${i}${j}`} className="list-group-item d-flex justify-content-between align-items-center font-small">
                                                                        {item.subCategory}
                                                                        <i className={`fa ${item.icon}`} aria-hidden="true"></i>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}