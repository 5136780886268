import React, { useContext, useEffect, useState } from "react";
import homeLabel from "./../../utils/property-file/componentOverview.json";
import buttonLabel from "./../../utils/property-file/buttons-label.json";
import overviewImg from "./../../assets/img/report-brand.jpg";
import appUpdatesJson from "./../../data/Analytics/appUpdates.json";
import reportsJson from "./../../data/Analytics/reports.json";
import { TableViewComponent } from "./tableView";
import { AnalyticsOverviewSidePanelComponent } from "./overviewSidePanel";
import reportCategoryJson from "./../../data/Analytics/reportCategory.json";
import SearchContext from "../../services/context/Search/searchContext";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";
import { RecentActivityPanel } from "../Common/recentActivitySidePanel";
import { AuthContext } from "../../services/context/Auth/authContext";

export const AnalyticsOverviewComponent = () => {

    window.scrollTo(0, 0);
    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const { loggedUserName } = useContext(AuthContext)

    useEffect(() => {
        setBreadcrumbMenu(
            [
                { slug: '', menuName: 'Analytics', isActive: true, hasSubMenu: true },
                { slug: '/analytics/overview', menuName: 'Overview', isActive: false, hasSubMenu: true }
            ]
        )
    }, [])

    const [updateList] = useState(appUpdatesJson);
    const [reports, setReports] = useState([]);
    const [filter, setFilter] = useState([]);
    const [reportColumn] = useState([
        'Category', 'Description', 'Report Type', 'Shared with'
    ])
    const [labels, setLabels] = useState({
        WELCOME: "",
        WELCOME_DESC: "",
        LATEST_UPDATE: "",
        GUIDE: "",
        YOUR_ACTIVITY: "",
        LATEST_UPDATE: "",
        ENTITY: {
            TITLE_1: "",
            DESC_1: "",
            TITLE_2: "",
            DESC_2: "",
            TITLE_3: "",
            DESC_3: ""
        },
        RECOMMEND: ""
    });

    useEffect(() => {
        const reportList = reportsJson.filter(r => r.isActive);
        setReports(reportList)
    }, [reportsJson, filter])

    useEffect(() => {
        let items = homeLabel.find(l => l.FEATURE === "ANALYTICS").items;
        setLabels(items)
    }, [homeLabel])

    useEffect(() => {
        let filters = [
            {
                val: 'all', name: 'All', isActive: true
            },
            {
                val: 'isRecentlyPublished', name: 'Recently Published', isActive: false
            },
            {
                val: 'createdBy', name: 'Created by you', isActive: false
            },
            {
                val: 'isRecentlyView', name: 'Recently viewed by you', isActive: false
            }
        ];
        setFilter(filters)
    }, [])

    useEffect(()=>{
        if(filter && filter.length){
            let reportList = [];
        const activeFilterItem = filter.find(f=> f.isActive).val;
        if(activeFilterItem && activeFilterItem !== 'all'){
            reportList = reportsJson.filter(r => {
                if(activeFilterItem === 'createdBy'){
                    return r[activeFilterItem] === loggedUserName && r.isActive
                }
                else {
                    return r[activeFilterItem] && r.isActive
                }
            })
        }
        else {
            reportList = reportsJson.filter(r => r.isActive)
        }
        setReports(reportList)
        }
    }, [filter])

    const selectFilterItem = (e, indx) => {
        e.preventDefault();
        const filterList = filter.map(f=>{
            f.isActive = false;
            return f;
        })
        filterList[indx].isActive = true;
        setFilter(filterList)
    }

    return (
        <div id="content">

            <div className="container-fluid pt-3">

                <div className="row">
                    <div className="col-xl-10 col-lg-10 col-md-9 col-12">
                    <section className="mb-3">
                            <div className="card">
                                <div className="card-body d-flex justify-content-between">
                                    <div className="align-self-center mr-16">
                                        <h5>{labels.WELCOME}</h5>
                                        <p className="mb-0">{labels.WELCOME_DESC}</p>
                                    </div>
                                    <img className="bl-overview-img" src={overviewImg} alt="overview-Img" />
                                </div>
                            </div>
                        </section>

                        <section className="mb-4">
                            {/* <h6 className="mb-3">{labels.GUIDE}</h6> */}
                            <div className="card-deck">
                                <div className="card shadow-sm">
                                    {/* <div className="card-header d-flex justify-content-between px-3">
                                        <div className="align-self-center">
                                            <h6 className="mb-0">{labels.ENTITY.TITLE_1}</h6>
                                        </div>
                                    </div> */}
                                    <div className="card-body">
                                        <h6 className="card-title">{labels.ENTITY.TITLE_1}</h6>
                                        <p className="card-text">{labels.ENTITY.DESC_1}.</p>
                                        <button className="btn btn-primary btn-sm btn-outline-primary">{buttonLabel.VIEW_NOW}</button>
                                    </div>
                                </div>
                                <div className="card shadow-sm">
                                    {/* <div className="card-header d-flex justify-content-between px-3">
                                        <div className="align-self-center">
                                            <h6 className="mb-0">{labels.ENTITY.TITLE_2}</h6>
                                        </div>
                                    </div> */}
                                    <div className="card-body">
                                    <h6 className="card-title">{labels.ENTITY.TITLE_2}</h6>
                                        <p className="card-text">{labels.ENTITY.DESC_2}.</p>
                                        <button className="btn btn-primary btn-sm btn-outline-primary">{buttonLabel.VIEW_NOW}</button>
                                    </div>
                                </div>
                                <div className="card shadow-sm">
                                    {/* <div className="card-header d-flex justify-content-between px-3">
                                        <div className="align-self-center">
                                            <h6 className="mb-0">{labels.ENTITY.TITLE_3}</h6>
                                        </div>
                                    </div> */}
                                    <div className="card-body">
                                    <h6 className="card-title">{labels.ENTITY.TITLE_3}</h6>
                                        <p className="card-text">{labels.ENTITY.DESC_3}</p>
                                        <button className="btn btn-primary btn-sm btn-outline-primary">{buttonLabel.ADMIN_PANEL}</button>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12">
                                            <h6 className="mb-3">{'Recently Viewed Reports'}</h6>
                                            <div className="d-flex pb-2">
                                                {
                                                    filter.map((f, i) => {
                                                        return (
                                                            <span key={`analytics-overview-filter-${i + 1}`} onClick={(e)=>selectFilterItem(e, i)} className={`badge bl-filter-chip cp ${f.isActive ? 'active' : ''}`}>{f.name}</span>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <TableViewComponent data={reports} reportColumn={reportColumn}></TableViewComponent>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>

                    <div className="col-xl-2 col-lg-2 col-md-3 py-3 mt-n3 mob-hide bl-fixed-right-panel">
                        <RecentActivityPanel title={labels.YOUR_ACTIVITY} data={updateList}></RecentActivityPanel>
                        <div className="text-center">
                            <button type="button" className="btn bg-gray btn-sm">Load More</button>
                        </div>
                        {/* <AnalyticsOverviewSidePanelComponent list={updateList} activityTitle={labels.YOUR_ACTIVITY} updatesTitle={labels.LATEST_UPDATE}></AnalyticsOverviewSidePanelComponent> */}
                    </div>

                </div>
            </div>

        </div>
    )
}
