import React, { useEffect, useState } from 'react';
import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";

export const CarbonZeroChart = ({ data, height='100px', id='carbonchartdiv', marker=true }) => {

    const [chartRoot, setChartRoot] = useState(null);

    useEffect(() => {
        if (chartRoot) {
            chartRoot.dispose();
        }


        let carbonRoot = am5.Root.new(id);

        carbonRoot.setThemes([
            am5themes_Animated.new(carbonRoot)
        ]);
        carbonRoot._logo.dispose();

        var chart = carbonRoot.container.children.push(am5xy.XYChart.new(carbonRoot, {
            panX: false,
            panY: false,
            wheelX: "none",
            wheelY: "none",
            layout: carbonRoot.verticalLayout,
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 0,
            paddingTop: 0
        }));

        var legend = chart.children.push(
            am5.Legend.new(carbonRoot, {
                centerX: am5.p50,
                x: am5.p50
            })
        );

        var data = [{
            category: "15",
            value: 100,
            columnSettings: {
                fill: am5.color(0xc6251a)
            }
        }, {
            category: "14",
            value: 100,
            columnSettings: {
                fill: am5.color(0xc6251a)
            }
        }, {
            category: "13",
            value: 100,
            columnSettings: {
                fill: am5.color(0xc6251a)
            }
        }, {
            category: "12",
            value: 100,
            columnSettings: {
                fill: am5.color(0xc6251a)
            }
        }, {
            category: "11",
            value: 100,
            columnSettings: {
                fill: am5.color(0xc6251a)
            }
        }, {
            category: "10",
            value: 100,
            columnSettings: {
                fill: am5.color(0xfcc034)
            }
        }, {
            category: "9",
            value: 100,
            columnSettings: {
                fill: am5.color(0xfcc034)
            }
        }, {
            category: "8",
            value: 100,
            columnSettings: {
                fill: am5.color(0xfcc034)
            }
        }, {
            category: "7",
            value: 100,
            columnSettings: {
                fill: am5.color(0xfcc034)
            }
        }, {
            category: "6",
            value: 100,
            columnSettings: {
                fill: am5.color(0xfcc034)
            }
        }, {
            category: "5",
            value: 100,
            columnSettings: {
                fill: am5.color(0x6bc352)
            }
        }, {
            category: "4",
            value: 100,
            columnSettings: {
                fill: am5.color(0x6bc352)
            }
        }, {
            category: "3",
            value: 100,
            currentBullet: true,
            columnSettings: {
                fill: am5.color(0x6bc352)
            }
        }, {
            category: "2",
            value: 100,
            columnSettings: {
                fill: am5.color(0x6bc352)
            }
        }, {
            category: "1",
            value: 100,
            columnSettings: {
                fill: am5.color(0x6bc352)
            }
        }, {
            category: "0",
            value: 100,
            targetBullet: true,
            columnSettings: {
                fill: am5.color(0x6bc352)
            }
        }];

        var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(carbonRoot, {
            categoryField: "category",
            renderer: am5xy.AxisRendererX.new(carbonRoot, {

            }),
            tooltip: am5.Tooltip.new(carbonRoot, {})
        }));

        var xRenderer = xAxis.get("renderer");

        xRenderer.grid.template.set("forceHidden", true);
        xRenderer.labels.template.set("forceHidden", true);

        xAxis.data.setAll(data);

        var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(carbonRoot, {
            min: 0,
            max: 400,
            strictMinMax: true,
            renderer: am5xy.AxisRendererY.new(carbonRoot, {})
        }));

        var yRenderer = yAxis.get("renderer");

        yRenderer.grid.template.set("forceHidden", true);
        yRenderer.labels.template.set("forceHidden", true);

        var series = chart.series.push(am5xy.ColumnSeries.new(carbonRoot, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            categoryXField: "category",
            maskBullets: false
        }));

        series.columns.template.setAll({
            //tooltipText: "{categoryX}: {valueY}",
            width: am5.p100,
            tooltipY: 0,
            strokeOpacity: 0,
            strokeWidth: 0,
            stroke: am5.color(0xffffff),
            templateField: "columnSettings"
        });

        series.bullets.push(function (carbonRoot, target, dataItem) {
            if (dataItem.dataContext.currentBullet) {
                var container = am5.Container.new(carbonRoot, {});

                if(marker){
                    var pin = container.children.push(am5.Graphics.new(carbonRoot, {
                        fill: dataItem.dataContext.columnSettings.fill,
                        dy: -2,
                        centerY: am5.p100,
                        centerX: am5.p50,
                        svgPath: "M6.71,9.61a.84.84,0,0,1-1.42,0L2.7,5.39.11,1.16A.78.78,0,0,1,.82,0H11.18a.78.78,0,0,1,.71,1.16L9.3,5.39Z",
                        
                    }));
                }
                

                return am5.Bullet.new(carbonRoot, {
                    locationY: 1,
                    sprite: container
                });
            }
            else if (dataItem.dataContext.targetBullet) {
                var container = am5.Container.new(carbonRoot, {
                    dx: 15
                });

                return am5.Bullet.new(carbonRoot, {
                    locationY: 0,
                    sprite: container
                });
            }
            return false;
        });

        series.data.setAll(data);

        function addAxisLabel(category, text) {
            var rangeDataItem = xAxis.makeDataItem({
                category: category
            });

            var range = xAxis.createAxisRange(rangeDataItem);

            range.get("label").setAll({
                //fill: am5.color(0xffffff),
                text: text,
                forceHidden: false
            });

            range.get("grid").setAll({
                //stroke: color,
                strokeOpacity: 1,
                location: 1
            });
        }

        // addAxisLabel("15", "20+");
        // addAxisLabel("10", "10");
        // addAxisLabel("5", "5");


        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        series.appear(1000, 100);
        chart.appear(1000, 100);

        setChartRoot(carbonRoot);

        return () => {
            // Cleanup when unmounting the component
            if (chartRoot) {
                chartRoot.dispose();
            }

        };
    }, [data]);

    return <div id={id} style={{ width: '100%', height: height }}></div>;
};