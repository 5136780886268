import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { SourceCommonCompanyListComponent } from "./Common/companyListView";
import SearchContext from "../../services/context/Search/searchContext";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";
import listViewLoader from "./../../assets/img/loading-animation.gif";
import { SourcingContext } from "../../services/context/sourcingContext";

export const SourcePinnedCompanyComponent = () => {

    window.scrollTo(0, 0);
    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const { sourcingCompanyList } = useContext(SourcingContext)
    const location = useLocation()

    useEffect(() => {
        setBreadcrumbMenu(
            [
                { slug: '', menuName: 'Sourcing', isActive: true, hasSubMenu: true },
                { slug: '/sourcing/all-company', menuName: 'All Companies', isActive: false, hasSubMenu: true }
            ]
        )
    }, [location])

    const [companyList, setCompanyList] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        setIsLoaded(sourcingCompanyList.length > 0)
        if (sourcingCompanyList && sourcingCompanyList.length) {
            setCompanyList([...sourcingCompanyList])
        }
    }, [sourcingCompanyList])

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <section className="mb-3">
                    <div className="row">
                        <div className="col-xl-6 col-md-6 col-12">
                            <h6>All Companies</h6>
                        </div>
                        <div className="col-xl-6 col-md-6 col-12 text-right align-self-center">
                            <button className="btn btn-primary btn-sm mr-2 mb-0">
                                <i className="fa fa-fw fa-exchange" aria-hidden="true" title="Compare"></i>
                            </button>
                            <button className="btn btn-primary btn-sm mr-2 mb-0">
                                <i className="fa fa-fw fa-download" aria-hidden="true" title="Download"></i>
                            </button>
                            <button className="btn btn-primary btn-sm mr-2 mb-0">
                                <i className="fa fa-fw fa-thumb-tack" aria-hidden="true"></i>
                            </button>
                            <button className="btn btn-primary btn-sm mr-2 mb-0">
                                <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </section>
                <section className="mb-3">
                    <div className="row">
                        <div className="col-12">
                            {
                                !isLoaded ? (
                                    <div className="col text-center">
                                        <img className="bl-list-loader" src={listViewLoader} />
                                    </div>
                                ) :
                                    companyList.length ? <SourceCommonCompanyListComponent list={companyList}></SourceCommonCompanyListComponent> : null
                            }
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}