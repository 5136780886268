import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import recomendedDataSourceJson from "../../data/Risk/dataSources_recommended.json";
import allDataSourceJson from "../../data/Risk/dataSources_all.json";
import { RecommendedSourceComponent } from "../Common/recommendedSource";
import { AllDataSourcesComponent } from "../Common/allDataSources";
import sphereMenuJson from "../../data/Spheres/subMenu.json";
import SearchContext from "../../services/context/Search/searchContext";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";


export const DataSphereCategoryComponent = ({ spherePageLoader }) => {

    window.scrollTo(0, 0);
    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const location = useLocation()

    useEffect(() => {
        const slug = getLocationSlug();
        if (slug === 'all-source') {
            setSourceType('ALL')
            setSourceTitle('All Data Sources')
        }
        else {
            setSourceType('RECOMMENDED')
            setSourceTitle('')
        }
        const category = sphereMenuJson[0].list.find(app => app.slug === slug);
        setBreadcrumbMenu(
            [
                { slug: '', menuName: 'Data Sphere', isActive: true, hasSubMenu: true },
                { slug: `/data-sphere/${slug}`, menuName: category.name, isActive: false, hasSubMenu: true }
            ]
        )
    }, [location])

    const [sourceType, setSourceType] = useState('RECOMMENDED')
    const [sourceTitle, setSourceTitle] = useState('')

    const getLocationSlug = () => {
        let pathArray = location.pathname.split('/');
        if (pathArray && pathArray.length > 2) {
            return pathArray[2];
        }
    }

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <div className="row">
                    <div className="col">
                        <section className="mb-3">
                            <h6>{sourceTitle}</h6>
                        </section>
                        <section className="mb-3">
                            {
                                sourceType === 'RECOMMENDED' ?
                                    <RecommendedSourceComponent recommendedDataSource={recomendedDataSourceJson}></RecommendedSourceComponent>
                                    : <AllDataSourcesComponent dataSources={allDataSourceJson}></AllDataSourcesComponent>
                            }
                        </section>

                    </div>
                </div>
            </div>
        </div>
    )
}