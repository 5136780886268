import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const BreadcrumbComponent = (props) => {

    const [menu, setMenu] = useState(props.item);
    const nav = useNavigate();

    useEffect(() => {
        if (props.item) {
            setMenu(props.item)
        }
    }, [props])

    const navigateToScreen = (path, displayName, indx) => {
        if (path) {
            if(indx === 0){
                nav(path, {state:{displayName:displayName}})
            }
            else {
                nav(path, {state:{displayName:displayName, parentName:menu[indx-1].menuName}})
            }
        }
    }
    
    return (
        <h1 className="h6 mb-0 text-gray-800">
            <div className="breadcrumb">
                {
                    menu.map((m, i) => {
                        return (
                            <li className={m.isActive ? 'breadcrumb-item active' : 'breadcrumb-item'} key={`breadcrum-${i+1}`}>
                                {
                                    m.isActive ? m.menuName : <a className="active-crumb text-black" onClick={() => navigateToScreen(m.slug, m.menuName, i)}>{m.menuName}</a>
                                }
                            </li>
                        )
                    })
                }
            </div>
        </h1>
    )
}
export default BreadcrumbComponent;