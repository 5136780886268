import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getAPIStatus } from "../../services/authService";
import logoIcon from "./../../assets/icons/Logo_Colored.png";
import Sourcing_Icon from "./../../assets/icons/app/Sourcing_Icon.png";
import kairosIcon from "./../../assets/icons/app/Kairos_B_Icon.svg";
import Risk_Icon from "./../../assets/icons/app/Risk_Icon.png";
import appsListJson from "./../../data/Apps/appList.json";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";


export const MenuComponent = () => {

    const { breadcrumbMenu } = useContext(BreadcrumbContext)

    const openSubMenu = () => {
        let isMobile = navigator.userAgent.match(/(iPhone)|(iPod)|(android)|(webOS)/i);
        let element1 = document.getElementById("vistaHeader");
        let element2 = document.getElementById("content-wrapper");
        let element3 = document.getElementById("submenucontent");
        if (isMobile) {
            if (element1 && !element1.classList.contains('collapsed-header-margin-sub-menu')) {
                element1.classList.add("mob-collapsed-header-margin-sub-menu");
            }
            if (element2 && !element2.classList.contains('collapsed-content-margin-sub-menu')) {
                element2.classList.add("mob-collapsed-content-margin-sub-menu");
            }
            if (element3 && !element3.classList.contains('mob-sub-menu')) {
                element3.classList.add('mob-sub-menu');
            }
        }
        else {
            if (element1 && !element1.classList.contains('collapsed-header-margin-sub-menu')) {
                element1.classList.add("collapsed-header-margin-sub-menu");
            }

            if (element2 && !element2.classList.contains('collapsed-content-margin-sub-menu')) {
                element2.classList.add("collapsed-content-margin-sub-menu");
            }
        }
        if (element3 && element3.classList.contains('close')) {
            element3.classList.remove("close")
        }
    }

    const closeSubMenu = () => {
        let isMobile = navigator.userAgent.match(/(iPhone)|(iPod)|(android)|(webOS)/i);
        let element1 = document.getElementById("vistaHeader");
        let element2 = document.getElementById("content-wrapper");
        let element3 = document.getElementById("submenucontent");
        if (isMobile) {
            if (element1 && element1.classList.contains('collapsed-header-margin-sub-menu')) {
                element1.classList.remove("mob-collapsed-header-margin-sub-menu");
            }
            if (element2 && element2.classList.contains('collapsed-content-margin-sub-menu')) {
                element2.classList.remove("mob-collapsed-content-margin-sub-menu");
            }
            if (element3 && element3.classList.contains('mob-sub-menu')) {
                element3.classList.remove('mob-sub-menu');
            }
        }
        else {
            if (element1 && element1.classList.contains('collapsed-header-margin-sub-menu')) {
                element1.classList.remove("collapsed-header-margin-sub-menu");
            }

            if (element2 && element2.classList.contains('collapsed-content-margin-sub-menu')) {
                element2.classList.remove("collapsed-content-margin-sub-menu");
            }
        }
        if (element3 && !element3.classList.contains('close')) {
            element3.classList.add("close")
        }
    }

    const nav = useNavigate();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState('dashboard')
    const [appList, setAppList] = useState([])

    useEffect(() => {
        mainPageContent();
    }, [location])

    useEffect(() => {
        let pinnedApp = appsListJson.filter(app => app.isPinned);
        setAppList(pinnedApp)
    }, [appsListJson])

    useEffect(() => {
        if (breadcrumbMenu && breadcrumbMenu.length) {
            if (breadcrumbMenu[0].hasSubMenu) {
                openSubMenu();
            }
            else {
                closeSubMenu()
            }

        }
    }, [breadcrumbMenu])


    useEffect(() => {

    }, [activeTab])


    const mainPageContent = () => {
        let pathArray = location.pathname.split('/');
        if (pathArray && pathArray.length > 1) {
            setActiveTab(pathArray[1])
        }
    }

    useEffect(() => {
        getStatus()
    }, [])

    const getStatus = async () => {
        // let stat = await getAPIStatus();
    }

    return (
        <ul className="navbar-nav navbar-light sidebar accordion fixed-top toggled mainmenu" id="accordionSidebar" >

            <button className="sidebar-brand d-flex align-items-center justify-content-center cp">
                <div className="sidebar-brand-icon">
                    <img src={logoIcon} height="40px" alt="logo" />
                </div>
            </button>

            <hr className="sidebar-divider" />

            <li className={activeTab === 'dashboard' ? 'nav-item active' : 'nav-item'}>
                <button className="nav-link" onClick={() => { nav('/dashboard'); setActiveTab('dashboard') }}>
                    <div className="active-menu">
                        <i className="fa fa-1x fa-home"></i>
                    </div>
                    <span>Home</span>
                </button>
            </li>
            <li className={activeTab === 'tasks' ? 'nav-item active' : 'nav-item'}>
                <button className="nav-link" onClick={() => { nav('/tasks/overview'); setActiveTab('tasks') }}>
                    <div className="active-menu">
                        <i className="fa fa-list"></i>
                    </div>
                    <span>Tasks</span>
                </button>
            </li>
            <li className={activeTab === 'analytics' ? 'nav-item active' : 'nav-item'}>
                <button className="nav-link" onClick={() => { nav('/analytics/overview'); setActiveTab('analytics') }}>
                    <div className="active-menu">
                        <i className="fa fa-pie-chart" aria-hidden="true"></i>
                    </div>
                    <span>Analytics</span>
                </button>
            </li>


            <li className={activeTab === 'apps' ? 'nav-item active' : 'nav-item'}>
                <button className="nav-link" onClick={() => { nav('/apps/overview'); setActiveTab('apps') }}>
                    <div className="active-menu">
                        <i className="fa fa-th-large" aria-hidden="true"></i>
                    </div>
                    <span>Apps</span>
                </button>
            </li>
            <li className={activeTab === 'risk' ? 'nav-item active' : 'nav-item'}>
                <button className="nav-link" onClick={() => { nav('/risk/overview'); setActiveTab('risk') }}>
                    <span className="active-menu">
                        <img className="img-fluid" src={Risk_Icon} alt="risk icon" />
                    </span>
                    <span>Risk<br />Monitor</span>
                </button>
            </li>
            <li className={activeTab === 'sourcing' ? 'nav-item active' : 'nav-item'}>
                <button className="nav-link" onClick={() => { nav('/sourcing/overview'); setActiveTab('sourcing') }}>
                    <span className="active-menu">
                        <img className="img-fluid" src={Sourcing_Icon} alt="sourcing icon" />
                    </span>
                    <span>Sourcing</span>
                </button>
            </li>
            <li className={activeTab === 'kairos' ? 'nav-item active' : 'nav-item'}>
                <button className="nav-link" onClick={() => { nav('/kairos'); setActiveTab('kairos') }}>
                    <span className="active-menu">
                        <img className="img-fluid" src={kairosIcon} alt="Kairos icon" />
                    </span>
                    <span>Kairos</span>
                </button>
            </li>
            {
                appList.length > 0 ? (
                    <li className={'nav-item topbar bl-h-1-25rem'}>
                        <button type="button" className="nav-link dropdown-toggle" title="More Pinned Apps" id="applisttoggledropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <div className="active-menu">
                                {/* <img className="img-fluid" src={Apps_Icon} alt="apps icon" /> */}
                                <span><i className="fa fa-ellipsis-h" aria-hidden="true"></i></span>
                            </div>
                        </button>
                        <div className="dropdown-list dropdown-menu shadow animated--grow-in dropright left-100" aria-labelledby="applisttoggledropdown">
                            <h6 className="dropdown-header">
                                Pinned Apps
                            </h6>
                            <div className="row p-3 bg-transparent">
                                {
                                    appList.map((app, i) => {
                                        return (
                                            <div key={`dropdown-app-list-${i}`} className="col-6 mb-3">
                                                <table width="100%" border="0" cellPadding="0">
                                                    <tbody>
                                                        <tr>
                                                            <td width="35%"><img className="rounded-circle" src={app.icon} alt="" height="35px" width="35px" /></td>
                                                            <td className="font-small text-black">{app.title}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            {/* <button className="dropdown-item text-center small text-gray-500">View All Apps</button> */}
                        </div>
                    </li>
                ) : null
            }


            <hr className="sidebar-divider" />

            <li className={activeTab === 'workflow' ? 'nav-item active' : 'nav-item'}>
                <button className="nav-link" onClick={() => { nav('/workflow/overview'); setActiveTab('workflow') }}>
                    <div className="active-menu">
                        <i className="fa fa-share-alt" aria-hidden="true"></i>
                    </div>
                    <span>Workflows</span>
                </button>
            </li>
            <li className={activeTab === 'rules' ? 'nav-item active' : 'nav-item'}>
                <button className="nav-link" onClick={() => { nav('/rules/overview'); setActiveTab('rules') }}>
                    <div className="active-menu">
                        <i className="fa fa-align-left" aria-hidden="true"></i>
                    </div>
                    <span>Rules</span>
                </button>
            </li>
            <li className={activeTab === 'ai-model' ? 'nav-item active' : 'nav-item'}>
                <button className="nav-link" onClick={() => { nav('/ai-model/overview'); setActiveTab('ai-model') }}>
                    <div className="active-menu">
                        <i className="fa fa-microchip" aria-hidden="true"></i>
                    </div>
                    <span>AI Models</span>
                </button>
            </li>
            <li className={activeTab === 'toolkit' ? 'nav-item active' : 'nav-item'}>
                <button className="nav-link" onClick={() => { nav('/toolkit/overview'); setActiveTab('toolkit') }}>
                    <div className="active-menu">
                        <i className="fa fa-tasks" aria-hidden="true"></i>
                    </div>
                    <span>Analytics Toolkit</span>
                </button>
            </li>
            <li className={activeTab === 'master-data' ? 'nav-item active' : 'nav-item'}>
                <button className="nav-link" onClick={() => { nav('/master-data/companies'); setActiveTab('master-data') }}>
                    <div className="active-menu">
                        <i className="fa fa-database" aria-hidden="true"></i>
                    </div>
                    <span>MDM</span>
                </button>
            </li>
            <li className={activeTab === 'data-sphere' ? 'nav-item active' : 'nav-item'}>
                <button className="nav-link" onClick={() => { nav('/data-sphere/recommended-source'); setActiveTab('data-sphere') }}>
                    <div className="active-menu">
                        <i className="fa fa-plug" aria-hidden="true"></i>
                    </div>
                    <span>Data Spheres</span>
                </button>
            </li>
        </ul>
    )
}