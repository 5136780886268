import React, { useEffect, useRef, useState } from "react";
import { BoldPieChart } from "../../Graph/boldPieChart";
import { fileUploadService, getUploadedFilesService } from "../../../services/commonService";
import moment from "moment";
import { addCompanyLinkService, addDealbookService, companyFileUploadService, getAllDatasourceService, getDealbookListService, removeCompanyService, updateCompanyDatasourceService, updateDataSourceService, updateDocumentUploadService } from "../../../services/companyService";
import { useLocation, useNavigate } from "react-router-dom";
import pdfFileIcon from "./../../../assets/img/pdf.png"
import missingImg from "./../../../assets/img/logoplaceholder.png";

export const SourceCommonCompanyTitle = ({ item, onChildInfoUpdate, displaySource = true, buttons, isDealProject = false, companyInformation = true }) => {

  const nav = useNavigate();
  const location = useLocation();
  const [companyInfo, setCompanyInfo] = useState({
    companyId: '', company: '', logo: '', website: '', description: '', address: '', employeeRange: '', fundingRound: '', valuation: '', fundingAmount: '',
    totalEmployee: '', acquisitions: '', investors: '', investments: '', exits: '', insights: '', BlScore: '', categoryStatus: [],
    sourcingStatus: [], isNew: false, socialNetworkUrl: [], isDataSourceAdded: false, isDocumentsAdded: false
  })
  const [companySetupPercent, setCompanySetupPercent] = useState('40')
  const [dataSource, setDatasource] = useState([])
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [selectedFile, setSelectedFile] = useState({ name: '' })
  const [actionButtons, setActionButtons] = useState([])
  const [dealbookList, setDealbookList] = useState([])
  const [selectedFileId, setSelectedFileId] = useState(null)
  const [dealProjectName, setDealProjectName] = useState(null)
  const [companyLink, setCompanyLink] = useState('')
  const [companyTags, setCompanyTags] = useState('')
  const [userTags] = useState(['Products', 'Services', 'Competitors', 'End Suppliers', 'Housing Conditions', 'Product Materials', 'News', 'Stock Prices', 'Key People'])
  const [showLinkLoader, setShowLinkLoader] = useState(false)

  useEffect(() => {
    return () => {
      let tooltip = document.getElementsByClassName('tooltip show');
      if (tooltip && tooltip.length) {
        tooltip[0].classList.remove('show')
      }
      window.$('#defaulttooltip').tooltip('hide')
    }
  }, [])

  useEffect(() => {
    setShowLinkLoader(false)
    setCompanyLink('')
    setCompanyTags('')
    getDealbookList()
    if (buttons && buttons.length) {
      setActionButtons(buttons)
    }
    else {
      const acButton = [
        { name: "Push to CRM", icon: "plug" },
        { name: "Compare", icon: "exchange" },
        { name: "Download", icon: "download" },
        { name: "", icon: "thumb-tack" },
        { name: "", icon: "trash", action: true }
      ]
      setActionButtons(acButton)
    }
  }, [location])

  useEffect(() => {
    if (companyInfo.companyId) {
      getAllDatasource()
    }

    if (companyInfo.isDataSourceAdded && companyInfo.isDocumentsAdded) {
      setCompanySetupPercent('95')
    }
    else if (companyInfo.isDataSourceAdded || companyInfo.isDocumentsAdded) {
      setCompanySetupPercent('75')
    }
    else {
      setCompanySetupPercent('45')
    }
    setTimeout(() => {
      window.$('[data-toggle="tooltip"]').tooltip();
      window.$('#defaulttooltip').tooltip('show');
    }, 900)

  }, [companyInfo]);


  useEffect(() => {
    setCompanyInfo(item)
    getUploadedFiles()
  }, [item])

  const getDealbookList = async () => {
    const resp = await getDealbookListService();
    if (resp && resp.success) {
      setDealbookList(resp.data)
    }
  }

  const getAllDatasource = async () => {
    const resp = await getAllDatasourceService(companyInfo.companyId);
    if (resp && resp.success) {
      let list = resp.data.map(s => {
        let isEnabled = !!s.mappingId;
        if (s.name === 'Google News' || s.name === 'LinkedIn') {
          isEnabled = true;
        }
        s.isEnabled = isEnabled;
        return s;
      })
      setDatasource(list)
    }
  }

  const changeSourceStatus = (id) => {
    setDatasource(((prevSourcelist) =>
      prevSourcelist.map((item) =>
        item.id === id ? { ...item, isEnabled: !item.isEnabled } : item
      )
    ))
  }

  const updateCompanyDatasource = async (e) => {
    e.preventDefault();
    let sourceItems = dataSource.map(s => {
      return {
        id: s.id,
        status: s.isEnabled ? 1 : 0
      }
    })
    let obj = {
      companyId: companyInfo.companyId,
      sourcingList: sourceItems
    }
    document.getElementById('pageLoaderContainer').style.display = 'flex';
    const resp = await updateCompanyDatasourceService(obj);
    if (resp && resp.success) {
      updateDataSources()
      getAllDatasource()
    }
  }

  const updateDataSources = async () => {
    document.getElementById('pageLoaderContainer').style.display = 'flex';
    let obj = {
      key: 1,
      companyId: companyInfo.companyId
    }
    const resp = await updateDataSourceService(obj)
    window.$('#datasourceModal').modal('toggle')
    if (resp && resp.success) {
      onChildInfoUpdate()
    }
    document.getElementById('pageLoaderContainer').style.display = 'none';
  }

  const updateDocumentUploads = async () => {
    let obj = {
      key: 1,
      companyId: companyInfo.companyId
    }
    const resp = await updateDocumentUploadService(obj)
    if (resp && resp.success) {
      onChildInfoUpdate()
    }
  }

  const getUploadedFiles = async () => {
    let obj = {
      page: 1,
      pageSize: 5
    }
    const resp = await getUploadedFilesService(obj);
    if (resp && resp.success) {
      if (resp.data.questions) {
        setUploadedFiles(resp.data.questions)

      }
    }
  }

  const getCompanyStatusBackground = (stage) => {
    let bgClass = "bl-default-status", bgStripedClass = "progress-bar-striped progress-bar-animated";
    if (companyInfo.categoryStatus && companyInfo.categoryStatus.length >= stage) {
      if (stage === 1) {
        bgClass = 'bl-prospects';
      }
      else if (stage === 2) {
        bgClass = 'bl-due-diligence';
      }
      else if (stage === 3) {
        bgClass = 'bl-negotiation';
      }
      else {
        bgClass = 'bl-closed';
      }
      if (companyInfo.categoryStatus && companyInfo.categoryStatus.length === stage) {
        bgClass = `${bgClass} ${bgStripedClass}`
      }
    }
    else {
      bgClass = 'bl-default-status';
    }
    return bgClass;
  }

  const getTooltipId = (stage) => {
    if (companyInfo.categoryStatus && companyInfo.categoryStatus.length === stage) {
      return 'defaulttooltip';
    }
    else {
      return `tooltip${stage}`
    }
  }

  const getRandomCompletedDate = () => {
    // Define a start date (e.g., January 1, 2022)
    const startDate = new Date('2022-01-01').getTime();

    // Define an end date (e.g., September 1, 2023)
    const endDate = new Date('2023-09-01').getTime();

    // Calculate a random timestamp within the range
    const randomTimestamp = startDate + Math.random() * (endDate - startDate);

    const randomDate = new Date(randomTimestamp);
    const options = { year: 'numeric', month: 'short', day: '2-digit' };
    return randomDate.toLocaleDateString('en-US', options);
  }

  const getTooltipTitle = (stage) => {
    let titleDate = "", preText = "Completed";
    if (companyInfo.categoryStatus && companyInfo.categoryStatus.length > stage) {
      titleDate = ` on ${getRandomCompletedDate()}`
    }
    else if (companyInfo.categoryStatus && companyInfo.categoryStatus.length === stage) {
      preText = "Current Stage"
    }
    if (companyInfo.categoryStatus && companyInfo.categoryStatus.length >= stage) {
      return `<em>${preText}: </em><b>${companyInfo.categoryStatus[stage - 1].statusName}${titleDate}</b>`
    }
    else return ''
  }

  const getStageName = () => {
    let name = companyInfo.categoryStatus && companyInfo.categoryStatus[companyInfo.categoryStatus.length - 1];
    return name && name.statusName ? name.statusName : '';
  }

  const matchMedia = (media) => {
    if (companyInfo.socialNetworkUrl && companyInfo.socialNetworkUrl.length) {
      const escapedWord = media.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const pattern = new RegExp(`\\b${escapedWord}\\b`, 'i');
      const matchingURLs = companyInfo.socialNetworkUrl.find(url => pattern.test(url.url));
      return matchingURLs;
    }
  }

  const getSocialMediaLink = (mediaType) => {
    let mediaInfo = { url: '' };
    switch (mediaType) {
      case 'TWITTER':
        mediaInfo = matchMedia('twitter')
        break;
      case 'FACEBOOK':
        mediaInfo = matchMedia('facebook')
        break;
      case 'LINKEDIN':
        mediaInfo = matchMedia('linkedin')
        break;
      case 'INSTAGRAM':
        mediaInfo = matchMedia('instagram')
        break;
      case 'YOUTUBE':
        mediaInfo = matchMedia('youtube')
        break;
      default:
        mediaInfo = { url: '' }
    }
    return mediaInfo && mediaInfo.url
  }

  const addDataSourceModalContent = () => {
    return (
      <div className="modal fade" id="datasourceModal" tabIndex="-1" role="dialog" aria-labelledby="datasourceModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <form>
              <div className="modal-header">
                <h5 className="modal-title" id="datasourceModalLabel">Add Data Sources</h5>
                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-row">
                  <div className="col-md-12">
                    <div className="d-flex">
                      <input type="text" id="companysite" className="form-control" placeholder="Search..." />
                    </div>
                  </div>
                  {
                    dataSource.map((source, i) => {
                      return (
                        <div key={`data-source-${i}`} className="mt-3 w-100">
                          <div className="col">
                            <div className="d-flex justify-content-between mb-2">
                              <div className="d-flex align-self-center">
                                <img className="mr-2 rounded align-self-center" src={source.imageUrl} height="40px" alt="Generic placeholder image" />
                                <div>
                                  <h6 className="mb-0 align-self-center">{source.name}</h6>
                                  <p className="mb-0 align-self-center font-small text-muted bl-truncate-line-2">{source.description}</p>
                                </div>
                              </div>
                              <div className="align-self-center">
                                <label className="bl-switch mb-0">
                                  <input type="checkbox" checked={source.isEnabled} onChange={() => changeSourceStatus(source.id)} />
                                  <span className="bl-slider round"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
              <div className="modal-footer p-2">
                <button className="btn btn-outline-primary secondary-link btn-sm" type="button" data-dismiss="modal">Cancel</button>
                <button type="submit" className="btn btn-primary btn-sm" onClick={updateCompanyDatasource}>Save & Update</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }

  const fileInputRef = useRef(null);

  const triggerFileInput = (e) => {
    // Trigger the click event on the file input
    e.preventDefault();
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const selectedDocFile = event.target.files[0];
    if (selectedDocFile) {
      if (selectedDocFile.name.endsWith('.xlsx') || selectedDocFile.name.endsWith('.xls')) {
        setSelectedFile(selectedDocFile)
      } else {
        fileInputRef.current.value = '';
        alert('Please select a valid Excel file.');
      }
    }
  };

  const uploadFilesToServer = async (e) => {
    e.preventDefault();
    if (isDealProject) {
      addDealbook()
    }
    else {
      document.getElementById('pageLoaderContainer').style.display = 'flex';
      if (selectedFile) {
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('id', companyInfo.companyId)
        const resp = await companyFileUploadService(formData);
        if (resp && resp.success) {
          updateDocumentUploads()
          window.$('#uploaddocumentModal').modal('toggle')
        }
        document.getElementById('pageLoaderContainer').style.display = 'none';
      }
    }

  }

  const addDealbook = async () => {
    document.getElementById('pageLoaderContainer').style.display = 'flex';
    const obj = {
      id: selectedFileId.toString(),
      companyName: dealProjectName,
      companyId: companyInfo.companyId.toString()
    }
    const resp = await addDealbookService(obj);
    setTimeout(() => {
      if (resp && resp.success) {
        onChildInfoUpdate()
        window.$('#uploaddocumentModal').modal('toggle');
      }
      else {
        alert("Couldn't added deal info, Please try again!")
      }
      document.getElementById('pageLoaderContainer').style.display = 'none';
    }, 10000)

  }

  const uploadDocumentModalContent = () => {
    return (
      <div className="modal fade" id="uploaddocumentModal" tabIndex="-1" role="dialog" aria-labelledby="uploaddocumentModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <form>
              <div className="modal-header">
                <h5 className="modal-title" id="uploaddocumentModalLabel">Upload Documents</h5>
                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-row">
                  <div className="col-md-12">
                    <div className="d-flex">
                      <input type="text" value={selectedFile.name} readOnly className="form-control bg-transparent" placeholder="Upload files here" />
                      <input type="file" ref={fileInputRef} onChange={handleFileChange} className="d-none" accept=".xlsx, .xls" />
                      <button className="btn btn-danger btn-sm text-nowrap ml-2" onClick={triggerFileInput}>Upload Now</button>
                    </div>
                  </div>
                  {
                    isDealProject ? dealbookList.length && dealbookList.map((file, i) => {
                      return (
                        <div key={`data-source-${i}`} className="mt-3 w-100 pl-3">
                          <div className="col">
                            <div className="d-flex justify-content-between mb-2">
                              <div className="d-flex align-self-center">
                                <input className="form-check-input align-self-center" type="radio" name="dealbook" onChange={() => { setSelectedFileId(file.id); setDealProjectName(file.companyName) }} />
                                <img className="mr-2 rounded align-self-center" src={pdfFileIcon} height="20px" alt="Generic image" />
                                <div className="align-self-center">
                                  <h6 className="mb-0 align-self-center">{file.fileName}</h6>
                                  {/* <p className="mb-0 align-self-center font-small text-gray">uploaded on {moment(file.created_on).format('MMM DD, YYYY hh:mm a')}</p> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    }) : uploadedFiles.map((file, i) => {
                      return (
                        <div key={`data-source-${i}`} className="mt-3 w-100">
                          <div className="col">
                            <div className="d-flex justify-content-between mb-2">
                              <div className="d-flex align-self-center">
                                <img className="mr-2 rounded align-self-center" src="https://www.freeiconspng.com/thumbs/excel-icon/excel-icon-17.png" height="40px" alt="Generic image" />
                                <div>
                                  <h6 className="mb-0 align-self-center">{file.file_name}</h6>
                                  <p className="mb-0 align-self-center font-small text-gray">uploaded on {moment(file.created_on).format('MMM DD, YYYY hh:mm a')}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
              <div className="modal-footer p-2">
                <button className="btn btn-outline-primary secondary-link btn-sm" type="button" data-dismiss="modal">Cancel</button>
                <button className="btn btn-primary btn-sm" onClick={(e) => uploadFilesToServer(e)}>Save & Update</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }

  const deleteCompany = async (e) => {
    e.preventDefault();
    const resp = await removeCompanyService(companyInfo.companyId);
    if (resp && resp.success) {
      window.$("#deletecompanymodal").modal('toggle');
      alert("Company removed successfully!");
      nav('/sourcing/overview')
    }
  }

  const deleteCompanyModalContent = () => {
    return (
      <div className="modal fade" id="deletecompanymodal" tabIndex="-1" role="dialog" aria-labelledby="deleteCompanyModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <form>
              <div className="modal-header">
                <h5 className="modal-title" id="deleteCompanyModalLabel">Delete Company</h5>
                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                Are you sure, you want to delete <b>{companyInfo.company}</b>?
              </div>
              <div className="modal-footer p-2">
                <button className="btn btn-outline-primary secondary-link btn-sm" type="button" data-dismiss="modal">Cancel</button>
                <button className="btn btn-primary btn-sm" onClick={(e) => deleteCompany(e)}>Yes, Delete</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }

  const addCompanyLinkInfo = async () => {
    const tags = userTags.filter((tag, i) => document.getElementById(`tag-${i}`).checked)
    if (!companyLink.trim()) {
      return alert('Please enter a avalid URL!')
    }
    if (tags.length === 0) {
      return alert('Please select at-least one topic!')
    }
    setShowLinkLoader(true)
    const pageLoader = document.getElementById('pageLoaderContainer');
    if (pageLoader) {
      pageLoader.style.display = 'flex';
    }
    const obj = {
      url: companyLink.trim(),
      userTags: tags.join(','),
      companyId: companyInfo.companyId
    }
    if (pageLoader) {
      pageLoader.style.display = 'none';
    }
    window.$('#addCompanyLinkModal').modal('toggle');
    const resp = await addCompanyLinkService(obj)
    setTimeout(() => {
      setShowLinkLoader(false)
      if (resp && resp.success) {
        setCompanyLink('')
        setCompanyTags('')
        alert("Link added successfully!")
      }
      else {
        alert("Couldn't find company details, Please try again!")
      }
    }, 10000)

  }

  const addCompanyLinksContent = () => {
    return (
      <div className="modal fade" id="addCompanyLinkModal" tabIndex="-1" role="dialog" aria-labelledby="addCompanyLinkModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <form onSubmit={(e) => addCompanyLinkInfo(e)}>
              <div className="modal-header">
                <h5 className="modal-title" id="addCompanyLinkModalLabel">Add URL</h5>
                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <div className="row col-12 p-0 m-0">
                    <label htmlFor="companysite" className="col-2 col-form-label pl-0">URL</label>
                    <div className="col-10 pr-0">
                      <input type="text" id="companysite" className="form-control" value={companyLink} onChange={(e) => setCompanyLink(e.target.value)} placeholder="Enter a valid url" />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row col-12 p-0 m-0">
                    <label htmlFor="companytags" className="col-2 col-form-label pl-0">Track Topics</label>
                    <div className="col-10 pr-0">
                      {
                        userTags && userTags.length && userTags.map((tag, i) => {
                          return (
                            <div key={`user-tags-${i}`} className="form-check form-check-inline">
                              <input className="form-check-input" type="checkbox" id={`tag-${i}`} value={tag} defaultChecked={i === 0 || i === 1} />
                              <label className="form-check-label" htmlFor={`tag-${i}`}>{tag}</label>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer py-2">
                <button className="btn btn-outline-primary secondary-link btn-sm" type="button" data-dismiss="modal">Cancel</button>
                <button type="submit" className="btn btn-primary btn-sm">Proceed</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }

  const handleImageError = (e) => {
    e.target.src = missingImg;
  }

  return (
    <div>
      {
        companyInformation ? <div id="comptitle" className="d-sm-flex align-items-center justify-content-between mb-3">
          <table width="40%" border="0" cellPadding="0" cellSpacing="0">
            <tbody>
              <tr>
                <td width="10%">
                  <div className="card shadow-sm mr-3 bl-w-5rem">
                    <div className="card-body text-center p-2">
                      {
                        companyInfo.BlScore && <BoldPieChart height="50px" score={companyInfo.BlScore}></BoldPieChart>
                      }
                      <p className="card-text">BL Score</p>
                    </div>
                  </div>
                </td>
                <td>
                  <h5 className="mb-0">
                    <img className="img-fluid bl-h-1-4rem mr-2" src={companyInfo.logo ? companyInfo.logo : missingImg} onError={handleImageError} alt="company-logo" />
                    {companyInfo.external_data && companyInfo.external_data.companyName ? companyInfo.external_data.companyName : companyInfo.company ? companyInfo.company : ''}
                  </h5>
                  <p className="text-gray mb-0"><a href={companyInfo.website} target="_blank" className="font-small">{companyInfo.website}</a></p>
                  <div className="bl-social-links mb-1">
                    {
                      getSocialMediaLink('TWITTER') && <a className="twitter" href={getSocialMediaLink('TWITTER')} target="_blank"><i className="fa fa-fw fa-twitter"></i></a>
                    }
                    {
                      getSocialMediaLink('FACEBOOK') && <a className="facebook" href={getSocialMediaLink('FACEBOOK')} target="_blank"><i className="fa  fa-fw fa-facebook"></i></a>
                    }
                    {
                      getSocialMediaLink('INSTAGRAM') && <a className="instagram" href={getSocialMediaLink('INSTAGRAM')} target="_blank"><i className="fa  fa-fw fa-instagram"></i></a>
                    }
                    {
                      getSocialMediaLink('LINKEDIN') && <a className="linkedin" href={getSocialMediaLink('LINKEDIN')} target="_blank"><i className="fa  fa-fw fa-linkedin"></i></a>
                    }
                    {
                      getSocialMediaLink('YOUTUBE') && <a className="youtube" href={getSocialMediaLink('YOUTUBE')} target="_blank"><i className="fa  fa-fw fa-youtube"></i></a>
                    }
                  </div>
                  <div className="mt-2">
                    {
                      companyInfo.categoryStatus && companyInfo.categoryStatus.length === 0 ? (
                        <div className="progress">
                          <div className="progress-bar bg-success" role="progressbar" style={{ width: `${companySetupPercent}%` }} aria-valuenow={companySetupPercent} aria-valuemin="0" aria-valuemax="100">{companySetupPercent}% Completed</div>
                        </div>
                      ) : (
                        <table width="100%" border="0" cellPadding="0" cellSpacing="0">
                          <tbody>
                            <tr>
                              <td>
                                <div className="progress mr-2 bl-h-10px">
                                  <div className={`progress-bar ${getCompanyStatusBackground(1)} w-100`} id={getTooltipId(1)} role="progressbar" data-toggle="tooltip" data-placement="bottom" data-html="true" title={getTooltipTitle(1)} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                              </td>
                              <td>
                                <div className="progress mr-2 bl-h-10px">
                                  <div className={`progress-bar ${getCompanyStatusBackground(2)} w-100`} id={getTooltipId(2)} role="progressbar" data-toggle="tooltip" data-placement="bottom" data-html="true" title={getTooltipTitle(2)} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                              </td>
                              <td>
                                <div className="progress mr-2 bl-h-10px">
                                  <div className={`progress-bar ${getCompanyStatusBackground(3)} w-100`} id={getTooltipId(3)} role="progressbar" data-toggle="tooltip" data-placement="bottom" data-html="true" title={getTooltipTitle(3)} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                              </td>
                              <td>
                                <div className="progress mr-2 bl-h-10px">
                                  <div className={`progress-bar ${getCompanyStatusBackground(4)} w-100`} id={getTooltipId(4)} role="progressbar" data-toggle="tooltip" data-placement="bottom" data-html="true" title={getTooltipTitle(4)} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      )
                    }

                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="align-self-center">
            {
              actionButtons && actionButtons.map((butn, i) => {
                return butn.action ?
                  (
                    <button key={`action-button-${i}`} className="btn btn-primary btn-sm mr-2 mb-0" data-toggle="modal" data-target="#deletecompanymodal">
                      <i className={`fa fa-fw fa-${butn.icon}`} aria-hidden="true" title={butn.name}></i> {butn.name}
                    </button>
                  ) : (
                    <button key={`action-button-${i}`} className="btn btn-primary btn-sm mr-2 mb-0">
                      <i className={`fa fa-fw fa-${butn.icon}`} aria-hidden="true" title={butn.name}></i> {butn.name}
                    </button>
                  )
              })
            }
          </div>

        </div> : null
      }
      {
        displaySource && companyInfo.isNew && (
          <div className="row mb-4">
            <div className="col-8">
              <div className="card-deck font-small">
                <div className="card bl-informative-cards">
                  <div className="card-header d-flex justify-content-between border-0 pb-0 px-3">
                    <h6 className="alert-link">1. Add Data Sources</h6>
                    <i className="fa fa-times text-gray" aria-hidden="true"></i>
                  </div>
                  <div className="card-body py-0 px-3">
                    <p className="mb-0">Augment private data from CRM and other systems to enhance the company profile created below</p>
                  </div>
                  <div className="card-footer bg-transparent border-0 py-0 px-3">
                    <button className="btn btn-link text-danger px-0" data-toggle="modal" data-target="#datasourceModal"><b>Add Now</b></button>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header d-flex justify-content-between border-0 pb-0 px-3">
                    <h6 className="alert-link">2. Upload Document</h6>
                    <i className="fa fa-times text-gray" aria-hidden="true"></i>
                  </div>
                  <div className="card-body py-0 px-3">
                    <p className="mb-0">Enhance the Company profile by securely uploading private data from documents</p>
                  </div>
                  <div className="card-footer bg-transparent border-0 py-0 px-3">
                    <button className="btn btn-link text-danger px-0" data-toggle="modal" data-target="#uploaddocumentModal"><b>Upload Now</b></button>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header d-flex justify-content-between border-0 pb-0 px-3">
                    <h6 className="alert-link">3. Add URLs</h6>
                    <i className="fa fa-times text-gray" aria-hidden="true"></i>
                  </div>
                  <div className="card-body py-0 px-3">
                    {
                      showLinkLoader ? <div className="text-center">
                        <p className="mb-0 font-small"><span className="line-loader"></span> <br />
                          Connecting to additional datasources
                        </p>
                      </div> : <p className="mb-0">Add relevant external links to enhance the company profile</p>
                    }

                  </div>
                  <div className="card-footer bg-transparent border-0 py-0 px-3">
                    {
                      !showLinkLoader ? <button className="btn btn-link text-danger px-0" data-toggle="modal" data-target="#addCompanyLinkModal"><b>Add Links</b></button> : null
                    }

                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
      {
        addDataSourceModalContent()
      }
      {
        uploadDocumentModalContent()
      }
      {
        deleteCompanyModalContent()
      }
      {addCompanyLinksContent()}
    </div>
  )
}