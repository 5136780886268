import React, { useEffect, useState } from "react";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5 from '@amcharts/amcharts5';
import * as am5Percent from "@amcharts/amcharts5/percent";


export const FunnelChartComponent = ({ id = 'funnelchart', height = '100px' }) => {

    const [chartRoot, setChartRoot] = useState(null);

    useEffect(() => {
        if (chartRoot) {
            chartRoot.dispose();
        }

        let root = am5.Root.new(id);
        root.setThemes([am5themes_Animated.new(root)]);
        root._logo.dispose();

        let chart = root.container.children.push(am5Percent.SlicedChart.new(root, {
            layout: root.verticalLayout,
            paddingBottom:0
        }));

        let series = chart.series.push(am5Percent.FunnelSeries.new(root, {
            alignLabels: false,
            orientation: "vertical",
            valueField: "value",
            categoryField: "category",
            bottomRatio: 1
        }));

        series.get("colors").set("colors", [
            am5.color("#0B84A5"),
            am5.color("#FABD05"),
            am5.color("#6F4E7C")
        ])
        series.data.setAll([
            { value: 18, category: "One" },
            { value: 12, category: "Two" },
            { value: 4, category: "Three" }
        ]);

        series.links.template.setAll({
            height: 0
          });

        series.labels.template.set("visible", false);
        series.ticks.template.set("visible", false);
        chart.appear(1000, 100);
        setChartRoot(root);

        return () => {
            if (chartRoot) {
                chartRoot.dispose();
            }
        }

    }, [])

    return <div id={id} style={{ width: '100%', height: height }}></div>;
}