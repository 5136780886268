import React, { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { SourcingContext } from "../../services/context/sourcingContext";
import { getCompanyInformationService, getCompanyListService } from "../../services/companyService";
import { AnalyzeCompanyContext } from "../../services/context/analyzeCompanyContext";
import moment from "moment/moment";


export const SourcingComponent = () => {

    const { sourcingCompanyList, setSourcingCompanyList } = useContext(SourcingContext)
    const { analyzedCompanyInfo } = useContext(AnalyzeCompanyContext)
    const [retryCount, setRetryCount] = useState(0);
    const [intervalDelay, setIntervalDelay] = useState(5000);

    // Initial useEffect to get company list when analyzedCompanyInfo changes
    useEffect(() => {
        const fetchCompanyList = async () => {
            setSourcingCompanyList([]);
            await getCompanyList(1);
        };

        fetchCompanyList();
    }, [analyzedCompanyInfo]);

    // Function to get company list with pagination
    const getCompanyList = async (page) => {
        const limit = 10;
        const resp = await getCompanyListService(page, limit);
        getCompanyListResponse(page, resp, limit);
    };

    // Handle the response for fetching company list
    const getCompanyListResponse = (page, resp, limit) => {
        if (resp && resp.success) {
            const newCompanyList = resp.data;
            setSourcingCompanyList((companies) => [...companies, ...newCompanyList]);
            if (resp.data.length >= limit) {
                getCompanyList(page + 1);
            }
        }
    };

    // Function to fetch individual company info
    const fetchCompanyInfo = async (slug) => {
        const resp = await getCompanyInformationService(slug);
        if (resp && resp.success) {
            return resp.data[0];
        } else {
            return null;
        }
    };

    // Second useEffect to handle retries and update missing company info
    useEffect(() => {
        if (retryCount >= 5) {
            console.log("Max retry count reached. Stopping further retries.");
            return;
        }

        const fetchCompanyData = async () => {
            let allInfoAvailable = true;
            const updatedCompanyList = await Promise.all(
                sourcingCompanyList.map(async (item) => {
                    const compDate = item.updated_at ? item.updated_at : moment();
                    const diffInHours = moment().diff(moment(compDate), 'hours', true)
                    if (Math.abs(diffInHours) < 1 && item.source === 'company' && (!item.linkedinCompanyId || !item.external_data)) {
                        allInfoAvailable = false;
                        try {
                            const info = await fetchCompanyInfo(item.slug);
                            if (info) {
                                return { ...item, ...info, reachedMaxLimit: retryCount === 4 };
                            }
                            if (retryCount === 4) {
                                return { ...item, reachedMaxLimit: true };
                            }
                        } catch (error) {
                            console.error(`Error fetching info for ID: ${item.id}`, error);
                        }
                    }
                    return item;
                })
            );
            if(retryCount >= 4){
                setSourcingCompanyList([])
                setTimeout(() => {
                    setSourcingCompanyList(updatedCompanyList)
                }, 500)
            }
            else{
                setSourcingCompanyList(updatedCompanyList);
            }
            // Update state after processing all companies
            

            if (!allInfoAvailable) {
                setRetryCount((prev) => prev + 1);
                setIntervalDelay((prev) => prev + 3000);
            }
        };

        const intervalId = setInterval(() => {
            fetchCompanyData();
        }, intervalDelay);

        return () => clearInterval(intervalId);
    }, [retryCount, intervalDelay, sourcingCompanyList]);




    return (
        <Outlet />
    )
}