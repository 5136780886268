import React, { useContext, useEffect, useState } from "react";
import cnbc from "./../../assets/img/cnbc.png";
import news2 from "./../../assets/img/news2.png";
import { AuthContext } from "../../services/context/Auth/authContext";
import ask_question from "./../../assets/icons/app/AI_Icon.png";
import AIContext from "../../services/context/AIAssistance/aiContext";
import { CardDropdownContent } from "./cardDropdown";


export const RightPanelComponent = () => {

    const { isCanabisApp } = useContext(AuthContext)
    const { setAIModalType } = useContext(AIContext);

    const [usedApps, setUsedApps] = useState([])
    const [myFeeds, setMyFeeds] = useState([])
    const [feedType, setFeedType] = useState('ALL')

    const [canabisFeed] = useState([
        {
            "title": "Apothecarium Dispensary in Phillipsburg Opens Today as 13th NJ ATC",
            "description": "Discover the latest addition to Terrascend Apothecarium's product lineup – a premium cannabis collection curated for connoisseurs seeking exceptional quality.",
            "sourceName": "Reddit",
            "url": "https://www.reddit.com/r/NewJerseyMarijuana/comments/k067zx/apothecarium_dispensary_in_phillipsburg_opens/",
            "date": "2023-07-15",
            icon: "fa-reddit"
        },
        {
            "title": "TerrAscend Opens Largest Apothecarium on the East Coast",
            "description": "Terrascend Apothecarium announces the grand opening of two new dispensary locations, bringing high-quality cannabis products to new communities.",
            "sourceName": "Reddit",
            "url": "https://www.reddit.com/r/weedstocks/comments/n6vzi7/terrascend_opens_largest_apothecarium_on_the_east/",
            "date": "2023-07-10",
            icon: "fa-reddit"
        },
        {
            "title": "NJ Dispensary Strain Review: Gary Payton (Apothecarium, Maplewood NJ)",
            "description": "Terrascend Apothecarium introduces an educational initiative to empower customers with knowledge about cannabis, its benefits, and responsible usage.",
            "sourceName": "Reddit",
            "url": "https://www.reddit.com/r/NewJerseyMarijuana/comments/wd2c09/nj_dispensary_strain_review_gary_payton/",
            "date": "2023-07-05",
            icon: "fa-reddit"
        },
        {
            "title": "TerrAscend Opens New Apothecarium Dispensary in Capitola, California",
            "description": "Terrascend Apothecarium announces the grand opening of two new dispensary locations, bringing high-quality cannabis products to new communities.",
            "sourceName": "Reddit",
            "url": "https://www.reddit.com/r/weedstocks/comments/js77gi/terrascend_opens_new_apothecarium_dispensary_in/",
            "date": "2023-07-10",
            icon: "fa-reddit"
        },
        {
            "title": "TerrAscend Announces Closing of Previously Announced Transactions with The Apothecarium in California",
            "description": "TerrAscend has closed a series of transactions to acquire the California operations of the award-winning retail dispensary brand known as The Apothecarium for consideration comprised of US$36.8 million",
            "sourceName": "Google News",
            "url": "https://www.newswire.ca/news-releases/terrascend-announces-closing-of-previously-announced-transactions-with-the-apothecarium-in-california-858649515.html",
            "date": "2023-06-28",
            icon: "fa-google-plus-official"
        },
        {
            "title": "TerrAscend Establishes US Retail Footprint",
            "description": "ANNOUNCES SIGNING OF SECURITIES PURCHASE AGREEMENTS WITH THE APOTHECARIUM. All of The Apothecarium team members will continue in their...",
            "sourceName": "Google News",
            "url": "https://www.prnewswire.com/news-releases/terrascend-establishes-us-retail-footprint-300793041.html",
            "date": "2023-06-20",
            icon: "fa-google-plus-official"
        },
        {
            "title": "COOKIES AND TERRASCEND Partner To Bring Top-Shelf Genetics To The Garden State",
            "description": "Additionally, TerrAscend will debut “Cookies Corners” in all three Apothecarium dispensaries in New Jersey...",
            "sourceName": "Google News",
            "url": "https://www.businesswire.com/news/home/20210819005283/en/COOKIES-AND-TERRASCEND-Partner-To-Bring-Top-Shelf-Genetics-To-The-Garden-State",
            "date": "2023-06-15",
            icon: "fa-google-plus-official"
        },
        {
            "title": "TerrAscend and Wana Brands Debut Cannabis-Infused Gummies in New Jersey",
            "description": "CNW/ - TerrAscend Corp (CSE: TER) (OTCQX: TRSSF), a leading North American cannabis operator,...",
            "sourceName": "Google News",
            "url": "https://www.newswire.ca/news-releases/terrascend-and-wana-brands-debut-cannabis-infused-gummies-in-new-jersey-898306307.html",
            "date": "2023-06-28",
            icon: "fa-google-plus-official"
        },
        {
            "title": "Terrascend Apothecarium's New Product Line Wins Industry Innovation Award",
            "description": "The recently launched product line from Terrascend Apothecarium receives industry acclaim, winning the 'Innovation Excellence Award' for outstanding contributions to cannabis innovation.",
            "sourceName": "Google News",
            "url": "https://example.com/news/article5",
            "date": "2023-06-20",
            icon: "fa-google-plus-official"
        },
        {
            "title": "Terrascend Apothecarium Partners with Local Artists for Dispensary Art Installation",
            "description": "In a unique collaboration, Terrascend Apothecarium partners with local artists to bring vibrant and inspiring artworks to its dispensary spaces.",
            "sourceName": "Google News",
            "url": "https://example.com/news/article6",
            "date": "2023-06-15",
            icon: "fa-google-plus-official"
        }
    ]);

    useEffect(() => {
        const canabisApps = [
            {
                name: "Flowhub",
                icon: "fa-foursquare"
            },
            {
                name: "BioTrackTHC",
                icon: "fa-joomla"
            },
            {
                name: "LeafLink",
                icon: "fa-modx"
            },
            {
                name: "CannaReg",
                icon: "fa-houzz"
            },
            {
                name: "Springbig",
                icon: "fa-scribd"
            },
            {
                name: "Headset",
                icon: "fa-steam"
            },
            {
                name: "Green Bits",
                icon: "fa-vine"
            }
        ];
        const defaultApps = [
            {
                name: "Risk Monitor",
                icon: "fa-exclamation-triangle"
            },
            {
                name: "ESG",
                icon: "fa-etsy"
            },
            {
                name: "DealFlow",
                icon: "fa-superpowers"
            },
            {
                name: "Valuation",
                icon: "fa-snowflake-o"
            },
            {
                name: "Fundraising",
                icon: "fa-bank"
            },
            {
                name: "Sourcing",
                icon: "fa-scribd"
            },
            {
                name: "Analytics",
                icon: "fa-pie-chart"
            },
            {
                name: "Task",
                icon: "fa-list"
            }
        ]
        setUsedApps(isCanabisApp ? canabisApps : defaultApps)


        const defaultFeed = [
            {
                title: "Q3 GDP Growth Exceeds Expectations at 6.5%, Inflation Rate Remains Stable",
                date: "59 minutes ago",
                icon: "fa-user-circle"
            },
            {
                title: "Healthcare Sector Surges as FDA Approves New Drug",
                date: "2 hours ago",
                icon: "fa-user-circle"
            },
            {
                title: "SEC Announces Amendments to Fair Value Measurement Standards.",
                date: "3 hours ago",
                icon: "fa-user-circle"
            },
            {
                title: "USD Strengthens Against Major Currencies, Affecting International Valuations.",
                date: "5 hours ago",
                icon: "fa-user-circle"
            },
            {
                title: "Government Announces Tax Incentives for Renewable Energy Companies.",
                date: "8 hours ago",
                icon: "fa-user-circle"
            }
        ];
        setMyFeeds(isCanabisApp ? canabisFeed : defaultFeed)
    }, [isCanabisApp])

    const recentlyUsedAppGrid = () => {
        return (
            <div className="recent-app-grid">
                {
                    usedApps.map((app, i) => {
                        return (
                            <div key={`app-list-${i}`} className="grid-app">
                                <i className={`fa ${app.icon}`} aria-hidden="true"></i>
                                <p className="small mb-0 text-nowrap">{app.name}</p>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    const selectFeed = (val) => {
        setFeedType(val);
        if (isCanabisApp) {
            if (val !== 'ALL') {
                const filteredFeed = canabisFeed.filter(feed => feed.sourceName === val);
                setMyFeeds(filteredFeed)
            }
            else setMyFeeds(canabisFeed)
        }
    }

    return (
        <div className="row">
            <section className="col-12 bg-dark h-13rem overflow-y-auto recent-app">
                <div className="text-white">
                    <div className="pt-3 text-white">
                        <div className="col-12">
                            <p className="font-weight-600">Recently Used</p>
                        </div>
                        {
                            recentlyUsedAppGrid()
                        }

                    </div>
                </div>
            </section>
            <section className="bg-gray p-3">
                <div className="row p-2">
                    <div className="d-flex col-6">
                        <h5 className="h5 mb-0 text-black font-weight-600">My Feed</h5>
                        <div className="ml-2">
                            <CardDropdownContent cardId="myfeeddropdown" cardType={'REDDIT'} onChildCardEvent={(item) => setAIModalType(item)} />
                        </div>
                    </div>
                    <div className="col-6 text-right">
                        <div className="btn-group">
                            <select id="contentview" value={feedType} onChange={(e) => selectFeed(e.target.value)} className="form-control form-control-sm blc_button text-black">
                                <option value={'ALL'}>All Topics</option>
                                <option value={'Reddit'}>Reddit</option>
                                <option value={'Google News'}>Google News</option>
                            </select>
                        </div>
                    </div>

                </div>
                <div className="feed font-small">
                    {
                        myFeeds.map((feed, i) => {
                            return (
                                <div key={`my-feed-item-${i}`} className="post-item clearfix">
                                    <span><i className={`fa ${feed.icon}`} aria-hidden="true"></i></span>
                                    <p>{
                                        feed.url ? (<a href={feed.url} target="_blank">{feed.title}</a>) : feed.title
                                    }</p>
                                    <p className="small">{feed.date} {feed.sourceName ? `| ${feed.sourceName}` : ''}</p>
                                </div>
                            )
                        })
                    }
                </div>
                <div>
                    <div className="card mb-4 blc_card news-feed">
                        <div className="card-body">
                            <p className="small">CNBC <span>| Yesterday</span></p>
                            <div className="post-item clearfix">
                                <img src={isCanabisApp ? "https://scx1.b-cdn.net/csz/news/800a/2022/cannabidiol.jpg" : cnbc} alt="" />
                                <p><a href="www.google.com/news" target={'_blank'}>{isCanabisApp ? "New research explores the effects of cannabidiol on general health" : "Stock Market Hits New All-Time Highs Amid Positive Earnings Reports"}</a></p>
                                <p className="small text-gray">{isCanabisApp ? "Kathleen Felton" : "google.com/news"}</p>
                            </div>
                        </div>
                    </div>
                    <div className="card mb-4 blc_card news-feed">
                        <div className="card-body">
                            <p className="small">CNBC <span>| Today</span></p>
                            <div className="post-item clearfix">
                                <img src={isCanabisApp ? "https://i0.wp.com/wausaupilotandreview.com/wp-content/uploads/2023/09/pexels-photo-2178565.jpeg?resize=1536%2C1024&quality=89&ssl=1" : news2} alt="" />
                                <p><a href="www.google.com/news" target={'_blank'}>{isCanabisApp ? "Lawmakers Push FDA to Regulate Cannabidiol as Dietary Supplement" : "Tech Giant Acquires Start-up in Multi-Billion Dollar Deal"}</a></p>
                                <p className="small text-gray">{isCanabisApp ? "Market Watch" : "google.com/news"}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}