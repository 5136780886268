import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import missingImg from "./../../../assets/img/imagemissing.png";


export const RiskEventViewComponent = ({ eventList, companyInfo }) => {

    const nav = useNavigate();

    const getRiskNewsCategoryBg = (category) => {
        if ('Legal Risk'.search(category) !== -1) {
            return 'badge-primary'
        }
        else if ('Human Capital Risk'.search(category) !== -1) {
            return 'badge-info'
        }
        else if ('Operational Risk'.search(category) !== -1) {
            return 'badge-success'
        }
        else if ('Reputation Risk'.search(category) !== -1) {
            return 'badge-light'
        }
        else if ('Financial Risk'.search(category) !== -1) {
            return 'badge-dark'
        }
        else if ('ESG Risk'.search(category) !== -1) {
            return 'badge-secondary'
        }
        else {
            return 'badge-warning'
        }
    }

    const getFieldsValue = (item, field) => {
        let info = '';
        switch (field) {
            case 'DATE':
                info = item.publishedAt ? moment(new Date(item.publishedAt)).format('MMM DD, YYYY') : item.publishedOn;
                break;
            case 'SOURCE':
                info = item.source && item.source.name ? item.source.name : item.source ? item.source : item.newsSource;
                break;
            case 'DESC':
                info = item.description ? item.description : item.newsDescription;
                break;
            case 'TITLE':
                info = item.title ? item.title : item.newsTitle;
                break;
            case 'IMAGE':
                info = item && item.urlToImage ? item.urlToImage : item && item.imgUrl ? item.imgUrl : missingImg;
                break;
            default:
                info = '';
                break;
        }
        return info;
    }

    const handleImageError = (e) => {
        e.target.src = missingImg;
        e.target.alt = 'Alternative Image';
    }

    const getEventTableData = (item) => {
        if (item) {
            return (
                <div className="card border-0">
                    <div className="card-header d-flex align-items-center justify-content-between px-3">
                        <h6 className="mb-0">{item.eventSubCategory ? item.eventSubCategory : getFieldsValue(item, 'SOURCE')}</h6>
                        <span className={`badge badge-pill ${getRiskNewsCategoryBg(item.riskCategory)}`}>{item.riskCategory}</span>
                    </div>
                    <div className="card-body">
                        <div className="media mb-2">
                            <img className="align-self-center mr-3" src={getFieldsValue(item, 'IMAGE')} onError={handleImageError} alt="Generic placeholder image" width="80px" />
                            <div className="media-body card-text">
                                <a className="text-left p-0" href={item.url} target="_blank">{getFieldsValue(item, 'TITLE')}</a>
                                {/* <button className="btn btn-link text-left p-0" onClick={()=> nav(`/risk/company/${companyInfo.slug}/${item.newsId}`)}>{getFieldsValue(item, 'TITLE')}</button> */}
                            </div>
                        </div>
                        <div className="card-text mb-2 font-small">{getFieldsValue(item, 'DESC')}</div>
                        <small className="text-muted">{getFieldsValue(item, 'DATE')}  |  <b>{item.author ? item.author : getFieldsValue(item, 'SOURCE')}</b></small>
                    </div>
                </div>
            )
        }
        else {
            return null
        }
    }

    return (
        <section className="mb-3">
            {
                eventList.map((e, i) => {
                    return (
                        <div key={`risk-comp-event-${i}`} className="row">

                            <div className="col-xl-2 col-lg-2 col-md-4 col-4 text-center align-self-center">
                                {
                                    e.icon ? (<i className={`fa fa-2x ${e.icon}`} aria-hidden="true"></i>) : <img src={e.eventCategoryIcon} height="40px" className="mb-2" />
                                }

                                <h6 className="mb-0">{e.eventCategory}</h6>
                                <span className="badge bl-source-badge"><i className="fa fa-database" aria-hidden="true"></i>{'Google News, Reddit, Business Wire'}</span>
                            </div>

                            <div className="col-xl-10 col-lg-10 col-md-8 col-8 p-0">
                                <table className="bl-company-overview-risk" width="100%">
                                    <tbody>
                                        <tr>
                                            <td width="33%" valign="top" className={e.events[0] ? '' : 'border-0'}>
                                                {getEventTableData(e.events[0])}
                                            </td>
                                            <td width="33%" valign="top" className={e.events[1] ? '' : 'border-0'}>
                                                {getEventTableData(e.events[1])}
                                            </td>
                                            <td valign="top" className={e.events[2] ? '' : 'border-0'}>
                                                {getEventTableData(e.events[2])}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )
                })
            }

        </section>
    )
}