import { Column } from "primereact/column";
import { TreeTable } from "primereact/treetable";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


export const SourceCommonCompanyListComponent = ({list, action=false}) => {

    const nav = useNavigate();
    const [nodes, setNodes] = useState([]);

    useEffect(() => {
        setNodes(loadNodes(0, list))
    }, [list])

    const loadNodes = (first, rows) => {
        let nodes = [];
        for (let i = 0; i < rows.length; i++) {
            let node = {
                key: `table-company-profile-key-${first + i}`,
                data: {
                    company: rows[i].company,
                    blScore:rows[i].BlScore,
                    about: rows[i].description,
                    funding: rows[i].fundingAmount,
                    valuation: rows[i].valuation,
                    address: rows[i].address,
                    stage: rows[i].type,
                    employeeCount: rows[i].totalEmployee,
                    revenue: rows[i].revenue ? rows[i].revenue : 'NA',
                    logo: rows[i].logo,
                    isPinned: rows[i].isPinned,
                    slug: rows[i].slug
                }
            }
            nodes.push(node);
        }
        return nodes;
    };

    const arrangeColumnValue = (item, column) => {
        let data = item.data;
        if (column === 'ABOUT') {
            return (
                <span className="bl-desc-truncate-line-2">{data.about}</span>
            )
        }
        else if (column === 'NAME') {
            return (
                <div className="d-flex">
                    {
                        data.isPinned ? (<i className="fa fa-fw fa-thumb-tack mr-2 align-self-center" aria-hidden="true"></i>) : (<i className="fa fa-fw fa-thumb-tack text-gray mr-2 align-self-center" aria-hidden="true"></i>)
                    }
                    <img src={data.logo} alt="logo" className="img-fluid bl-report-thumbnail mr-2" />
                    <p className="mb-0 align-self-center">
                        <b>{data.company}</b>
                    </p>
                </div>
            )
        }
        else if(column === 'ACTION'){
            return (
                <button className="btn btn-primary btn-sm mr-2 mb-0">
                    <i className="fa fa-fw fa-exchange" aria-hidden="true" title="Compare"></i>Compare
                </button>
            )
        }
    }

    const openCompanyProfile = (e) => {
        nav(`/sourcing/company/${e.data.slug}/profile`)
    }

    return (
        <TreeTable className="cp" value={nodes} onRowClick={(e) => openCompanyProfile(e.node)}>
            <Column field="name" header="Company Name" body={(e) => arrangeColumnValue(e, 'NAME')} sortable></Column>
            <Column field="blScore" header="BL Score" style={{ width: '8%' }} sortable></Column>
            <Column field="about" header="About" body={(e) => arrangeColumnValue(e, 'ABOUT')} sortable></Column>
            <Column field="funding" header="Funding" style={{ width: '8%' }} sortable></Column>
            <Column field="valuation" header="Valuation" style={{ width: '9%' }} sortable></Column>
            <Column field="address" header="Location" sortable></Column>
            <Column field="stage" header="Stage" style={{ width: '7%' }} sortable></Column>
            <Column field="employeeCount" header="Employee" style={{ width: '9%' }} sortable></Column>
            <Column field="revenue" header="Revenue" style={{ width: '9%' }} sortable></Column>
            {
                action ? <Column header="" body={(e) => arrangeColumnValue(e, 'ACTION')} style={{ width: '9%' }}></Column> : null
            }
            
        </TreeTable>
    )
}