import React, { useContext, useEffect, useState } from "react";
import HeatmapChart from "./Graph/heatmap";
import heatmapJson from './../data/heatmap.json';
import heatmapCanabisJson from './../data/heatmapCanabis.json';
import { RightPanelComponent } from "./Common/rightPanel";
import c1Img from "./../assets/img/c1.png";
import c2Img from "./../assets/img/c2.png";
import { ColumnChart } from "./Graph/columnChart";
import { TrendChartComponent } from "./Graph/trendChart";
import SearchContext from "../services/context/Search/searchContext";
import BreadcrumbContext from "../services/context/Breadcrumb/breadcrumbContext";
import { AuthContext } from "../services/context/Auth/authContext";
import { PIEChartComponent } from "./Graph/piechart";
import AIContext from "../services/context/AIAssistance/aiContext";
import ask_question from "./../assets/icons/app/AI_Icon.png";
import errascend_market from "./../assets/img/terrascend-market-around.png";
import { CardDropdownContent } from "./Common/cardDropdown";

export const DashboardComponent = () => {

    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const { isCanabisApp } = useContext(AuthContext)
    const { setAIModalType } = useContext(AIContext);

    useEffect(() => {
        setBreadcrumbMenu([{ slug: '/dashboard', menuName: 'Dashboard', isActive: true, hasSubMenu: false }])
    }, [])

    window.scrollTo(0, 0)

    const [panelVisibility, setPanelVisibility] = useState(true);
    const [heatMapData, setHeatMapData] = useState([])
    const [incomePeriodData, setIncomePeriodData] = useState([
        {
            "date": "Jun 2022",
            "revenue": 120,
            "netIncome": 110
        },
        {
            "date": "Sep 2022",
            "revenue": 130,
            "netIncome": 80
        },
        {
            "date": "Dec 2022",
            "revenue": 150,
            "netIncome": 100
        }
    ])
    const [incomeSeries, setIncomeSeries] = useState([
        {
            name: "Revenue", val: 'revenue'
        },
        {
            name: 'Net Income', val: 'netIncome'
        }
    ])

    useEffect(() => {
        setHeatMapData(isCanabisApp ? heatmapCanabisJson : heatmapJson)
        if (isCanabisApp) {
            setIncomePeriodData([
                {
                    "date": "18-25",
                    "revenue": 20,
                    "netIncome": 110
                },
                {
                    "date": "26-35",
                    "revenue": 40,
                    "netIncome": 80
                },
                {
                    "date": "36-45",
                    "revenue": 25,
                    "netIncome": 100
                },
                {
                    "date": "46+",
                    "revenue": 15,
                    "netIncome": 100
                }
            ])
            setIncomeSeries([{
                name: "Customer", val: 'revenue'
            }])
        }
    }, [isCanabisApp])

    const heatYAxisData = isCanabisApp ? [
        { weekday: "Lancaster" },
        { weekday: "Thorndale" },
        { weekday: "Stroudsburg" },
        { weekday: "Allentown" },
        { weekday: "Pennsylvania" },
        { weekday: "Phillipsburg" }
    ] : [
        { weekday: "ALTADIA" },
        { weekday: "GLOVIS" },
        { weekday: "FLENDER" },
        { weekday: "JIO" },
        { weekday: "SEPSA" },
        { weekday: "GCI" }
    ];

    const dashHeat = (item) => {
        // nav(`/dashboard/company`, { state: item.weekday })
    }

    const changePageContentSize = () => {
        setPanelVisibility(!panelVisibility);
    }

    const [pieItem, setPieItem] = useState([
        { sector: "Flowers", size: 45 },
        { sector: "Edibles", size: 30 },
        { sector: "Concentrates", size: 15 },
        { sector: "Accessories", size: 10 },
    ])

    const canabisCardContent = () => {
        return (
            <>
                <div className="grid-item item1">
                    <div className="card blc_card">
                        <div className="card-header d-flex justify-content-between">
                            <div className="d-flex align-self-center">
                                <h6 className="mb-0">Market definition and local competition</h6>
                            </div>
                            <div className="d-flex align-self-center">
                                <select id="brandview" className="form-control form-control-sm blc_button text-black mr-2 w-auto" defaultValue={''}>
                                    <option>Apothecarium</option>
                                    <option>Kind Tree</option>
                                    <option>Legend</option>
                                    <option>Gage</option>
                                    <option>State Flower</option>
                                    <option>Valhalla</option>
                                    <option>Ilera</option>
                                </select>
                                <select id="contentview" className="form-control form-control-sm blc_button text-black mr-3 w-auto" defaultValue={''}>
                                    <option>Lodi</option>
                                    <option>Maplewood</option>
                                    <option>Phillipsburg</option>
                                </select>
                                <CardDropdownContent cardId="marketdefinition" cardType={'REDDIT'} onChildCardEvent={(item) => setAIModalType(item)} />
                                {/* {cardDropdownContent('marketdefinition')} */}
                            </div>

                        </div>
                        <div className="card-body">
                            <img className="img-fluid" src={errascend_market} />
                        </div>
                    </div>
                </div>
                <div className="grid-item item2">
                    <div className="card blc_card">
                        <div className="card-header d-flex justify-content-between">
                            <div className="d-flex align-self-center">
                                <h6 className="mb-0">Sales Trend</h6>
                            </div>
                            <CardDropdownContent cardId="salestrend" cardType={'REDDIT'} onChildCardEvent={(item) => setAIModalType(item)} />
                            {/* {cardDropdownContent('salestrend')} */}
                        </div>
                        <div className="card-body">
                            <TrendChartComponent id="sourceprofilevaluationdash" height="9rem"></TrendChartComponent>
                        </div>
                    </div>
                </div>
                <div className="grid-item item2">
                    <div className="card blc_card">
                        <div className="card-header d-flex justify-content-between">
                            <div className="d-flex align-self-center">
                                <h6 className="mb-0">Revenue Breakdown</h6>
                            </div>
                            <CardDropdownContent cardId="revenuebreakdown" cardType={'REDDIT'} onChildCardEvent={(item) => setAIModalType(item)} />
                            {/* {cardDropdownContent('revenuebreakdown')} */}
                        </div>
                        <div className="card-body">
                            <PIEChartComponent data={pieItem} hideCenterText={true} height="9rem"></PIEChartComponent>
                        </div>
                    </div>
                </div>
                <div className="grid-item item3">
                    <div className="card blc_card">
                        <div className="card-header d-flex justify-content-between">
                            <div className="d-flex align-self-center">
                                <h6 className="mb-0">Customer Demographics</h6>
                            </div>
                            <CardDropdownContent cardId="customerdemographics" cardType={'REDDIT'} onChildCardEvent={(item) => setAIModalType(item)} />
                            {/* {cardDropdownContent('customerdemographics')} */}
                        </div>
                        <div className="card-body">
                            {
                                incomePeriodData.length ? <ColumnChart id="incomestatementfinancedash" data={incomePeriodData} categoryField={'date'} seriesItem={incomeSeries} height="9rem"></ColumnChart> : null
                            }
                        </div>
                    </div>
                </div>
                <div className="grid-item item3">
                    <div className="card blc_card">
                        <div className="card-header d-flex justify-content-between">
                            <div className="d-flex align-self-center">
                                <h6 className="mb-0">Best Selling Products</h6>
                            </div>
                            <CardDropdownContent cardId="bestsellingproducts" cardType={'REDDIT'} onChildCardEvent={(item) => setAIModalType(item)} />
                            {/* {cardDropdownContent('bestsellingproducts')} */}
                        </div>
                        <div className="card-body p-3">
                            <div>
                                <table width="100%" cellPadding="0" cellSpacing="0" border="0" className="table table-sm mb-0 bl-h-9rem">
                                    <tbody>
                                        <tr>
                                            <td className="border-top-0">OG Kush</td>
                                            <td className="border-top-0 text-right">$25,000</td>
                                        </tr>
                                        <tr>
                                            <td>Brownie Bites</td>
                                            <td className="text-right">$15,000</td>
                                        </tr>
                                        <tr>
                                            <td>Live Resin</td>
                                            <td className="text-right">$10,000</td>
                                        </tr>
                                        <tr>
                                            <td>Early Bird</td>
                                            <td className="text-right">$8,000</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const defaultCardContent = () => {
        return (
            <>
                <div className="grid-item item2">
                    <div className="card blc_card">
                        <div className="card-body h-17">
                            <div className="mb-2 font-weight-600">
                                <p>Portfolio Valuation Trend</p>
                                <TrendChartComponent id="sourceprofilevaluationdash" height="9rem"></TrendChartComponent>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid-item item3">
                    <div className="card blc_card">
                        <div className="card-body h-17">
                            <div className="mb-2 font-weight-600">
                                <p>Valuation Performance</p>
                                {
                                    incomePeriodData.length ? <ColumnChart id="incomestatementfinancedash" data={incomePeriodData} categoryField={'date'} seriesItem={incomeSeries} height="9rem"></ColumnChart> : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid-item item4">
                    <div className="card blc_card">
                        <div className="card-body p-3">
                            <div className="mb-2 font-weight-600">
                                <p>Alerts that need your attention</p>
                            </div>
                            <div>
                                <table width="100%" cellPadding="0" cellSpacing="0" border="0" className="table table-sm mb-0">
                                    <tbody>
                                        <tr>
                                            <td className="border-top-0">
                                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center bg-warning text-white">
                                                    3
                                                </div>
                                            </td>
                                            <td className="border-top-0">New Monthly Financial datasets released</td>
                                            <td className="border-top-0"><i className="fa fa-angle-right" aria-hidden="true"></i></td>
                                        </tr>
                                        <tr>
                                            <td className="border-top-0">
                                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center bg-danger text-white">
                                                    5
                                                </div>
                                            </td>
                                            <td>Data Exception(s) detected that has impacted the gener...</td>
                                            <td><i className="fa fa-angle-right" aria-hidden="true"></i></td>
                                        </tr>
                                        <tr>
                                            <td className="border-top-0">
                                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center bg-warning text-white">
                                                    4
                                                </div>
                                            </td>
                                            <td>Company Financial(s) Overdue</td>
                                            <td><i className="fa fa-angle-right" aria-hidden="true"></i></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const cardDropdownContent = (id) => {
        return (
            <div className="">
                <button className="icon text-secondary btn-navigate" id={id} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fa fa-ellipsis-h" aria-hidden="true"></i></button>
                <div className="dropdown-menu" aria-labelledby={id}>
                    <button className="dropdown-item" >
                        <i className="fa fa-user fa-sm mr-2 text-gray-400"></i>
                        View
                    </button>
                    <button className="dropdown-item" >
                        <i className="fa fa-edit fa-sm mr-2 text-gray-400"></i>
                        Update
                    </button>
                    <button className="dropdown-item" >
                        <i className="fa fa-list fa-sm mr-2 text-gray-400"></i>
                        Activity Log
                    </button>
                    <button className="dropdown-item text-danger pl-3" onClick={() => setAIModalType('REDDIT')} data-toggle="modal" data-target="#aimodalbox">
                        <img className="img-fluid mr-2" src={ask_question} />
                        Ask a question
                    </button>
                </div>
            </div>
        )
    }

    const cardGridContainer = () => {
        return (
            <div className="grid-container">
                <div className="grid-item item1">
                    <div className="card blc_card">
                        <div className="card-header d-flex justify-content-between">
                            <div className="d-flex align-self-center">
                                <h6 className="mb-0">{isCanabisApp ? 'Store Footfall by Location' : 'Data Collection Overview'}</h6>
                            </div>
                            <CardDropdownContent cardId="storefootfall" cardType={'REDDIT'} onChildCardEvent={(item) => setAIModalType(item)} />
                            {/* {cardDropdownContent('storefootfall')} */}
                        </div>
                        <div className="card-body">
                            <HeatmapChart data={heatMapData} yaxisData={heatYAxisData} yAxisVisiblity={true} height={'350px'} dashHeat={(item) => dashHeat(item)}></HeatmapChart>
                        </div>
                    </div>
                </div>
                {
                    isCanabisApp ? canabisCardContent() : defaultCardContent()
                }
                <div className="grid-item item5 d-none">
                    <div className="card blc_card">
                        <div className="card-body h-17">
                            <div className="mb-2 font-weight-600">
                                <p>Portfolio Valuation Trend</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid-item item6 d-none">
                    <div className="card blc_card">
                        <div className="card-body h-17">
                            <div className="mb-2 font-weight-600">
                                <p>Portfolio Valuation Trend</p>
                            </div>
                            {/* <BarChart height="15rem"></BarChart> */}
                        </div>
                    </div>
                </div>
                <div className="grid-item item7 d-none">
                    <div className="card blc_card">
                        <div className="card-body h-17">
                            <div className="mb-2 font-weight-600">
                                <p>Portfolio Valuation Trend</p>
                            </div>
                            {/* <BarChart height="15rem"></BarChart> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div id="content">
            <div className="row">
                <div className={panelVisibility ? 'col-md-9 pr-0' : 'col-12'}>
                    <div className="bg-dark h-13rem border-right dash-title">
                        <div className="container-fluid">
                            <div className="row pt-3">
                                {
                                    isCanabisApp ?
                                        <div className="col-10 dash-header-info d-flex">
                                            <div>
                                                <p>Total Sales</p>
                                                <h4>$150,000</h4>
                                            </div>
                                            <div>
                                                <p>Average Order Value (AOV)</p>
                                                <h4>$60</h4>
                                            </div>
                                            <div>
                                                <p>Conversion Rate</p>
                                                <h4>3.5%</h4>
                                            </div>
                                            <div className="border-0">
                                                <p>Time Range</p>
                                                <h4>Last 30 days</h4>
                                            </div>
                                        </div> :
                                        <div className="col-10 dash-header-info d-flex">
                                            <div>
                                                <p>Total Invested</p>
                                                <h4>$ 234.9 M</h4>
                                            </div>
                                            <div>
                                                <p>Drawdown Available</p>
                                                <h4>$ 44.5 M</h4>
                                            </div>
                                            <div>
                                                <p>Fund Gross IRR</p>
                                                <h4>16.32 %</h4>
                                            </div>
                                            <div className="border-0">
                                                <p>LP Net IRR</p>
                                                <h4>11.23 %</h4>
                                            </div>
                                        </div>
                                }

                                <div className="col-2 mob-hide">
                                    <div className="text-right">
                                        <i className="fa fa-sliders mr-3" aria-hidden="true"></i>
                                        <i className="fa fa-window-maximize transform-270 cp" aria-hidden="true" onClick={() => changePageContentSize()}></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">


                        <div className="card-visibility font-weight-600">
                            {
                                isCanabisApp ?
                                    <div className="row bl-dash-card">
                                        <div className="mt-6 col-xl-3 col-lg-6 col-md-12 col-12">
                                            <div className="card mb-4 blc_card"><div className="card-body">
                                                <div className="d-flex justify-content-between align-items-center mb-3">
                                                    <div><p className="mb-0">New vs Returning Customers</p></div>
                                                    <div>
                                                        <i className="fa fa-users" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h5 className="fw-bold">New: 60%</h5>
                                                    <h5 className="fw-bold">Returning: 40%</h5>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="mt-6 col-xl-3 col-lg-6 col-md-12 col-12">
                                            <div className="card mb-4 blc_card"><div className="card-body">
                                                <div className="d-flex justify-content-between align-items-center mb-3">
                                                    <div>
                                                        <p className="mb-0">Geographical Sales Distribution</p>
                                                    </div>
                                                    <div>
                                                        <i className="fa fa-bar-chart" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h5 className="fw-bold">West Coast: $80,000</h5>
                                                    <h5 className="fw-bold">East Coast: $50,000</h5>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="mt-6 col-xl-3 col-lg-6 col-md-12 col-12">
                                            <div className="card mb-4 blc_card">
                                                <div className="card-body">
                                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                                        <div>
                                                            <p className="mb-0">Sales by Channel</p>
                                                        </div>
                                                        <div>
                                                            <i className="fa fa-snowflake-o" aria-hidden="true"></i>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <h5 className="fw-bold">Online Store: $120,000</h5>
                                                        <h5 className="fw-bold">Physical Dispensary: $50,000</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-6 col-xl-3 col-lg-6 col-md-12 col-12">
                                            <div className="card mb-4 blc_card"><div className="card-body">
                                                <div className="d-flex justify-content-between align-items-center mb-3">
                                                    <div>
                                                        <p className="mb-0">Regulatory Compliance Score</p>
                                                    </div>
                                                    <div>
                                                        <i className="fa fa-bullseye" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h5 className="fw-bold">January 2023: 95%</h5>
                                                    <h5 className="fw-bold">February 2023: 98%</h5>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div> :
                                    <div className="row">
                                        <div className="mt-6 col-xl-3 col-lg-6 col-md-12 col-12">
                                            <div className="card mb-4 blc_card"><div className="card-body">
                                                <div className="d-flex justify-content-between align-items-center mb-3">
                                                    <div><p className="mb-0">Total Valuation Req</p></div>
                                                    <div className="icon-shape icon-md bg-light-primary text-primary rounded-2">
                                                        <i className="fa fa-list-alt" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h4 className="fw-bold">55</h4>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="mt-6 col-xl-3 col-lg-6 col-md-12 col-12">
                                            <div className="card mb-4 blc_card"><div className="card-body">
                                                <div className="d-flex justify-content-between align-items-center mb-3">
                                                    <div>
                                                        <p className="mb-0">Not Started</p>
                                                    </div>
                                                    <div className="text-secondary">
                                                        <i className="fa fa-history" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h4 className="fw-bold">10</h4>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="mt-6 col-xl-3 col-lg-6 col-md-12 col-12">
                                            <div className="card mb-4 blc_card">
                                                <div className="card-body">
                                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                                        <div>
                                                            <p className="mb-0">Pending Approvals</p>
                                                        </div>
                                                        <div className="text-warning">
                                                            <i className="fa fa-user-circle-o" aria-hidden="true"></i>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <h4 className="fw-bold">30</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-6 col-xl-3 col-lg-6 col-md-12 col-12">
                                            <div className="card mb-4 blc_card"><div className="card-body">
                                                <div className="d-flex justify-content-between align-items-center mb-3">
                                                    <div>
                                                        <p className="mb-0">Completed</p>
                                                    </div>
                                                    <div className="text-success">
                                                        <i className="fa fa-check-circle" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h4 className="fw-bold">15</h4>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                            }

                        </div>
                        <section className="mb-4">
                            {cardGridContainer()}
                        </section>
                        <section>
                            <div className="col-12 my-3 text-black p-0">
                                <h5 className="h5 mb-0 text-black font-weight-600">My Tasks</h5>
                            </div>
                            <div className="col-xl-12 col-md-12 p-0 mb-2">
                                <div className="form-row blc_button">
                                    <div className="form-group col-md-2">
                                        <select id="inputState" className="form-control blc_button text-black" defaultValue={''}>
                                            <option>Category</option>
                                            <option>...</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-md-2">
                                        <select id="inputState" className="form-control blc_button text-black" defaultValue={''}>
                                            <option>All...</option>
                                            <option>...</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-md-2">
                                        <select id="inputState" className="form-control blc_button text-black" defaultValue={''}>
                                            <option>Submitted</option>
                                            <option>...</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-12 col-md-12 p-0 mb-4 table-responsive font-small">
                                {
                                    isCanabisApp ?
                                        <table className="table table-sm" id="dataTable" width="100%" cellSpacing="0">
                                            <thead>
                                                <tr>
                                                    <th>Task Description</th>
                                                    <th>Priority</th>
                                                    <th>Status</th>
                                                    <th>Due Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="dash-tab-comp">
                                                        Obtain Regulatory Approval for New Product Launch
                                                    </td>
                                                    <td>High</td>
                                                    <td><span className="badge badge-pill badge-success">In Progress</span></td>
                                                    <td className="text-gray">2023-12-15</td>
                                                </tr>
                                                <tr>
                                                    <td className="dash-tab-comp">
                                                        Conduct Inventory Audit for Year-End Reporting
                                                    </td>
                                                    <td>High</td>
                                                    <td><span className="badge badge-pill badge-warning">Pending</span></td>
                                                    <td className="text-gray">2023-12-31</td>
                                                </tr>
                                                <tr>
                                                    <td className="dash-tab-comp">
                                                        Implement Age Verification System on Online Store
                                                    </td>
                                                    <td>High</td>
                                                    <td><span className="badge badge-pill badge-secondary">Not Started</span></td>
                                                    <td className="text-gray">2023-12-20</td>
                                                </tr>
                                                <tr>
                                                    <td className="dash-tab-comp">
                                                        Review and Update Employee Training on Compliance
                                                    </td>
                                                    <td>Medium</td>
                                                    <td><span className="badge badge-pill badge-info">In Review</span></td>
                                                    <td className="text-gray">2023-12-10</td>
                                                </tr>
                                                <tr>
                                                    <td className="dash-tab-comp">
                                                        Analyze Marketing Channel Performance for Q4
                                                    </td>
                                                    <td>High</td>
                                                    <td><span className="badge badge-pill badge-primary">Completed</span></td>
                                                    <td className="text-gray">2023-12-15</td>
                                                </tr>
                                            </tbody>
                                        </table> :
                                        <table className="table table-sm" id="dataTable" width="100%" cellSpacing="0">
                                            <thead>
                                                <tr>
                                                    <th><input type={'checkbox'} /></th>
                                                    <th>Company</th>
                                                    <th>Latest Comments</th>
                                                    <th></th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td><input type={'checkbox'} /></td>
                                                    <td className="dash-tab-comp">
                                                        <img src={c1Img} alt="c1" />
                                                        <p>XYZ Technologies Inc.</p>
                                                        <p className="small">Request ID: VAL-005</p>
                                                    </td>
                                                    <td>The Valuation Team has submitted a valuation request</td>
                                                    <td className="text-gray">Today</td>
                                                    <td><span className="badge badge-pill badge-secondary">Pending Approval</span></td>
                                                </tr>
                                                <tr>
                                                    <td><input type={'checkbox'} /></td>
                                                    <td className="dash-tab-comp">
                                                        <img src={c2Img} alt="c2" />
                                                        <p>DEF Energy Corp</p>
                                                        <p className="small">Request ID: VAL-008</p>
                                                    </td>
                                                    <td>Escalated to the CEO for final approval.</td>
                                                    <td className="text-gray">2 days ago</td>
                                                    <td><span className="badge badge-pill badge-secondary">IMMEDIATE</span></td>
                                                </tr>
                                                <tr>
                                                    <td><input type={'checkbox'} /></td>
                                                    <td className="dash-tab-comp">
                                                        <img src={c1Img} alt="c1" />
                                                        <p>XYZ Technologies Inc.</p>
                                                        <p className="small">Request ID: VAL-009</p>
                                                    </td>
                                                    <td>Escalated to the CEO for final approval.</td>
                                                    <td className="text-gray">2 days ago</td>
                                                    <td><span className="badge badge-pill badge-secondary">Pending Approval</span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                }

                            </div>
                        </section>

                    </div>
                </div>
                {
                    panelVisibility ? <div className="col-xl-3 col-md-3 col-12 bg-gray">
                        <RightPanelComponent></RightPanelComponent>
                    </div> : null
                }
            </div>
        </div>
    )
}