import React, { useEffect, useState } from 'react';
import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";

export const BubbleChart = ({ data, height = '100px', id = 'bubblechartdiv' }) => {

  const [chartRoot, setChartRoot] = useState(null);

  useEffect(() => {
    if (chartRoot) {
      chartRoot.dispose();
    }


    let root = am5.Root.new(id);

    root.setThemes([
      am5themes_Animated.new(root)
    ]);
    root._logo.dispose();

    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "none",
        wheelY: "none",
        layout: root.verticalLayout,
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0,
        paddingTop: 0
      })
    );

    // Create axes and their renderers
    var yRenderer = am5xy.AxisRendererY.new(root, {
      visible: false,
      minGridDistance: 20,
      inversed: true
    });

    yRenderer.grid.template.set("visible", false);

    var yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0,
        renderer: yRenderer,
        categoryField: "company",
        visible: true
      })
    );

    var xRenderer = am5xy.AxisRendererX.new(root, {
      visible: false,
      minGridDistance: 30,
      opposite: true
    });

    xRenderer.grid.template.set("visible", false);

    var xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        renderer: xRenderer,
        categoryField: "week",
        visible: false
      })
    );

    yAxis.get("renderer").labels.template.setAll({
      centerX: am5.p0,
      fontSize: 12
    });

    xAxis.get("renderer").labels.template.setAll({
      fontSize: 12
    });

    // Create series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/#Adding_series
    var series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        calculateAggregates: true,
        stroke: am5.color(0xffffff),
        clustered: false,
        xAxis: xAxis,
        yAxis: yAxis,
        categoryXField: "week",
        categoryYField: "company",
        valueField: "value"
      })
    );

    series.columns.template.setAll({
      forceHidden: true
    });

    var circleTemplate = am5.Template.new({ radius: 5 });

    // Add circle bullet
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/#Bullets
    series.bullets.push(function () {
      var graphics = am5.Circle.new(
        root, {
        stroke: series.get("stroke"),
        fill: series.get("fill")
      }, circleTemplate
      );
      return am5.Bullet.new(root, {
        sprite: graphics
      });
    });

    // Set up heat rules
    // https://www.amcharts.com/docs/v5/concepts/settings/heat-rules/
    series.set("heatRules", [{
      target: circleTemplate,
      min: 5,
      max: 35,
      dataField: "value",
      key: "radius"
    }]);

    // Set data
    // https://www.amcharts.com/docs/v5/charts/xy-chart/#Setting_data
    var data = [
      {
        week: "01-June to 08-June",
        company: "ACME Inc",
        value: 23
      }, {
        week: "09-June to 16-June",
        company: "ACME Inc",
        value: 25
      }, {
        week: "17-June to 24-June",
        company: "ACME Inc",
        value: 29
      }, {
        week: "25-June to 02-July",
        company: "ACME Inc",
        value: 31
      }, {
        week: "01-June to 08-June",
        company: "StrongBeat",
        value: 16
      }, {
        week: "09-June to 16-June",
        company: "StrongBeat",
        value: 18
      }, {
        week: "17-June to 24-June",
        company: "StrongBeat",
        value: 16
      }, {
        week: "25-June to 02-Julye",
        company: "StrongBeat",
        value: 31
      }, {
        week: "01-June to 08-June",
        company: "Party.AI",
        value: 19
      }, {
        week: "09-June to 16-June",
        company: "Party.AI",
        value: 21
      }, {
        week: "17-June to 24-June",
        company: "Party.AI",
        value: 20
      }, {
        week: "25-June to 02-July",
        company: "Party.AI",
        value: 32
      }
    ];

    series.data.setAll(data);

    yAxis.data.setAll([
      { company: "ACME Inc" },
      { company: "StrongBeat" },
      { company: "Party.AI" }
    ]);

    xAxis.data.setAll([
      { week: "01-June to 08-June" },
      { week: "09-June to 16-June" },
      { week: "17-June to 24-June" },
      { week: "25-June to 02-July" }

    ]);

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/#Initial_animation
    chart.appear(1000, 100);

    setChartRoot(root);

    return () => {
      // Cleanup when unmounting the component
      if (chartRoot) {
        chartRoot.dispose();
      }

    };
  }, [data]);

  return <div id={id} style={{ width: '100%', height: height }}></div>;
};