import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { BoldPieChart } from "../Graph/boldPieChart";


export const ESGScoreCardComponent = ({ id='', scores }) => {

    const location = useLocation();
    const [isLoaded, setIsLoaded] = useState(false)
    const [ESGScores, setESGScores] = useState({
        "esg_score": 77,
        "community": 87,
        "environment": 83,
        "employees": 65,
        "governance": 81
    })

    useEffect(()=>{
        if(scores){
            setESGScores(scores)
            setTimeout(()=>{
                setIsLoaded(true)
            })
        }
        else {
            setTimeout(()=>{
                setIsLoaded(true)
            })
        }
    }, [scores])

    useEffect(() => {
        setIsLoaded(false)
    }, [location])

    return (
        <div className="row align-items-center">
            <div className="col-2 align-content-center p-2">
                <div className="text-center">
                    {
                        isLoaded ? <BoldPieChart height="4rem" id={`${id}esgscorechart`} usedColor="#8ABB5C" remainingColor="#EDF0F1" score={ESGScores.esg_score}></BoldPieChart> : null
                    }
                    <p className="mb-0 font-small">ESG Score</p>
                </div>
            </div>
            <div className="col-10">
                <div className="row align-items-center text-center bl-border-bottom-grad">
                    <div className="col bl-border-right-grad">
                        <div className="d-flex justify-content-between align-items-center p-2">
                            <div className="font-small">Community</div>
                            <div className="bl-w-3rem">
                                {
                                    isLoaded && <BoldPieChart height="3rem" id={`${id}communityscorechart`} usedColor="#8ABB5C" remainingColor="#EDF0F1" score={ESGScores.community}></BoldPieChart>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="font-small">Environment</div>
                            <div className="bl-w-3rem">
                                {
                                    isLoaded && <BoldPieChart height="3rem" id={`${id}environmentscorechart`} usedColor="#8ABB5C" remainingColor="#EDF0F1" score={ESGScores.environment}></BoldPieChart>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center text-center">
                    <div className="col bl-border-right-grad-2">
                        <div className="d-flex justify-content-between align-items-center p-2">
                            <div className="font-small">Employees</div>
                            <div className="bl-w-3rem">
                                {
                                    isLoaded && <BoldPieChart height="3rem" id={`${id}employeesscorechart`} usedColor="#8ABB5C" remainingColor="#EDF0F1" score={ESGScores.employees}></BoldPieChart>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="font-small">Governance</div>
                            <div className="bl-w-3rem">
                                {
                                    isLoaded && <BoldPieChart height="3rem" id={`${id}governancescorechart`} usedColor="#8ABB5C" remainingColor="#EDF0F1" score={ESGScores.governance}></BoldPieChart>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}