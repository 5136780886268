import React, { useEffect, useState } from 'react';
import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5radar from "@amcharts/amcharts5/radar";
import * as am5xy from "@amcharts/amcharts5/xy";

const GuageChart = ({ data, height }) => {

  const [chartRoot, setChartRoot] = useState(null);

  useEffect(() => {
    if (chartRoot) {
      chartRoot.dispose();
    }


    let root = am5.Root.new("guagechartdiv");

    root.setThemes([
      am5themes_Animated.new(root)
    ]);
    root._logo.dispose();

    var chart = root.container.children.push(am5radar.RadarChart.new(root, {
      panX: false,
      panY: false,
      wheelX: "panX",
      wheelY: "zoomX",
      innerRadius: am5.percent(20),
      startAngle: -90,
      endAngle: 180,
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 0,
      paddingTop: 0
    }));


    // Data
    var data = [{
      category: "Legal Risk",
      value: 80,
      full: 100,
      columnSettings: {
        fill: chart.get("colors").getIndex(0)
      }
    }, {
      category: "Operations Risk",
      value: 35,
      full: 100,
      columnSettings: {
        fill: chart.get("colors").getIndex(1)
      }
    }, {
      category: "Financial Risk",
      value: 92,
      full: 100,
      columnSettings: {
        fill: chart.get("colors").getIndex(2)
      }
    }, {
      category: "ESG Risk",
      value: 68,
      full: 100,
      columnSettings: {
        fill: chart.get("colors").getIndex(3)
      }
    }];

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/radar-chart/#Cursor
    var cursor = chart.set("cursor", am5radar.RadarCursor.new(root, {
      behavior: "zoomX"
    }));

    cursor.lineY.set("visible", false);

    // Create axes and their renderers
    // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_axes
    var xRenderer = am5radar.AxisRendererCircular.new(root, {
      //minGridDistance: 50
    });

    xRenderer.labels.template.setAll({
      radius: 10
    });

    xRenderer.grid.template.setAll({
      forceHidden: true
    });

    var xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
      renderer: xRenderer,
      min: 0,
      max: 100,
      strictMinMax: true,
      numberFormat: "#",
      tooltip: am5.Tooltip.new(root, {})
    }));


    var yRenderer = am5radar.AxisRendererRadial.new(root, {
      minGridDistance: 20
    });

    yRenderer.labels.template.setAll({
      centerX: am5.p100,
      fontWeight: "500",
      fontSize: 10,
      templateField: "columnSettings"
    });

    yRenderer.grid.template.setAll({
      forceHidden: true
    });

    var yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: "category",
      renderer: yRenderer
    }));

    yAxis.data.setAll(data);


    // Create series
    // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_series
    var series1 = chart.series.push(am5radar.RadarColumnSeries.new(root, {
      xAxis: xAxis,
      yAxis: yAxis,
      clustered: false,
      valueXField: "full",
      categoryYField: "category",
      fill: root.interfaceColors.get("alternativeBackground")
    }));

    series1.columns.template.setAll({
      width: am5.p100,
      fillOpacity: 0.08,
      strokeOpacity: 0,
      cornerRadius: 20
    });

    series1.data.setAll(data);


    var series2 = chart.series.push(am5radar.RadarColumnSeries.new(root, {
      xAxis: xAxis,
      yAxis: yAxis,
      clustered: false,
      valueXField: "value",
      categoryYField: "category"
    }));

    series2.columns.template.setAll({
      width: am5.p100,
      strokeOpacity: 0,
      tooltipText: "{category}: {valueX}",
      cornerRadius: 20,
      templateField: "columnSettings"
    });

    series2.data.setAll(data);

    // Animate chart and series in
    // https://www.amcharts.com/docs/v5/concepts/animations/#Initial_animation
    series1.appear(1000);
    series2.appear(1000);
    chart.appear(1000, 100);
    setChartRoot(root);

    return () => {
      // Cleanup when unmounting the component
      if (chartRoot) {
        chartRoot.dispose();
      }

    };
  }, [data]);

  return <div id="guagechartdiv" style={{ width: '100%', height: height }}></div>;
};

export default GuageChart;
