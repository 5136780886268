import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { RecentActivityPanel } from "../Common/recentActivitySidePanel";
import recentActivityJson from "./../../data/Apps/recentActivity.json";
import itemListJson from "./../../data/Workflows/workflowItems.json";
import homeLabel from "./../../utils/property-file/componentOverview.json";
import { WorkflowCardComponent } from "./workflowCard";
import SearchContext from "../../services/context/Search/searchContext";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";


export const WorkflowOverviewComponent = () => {

    window.scrollTo(0, 0);
    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const location = useLocation()

    useEffect(() => {
        setBreadcrumbMenu(
            [
                { slug: '', menuName: 'Workflows', isActive: true, hasSubMenu: true },
                { slug: '/workflow/overview', menuName: 'Overview', isActive: false, hasSubMenu: true }
            ]
        )
    }, [location])

    const [activity, setActivity] = useState([])
    const [workflowItem, setWorkflowItem] = useState([])
    const [labels, setLabels] = useState({
        WELCOME: "",
        WELCOME_DESC: "",
        WELCOME_ICON: "",
        YOUR_ACTIVITY: "",
        RECOMMEND: ""
    });

    useEffect(() => {
        let items = homeLabel.find(l => l.FEATURE === "WORKFLOW").items;
        setLabels(items)
    }, [homeLabel])

    useEffect(() => {
        setActivity(recentActivityJson)
    }, [recentActivityJson])

    useEffect(() => {
        let items = itemListJson.filter(item => item.isWidelyUsed);
        setWorkflowItem(items)
    }, [itemListJson])

    return (
        <div id="content">
            <div className="container-fluid pt-3">

                <div className="row">
                    <div className="col-xl-10 col-lg-10 col-md-9 col-12">
                        <section className="d-flex justify-content-between mb-3">
                            <div className="align-self-center mr-16">
                                <h4>{labels.WELCOME}</h4>
                                <p className="mb-0">{labels.WELCOME_DESC}</p>
                            </div>
                            <div>
                                <img className="bl-overview-img" src={labels.WELCOME_ICON} alt="overview-Img" />
                            </div>
                        </section>
                        <section className="mb-3">
                            <h6 className="mb-3">{labels.RECOMMEND}</h6>
                            <div className="card-deck">
                                {
                                    workflowItem.map((item, i) => {
                                        return (
                                            <WorkflowCardComponent key={`workflow-app-items-${i}`} item={item}></WorkflowCardComponent>
                                        )
                                    })
                                }
                            </div>
                        </section>
                    </div>

                    <div className="col-xl-2 col-lg-2 col-md-3 py-3 mt-n3 mob-hide border-left bg-gray">
                        {
                            activity.length ? <RecentActivityPanel title={labels.YOUR_ACTIVITY} data={activity}></RecentActivityPanel> : null
                        }

                    </div>

                </div>
            </div>

        </div>
    )
}