import React, { useContext, useEffect } from "react";
import SearchContext from "../../../services/context/Search/searchContext";
import BreadcrumbContext from "../../../services/context/Breadcrumb/breadcrumbContext";


export const RiskAIModelsComponent = () => {
    window.scrollTo(0, 0);

    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)

    useEffect(() => {
        setBreadcrumbMenu(
            [
                { slug: '', menuName: 'Risk Monitor', isActive: true, hasSubMenu: true },
                { slug: '/risk/ai-models', menuName: 'AI Models', isActive: false, hasSubMenu: true }
            ]
        )
    }, [])

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <section className="mb-3 d-flex justify-content-between">
                    <div>
                        <h6>AI Models</h6>
                    </div>
                    <div>
                        <button className="btn btn-primary btn-sm mr-2"><i className="fa fa-fw fa-refresh" aria-hidden="true"></i>Refresh</button>
                        <button className="btn btn-primary btn-sm"><i className="fa fa-fw fa-plus" aria-hidden="true"></i>{'Add New'}</button>
                    </div>
                </section>
                <section className="mb-3">
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                            <div className="card shadow-sm mb-3">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <img className="mr-2 rounded" src="https://cdn-icons-png.flaticon.com/512/2177/2177012.png" height="40px" alt="Generic placeholder image" />
                                            <h6 className="mb-0 align-self-center">Risk Assessment</h6>
                                        </div>
                                        <div className="align-self-center">
                                            <label className="bl-switch mb-0">
                                                <input type="checkbox" defaultChecked/>
                                                    <span className="bl-slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                            <div className="card shadow-sm mb-3">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <img className="mr-2 rounded" src="https://substackcdn.com/image/fetch/w_264,c_limit,f_auto,q_auto:best,fl_progressive:steep/https%3A%2F%2Fbucketeer-e05bbc84-baa3-437e-9518-adb32be77984.s3.amazonaws.com%2Fpublic%2Fimages%2F8d835313-4115-43c2-b26d-e4ab2ff5aed7_256x256.png" height="40px" alt="Generic placeholder image" />
                                            <h6 className="mb-0 align-self-center">ESG Scorecard</h6>
                                        </div>
                                        <div className="align-self-center">
                                            <label className="bl-switch mb-0">
                                                <input type="checkbox" defaultChecked/>
                                                    <span className="bl-slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-xs-12">
                            <div className="card shadow-sm mb-3">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <img className="mr-2 rounded" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2vnoYFum2zKzjxfsxuTymOtiiOjGXEi-UL26FAWJWSlJmWV8tXMqZlnwx9g7dktqYpL0&usqp=CAU" height="40px" alt="Generic placeholder image" />
                                            <h6 className="mb-0 align-self-center">Deal Sourcing</h6>
                                        </div>
                                        <div className="align-self-center">
                                            <label className="bl-switch mb-0">
                                                <input type="checkbox" defaultChecked/>
                                                    <span className="bl-slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}