import React, { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import acmePic from "./../../assets/img/company/acme.png";
import { CompanyProfileSidebarComponent } from "./companyProfileSidebar";
import companyJson from "./../../data/companyMaster.json";
import SearchContext from "../../services/context/Search/searchContext";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";

export const MDMCompanyProfileComponent = () => {
    window.scrollTo(0, 0);

    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)

    useEffect(() => {
        setBreadcrumbMenu(
            [
                { slug: '', menuName: 'Master Data', isActive: true, hasSubMenu: true },
                { slug: '/master-data/companies', menuName: 'Companies', isActive: false, hasSubMenu: true },
                { slug: '/master-data/companies/profile', menuName: 'Profile', isActive: false, hasSubMenu: true }
            ]
        )
    }, [])

    const [startDate, setStartDate] = useState(new Date(companyJson[0].incorporationDate));
    const [companyInfo, setComapnyInfo] = useState(companyJson[0])

    return (
        <div className="content">
            <div className="container-fluid pt-3">
                <div className="row">
                    <div className="col-xl-10 col-md-10 col-12">
                        <section className="mb-3">
                            <div className="row">
                                <div className="col-xl-6 col-md-6 col-12">
                                    <div className="bl-company-profile d-flex">
                                        <div className="mr-2"><img src={acmePic} alt="company-logo" /></div>
                                        <div>
                                            <h5 className="mb-0">{companyInfo.companyName}</h5>
                                            <p className="text-gray mb-0"><a>www.acme.com</a></p>
                                            <div className="bl-social-links">
                                                <a className="twitter"><i className="fa fa-twitter"></i></a>
                                                <a className="facebook"><i className="fa fa-facebook"></i></a>
                                                <a className="instagram"><i className="fa fa-instagram"></i></a>
                                                <a className="linkedin"><i className="fa fa-linkedin"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-md-6 col-12 text-right align-self-center">
                                    <button className="btn btn-primary btn-sm mr-2 mb-0">
                                        <i className="fa fa-file-code-o mr-2" aria-hidden="true"></i> Edit Entity Data
                                    </button>
                                    <button className="btn btn-primary btn-sm mr-2 mb-0">
                                        <i className="fa fa-file-code-o mr-2" aria-hidden="true"></i> Download
                                    </button>
                                    <button className="btn btn-primary btn-sm mr-2 mb-0">
                                        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                        </section>
                        <section className="mb-3">
                            <div className="accordion" id="blcompanyprofile">
                                <div className="accordion-item mb-3">
                                    <div className="card">
                                        <div className="card-header">
                                            <h6 className="accordion-header mb-0" id="companyinfo">
                                                Company Information
                                                <span className="bl-accordion-icon">
                                                    <i className="fa fa-angle-down accordion-button" data-toggle="collapse" data-target="#blcompanyinfo" aria-expanded="false" aria-controls="blcompanyinfo"></i>
                                                </span>
                                            </h6>
                                        </div>
                                        <div className="card-body p-3">
                                            <div id="blcompanyinfo" className="accordion-collapse collapse show" aria-labelledby="companyinfo" >
                                                <div className="accordion-body">
                                                    <form>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-3">
                                                                <label htmlFor="blcomname">Company Name</label>
                                                                <input type="text" className="form-control" value={companyInfo.companyName} id="blcomname" placeholder="Company Name" />
                                                            </div>
                                                            <div className="form-group col-md-3">
                                                                <label htmlFor="blcomid">Registration Number</label>
                                                                <input type="text" className="form-control" value={companyInfo.registration} id="blcomid" placeholder="Reg No" />
                                                            </div>

                                                            <div className="form-group col-md-3">
                                                                <label htmlFor="blcomst">Legal Structure</label>
                                                                <input type="text" className="form-control" value={companyInfo.structure} id="blcomst" placeholder="Legal Structure" />
                                                            </div>
                                                            <div className="form-group col-md-3">
                                                                <label htmlFor="blcomind">Date of Incorporation</label>
                                                                <DatePicker className="form-control" dateFormat="MMM dd, yyyy" selected={startDate} onChange={(date) => setStartDate(date)} />
                                                            </div>
                                                        </div>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6 mb-0">
                                                                <label htmlFor="blcomdesc">Company Description</label>
                                                                <textarea className="form-control" value={companyInfo.description} id="blcomdesc"></textarea>
                                                            </div>
                                                            <div className="form-group col-md-6 mb-0">
                                                                <label htmlFor="blcomind">Indutry Classification</label>
                                                                <textarea className="form-control" value={companyInfo.classification} id="blcomind"></textarea>
                                                            </div>
                                                        </div>

                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item mb-3">
                                    <div className="card">
                                        <div className="card-header">
                                            <h6 className="accordion-header mb-0" id="ownergovern">
                                                Ownership & Governance
                                                <span className="bl-accordion-icon">
                                                    <i className="fa fa-angle-down accordion-button" data-toggle="collapse" data-target="#blownergovern" aria-expanded="false" aria-controls="blownergovern"></i>
                                                </span>
                                            </h6>
                                        </div>
                                        <div className="card-body p-3">
                                            <div id="blownergovern" className="accordion-collapse collapse show" aria-labelledby="ownergovern" >
                                                <div className="accordion-body">
                                                    <form>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-3">
                                                                <label htmlFor="blcomtype">Type</label>
                                                                <input type="text" className="form-control" value={companyInfo.type} id="blcomtype" placeholder="Type" />
                                                            </div>
                                                            <div className="form-group col-md-3">
                                                                <label htmlFor="blcomult">Ultimate Parent Company</label>
                                                                <input type="text" className="form-control" value={companyInfo.parentCompany} id="blcomult" placeholder="Ultimate Parent Company" />
                                                            </div>

                                                            <div className="form-group col-md-3">
                                                                <label htmlFor="blcomhry">Ownership Hierarchy</label>
                                                                <input type="text" className="form-control" value={companyInfo.ownership} id="blcomhry" placeholder="Ownership Hierarchy" />
                                                            </div>
                                                            <div className="form-group col-md-3">
                                                                <label htmlFor="blcomshst">Shareholding Structure</label>
                                                                <input type="text" className="form-control" value={companyInfo.shareholder} id="blcomshst" placeholder="Shareholding Structure" />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="card border-0">
                                                                    <div className="card-header p-2">
                                                                        Board of Directors
                                                                    </div>
                                                                    <div className="card-body table-responsive font-small p-0">
                                                                        <table className="table mb-0" cellPadding={0} cellSpacing="0">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Name</th>
                                                                                    <th>Role</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>Mary Johnson</td>
                                                                                    <td>Chairman</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>David Lee</td>
                                                                                    <td>CEO</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Emily White</td>
                                                                                    <td>CFO</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="card border-0">
                                                                    <div className="card-header p-2">
                                                                        Voting Rights
                                                                    </div>
                                                                    <div className="card-body table-responsive font-small p-0">
                                                                        <table className="table mb-0" cellPadding={0} cellSpacing="0">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Name</th>
                                                                                    <th>Right%</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>Mary Johnson</td>
                                                                                    <td>0.4</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>David Lee</td>
                                                                                    <td>0.3</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Emily White</td>
                                                                                    <td>0.3</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item mb-3">
                                    <div className="card">
                                        <div className="card-header">
                                            <h6 className="accordion-header mb-0" id="financedata">
                                                Financial Data
                                                <span className="bl-accordion-icon">
                                                    <i className="fa fa-angle-down accordion-button" data-toggle="collapse" data-target="#blfinancedata" aria-expanded="false" aria-controls="blfinancedata"></i>
                                                </span>
                                            </h6>
                                        </div>
                                        <div className="card-body p-3">
                                            <div id="blfinancedata" className="accordion-collapse collapse show" aria-labelledby="financedata" >
                                                <div className="accordion-body">
                                                    <form>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-4">
                                                                <label htmlFor="blcomfst">Financial Statements</label>
                                                                <select id="blcomfst" className="form-control">
                                                                    <option>Statement Link1</option>
                                                                    <option>Statement Link2</option>
                                                                    <option>Statement Link3</option>
                                                                </select>
                                                            </div>
                                                            <div className="form-group col-md-4">
                                                                <label htmlFor="blcomebi">EBITDA</label>
                                                                <input type="text" className="form-control" value={companyInfo.EBITDA} id="blcomebi" placeholder="EBITDA" />
                                                            </div>

                                                            <div className="form-group col-md-4">
                                                                <label htmlFor="blcomhsp">Historical Performance</label>
                                                                <input type="text" className="form-control" value={companyInfo.revenueGrowth} id="blcomhsp" placeholder="Historical Performance" />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="card border-0">
                                                                    <div className="card-header p-2">
                                                                        Revenue Breakdown
                                                                    </div>
                                                                    <div className="card-body table-responsive font-small p-0">
                                                                        <table className="table mb-0" cellPadding={0} cellSpacing="0">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Type</th>
                                                                                    <th>Value</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>Product</td>
                                                                                    <td>500000</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Services</td>
                                                                                    <td>300000</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="card border-0">
                                                                    <div className="card-header p-2">
                                                                        Debt Structure
                                                                    </div>
                                                                    <div className="card-body table-responsive font-small p-0">
                                                                        <table className="table mb-0" cellPadding={0} cellSpacing="0">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Type</th>
                                                                                    <th>Value</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>Long Term Loans</td>
                                                                                    <td>3000000</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Bonds</td>
                                                                                    <td>1000000</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item mb-3">
                                    <div className="card">
                                        <div className="card-header">
                                            <h6 className="accordion-header mb-0" id="operationaldata">
                                                Operational Data
                                                <span className="bl-accordion-icon">
                                                    <i className="fa fa-angle-down accordion-button" data-toggle="collapse" data-target="#bloperationaldata" aria-expanded="false" aria-controls="bloperationaldata"></i>
                                                </span>
                                            </h6>
                                        </div>
                                        <div className="card-body p-0">
                                            <div id="bloperationaldata" className="accordion-collapse collapse show" aria-labelledby="operationaldata" >
                                                <div className="accordion-body">
                                                    <div className="table-responsive font-small">
                                                        <table className="table mb-0" cellPadding={0} cellSpacing="0">
                                                            <thead>
                                                                <tr>
                                                                    <th>Company Type</th>
                                                                    <th>Locations</th>
                                                                    <th>Key Personnel</th>
                                                                    <th>Employees Count</th>
                                                                    <th>Products Offered</th>
                                                                    <th>IP Assets</th>
                                                                    <th>Supply Chain</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Technology Company</td>
                                                                    <td>HQ - San Francisco, Office - New York, R&D Center - Bangalore</td>
                                                                    <td>CEO: Alex Johnson, CTO: Emily Brown, CFO: Mark Smith</td>
                                                                    <td>300</td>
                                                                    <td>Software Solutions, Cloud Services</td>
                                                                    <td>Patents: 10, Trademarks: 5</td>
                                                                    <td>Key Suppliers: A Corp, B Inc</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Technology Company</td>
                                                                    <td>HQ - San Francisco, Office - New York, R&D Center - Bangalore</td>
                                                                    <td>CEO: Alex Johnson, CTO: Emily Brown, CFO: Mark Smith</td>
                                                                    <td>300</td>
                                                                    <td>Software Solutions, Cloud Services</td>
                                                                    <td>Patents: 10, Trademarks: 5</td>
                                                                    <td>Key Suppliers: A Corp, B Inc</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className="col-xl-2 col-md-2 mob-hide border-left">
                        <section>
                            <CompanyProfileSidebarComponent></CompanyProfileSidebarComponent>
                        </section>
                    </div>
                </div>

            </div>
        </div>
    )
}