import React, { useContext, useEffect, useState } from "react";
import { BarChart } from "../Graph/barchart";
import { BubbleChart } from "../Graph/bubblechart";
import HeatmapChart from "../Graph/heatmap";
import homeLabel from "./../../utils/property-file/componentOverview.json";
import companyRiskProfile from "./../../data/Risk/companyRiskProfile.json";
import appUpdatesJson from "./../../data/Risk/appUpdates.json";
import { useLocation, useNavigate } from "react-router-dom";
import bannerIcon from "./../../assets/img/risk_bannerImg.png";
import { RecentActivityPanel } from "../Common/recentActivitySidePanel";
import SearchContext from "../../services/context/Search/searchContext";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";


export const RiskOverviewComponent = () => {

    window.scrollTo(0, 0);

    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const nav = useNavigate()
    const location = useLocation()

    useEffect(() => {
        setBreadcrumbMenu(
            [
                { slug: '', menuName: 'Risk Monitor', isActive: true, hasSubMenu: true },
                { slug: '/risk/overview', menuName: 'Overview', isActive: false, hasSubMenu: true }
            ]
        )
    }, [location])

    const [riskScores, setRiskScores] = useState([]);
    const [heatYAxisData, setHeatYAxisData] = useState([]);
    const [heatXAxisData, setHeatXAxisData] = useState([]);
    const [labels, setLabels] = useState({
        WELCOME: "",
        WELCOME_DESC: "",
        LATEST_UPDATE: "",
        WELCOME_ICON: "",
        GUIDE: "",
        YOUR_ACTIVITY: "",
        ENTITY: {
            TITLE_1: "",
            DESC_1: "",
            TITLE_2: "",
            DESC_2: "",
            TITLE_3: "",
            DESC_3: ""
        },
        RECOMMEND: ""
    });

    useEffect(() => {
        let items = homeLabel.find(l => l.FEATURE === "RISK").items;
        setLabels(items)
    }, [homeLabel])

    useEffect(() => {
        let companyNames = [];
        let riskScoresList = [];
        companyRiskProfile.map(comp => {
            companyNames.push({ company: comp.company })
            comp.riskScores.map((s, i) => {
                if (i < 18) {
                    riskScoresList.push({
                        company: comp.company,
                        period: s.period,
                        value: s.value,
                        slug: comp.slug
                    })
                }

            })
        })
        setHeatYAxisData(companyNames);
        setRiskScores(riskScoresList);
        let periods = [];
        companyRiskProfile[0].riskScores.map((sc, i) => {
            if (i < 18) {
                periods.push({
                    period: sc.period
                })
            }
        });
        setHeatXAxisData(periods)
    }, [companyRiskProfile])

    const dashHeat = (item) => {
        nav(`/risk/company/${item.slug}`)
    }

    return (
        <div id="content">

            <div className="container-fluid pt-3">

                <div className="row">
                    <div className="col-xl-10 col-lg-10 col-md-9 col-12">
                        <section className="d-flex justify-content-between mb-3">
                            <div className="align-self-center mr-16">
                                <h4>{labels.WELCOME}</h4>
                                <p>{labels.WELCOME_DESC}</p>
                            </div>
                            <div>
                                <img className="bl-overview-img" src={bannerIcon} alt="overview-Img" />
                            </div>
                        </section>
                        <section className="mb-3">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                    <div className="card shadow-sm">
                                        <div className="card-header d-flex justify-content-between">
                                            <div className="align-self-center">
                                                <h6 className="mb-0">{labels.ENTITY.TITLE_1}</h6>
                                            </div>
                                            <div>
                                                <div className="form-group mb-0">
                                                    <select className="form-control form-control-sm blc_button">
                                                        <option>Past 4 Weeks</option>
                                                        <option>Past 3 Weeks</option>
                                                        <option>Past 2 Weeks</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <BubbleChart height="13rem" id="riskbubble"></BubbleChart>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                    <div className="card shadow-sm">
                                        <div className="card-header d-flex justify-content-between">
                                            <div className="align-self-center">
                                                <h6 className="mb-0">{labels.ENTITY.TITLE_2}</h6>
                                            </div>
                                            <div>
                                                <div className="form-group mb-0">
                                                    <select className="form-control form-control-sm blc_button">
                                                        <option>Past 4 Weeks</option>
                                                        <option>Past 3 Weeks</option>
                                                        <option>Past 2 Weeks</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <BarChart height="13rem" id="riskbarchart"></BarChart>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-12 mt-3">
                                    <div className="">
                                        <div className="d-flex justify-content-between mb-3">
                                            <div className="align-self-center">
                                                <h6 className="mb-0">{labels.ENTITY.TITLE_3}</h6>
                                            </div>
                                            <div>
                                                <div className="form-group mb-0">
                                                    <select className="form-control form-control-sm blc_button">
                                                        <option>Heatmap view</option>
                                                        <option>....</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="">
                                            <HeatmapChart id="companyriskscorechart" data={riskScores} yaxisData={heatYAxisData} xAxisData={heatXAxisData} categoryYField="company" categoryXField="period" yAxisVisiblity={true} height={'30rem'} dashHeat={(item) => dashHeat(item)}></HeatmapChart>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>

                    <div className="col-xl-2 col-lg-2 col-md-3 py-3 mt-n3 mob-hide border-left bg-gray">
                        <RecentActivityPanel title={labels.YOUR_ACTIVITY} data={appUpdatesJson} />
                    </div>

                </div>
            </div>

        </div>
    )
}