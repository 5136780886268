import { Column } from "primereact/column";
import { TreeTable } from "primereact/treetable";
import React, { useContext, useEffect, useState } from "react";
import fundJson from "./../../data/fund.json";
import SearchContext from "../../services/context/Search/searchContext";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";


export const MDMFundComponent = () => {
    window.scrollTo(0, 0);

    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)

    useEffect(() => {
        setBreadcrumbMenu(
            [
                { slug: '', menuName: 'Master Data', isActive: true, hasSubMenu: true },
                { slug: '/master-data/fund', menuName: 'Funds', isActive: false, hasSubMenu: true }
            ]
        )
    }, [])

    const [nodes, setNodes] = useState([]);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [loading, setLoading] = useState(true);
    const [sortField, setSortField] = useState(null); // Column to sort by
    const [sortOrder, setSortOrder] = useState(null);

    useEffect(() => {
        setNodes(loadFundNodes(first, rows));
        setLoading(false)
    }, []);

    const onSort = (event) => {
        setSortField(event.sortField);
        setSortOrder(event.sortOrder);
    };

    const loadFundNodes = (first, rows) => {
        let nodes = [];

        for (let i = 0; i < rows; i++) {
            let node = {
                key: `fund-key-${first + i}`,
                data: {
                    "fundId": fundJson[0].fundId,
                    "fundType": fundJson[0].fundType,
                    "fundName": fundJson[0].fundName,
                    "strategy": fundJson[0].strategy,
                    "status": fundJson[0].status,
                    "vinatageYear": fundJson[0].vinatageYear,
                    "size": fundJson[0].size,
                    "currency": fundJson[0].currency,
                    "manager": fundJson[0].manager,
                    "generalPartner": fundJson[0].generalPartner,
                    "limitedPartner": fundJson[0].limitedPartner,
                    "structure": fundJson[0].structure,
                    "jurisdiction": fundJson[0].jurisdiction,
                    "investmentPeriod": fundJson[0].investmentPeriod,
                    "term": fundJson[0].term,
                    "managementFee": fundJson[0].managementFee,
                    "performanceFee": fundJson[0].performanceFee,
                    "documents": fundJson[0].documents,
                    "companies": fundJson[0].companies,
                    "fundraisingHistory": fundJson[0].fundraisingHistory,
                    "contact": fundJson[0].contact
                },
                // leaf: false
            };

            nodes.push(node);
        }
        setTimeout(() => {
            let leafItem = nodes.find(n => n.leaf !== undefined && n.leaf === false);
            if (leafItem) {
                let classList = document.getElementsByClassName('p-treetable-toggler');
                for (let i = 0; i < classList.length; i++) {
                    classList[i].classList.remove('d-none')
                }
            }
            else {
                let classList = document.getElementsByClassName('p-treetable-toggler');
                for (let i = 0; i < classList.length; i++) {
                    classList[i].classList.add('d-none')
                }
            }
        }, 500)

        return nodes;
    };

    const companyGridCardContent = () => {
        return (
            <div className="companies-grid-container font-weight-600">
                <div className="grid-item">
                    <div className="card blc_card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center pb-1">
                                <div><p className="mb-0">Total Records</p></div>
                            </div>
                            <div>
                                <h4 className="font-weight-600 m-0">55</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid-item">
                    <div className="card blc_card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center pb-1">
                                <div><p className="mb-0">Exception Detected</p></div>

                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <div><h4 className="font-weight-600 m-0 text-warning">15</h4></div>
                                <div className="text-warning">
                                    <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid-item">
                    <div className="card blc_card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center pb-1">
                                <div><p className="mb-0">Resolved</p></div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <div><h4 className="font-weight-600 m-0 text-primary">8</h4></div>
                                <div className="text-primary">
                                    <i className="fa fa-check-circle" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid-item">
                    <div className="card blc_card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center pb-1">
                                <div><p className="mb-0">Resolution In-progress</p></div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <div><h4 className="font-weight-600 m-0 text-success">55</h4></div>
                                <div className="text-success">
                                    <i className="fa fa-spinner" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid-item">
                    <div className="card blc_card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center pb-1">
                                <div><p className="mb-0">Data Update Request</p></div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <h4 className="font-weight-600 m-0">10</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const columnArrangement = (info, column) => {
        let columnInfo = null;
        switch (column) {
            case 'DOCUMENT':
                columnInfo = info.data.documents;
                break;
            case 'COMPANY':
                columnInfo = info.data.companies;
                break;
            case 'HISTORY':
                columnInfo = info.data.fundraisingHistory;
                break;
            case 'CONTACT':
                columnInfo = info.data.contact;
                break;
            default:
                columnInfo = null;
                break;
        }
        return (
            <div>
                {
                    columnInfo.map((p, i)=>{
                        return (
                            <span key={`column-${column}-${info.key}-${i+1}`}>{p} <br /></span>
                        )
                    })
                }
            </div>
        )
    }

    const getTableViewContent = () => {
        return (
            <TreeTable value={nodes} onSort={onSort} sortField={sortField} sortOrder={sortOrder} reorderableColumns>
                {/* <Column className="bl-width-6rem" field="fundId" header="ID" sortable></Column> */}
                <Column className="bl-width-6rem" field="fundName" header="Name" sortable></Column>
                <Column field="fundType" header="Type" sortable></Column>
                <Column field="strategy" header="Strategy" sortable></Column>
                <Column field="status" header="Status" sortable></Column>
                <Column field="vinatageYear" header="Vinatage Year" sortable></Column>
                <Column field="size" header="Size" sortable></Column>
                {/* <Column field="currency" header="Currency" sortable></Column> */}
                <Column field="manager" header="Manager" sortable></Column>
                <Column field="generalPartner" header="General Partner" sortable></Column>
                {/* <Column field="limitedPartner" header="Limited Partner" sortable></Column> */}
                {/* <Column field="structure" header="Structure" sortable></Column> */}
                {/* <Column field="jurisdiction" header="Jurisdiction" sortable></Column>
                <Column field="investmentPeriod" header="Investment Period" sortable></Column>
                <Column field="term" header="Term" sortable></Column>
                <Column field="managementFee" header="Management Fee" sortable></Column>
                <Column field="performanceFee" header="Performance Fee" sortable></Column>
                <Column field="documents" header="Documents" body={(e)=>columnArrangement(e, 'DOCUMENT')} sortable></Column>
                <Column field="companies" header="Companies" body={(e)=>columnArrangement(e, 'COMPANY')} sortable></Column>
                <Column field="fundraisingHistory" header="History" body={(e)=>columnArrangement(e, 'HISTORY')} sortable></Column>
                <Column field="contact" header="Contact" body={(e)=>columnArrangement(e, 'CONTACT')} sortable></Column> */}
            </TreeTable>
        )
    }

    return (
        <div className="content">
            <div className="container-fluid pt-3">
                <section className="mb-3">
                    <div className="row">
                        <div className="col-xl-12 col-md-12">
                            <figure className="highlight p-3 bg-gray mb-0">
                                <pre className="m-0"><i className="fa fa-info-circle"></i> PreProd LLC: Legal Risks with High Impact identified  <a className="cp">click to view​</a></pre>
                            </figure>
                        </div>
                    </div>
                </section>
                <section className="mb-3">
                    {companyGridCardContent()}
                </section>
                <section className="mb-3">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    Funds
                                </div>
                                <div className="card-section d-none">
                                    <nav className="nav">
                                        <a className="nav-link active" aria-current="page">All</a>
                                        <a className="nav-link">Active</a>
                                        <a className="nav-link">Private</a>
                                        <a className="nav-link">Exited</a>
                                        <a className="nav-link">Public</a>
                                        <a className="nav-link">Acquired</a>
                                    </nav>
                                </div>
                                {/* <div className="card-actions p-2 d-flex justify-content-between d-none">
                                    <div className="align-self-center">
                                        <button className="btn btn-primary btn-sm mr-2 mb-0">
                                            <i className="fa fa-file-code-o mr-2" aria-hidden="true"></i> Add New Entity
                                        </button>
                                        <button className="btn btn-primary btn-sm mr-2 mb-0">
                                            <i className="fa fa-file-code-o mr-2" aria-hidden="true"></i> Edit Entity Data
                                        </button>
                                        <button className="btn btn-primary btn-sm mr-2 mb-0">
                                            <i className="fa fa-file-code-o mr-2" aria-hidden="true"></i> Upload
                                        </button>
                                        <button className="btn btn-primary btn-sm mr-2 mb-0">
                                            <i className="fa fa-search" aria-hidden="true"></i>
                                        </button>
                                        <button className="btn btn-primary btn-sm mr-2 mb-0">
                                            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                    <div className="form-group w-25 mb-0">
                                        <input type="text" className="form-control" id="blcomshst" placeholder="Search" />
                                    </div>
                                </div> */}
                                <div className="card-filter py-2">
                                    <div className="col-xl-12 col-md-12">
                                        <div className="form-row blc_button align-items-end">
                                            <div className="form-group mr-2 mb-0">
                                                <label htmlFor="inputState">Fund Status</label>
                                                <select id="inputState" className="form-control form-control-sm blc_button">
                                                    <option>Active</option>
                                                    <option>Closed</option>
                                                    <option>Liquidated</option>
                                                    <option>Suspended</option>
                                                </select>
                                            </div>
                                            <div className="form-group mr-2 mb-0">
                                                <label htmlFor="inputState">Fund Type</label>
                                                <select id="inputState" className="form-control form-control-sm blc_button">
                                                    <option>Buyout</option>
                                                    <option>Venture</option>
                                                    <option>Mezzanine</option>
                                                    <option>Real Estate</option>
                                                    <option>Infrastructure</option>
                                                    <option>Multi-Strategy</option>
                                                    <option>Other</option>
                                                </select>
                                            </div>
                                            <div className="form-group mr-2 mb-0">
                                                <label htmlFor="inputState">Fund Strategy</label>
                                                <select id="inputState" className="form-control form-control-sm blc_button">
                                                    <option>Technology Startups</option>
                                                    <option>Real Estate Development</option>
                                                    <option>Energy</option>
                                                    <option>Healthcare</option>
                                                    <option>Renewable Energy</option>
                                                    <option>Various Sectors</option>
                                                    <option>Other</option>
                                                </select>
                                            </div>
                                            <div className="form-group mr-2 mb-0">
                                                <label htmlFor="inputState">Currency</label>
                                                <select id="inputState" className="form-control form-control-sm blc_button">
                                                    <option>USD</option>
                                                    <option>EUR</option>
                                                    <option>GBP</option>
                                                    <option>JPY</option>
                                                    <option>AUD</option>
                                                    <option>Other</option>
                                                </select>
                                            </div>
                                            <div className="form-group mr-2 mb-0">
                                                <label htmlFor="inputState">Fund Structure</label>
                                                <select id="inputState" className="form-control form-control-sm blc_button">
                                                    <option>Limited Partnership</option>
                                                    <option>Limited Liability Company</option>
                                                    <option>Other</option>
                                                </select>
                                            </div>
                                            <div className="form-group mr-2 mb-0">
                                                <label htmlFor="inputState">Fund Jurisdiction</label>
                                                <select id="inputState" className="form-control form-control-sm blc_button">
                                                    <option>Delaware</option>
                                                    <option>Cayman Islands</option>
                                                    <option>Luxembourg</option>
                                                    <option>United Kingdom</option>
                                                    <option>Japan</option>
                                                    <option>Other</option>
                                                </select>
                                            </div>
                                            {/* <div className="form-group mr-2 mb-0">
                                                <label htmlFor="inputState">Investment Period</label>
                                                <select id="inputState" className="form-control form-control-sm blc_button">
                                                    <option>5 Years</option>
                                                    <option>7 Years</option>
                                                    <option>10 Years</option>
                                                    <option>Other</option>
                                                </select>
                                            </div>
                                            <div className="form-group mr-2 mb-0">
                                                <label htmlFor="inputState">Fund Term</label>
                                                <select id="inputState" className="form-control form-control-sm blc_button">
                                                    <option>10 Years</option>
                                                    <option>12 Years</option>
                                                    <option>15 Years</option>
                                                    <option>Other</option>
                                                </select>
                                            </div> */}
                                            <div className="filter-btn">
                                                <button className="btn btn-danger btn-sm mr-2">Apply Filter</button>
                                            </div>
                                            <div className="clear-btn mb-1">
                                                <a className="text-danger">Clear All</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-table" id="fundtable">
                                    {getTableViewContent()}
                                    {/* <TreeTableComponent data={companyJson}></TreeTableComponent> */}
                                </div>
                                {/* <div className="load-more">
                                    <h6>Load More</h6>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}