import React, { useContext, useEffect, useState } from "react";
import HierarchyChart from "../Graph/hierarchychart";
import eventExplorerJson from "./../../data/Risk/eventsExplorer.json";
import SearchContext from "../../services/context/Search/searchContext";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";


export const RiskEventExplorer = () => {

    window.scrollTo(0, 0);

    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)

    useEffect(() => {
        setBreadcrumbMenu(
            [
                { slug: '', menuName: 'Risk Monitor', isActive: true, hasSubMenu: true },
                { slug: '/risk/event-explorer', menuName: 'Event Explorer', isActive: false, hasSubMenu: true }
            ]
        )
    }, [])

    const [viewType, setViewType] = useState('LIST')

    const eventListContent = () => {
        return (
            <section className="mb-3">
                {
                    eventExplorerJson.map((period, i) => {
                        return (
                            <div key={`risk-event-exp-period-${i}`} className="row">
                                <div className="col-xl-12 col-md-12 col-12 mb-3">
                                    <h6>{period.eventFor}</h6>
                                </div>
                                <div className="col-md-12 col-lg-12 mb-4">
                                    <div className="card-deck">
                                        {
                                            period.categories.map((list, j) => {
                                                return (
                                                    <div key={`risk-event-exp-list-${i}${j}`} className="card">
                                                        <div className="card-header"><h6 className="mb-0">{list.eventCategory}</h6></div>
                                                        <div className="card-body p-3">
                                                            {
                                                                list.list.map((item, k) => {
                                                                    return (
                                                                        <React.Fragment key={`risk-event-exp-items-${i}${j}${k}`}>
                                                                            <div className="media mb-3">
                                                                                <div className="media-body mr-2">
                                                                                    <p className="mb-0"><small className="text-muted">{item.eventSubCategory}</small></p>
                                                                                    <a href="#" target="_blank" rel="noopener">
                                                                                        {item.newsTitle}
                                                                                    </a>
                                                                                </div>
                                                                                <img className="rounded" src={item.imgUrl} alt="Generic placeholder image" height="80px" width="80px" />
                                                                            </div>
                                                                            {
                                                                                list.list.length !== k + 1 ? (<hr />) : null
                                                                            }
                                                                        </React.Fragment>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                        <div className="card-footer bg-transparent"><p className="card-text"><small className="text-muted">View All</small></p></div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>

                                {
                                    eventExplorerJson.length !== i + 1 ? (<div className="col-12 mb-2"><hr /></div>) : null
                                }
                            </div>
                        )
                    })
                }
            </section>
        )
    }

    const graphViewContent = () => {
        return (
            <section className="mb-3">
                <div className="row">
                    <div className="col-8">
                        <HierarchyChart></HierarchyChart>
                    </div>
                    <div className="col-4 card-deck mb-4">
                        <div className="card bg-transparent">
                            <div className="card-header"><h6 className="mb-0">Science and Technology</h6></div>
                            <div className="card-body p-3">

                                <div className="media mb-3">
                                    <div className="media-body mr-2">
                                        <p className="card-text mb-0"><small className="text-muted">Breakthrough scientific discoveries</small></p>
                                        <a href="#" target="_blank" rel="noopener">
                                            Government Contracts Boost PreProd LLC's market presence, Expanding Its Influence in the Public Sector
                                        </a>
                                    </div>
                                    <img className="rounded" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQO6lwWoftqsyXY92T_493EywTKsogXVsdDQg&usqp=CAU" alt="Generic placeholder image" height="80px" width="80px" />
                                </div>
                                <hr />
                                <div className="media mb-3">
                                    <div className="media-body mr-2">
                                        <p className="card-text mb-0"><small className="text-muted">Space exploration and astronomy</small></p>
                                        <a href="#" target="_blank" rel="noopener">
                                            PreProd LLC CEO John Anderson Receives Industry Leadership Award
                                        </a>
                                    </div>
                                    <img className="rounded" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-8F_0cXlyiDHQpQFB_7GhF86XO6M1wWs0AyIbIerrDgvOHQMMiJUMuTYji__cD0uHc0k&usqp=CAU" alt="Generic placeholder image" height="80px" width="80px" />
                                </div>
                                <hr />
                                <div className="media mb-3">
                                    <div className="media-body mr-2">
                                        <p className="card-text mb-0"><small className="text-muted">Artificial intelligence and robotics</small></p>
                                        <a href="#" target="_blank" rel="noopener">
                                            PreProd LLC in talks with Leading Venture Capital Firm for Series C Funding.
                                        </a>
                                    </div>
                                    <img className="rounded" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTocanXSl5l-KRy3BgBkBzgPtnPwr7iU4pbIA&usqp=CAU" alt="Generic placeholder image" height="80px" width="80px" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    return (
        <div id="content">
            <div className="container-fluid mt-3">
                <section className="mb-3 d-flex justify-content-between">
                    <div>
                        <h6>Event Explorer</h6>
                    </div>
                    <div>
                        <button className="btn btn-primary btn-sm mr-2"><i className="fa fa-fw fa-refresh" aria-hidden="true"></i>Refresh</button>
                        <button className="btn btn-primary btn-sm mr-2"><i className="fa fa-fw fa-plus" aria-hidden="true"></i>{'Add New'}</button>
                        <button className="btn btn-primary btn-sm"><i className="fa fa-filter" aria-hidden="true"></i></button>
                    </div>
                </section>
                <section className="mb-3">
                    <div className="row">

                        <div className="col-xl-12 col-md-12 mb-3">
                            <ul className="nav nav-pills border-bottom border-light">
                                <li className="nav-item">
                                    <a className={viewType === 'LIST' ? 'nav-link active blc_nav_active' : 'nav-link'} onClick={() => setViewType('LIST')}>Event List</a>
                                </li>
                                <li className="nav-item">
                                    <a className={viewType === 'GRAPH' ? 'nav-link active blc_nav_active' : 'nav-link'} onClick={() => setViewType('GRAPH')}>View Connection between events</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
                {
                    viewType === 'LIST' ? eventListContent() : graphViewContent()
                }


            </div>
        </div>
    )
}