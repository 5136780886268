import React, { useContext, useEffect, useState } from "react";
import { SourceCommonCompanyTitle } from "../../Common/companyTitle";
import { useLocation } from "react-router-dom";
import BreadcrumbContext from "../../../../services/context/Breadcrumb/breadcrumbContext";
import { createAnalysisInfoService, getAnalysisCategoryService, getAnalysisInfoService, getCompanyInformationService, getFinanceInfoService } from "../../../../services/companyService";
import { convertCamelCaseToSeparateWords } from "../../../../services/commonService";
import { ColumnChart } from "../../../Graph/columnChart";
import { EmptyCardsContent } from "../../Common/emptyCard";

export const ICMemoAnalysisComponent = () => {

    const location = useLocation()
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)

    const [buttons] = useState([
        { name: "Export As IC Memo", icon: "cloud-download" },
        { name: "Download", icon: "download" },
        { name: "", icon: "thumb-tack" },
        { name: "", icon: "trash", action: true }
    ])
    const [companyInfo, setCompanyInfo] = useState({
        companyId: '', company: '', logo: '', website: '', description: '', address: '', employeeRange: '', fundingRound: '', valuation: '', fundingAmount: '',
        totalEmployee: '', acquisitions: '', investors: '', investments: '', exits: '', insights: '', BlScore: '', slug: '', isNew: false,
        socialNetworkUrl: [], isDataSourceAdded: false, isDocumentsAdded: false
    });
    const [isLoaded, setIsLoaded] = useState(false)

    const [isMemoContentLoaded, setIsMemoContentLoaded] = useState(false)
    const [memoIndex, setMemoIndex] = useState([])
    const [keyPeople, setKeyPeople] = useState([])
    const [isFinanceGraphDataLoaded, setIsFinanceGraphDataLoaded] = useState(false)
    const [categoryField, setCategoryField] = useState("");
    const [incomeSeries, setIncomeSeries] = useState([]);
    const [incomePeriodData, setIncomePeriodData] = useState([]);
    const [balanceSeries, setBalanceSeries] = useState([]);
    const [balancePeriodData, setBalancePeriodData] = useState([]);

    const [companyOverview, setCompanyOverview] = useState({
        competitors: [], customers: "", description: "", history: "", suppliers: "", offerings: []
    })
    const [dueDiligence, setDueDiligence] = useState({
        businessRisk: [], industryRisk: [], legalRisk: [], technologyRisk: []
    })
    const [executiveSummary, setExecutiveSummary] = useState({
        background: "", proposedTransaction: "", recommendation: []
    })
    const [exitStrategy, setExitStrategy] = useState({
        exitOptions: [], exitTiming: ""
    })
    const [financialAnalysis, setFinancialAnalysis] = useState({
        historicalPerformance: "", projections: ""
    })
    const [marketAnalysis, setMarketAnalysis] = useState({
        competitiveLandscape: "", industryOverview: "", keyMarketGrowthRates: [], targetMarket: [], trends: []
    })
    const [valuationOverview, setValuationOverview] = useState({
        DCFAnalysis: "", LBOAnalysis: "", comparableCompanies: [], precedentTransactions: []
    })

    useEffect(() => {
        document.getElementById('pageLoaderContainer').style.display = 'flex';
        getAnalysisCategory()
        setBreadcrumbMenu(
            [
                { slug: '', menuName: 'Sourcing', isActive: true, hasSubMenu: true },
                { slug: '/sourcing/overview', menuName: 'Overview', isActive: false, hasSubMenu: true },
                { slug: '', menuName: 'Investment Thesis', isActive: false, hasSubMenu: true }
            ]
        )

        setIsLoaded(false)
        let pathArray = location.pathname.split('/');
        const companySlug = pathArray.length > 3 ? pathArray[3] : 'smartbeat';
        getCompanyInformation(companySlug)
    }, [location])

    const getCompanyInformation = async (slug) => {
        const resp = await getCompanyInformationService(slug);
        setIsLoaded(true)
        if (resp && resp.data && resp.data.length) {
            if (resp.data.length && resp.data[0].keyexecutives) {
                let peoples = resp.data[0].keyexecutives.map(p => {
                    return {
                        name: p.Name,
                        about: p.Title,
                        country: resp.data[0].country ? (resp.data[0].country).toLowerCase() : 'us',
                        phone: 'NA',
                        email: 'NA',
                        url: p.employee_link
                    }
                })
                console.log("peoples ===>", peoples);
                setKeyPeople(peoples)
            }
            getCompanyAnalysisInfo(resp.data[0].companyId)
            setCompanyInfo(resp.data[0])
            getFinanceInfo(resp.data[0].companyId)
        }
    }

    const getAnalysisCategory = async () => {
        const resp = await getAnalysisCategoryService();
        if (resp && resp.success) {
            setMemoIndex(resp.data)
        }
    }

    const getCompanyAnalysisInfo = async (companyId) => {
        const resp = await getAnalysisInfoService(companyId);
        console.log("getCompanyAnalysisInfo ====>>>>", resp);
        if (resp && resp.success) {
            if (resp.data && resp.data.investmentMemorandum) {
                storeAnalysisInfo(resp.data.investmentMemorandum)
            }
            else {
                storeAndViewCompanyAnalysisInfo(companyId)
            }
        }
    }

    const storeAndViewCompanyAnalysisInfo = async (companyId) => {
        const obj = {
            companyId: companyId
        }
        const resp = await createAnalysisInfoService(obj);
        if (resp && resp.success) {
            if (resp.data && resp.data.investmentMemorandum) {
                storeAnalysisInfo(resp.data.investmentMemorandum)
            }
        }
    }

    const storeAnalysisInfo = (item) => {
        document.getElementById('pageLoaderContainer').style.display = 'none';
        setCompanyOverview(item.companyOverview)
        setDueDiligence(item.dueDiligence)
        setExecutiveSummary(item.executiveSummary)
        setExitStrategy(item.exitStrategy)
        setFinancialAnalysis(item.financialAnalysis)
        setMarketAnalysis(item.marketAnalysis)
        setValuationOverview(item.valuationOverview)
    }

    const updateCompanyInfo = () => {
        getCompanyInformation(companyInfo.slug)
    }

    const getUserNameProfile = (username) => {
        let splitName = username ? username.split(' ') : '';
        if (splitName.length > 1) {
            return `${splitName[0].charAt(0)}${splitName[1].charAt(0)}`
        }
        else return `${splitName[0].charAt(0)}${splitName[0].charAt(1)}`
    }

    const getFinanceInfo = async (companyId) => {
        setIsFinanceGraphDataLoaded(false)
        const resp = await getFinanceInfoService(companyId);
        setIsFinanceGraphDataLoaded(true)
        if (resp && resp.success && resp.data && resp.data.length) {
            if (resp.data[0].income_statement) {
                getIncomeStatementData(resp.data[0].income_statement)
            }
            if (resp.data[0].balance_sheet) {
                getBalanceSheetData(resp.data[0].balance_sheet)
            }
        }
    }

    const getIncomeStatementData = (statements) => {
        const data = statements.graph ? statements.graph : [];
        setIncomePeriodData(data);
        setCategoryField('date');
        let seriesItem = []
        for (let key in data[0]) {
            seriesItem.push({
                name: convertCamelCaseToSeparateWords(key),
                val: key
            })
        }
        const items = seriesItem.filter(item => item.val !== 'date');
        setIncomeSeries(items)
    }

    const getBalanceSheetData = (statements) => {
        const data = statements.graph ? statements.graph : [];
        setBalancePeriodData(data);
        let seriesItem = []
        for (let key in data[0]) {
            seriesItem.push({
                name: convertCamelCaseToSeparateWords(key),
                val: key
            })
        }
        const items = seriesItem.filter(item => item.val !== 'date');
        setBalanceSeries(items)
    }

    const accordionDirection = (id, targetId) => {
        const element = document.getElementById(id);
        const targetElement = document.getElementById(targetId);
        if (element.classList.contains('show')) {
            targetElement.classList.add('fa-angle-down')
            targetElement.classList.remove('fa-angle-up')
        }
        else {
            targetElement.classList.add('fa-angle-up')
            targetElement.classList.remove('fa-angle-down')
        }
    }

    const memoIndexAccordionItem = (item, primaryIndex) => {
        return (
            <div className="accordion accordion-flush" id={`memoIndexaccordion${primaryIndex}`}>
                <div className="accordion-item">
                    <h6 className={`cp mt-2 text-uppercase text-muted d-flex justify-content-between align-items-center`}>
                        <span className="accordion-header" id={`memoIndexHeader${primaryIndex}`} onClick={() => accordionDirection(`memoIndexCollapse${primaryIndex}`, `angle${primaryIndex}`)} data-toggle="collapse" data-target={`#memoIndexCollapse${primaryIndex}`} aria-expanded="false" aria-controls={`memoIndexCollapse${primaryIndex}`}>
                            <i id={`angle${primaryIndex}`} className="fa fa-angle-up accordion-button mr-2"></i>
                            {primaryIndex + 1}. {item.name}
                        </span>
                        <i className="fa fa-edit" onClick={() => updateMemoAnalysis(item.name)} data-toggle="modal" data-target="#updatememoanalysismodal"></i>
                    </h6>
                    <div id={`memoIndexCollapse${primaryIndex}`} className="accordion-collapse show collapse m-0" aria-labelledby={`memoIndexHeader${primaryIndex}`} data-parent={`#memoIndexaccordion${primaryIndex}`}>
                        <div className="accordion-body">
                            <ul className="navbar-nav">
                                {
                                    item.subCategory.map((subItem, i) => {
                                        return (
                                            <li key={`datasource-accordion-${i}`} className={`nav-item d-flex justify-content-between text-capitalize ${!subItem.isActive && 'disabled'}`}>
                                                <a className={`nav-link py-2 w-100 bl-limited-text`}>
                                                    <span className="ml-2">{primaryIndex + 1}.{i + 1} {subItem.name}</span>
                                                </a>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const [updateMemoBy, setUpdateMemoBy] = useState('')

    const updateMemoAnalysis = (type) => {
        setUpdateMemoBy(type)
    }

    const getMemoAnalysisModifyContent = () => {
        if (updateMemoBy.toUpperCase() === "EXECUTIVE SUMMARY") {
            return (
                <div>
                    <div>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex">
                                <i className="fa fa-check-square text-secondary mr-2" aria-hidden="true"></i>
                                <h6 className="text-muted small">Background</h6>
                            </div>
                            <div className="d-flex">
                                <i className="fa fa-gg-circle text-danger mr-2" aria-hidden="true"></i>
                                <h6 className="text-danger small">Use Kairos AI</h6>
                            </div>
                        </div>
                        <textarea className="form-control" defaultValue={executiveSummary.background}></textarea>
                    </div>
                    <div className="py-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex">
                                <i className="fa fa-check-square text-secondary mr-2" aria-hidden="true"></i>
                                <h6 className="text-muted small">Proposed Transaction</h6>
                            </div>
                            <div className="d-flex">
                                <i className="fa fa-gg-circle text-danger mr-2" aria-hidden="true"></i>
                                <h6 className="text-danger small">Use Kairos AI</h6>
                            </div>
                        </div>
                        <textarea className="form-control" defaultValue={executiveSummary.proposedTransaction}></textarea>
                    </div>
                    <div>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex">
                                <i className="fa fa-check-square text-secondary mr-2" aria-hidden="true"></i>
                                <h6 className="text-muted small">Recommendation</h6>
                            </div>
                            <div className="d-flex">
                                <i className="fa fa-gg-circle text-danger mr-2" aria-hidden="true"></i>
                                <h6 className="text-danger small">Use Kairos AI</h6>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body p-2">
                                <ul className="mb-0 pl-4">
                                    {
                                        executiveSummary.recommendation.map((r, i) => {
                                            return (
                                                <li key={`update-reco-exec-${i}`}>{r}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else if (updateMemoBy.toUpperCase() === "COMPANY OVERVIEW") {
            return (
                <div>
                    <div className="pb-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex">
                                <i className="fa fa-check-square text-secondary mr-2" aria-hidden="true"></i>
                                <h6 className="text-muted small">History</h6>
                            </div>
                            <div className="d-flex">
                                <i className="fa fa-gg-circle text-danger mr-2" aria-hidden="true"></i>
                                <h6 className="text-danger small">Use Kairos AI</h6>
                            </div>
                        </div>
                        <textarea className="form-control" defaultValue={companyOverview.history}></textarea>
                    </div>
                    <div className="pb-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex">
                                <i className="fa fa-check-square text-secondary mr-2" aria-hidden="true"></i>
                                <h6 className="text-muted small">Description</h6>
                            </div>
                            <div className="d-flex">
                                <i className="fa fa-gg-circle text-danger mr-2" aria-hidden="true"></i>
                                <h6 className="text-danger small">Use Kairos AI</h6>
                            </div>
                        </div>
                        <textarea className="form-control" defaultValue={companyOverview.description}></textarea>
                    </div>
                    <div className="pb-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex">
                                <i className="fa fa-check-square text-secondary mr-2" aria-hidden="true"></i>
                                <h6 className="text-muted small">OFFERINGS (PRODUCTS/SERVICES)</h6>
                            </div>
                            <div className="d-flex">
                                <i className="fa fa-gg-circle text-danger mr-2" aria-hidden="true"></i>
                                <h6 className="text-danger small">Use Kairos AI</h6>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body p-2">
                                <ul className="mb-0 pl-4">
                                    {
                                        companyOverview.offerings.map((r, i) => {
                                            return (
                                                <li key={`update-comp-offer-${i}`}>{r}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex">
                                <i className="fa fa-check-square text-secondary mr-2" aria-hidden="true"></i>
                                <h6 className="text-muted small">CUSTOMERS</h6>
                            </div>
                            <div className="d-flex">
                                <i className="fa fa-gg-circle text-danger mr-2" aria-hidden="true"></i>
                                <h6 className="text-danger small">Use Kairos AI</h6>
                            </div>
                        </div>
                        <textarea className="form-control" defaultValue={companyOverview.customers}></textarea>
                    </div>
                </div>
            )
        }
        else if (updateMemoBy.toUpperCase() === "MARKET & INDUSTRY") {
            return (
                <div>
                    <div className="pb-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex">
                                <i className="fa fa-check-square text-secondary mr-2" aria-hidden="true"></i>
                                <h6 className="text-muted small">INDUSTRY OVERVIEW</h6>
                            </div>
                            <div className="d-flex">
                                <i className="fa fa-gg-circle text-danger mr-2" aria-hidden="true"></i>
                                <h6 className="text-danger small">Use Kairos AI</h6>
                            </div>
                        </div>
                        <textarea className="form-control" defaultValue={marketAnalysis.industryOverview}></textarea>
                    </div>
                    <div className="pb-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex">
                                <i className="fa fa-check-square text-secondary mr-2" aria-hidden="true"></i>
                                <h6 className="text-muted small">TARGET MARKET</h6>
                            </div>
                            <div className="d-flex">
                                <i className="fa fa-gg-circle text-danger mr-2" aria-hidden="true"></i>
                                <h6 className="text-danger small">Use Kairos AI</h6>
                            </div>
                        </div>
                        <textarea className="form-control" defaultValue={marketAnalysis.targetMarket}></textarea>
                    </div>
                    <div className="pb-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex">
                                <i className="fa fa-check-square text-secondary mr-2" aria-hidden="true"></i>
                                <h6 className="text-muted small">COMPETITIVE LANDSCAPE</h6>
                            </div>
                            <div className="d-flex">
                                <i className="fa fa-gg-circle text-danger mr-2" aria-hidden="true"></i>
                                <h6 className="text-danger small">Use Kairos AI</h6>
                            </div>
                        </div>
                        <textarea className="form-control" defaultValue={marketAnalysis.competitiveLandscape}></textarea>
                    </div>
                    <div className="pb-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex">
                                <i className="fa fa-check-square text-secondary mr-2" aria-hidden="true"></i>
                                <h6 className="text-muted small">TRENDS</h6>
                            </div>
                            <div className="d-flex">
                                <i className="fa fa-gg-circle text-danger mr-2" aria-hidden="true"></i>
                                <h6 className="text-danger small">Use Kairos AI</h6>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body p-2">
                                <ul className="mb-0 pl-4">
                                    {
                                        marketAnalysis.trends.map((r, i) => {
                                            return (
                                                <li key={`update-market-trend-${i}`}>{r}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex">
                                <i className="fa fa-check-square text-secondary mr-2" aria-hidden="true"></i>
                                <h6 className="text-muted small">GROWTH RATES</h6>
                            </div>
                            <div className="d-flex">
                                <i className="fa fa-gg-circle text-danger mr-2" aria-hidden="true"></i>
                                <h6 className="text-danger small">Use Kairos AI</h6>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body p-2">
                                <ul className="mb-0 pl-4">
                                    {
                                        marketAnalysis.keyMarketGrowthRates.map((r, i) => {
                                            return (
                                                <li key={`update-market-growth-${i}`}>{r}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else if (updateMemoBy.toUpperCase() === "DUE-DILIGENCE") {
            return (
                <div>
                    <div className="pb-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex">
                                <i className="fa fa-check-square text-secondary mr-2" aria-hidden="true"></i>
                                <h6 className="text-muted small">INDUSTRY RISK</h6>
                            </div>
                            <div className="d-flex">
                                <i className="fa fa-gg-circle text-danger mr-2" aria-hidden="true"></i>
                                <h6 className="text-danger small">Use Kairos AI</h6>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body p-2">
                                <ul className="mb-0 pl-4">
                                    {
                                        dueDiligence.industryRisk.map((r, i) => {
                                            return (
                                                <li key={`update-duedili-ind-${i}`}>{r.risk}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="pb-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex">
                                <i className="fa fa-check-square text-secondary mr-2" aria-hidden="true"></i>
                                <h6 className="text-muted small">BUSINESS RISK</h6>
                            </div>
                            <div className="d-flex">
                                <i className="fa fa-gg-circle text-danger mr-2" aria-hidden="true"></i>
                                <h6 className="text-danger small">Use Kairos AI</h6>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body p-2">
                                <ul className="mb-0 pl-4">
                                    {
                                        dueDiligence.businessRisk.map((r, i) => {
                                            return (
                                                <li key={`update-duedili-business-${i}`}>{r.risk}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="pb-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex">
                                <i className="fa fa-check-square text-secondary mr-2" aria-hidden="true"></i>
                                <h6 className="text-muted small">LEGAL RISK</h6>
                            </div>
                            <div className="d-flex">
                                <i className="fa fa-gg-circle text-danger mr-2" aria-hidden="true"></i>
                                <h6 className="text-danger small">Use Kairos AI</h6>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body p-2">
                                <ul className="mb-0 pl-4">
                                    {
                                        dueDiligence.legalRisk.map((r, i) => {
                                            return (
                                                <li key={`update-duedili-legal-${i}`}>{r.risk}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex">
                                <i className="fa fa-check-square text-secondary mr-2" aria-hidden="true"></i>
                                <h6 className="text-muted small">TECHNOLOGY RISK</h6>
                            </div>
                            <div className="d-flex">
                                <i className="fa fa-gg-circle text-danger mr-2" aria-hidden="true"></i>
                                <h6 className="text-danger small">Use Kairos AI</h6>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body p-2">
                                <ul className="mb-0 pl-4">
                                    {
                                        dueDiligence.technologyRisk.map((r, i) => {
                                            return (
                                                <li key={`update-duedili-tech-${i}`}>{r.risk}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else if (updateMemoBy.toUpperCase() === "VALUATION OVERVIEW") {
            return (
                <div>
                    <div className="pb-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex">
                                <i className="fa fa-check-square text-secondary mr-2" aria-hidden="true"></i>
                                <h6 className="text-muted small">DCF ANALYSIS</h6>
                            </div>
                            <div className="d-flex">
                                <i className="fa fa-gg-circle text-danger mr-2" aria-hidden="true"></i>
                                <h6 className="text-danger small">Use Kairos AI</h6>
                            </div>
                        </div>
                        <textarea className="form-control" defaultValue={valuationOverview.DCFAnalysis}></textarea>
                    </div>
                    <div className="pb-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex">
                                <i className="fa fa-check-square text-secondary mr-2" aria-hidden="true"></i>
                                <h6 className="text-muted small">LBO ANALYSIS</h6>
                            </div>
                            <div className="d-flex">
                                <i className="fa fa-gg-circle text-danger mr-2" aria-hidden="true"></i>
                                <h6 className="text-danger small">Use Kairos AI</h6>
                            </div>
                        </div>
                        <textarea className="form-control" defaultValue={valuationOverview.LBOAnalysis}></textarea>
                    </div>
                    <div>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex">
                                <i className="fa fa-check-square text-secondary mr-2" aria-hidden="true"></i>
                                <h6 className="text-muted small">PRECEDENT TRANSACTIONS</h6>
                            </div>
                            <div className="d-flex">
                                <i className="fa fa-gg-circle text-danger mr-2" aria-hidden="true"></i>
                                <h6 className="text-danger small">Use Kairos AI</h6>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body p-2">
                                <ul className="mb-0 pl-4">
                                    {
                                        valuationOverview.precedentTransactions.map((r, i) => {
                                            return (
                                                <li key={`update-valuation-trans-${i}`}>{r}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else if (updateMemoBy.toUpperCase() === "EXIT") {
            return (
                <div>
                    <div className="pb-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex">
                                <i className="fa fa-check-square text-secondary mr-2" aria-hidden="true"></i>
                                <h6 className="text-muted small">EXIT TIMING</h6>
                            </div>
                            <div className="d-flex">
                                <i className="fa fa-gg-circle text-danger mr-2" aria-hidden="true"></i>
                                <h6 className="text-danger small">Use Kairos AI</h6>
                            </div>
                        </div>
                        <textarea className="form-control" defaultValue={exitStrategy.exitTiming}></textarea>
                    </div>
                    <div>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex">
                                <i className="fa fa-check-square text-secondary mr-2" aria-hidden="true"></i>
                                <h6 className="text-muted small">EXIT OPTIONS</h6>
                            </div>
                            <div className="d-flex">
                                <i className="fa fa-gg-circle text-danger mr-2" aria-hidden="true"></i>
                                <h6 className="text-danger small">Use Kairos AI</h6>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body p-2">
                                <ul className="mb-0 pl-4">
                                    {
                                        exitStrategy.exitOptions.map((r, i) => {
                                            return (
                                                <li key={`update-exit-option-${i}`}>{r}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else if (updateMemoBy.toUpperCase() === "FINANCIAL OVERVIEW") {
            return (
                <div>
                    <div className="pb-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex">
                                <i className="fa fa-check-square text-secondary mr-2" aria-hidden="true"></i>
                                <h6 className="text-muted small">Income Statement</h6>
                            </div>
                            <div className="d-flex">
                                <i className="fa fa-gg-circle text-danger mr-2" aria-hidden="true"></i>
                                <h6 className="text-danger small">Use Kairos AI</h6>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                {
                                    !isFinanceGraphDataLoaded ?
                                        (<div className="row align-items-center h-100">
                                            <div className="col"> <span className="loader"></span></div>
                                        </div>) :
                                        companyInfo.isDocumentsAdded && incomePeriodData.length ?
                                            <ColumnChart id="incomestatementmemoupdate" data={incomePeriodData} categoryField={categoryField} seriesItem={incomeSeries} height="15rem"></ColumnChart> :
                                            <EmptyCardsContent title="No Income Statement Summary data found" buttonView={false} />
                                }
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex">
                                <i className="fa fa-check-square text-secondary mr-2" aria-hidden="true"></i>
                                <h6 className="text-muted small">Balance Sheet</h6>
                            </div>
                            <div className="d-flex">
                                <i className="fa fa-gg-circle text-danger mr-2" aria-hidden="true"></i>
                                <h6 className="text-danger small">Use Kairos AI</h6>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                {
                                    !isFinanceGraphDataLoaded ?
                                        (<div className="row align-items-center h-100">
                                            <div className="col"> <span className="loader"></span></div>
                                        </div>) :
                                        companyInfo.isDocumentsAdded && balancePeriodData.length ?
                                            <ColumnChart id="balancesheetmemoupdate" data={balancePeriodData} categoryField={categoryField} seriesItem={balanceSeries} height="15rem"></ColumnChart> :
                                            <EmptyCardsContent title="No Balance Sheet Summary data found" buttonView={false} />
                                }
                            </div>
                        </div>

                    </div>
                </div>
            )
        }
        else {
            return (
                <div className="text-center align-content-center h-100">
                    <h6>No data found to update</h6>
                </div>
            )
        }
    }

    const updateMemoModalContent = () => {
        return (
            <div className="modal fade" id="updatememoanalysismodal" tabIndex="-1" role="dialog" aria-labelledby="updatememoanalysismodalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <form>
                            <div className="modal-header">
                                <h5 className="modal-title" id="updatememoanalysismodalLabel">Update {updateMemoBy}</h5>
                                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body py-0">
                                <div className="row">
                                    <div className="col-8 py-3">
                                        {getMemoAnalysisModifyContent()}
                                    </div>
                                    <div className="col-4 py-2 border-left">
                                        <div className="pb-2 border-bottom">
                                            <h6>Sources</h6>
                                            <h6 className="text-muted text-uppercase small">Public Sites</h6>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="d-flex align-items-center">
                                                    <i className="fa fa-linkedin-square mr-2" aria-hidden="true"></i>
                                                    <p className="mb-0">LinkedIn</p>
                                                </div>
                                                <i className="fa fa-check-square text-success" aria-hidden="true"></i>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="d-flex align-items-center">
                                                    <i className="fa fa-google-plus-square mr-2" aria-hidden="true"></i>
                                                    <p className="mb-0">Google News</p>
                                                </div>
                                                <i className="fa fa-check-square text-success" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div className="py-2 border-bottom">
                                            <h6 className="text-muted text-uppercase small">Reports</h6>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="d-flex align-items-center">
                                                    <i className="fa fa-area-chart mr-2" aria-hidden="true"></i>
                                                    <p className="mb-0">Financial Report 2024</p>
                                                </div>
                                                <i className="fa fa-check-square text-success" aria-hidden="true"></i>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="d-flex align-items-center">
                                                    <i className="fa fa-area-chart mr-2" aria-hidden="true"></i>
                                                    <p className="mb-0">SKU Performance</p>
                                                </div>
                                                <i className="fa fa-check-square text-success" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div className="py-2">
                                            <h6 className="text-muted text-uppercase small">Files</h6>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="d-flex align-items-center">
                                                    <i className="fa fa-file-excel-o mr-2" aria-hidden="true"></i>
                                                    <p className="mb-0">Balance Sheet</p>
                                                </div>
                                                <i className="fa fa-check-square text-success" aria-hidden="true"></i>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="d-flex align-items-center">
                                                    <i className="fa fa-file-excel-o mr-2" aria-hidden="true"></i>
                                                    <p className="mb-0">Cash flow Statement</p>
                                                </div>
                                                <i className="fa fa-check-square text-success" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-between p-2">
                                <button className="btn btn-outline-primary secondary-link btn-sm" type="button">Refresh Section</button>
                                <div>
                                    <button className="btn btn-primary btn-sm">Save & Update</button>
                                    <button className="btn btn-outline-primary secondary-link btn-sm ml-2" type="button" data-dismiss="modal">Cancel</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                {
                    !isMemoContentLoaded ? <div className="page-loader-container" id="pageLoaderContainer">
                        <div className="page-loader"></div>
                    </div> : null
                }
                <section className="mb-3">
                    {
                        isLoaded ? <SourceCommonCompanyTitle item={companyInfo} onChildInfoUpdate={updateCompanyInfo} displaySource={false} buttons={buttons}></SourceCommonCompanyTitle> : null
                    }
                </section>
                <section className="mb-3 mt-4 bl-memo-context">
                    <div className="row">
                        <div className="col-md-2 col-lg-2">
                            <ul className="navbar-nav bg-white sidebar w-100" id="accordionSidebar" >
                                {
                                    memoIndex && memoIndex.length ? memoIndex.map((item, i) => {
                                        return (
                                            <li key={`memo-index-${i}`} className={`nav-item`}>
                                                {
                                                    item.subCategory && item.subCategory.length ? (memoIndexAccordionItem(item, i)) :
                                                        <h6 className={`cp mt-2 text-uppercase text-muted d-flex justify-content-between align-items-center`} >
                                                            <span className="accordion-header">
                                                                <i className="fa fa-angle-up accordion-button mr-2"></i>
                                                                {i + 1}. {item.name}
                                                            </span>
                                                            <i className="fa fa-edit" data-toggle="modal" data-target="#updatememoanalysismodal"></i>
                                                        </h6>
                                                }
                                            </li>
                                        )
                                    }) : null
                                }
                            </ul>
                        </div>
                        <div className="col-md-7 col-lg-7 mt-3">
                            <section className="mb-4">
                                <div className="card">
                                    <h6 className="bl-section-header">Executive Summary</h6>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-8">
                                                <div>
                                                    <h6 className="text-muted text-uppercase">Background</h6>
                                                    <p>{executiveSummary.background}</p>
                                                </div>
                                                <div>
                                                    <h6 className="text-muted text-uppercase">PROPOSED TRANSACTION</h6>
                                                    <p>{executiveSummary.proposedTransaction}</p>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <h6 className="text-muted text-uppercase">Recommendation</h6>
                                                <ul>
                                                    {
                                                        executiveSummary.recommendation.map((r, i) => {
                                                            return (
                                                                <li key={`executive-summary-recomend-${i}`}>{r}</li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="mb-4">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card">
                                            <h6 className="bl-section-header">Market & Industry</h6>
                                            <div className="card-body pb-0">
                                                <div>
                                                    <h6 className="text-muted text-uppercase">Industry Overview</h6>
                                                    <p>{marketAnalysis.industryOverview}</p>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <h6 className="text-muted text-uppercase">Target Market</h6>
                                                        <p>{marketAnalysis.targetMarket}</p>
                                                    </div>
                                                    <div className="col">
                                                        <h6 className="text-muted text-uppercase">Competitive Landscape</h6>
                                                        <p>{marketAnalysis.competitiveLandscape}</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <h6 className="text-muted text-uppercase">Trends</h6>
                                                        <ul>
                                                            {
                                                                marketAnalysis.trends.map((trend, i) => {
                                                                    return (
                                                                        <li key={`trend-market-${i}`}>{trend}</li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                    <div className="col">
                                                        <h6 className="text-muted text-uppercase">Growth Rates</h6>
                                                        <ul>
                                                            {
                                                                marketAnalysis.keyMarketGrowthRates.map((growth, i) => {
                                                                    return (
                                                                        <li key={`growth-market-${i}`}>{growth}</li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="mb-4">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="card">
                                            <h6 className="bl-section-header">Income Statement</h6>
                                            <div className="card-body">
                                                {
                                                    !isFinanceGraphDataLoaded ?
                                                        (<div className="row align-items-center h-100">
                                                            <div className="col"> <span className="loader"></span></div>
                                                        </div>) :
                                                        companyInfo.isDocumentsAdded && incomePeriodData.length ?
                                                            <ColumnChart id="incomestatementmemo" data={incomePeriodData} categoryField={categoryField} seriesItem={incomeSeries} height="15rem"></ColumnChart> :
                                                            <EmptyCardsContent title="No Income Statement Summary data found" buttonView={false} />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="card">
                                            <h6 className="bl-section-header">Balance Sheet</h6>
                                            <div className="card-body">
                                                {
                                                    !isFinanceGraphDataLoaded ?
                                                        (<div className="row align-items-center h-100">
                                                            <div className="col"> <span className="loader"></span></div>
                                                        </div>) :
                                                        companyInfo.isDocumentsAdded && balancePeriodData.length ?
                                                            <ColumnChart id="balancesheetmemo" data={balancePeriodData} categoryField={categoryField} seriesItem={balanceSeries} height="15rem"></ColumnChart> :
                                                            <EmptyCardsContent title="No Balance Sheet Summary data found" buttonView={false} />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="mb-4">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card">
                                            <h6 className="bl-section-header">Due Diligence</h6>
                                            <div className="card-body pb-0">
                                                <div className="row">
                                                    <div className="col">
                                                        <h6 className="text-muted text-uppercase">Industry Risk</h6>
                                                        <ul>
                                                            {
                                                                dueDiligence.industryRisk.map((ind, i) => {
                                                                    return (
                                                                        <li key={`industry-deligence-${i}`}>{ind.risk}</li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                    <div className="col">
                                                        <h6 className="text-muted text-uppercase">Business Risk</h6>
                                                        <ul>
                                                            {
                                                                dueDiligence.businessRisk.map((bus, i) => {
                                                                    return (
                                                                        <li key={`business-deligence-${i}`}>{bus.risk}</li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                    <div className="col">
                                                        <h6 className="text-muted text-uppercase">Legal Risk</h6>
                                                        <ul>
                                                            {
                                                                dueDiligence.legalRisk.map((leg, i) => {
                                                                    return (
                                                                        <li key={`legal-deligence-${i}`}>{leg.risk}</li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                    <div className="col">
                                                        <h6 className="text-muted text-uppercase">Technology Risk</h6>
                                                        <ul>
                                                            {
                                                                dueDiligence.technologyRisk.map((tech, i) => {
                                                                    return (
                                                                        <li key={`technology-deligence-${i}`}>{tech.risk}</li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="mb-4">
                                <div className="row">
                                    <div className="col-12 mb-4">
                                        <div className="card">
                                            <h6 className="bl-section-header">Valuation Overview</h6>
                                            <div className="card-body pb-0">
                                                <div>
                                                    <h6 className="text-muted text-uppercase">Precedent Transactions</h6>
                                                    <ul>
                                                        {
                                                            valuationOverview.precedentTransactions.map((prec, i) => {
                                                                return (
                                                                    <li key={`precedent-valuation-${i}`}>{prec}</li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                                <div>
                                                    <h6 className="text-muted text-uppercase">Comparable Companies</h6>
                                                    <ul>
                                                        {
                                                            valuationOverview.comparableCompanies.map((comp, i) => {
                                                                return (
                                                                    <li key={`comp-valuation-${i}`}>{comp.companyName}</li>
                                                                )
                                                            })
                                                        }
                                                    </ul>

                                                </div>
                                                <div>
                                                    <h6 className="text-muted text-uppercase">DCF Analysis</h6>
                                                    <p>{valuationOverview.DCFAnalysis}</p>
                                                </div>
                                                <div>
                                                    <h6 className="text-muted text-uppercase">LBO Analysis</h6>
                                                    <p>{valuationOverview.LBOAnalysis}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-4">
                                        <div className="card">
                                            <h6 className="bl-section-header">Exit Strategy</h6>
                                            <div className="card-body pb-0">
                                                <div>
                                                    <h6 className="text-muted text-uppercase">Exit Options</h6>
                                                    <ul>
                                                        {
                                                            exitStrategy.exitOptions.map((exit, i) => {
                                                                return (
                                                                    <li key={`exit-options-${i}`}>{exit}</li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                                <div>
                                                    <h6 className="text-muted text-uppercase">Exit Timing</h6>
                                                    <p>{exitStrategy.exitTiming}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div className="col-md-3 col-lg-3 mt-3">
                            <section>
                                <div className="card">
                                    <h6 className="bl-section-header">Company Overview</h6>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12">
                                                <div>
                                                    <h6 className="text-muted text-uppercase">History</h6>
                                                    <p>{companyOverview.history}</p>
                                                </div>
                                                <div>
                                                    <h6 className="text-muted text-uppercase">Description</h6>
                                                    <p>{companyOverview.description}</p>
                                                </div>
                                                <div>
                                                    <h6 className="text-muted text-uppercase">Offerings (Products/Services)</h6>
                                                    <ul>
                                                        {
                                                            companyOverview.offerings.map((o, i) => {
                                                                return (
                                                                    <li key={`company-offer-${i}`}>{o}</li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                                <div>
                                                    <h6 className="text-muted text-uppercase">Customers</h6>
                                                    <p>{companyOverview.customers}</p>
                                                </div>
                                                <div>
                                                    <h6 className="text-muted text-uppercase">Management Team</h6>
                                                    {
                                                        keyPeople && keyPeople.length ? keyPeople.map((people, i) => {
                                                            return (
                                                                <div key={`key-people-comp-${i}`} className="mb-2 d-flex justify-content-betwe">
                                                                    <span className="bl-profile-circle text-uppercase mr-2 align-self-center">{getUserNameProfile(people.name)}</span>
                                                                    <div className="d-lg-inline text-dark text-capitalize">
                                                                        <p className="mb-0 text-small">{people.name}</p>
                                                                        <p className="bl-truncate-line-1 text-small mb-0"><b>{people.about}</b></p>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }) : null
                                                    }
                                                </div>
                                                <div className="mt-3">
                                                    <h6 className="text-muted text-uppercase">Competitors</h6>
                                                    <ul>
                                                        {
                                                            companyOverview.competitors.map((comp, i) => {
                                                                return (
                                                                    <li key={`company-offer-${i}`}>{comp.companyName}</li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
                {updateMemoModalContent()}
            </div>
        </div>
    )
}