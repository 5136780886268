import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import itemListJson from "./../../data/Tasks/taskList.json";
import taskMenuJson from "./../../data/Tasks/subMenu.json";
import { TaskListViewComponent } from "./taskListView";
import SearchContext from "../../services/context/Search/searchContext";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";


export const TaskCategoryComponent = () => {

    window.scrollTo(0, 0);
    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const location = useLocation()

    useEffect(() => {
        const slug = getLocationSlug();
        const category = taskMenuJson[0].list.find(c => c.slug === slug);
        if (category) {
            setTaskCategory(category.name)
            if (category.name === 'All') {
                let items = itemListJson.map(item => {
                    let categoryItem = taskMenuJson[0].list.find((menu) => menu.name === item.category)
                    if (categoryItem) {
                        item.icon = categoryItem.icon
                    }
                    return item;
                });
                setTaskItems(items)
            }
            else {
                let items = itemListJson.filter(item => {
                    if (item.category === category.name) {
                        let categoryItem = taskMenuJson[0].list.find((menu) => menu.name === item.category)
                        if (categoryItem) {
                            item.icon = categoryItem.icon
                        }
                        return item;
                    }

                });
                setTaskItems(items)
            }

        }
        setBreadcrumbMenu(
            [
                { slug: '', menuName: 'Tasks', isActive: true, hasSubMenu: true },
                { slug: `/tasks/category/${slug}`, menuName: category.name, isActive: false, hasSubMenu: true }
            ]
        )
    }, [location])

    const [taskItems, setTaskItems] = useState([])
    const [taskCategory, setTaskCategory] = useState('')

    const getLocationSlug = () => {
        let pathArray = location.pathname.split('/');
        if (pathArray && pathArray.length > 3) {
            return pathArray[3];
        }
    }

    return (
        <div id="content">
            <div className="container-fluid pt-3">

                <div className="row">
                    <div className="col-12">
                        <section className="d-flex justify-content-between mb-3">
                            <div className="align-self-center mr-16">
                                <h6>{taskCategory}</h6>
                            </div>
                        </section>
                        <section className="mb-3">
                            <TaskListViewComponent list={taskItems} all={taskCategory === 'All'}></TaskListViewComponent>
                        </section>
                    </div>

                </div>
            </div>

        </div>
    )
}