import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import reportJson from "./../../data/Analytics/reports.json";
import analyticsSubMenuListJson from "./../../data/Analytics/subMenu.json";
import riskSubMenuListJson from "./../../data/Risk/risk-submenu.json";
import companyRiskProfile from "./../../data/Risk/companyRiskProfile.json";
import appsMenuJson from "./../../data/Apps/subMenu.json";
import AIModelMenuJson from "./../../data/AIModel/subMenu.json";
import dataSphereMenuJson from "./../../data/Spheres/subMenu.json";
import workflowMenuJson from "./../../data/Workflows/subMenu.json";
import rulesMenuJson from "./../../data/Rules/subMenu.json";
import tasksMenuJson from "./../../data/Tasks/subMenu.json";
import toolkitMenuJson from "./../../data/Toolkit/subMenu.json";
import { addDealbookService, addNewCompanyService, getActiveCompanyCountService, getDealStagesService, getDealbookListService } from "../../services/companyService";
import { AnalyzeCompanyContext } from "../../services/context/analyzeCompanyContext";
import pdfIcon from "./../../assets/img/pdf.png"
import missingImg from "./../../assets/img/logoplaceholder.png";
import { SourcingContext } from "../../services/context/sourcingContext";


export const SubMenuComponent = () => {

    const { setAnalyzedCompanyInfo } = useContext(AnalyzeCompanyContext)
    const { sourcingCompanyList } = useContext(SourcingContext)
    const location = useLocation();
    const nav = useNavigate();
    const [activeSubTab, setActiveSubTab] = useState('');
    const [activeSupSubMenu, setActiveSupSubMenu] = useState('');
    const [sourceCompanySlug, setSourceCompanySlug] = useState('');
    const [pinnedReport, setPinnedReport] = useState([]);
    const [pinnedCompany, setPinnedCompany] = useState([]);
    const [analyticsSubMenuList, SetAnalyticsSubMenuList] = useState([]);
    const [riskSubMenuList, SetriskSubMenuList] = useState([]);
    const [appSubMenu, setAppSubMenu] = useState([]);
    const [aiModelSubMenu, setAIModelSubMenu] = useState([]);
    const [toolkitSubMenu, setToolkitSubMenu] = useState([]);
    const [sphereSubMenu, setSphereSubMenu] = useState([]);
    const [workflowSubMenu, setWorkflowSubMenu] = useState([]);
    const [ruleSubMenu, setRuleSubMenu] = useState([]);
    const [tasksSubMenu, setTasksSubMenu] = useState([]);
    const [sourcingPinnedCompanies, setSourcingPinnedCompanies] = useState([
        {
            companyName: 'Vista Equity Partners',
            slug: 'vista-equity-partners',
            enable: true
        },
        {
            companyName: 'The Carlyle Group',
            slug: 'the-carlyle-group',
            enable: true
        },
        {
            companyName: "Salesforce",
            slug: "salesforce",
            enable: true
        },
        {
            companyName: "Custom View A",
            slug: "custom-view-a",
            enable: false
        },
        {
            companyName: "Custom View B",
            slug: "custom-view-b",
            enable: false
        }
    ])
    const [companyCount, setCompanyCount] = useState(0)
    const [dealStages, setDealStages] = useState([])
    const [mainMenuItem, setMainMenuItem] = useState('')
    const [companySite, setCompanySite] = useState('')
    const [dealbookList, setDealbookList] = useState([])
    const [analyzeType, setAnalyzeType] = useState('DEAL')
    const [selectedFileId, setSelectedFileId] = useState(null)
    const [topAnalysedCompany, setTopAnalysedCompany] = useState([])

    useEffect(() => {
        const path = mainPageContent();
        setMainMenuItem(path)
        setActiveSubTab(getSubMenu())
    }, [location])

    useEffect(() => {
        if (sourcingCompanyList && sourcingCompanyList.length) {
            const top5Companies = sourcingCompanyList.slice(0, 5);
            setTopAnalysedCompany(top5Companies)
        }
    }, [sourcingCompanyList])

    useEffect(() => {
        let item = dataSphereMenuJson.map(items => {
            let itemList = items.list.filter(li => li.isActive);
            items.list = itemList;
            return items;
        })
        setSphereSubMenu(item)
    }, [dataSphereMenuJson])

    useEffect(() => {
        let item = AIModelMenuJson.map(items => {
            let itemList = items.list.filter(li => li.isActive);
            items.list = itemList;
            return items;
        })
        setAIModelSubMenu(item)
    }, [AIModelMenuJson])

    useEffect(() => {
        let item = toolkitMenuJson.map(items => {
            let itemList = items.list.filter(li => li.isActive);
            items.list = itemList;
            return items;
        })
        setToolkitSubMenu(item)
    }, [toolkitMenuJson])

    useEffect(() => {
        let item = appsMenuJson.map(items => {
            let itemList = items.list.filter(li => li.isActive);
            items.list = itemList;
            return items;
        })
        setAppSubMenu(item)
    }, [appsMenuJson])

    useEffect(() => {
        let reports = reportJson.filter(r => r.pinned && r.isActive);
        setPinnedReport(reports)
    }, [reportJson])

    useEffect(() => {
        let item = workflowMenuJson.map(items => {
            let itemList = items.list.filter(li => li.isActive);
            items.list = itemList;
            return items;
        })
        setWorkflowSubMenu(item)
    }, [workflowMenuJson])

    useEffect(() => {
        let item = analyticsSubMenuListJson.map((items) => {
            let itemList = items.list.filter((li) => li.isActive);
            items.list = itemList;
            return items;
        });
        SetAnalyticsSubMenuList(item)
    }, [analyticsSubMenuListJson])

    useEffect(() => {
        let items = riskSubMenuListJson.map(item => {
            let itemList = item.list.filter(li => li.isActive);
            item.list = itemList;
            return item;
        });
        SetriskSubMenuList(items)
    }, [riskSubMenuListJson])

    useEffect(() => {
        let items = rulesMenuJson.map(item => {
            let itemList = item.list.filter(li => li.isActive);
            item.list = itemList;
            return item;
        });
        setRuleSubMenu(items)
    }, [rulesMenuJson])

    useEffect(() => {
        let items = tasksMenuJson.map(item => {
            let itemList = item.list.filter(li => li.isActive);
            item.list = itemList;
            return item;
        });
        setTasksSubMenu(items)
    }, [tasksMenuJson])

    useEffect(() => {
        if (mainMenuItem === 'sourcing') {
            getActiveCompanyCount();
            getCompanyDealStages();
            getDealbookList()
        }
    }, [location, mainMenuItem])

    useEffect(() => {
        let companies = companyRiskProfile.filter(comp => comp.isActive && comp.isPinned);
        setPinnedCompany(companies)
    }, [companyRiskProfile])

    const getActiveCompanyCount = async () => {
        const resp = await getActiveCompanyCountService();
        if (resp && resp.success) {
            setCompanyCount(resp.data[0].ActiveCompanyCount)
        }
    }

    const getCompanyDealStages = async () => {
        const resp = await getDealStagesService();
        if (resp && resp.success) {
            setDealStages(resp.data)
        }
    }

    const getDealbookList = async () => {
        const resp = await getDealbookListService();
        if (resp && resp.success) {
            setDealbookList(resp.data)
        }
    }

    const addCompanyInfo = async (e) => {
        e.preventDefault()
        if (analyzeType === 'DEAL') {
            addDealbook()
        }
        else {
            if (!companySite.trim()) {
                return alert('Please enter a avalid URL!')
            }
            document.getElementById('pageLoaderContainer').style.display = 'flex';
            let obj = {
                companyUrl: companySite
            }
            const resp = await addNewCompanyService(obj)
            if (resp && resp.success) {
                setCompanySite('')
                setAnalyzedCompanyInfo(resp.data)
                getActiveCompanyCount();
                getCompanyDealStages();
                window.$('#addCompanyModal').modal('toggle');
            }
            else {
                alert("Couldn't find company details, Please try again!")
            }
            document.getElementById('pageLoaderContainer').style.display = 'none';
        }
    }

    const addDealbook = async () => {
        if (!companySite.trim()) {
            return alert('Please enter a deal name!')
        }
        document.getElementById('pageLoaderContainer').style.display = 'flex';
        const obj = {
            id: selectedFileId,
            companyName: companySite
        }
        const resp = await addDealbookService(obj);
        setTimeout(() => {
            if (resp && resp.success) {
                setCompanySite('')
                setAnalyzedCompanyInfo(resp.data)
                getActiveCompanyCount();
                getCompanyDealStages();
                window.$('#addCompanyModal').modal('toggle');
            }
            else {
                alert("Couldn't added deal info, Please try again!")
            }
            document.getElementById('pageLoaderContainer').style.display = 'none';
        }, 10000)

    }

    const getSubMenu = () => {
        setActiveSupSubMenu('')
        let pathArray = location.pathname.split('/');
        if (pathArray && pathArray.length > 2) {
            if (pathArray[2] === 'reports' && pathArray.length === 4) {
                return pathArray[3]
            }
            else if (pathArray[2] === 'reports' && pathArray.length === 5) {
                setActiveSupSubMenu(`${pathArray[3]}-${pathArray[4]}`)
                return pathArray[3]
            }
            else if (pathArray[2] === 'reports' && pathArray[3] === 'category' && pathArray.length === 6) {
                setActiveSupSubMenu(pathArray[5])
                return pathArray[4]
            }
            else if (pathArray[1] === 'risk' && pathArray[2] === 'company' && pathArray.length >= 4) {
                let company = companyRiskProfile.find(comp => comp.isActive && comp.slug === pathArray[3]);
                let _pathName = 'overview';
                if (company && company.isPinned) {
                    setActiveSupSubMenu(`${company.slug}-${company.companyId}`);
                    _pathName = pathArray[3];
                }
                return _pathName
            }
            else if (pathArray[1] === 'sourcing' && pathArray[2] === 'company' && pathArray.length > 4) {
                setActiveSupSubMenu(pathArray[4])
                setSourceCompanySlug(pathArray[3])
                return pathArray[2]
            }
            else if (pathArray[1] === 'sourcing' && pathArray[2] === 'deal-stage' && pathArray.length == 4) {
                setActiveSupSubMenu(pathArray[3])
                setSourceCompanySlug(pathArray[3])
                return pathArray[2]
            }
            else if (pathArray[1] === 'apps' && pathArray.length > 3) {
                return pathArray[3]
            }
            else if (pathArray[1] === 'ai-model' && pathArray.length > 3) {
                return pathArray[3]
            }
            else if (pathArray[1] === 'toolkit' && pathArray.length > 3) {
                return pathArray[3]
            }
            else if (pathArray[1] === 'workflow' && pathArray.length > 3) {
                return pathArray[3]
            }
            else if (pathArray[1] === 'rules' && pathArray.length > 3) {
                return pathArray[3]
            }
            else if (pathArray[1] === 'tasks' && pathArray.length > 3) {
                return pathArray[3]
            }
            return pathArray[2]
        }
    }

    const mainPageContent = () => {
        let pathArray = location.pathname.split('/');
        if (pathArray && pathArray.length > 1) {
            return pathArray[1];
        }

    }

    const getSubMenuContent = () => {
        const mainMenu = mainPageContent();
        if (mainMenu === 'master-data') {
            return masterdataSubMenu();
        }
        else if (mainMenu === 'analytics') {
            return analyticsSubMenu();
        }
        else if (mainMenu === 'risk') {
            return riskSubMenu()
        }
        else if (mainMenu === 'sourcing') {
            return sourcingSubMenu()
        }
        else if (mainMenu === 'apps') {
            return appsSubMenu()
        }
        else if (mainMenu === 'ai-model') {
            return AIModelSubMenu()
        }
        else if (mainMenu === 'toolkit') {
            return ToolkitSubMenu()
        }
        else if (mainMenu === 'data-sphere') {
            return dataSphereSubMenu()
        }
        else if (mainMenu === 'workflow') {
            return getWorkflowsSubMenu()
        }
        else if (mainMenu === 'rules') {
            return getRulesSubMenu()
        }
        else if (mainMenu === 'tasks') {
            return getTasksSubMenu()
        }
        else {
            return commonSubMenu();
        }
    }

    const masterdataSubMenu = () => {

        return (
            <ul id="submenucontent" className="navbar-nav navbar-light sidebar accordion fixed-top sub-menu close" >
                <div className="sidebar-heading pb-2 mt-4">
                    ENTITIES
                </div>
                <li className={activeSubTab === 'companies' ? 'nav-item selected' : 'nav-item'}>
                    <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav('/master-data/companies'); setActiveSubTab('companies') }}>
                        <span><i className="fa fa-fw fa-building"></i> Companies</span></button>
                </li>
                <li className={activeSubTab === 'fund' ? 'nav-item selected' : 'nav-item'}>
                    <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav('/master-data/fund'); setActiveSubTab('fund') }}>
                        <span><i className="fa fa-fw fa-usd"></i> Funds</span></button>
                </li>
                <li className={activeSubTab === 'deal' ? 'nav-item selected' : 'nav-item'}>
                    <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav('/master-data/deal'); setActiveSubTab('deal') }}>
                        <span><i className="fa fa-fw fa-handshake-o" aria-hidden="true"></i> Deals</span></button>
                </li>
                <li className={activeSubTab === 'my-reports' ? 'nav-item selected' : 'nav-item'}>
                    <button className="nav-link d-flex justify-content-between align-items-center">
                        <span><i className="fa fa-fw fa-money" aria-hidden="true"></i> Investors</span></button>
                </li>

                <li className={activeSubTab === 'event-explorer' ? 'nav-item selected' : 'nav-item'}>
                    <button className="nav-link d-flex justify-content-between align-items-center">
                        <span><i className="fa fa-fw fa-gavel"></i> Legal Entities</span></button>
                </li>
                <li className={activeSubTab === 'my-reports' ? 'nav-item selected' : 'nav-item'}>
                    <button className="nav-link d-flex justify-content-between align-items-center">
                        <span><i className="fa fa-fw fa-credit-card-alt" aria-hidden="true"></i> Investments</span></button>
                </li>
                <li className={activeSubTab === 'my-reports' ? 'nav-item selected' : 'nav-item'}>
                    <button className="nav-link d-flex justify-content-between align-items-center">
                        <span><i className="fa fa-fw fa-user" aria-hidden="true"></i> People</span></button>
                </li>

                <div className="sidebar-heading pb-2 mt-4">
                    MORE
                </div>
                <li className={activeSubTab === 'Referance' ? 'nav-item selected' : 'nav-item'}>
                    <button className="nav-link d-flex justify-content-between align-items-center">
                        <span><i className="fa fa-fw fa-link"></i> Referance Data</span></button>
                </li>
                <li className={activeSubTab === 'Manage Entity' ? 'nav-item selected' : 'nav-item'}>
                    <button className="nav-link d-flex justify-content-between align-items-center">
                        <span><i className="fa fa-fw fa-sliders"></i> Manage Entity</span></button>
                </li>
                <li className={activeSubTab === 'permissions' ? 'nav-item selected' : 'nav-item'}>
                    <button className="nav-link d-flex justify-content-between align-items-center">
                        <span><i className="fa fa-fw fa-cog" aria-hidden="true"></i> Permissions</span></button>
                </li>
                <li className={activeSubTab === 'integration' ? 'nav-item selected' : 'nav-item'}>
                    <button className="nav-link d-flex justify-content-between align-items-center">
                        <span><i className="fa fa-fw fa-plug" aria-hidden="true"></i> Integration</span></button>
                </li>

                <li className={activeSubTab === 'execptions' ? 'nav-item selected' : 'nav-item'}>
                    <button className="nav-link d-flex justify-content-between align-items-center">
                        <span><i className="fa fa-fw fa-exclamation-triangle"></i> Exceptions</span></button>
                </li>

            </ul>
        )
    }

    const getNavigationLink = (item, sectionType, mainMenu) => {
        if (sectionType === 'CATEGORIES') {
            if (mainMenu) {
                return `/analytics/reports/category/${mainMenu.slug}/${item.slug}`
            }
            return `/analytics/reports/${item.slug}`
        }
        else {
            return `/analytics/${item.slug}`
        }
    }

    const changeAccordionBGColor = (parentId, childId) => {
        const childElement = document.getElementById(childId);
        if (childElement.classList.contains('show')) {
            const parentElement = document.getElementById(parentId);
            parentElement.classList.remove('bl-bg-black')
        }
        else {
            const parentElement = document.getElementById(parentId);
            parentElement.classList.add('bl-bg-black')
        }
    }

    const accordionMenusContent = (menu, list, indx, type, sectionType) => {
        return (
            <div key={`${type}-list-${indx}`} id={`${type}parent${indx}`} className="accordion bl-menu-accordion nav-link m-0 bl-bg-black">
                <div className="accordion-item">
                    <h6 className={`accordion-header cp ${activeSubTab === menu.slug ? 'text-white' : ''}`} id={`${type}submenuheading${indx}`} onClick={() => changeAccordionBGColor(`${type}parent${indx}`, `${type}submenucollapseitem-${indx}`)} data-toggle="collapse" data-target={`#${type}submenucollapseitem-${indx}`} aria-expanded="false" aria-controls={`${type}submenucollapseitem-${indx}`}>
                        <span><i className={`fa fa-fw ${menu.icon}`}></i> {menu.name}</span>
                        <span className="bl-accordion-icon border-0 bl-pr-point3">
                            <i className="fa fa-angle-down accordion-button m-0" onClick={() => changeAccordionBGColor(`${type}parent${indx}`, `${type}submenucollapseitem-${indx}`)} data-toggle="collapse" data-target={`#${type}submenucollapseitem-${indx}`} aria-expanded="false" aria-controls={`${type}submenucollapseitem-${indx}`}></i>
                        </span>
                    </h6>
                    <div id={`${type}submenucollapseitem-${indx}`} className="accordion-collapse show collapse m-0" aria-labelledby={`${type}submenuheading${indx}`}>
                        <div className="accordion-body pb-2">
                            <ul className="navbar-nav">
                                {
                                    list.map((subItem, i) => {
                                        return (
                                            <li key={`${type}${subItem.slug}-${i}-${indx}`} className={activeSupSubMenu === subItem.slug ? 'nav-item mb-0 selected' : 'nav-item mb-0'}>
                                                <button key={`${type}-sub-menu-item-${indx}-${i}`} className={`nav-link d-flex justify-content-between align-items-center m-0 bl-pr-point3 ${subItem.isDisabled ? 'disabled' : ''}`} onClick={() => { nav(getNavigationLink(subItem, sectionType, menu)); setActiveSubTab(subItem.slug) }}>
                                                    <span className="ml-3">{subItem.name}</span>
                                                    {
                                                        sectionType === 'CATEGORIES' ? <span className="bl-report-count">{subItem.count ? `${subItem.count}` : '0'}</span> : null
                                                    }
                                                </button>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const getAnalyticsSubMenu = (list, sectionType) => {
        return (
            <>
                {
                    list.map((menu, i) => {
                        return (
                            <li key={`analytics-sub-${menu.name}-${sectionType}`} className={activeSubTab === menu.slug ? 'nav-item selected' : 'nav-item'}>
                                {
                                    menu.list && menu.list.length ? accordionMenusContent(menu, menu.list, i, 'analytics', sectionType)
                                        : (
                                            <button className={`nav-link d-flex justify-content-between align-items-center ${menu.isDisabled ? 'disabled' : ''}`} onClick={() => { nav(getNavigationLink(menu, sectionType)); setActiveSubTab(menu.slug) }}>
                                                <span><i className={`fa fa-fw ${menu.icon}`}></i> {menu.name}</span>
                                                {
                                                    sectionType === 'CATEGORIES' ? <span className="bl-report-count">{menu.count ? `${menu.count}` : '0'}</span> : null
                                                }
                                            </button>
                                        )
                                }

                            </li >
                        )
                    })
                }
            </>
        )
    }

    const analyticsSubMenu = () => {

        return (
            <ul id="submenucontent" className="navbar-nav navbar-light sidebar accordion fixed-top sub-menu close" >

                <li className={activeSubTab === 'overview' ? 'nav-item selected mt-3' : 'nav-item mt-3'}>
                    <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav('/analytics/overview'); setActiveSubTab('overview') }}>
                        <span><i className="fa fa-fw fa-tachometer"></i> Overview</span></button>
                </li>
                {
                    pinnedReport.length > 0 ? (<div className="sidebar-heading pb-2 mt-4">
                        PINNED REPORTS
                    </div>) : null
                }
                {
                    pinnedReport.map((p, i) => {
                        return (
                            <li key={`pinned-report-${i + 1}`} className={activeSupSubMenu === `${p.catSlug}-${p.reportId}` ? 'nav-item selected' : 'nav-item'}>
                                <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav(`/analytics/reports/${p.catSlug}/${p.reportId}`); setActiveSupSubMenu(`${p.catSlug}-${p.reportId}`) }}>
                                    <span><i className="fa fa-fw fa-thumb-tack"></i> {p.reportName}</span></button>
                            </li>
                        )
                    })
                }
                {
                    analyticsSubMenuList.map((items, i) => {
                        if (items.list.length) {
                            return (
                                <React.Fragment key={`analytics-sub-menu-${items.menuType}-${i + 1}`}>
                                    <div key={`analytics-sub-header-${i + 1}`} className="sidebar-heading pb-2 mt-4">
                                        {items.menuType}
                                    </div>
                                    {
                                        getAnalyticsSubMenu(items.list, items.menuType)
                                    }
                                </React.Fragment>
                            )
                        }

                    })
                }


            </ul>
        )
    }

    const riskSubMenu = () => {

        return (
            <ul id="submenucontent" className="navbar-nav navbar-light sidebar accordion fixed-top sub-menu close" >

                <li className={activeSubTab === 'overview' ? 'nav-item selected mt-3' : 'nav-item mt-3'}>
                    <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav('/risk/overview'); setActiveSubTab('overview') }}>
                        <span><i className="fa fa-fw fa-tachometer"></i> Overview</span></button>
                </li>
                <div className="sidebar-heading pb-2 mt-4">
                    PINNED COMPANIES
                </div>
                {
                    pinnedCompany.map((p, i) => {
                        return (
                            <li key={`pinned-companies-${i + 1}`} className={activeSupSubMenu === `${p.slug}-${p.companyId}` ? 'nav-item selected' : 'nav-item'}>
                                <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav(`/risk/company/${p.slug}`); setActiveSupSubMenu(`${p.slug}-${p.companyId}`) }}>
                                    <span><i className="fa fa-fw fa-thumb-tack"></i> {p.company}</span></button>
                            </li>
                        )
                    })
                }
                {
                    riskSubMenuList.map((items, i) => {
                        return (
                            <React.Fragment key={`risk-menu-items-${i + 1}`}>
                                <div className="sidebar-heading pb-2 mt-4">
                                    {items.menuType}
                                </div>
                                {
                                    items.list.map((item, j) => {
                                        return (
                                            <li key={`risk-menu-${j + 1}`} className={activeSubTab === item.slug ? 'nav-item selected' : 'nav-item'}>
                                                <button className={`nav-link d-flex justify-content-between align-items-center ${item.isDisabled ? 'disabled' : ''}`} onClick={() => { nav(`/risk/${item.slug}`); setActiveSubTab(item.slug) }}>
                                                    <span><i className={`fa fa-fw ${item.icon}`}></i> {item.name}</span></button>
                                            </li>
                                        )
                                    })
                                }
                            </React.Fragment>
                        )
                    })
                }

            </ul>
        )
    }

    const getCompanySourceStatus = (state) => {
        let count = 0;
        companyRiskProfile.map(c => {
            if (c.sourcingStatus[c.sourcingStatus.length - 1].stage === state) {
                count++;
            }
        })
        return count;
    }

    const getDealStageIcon = (stage) => {
        let icon = '';
        switch (stage) {
            case "prospect":
                icon = 'fa-newspaper-o';
                break;
            case "dueDiligence":
                icon = "fa-usd";
                break;
            case "negotiations":
                icon = "fa-medium";
                break;
            case "closed":
                icon = "fa-window-close";
                break;
            default:
                icon = "";
                break;
        }
        return icon;
    }

    const getDealStageColor = (stage) => {
        let stageClass = '';
        switch (stage) {
            case "prospect":
                stageClass = 'bl-prospects';
                break;
            case "dueDiligence":
                stageClass = "bl-due-diligence";
                break;
            case "negotiations":
                stageClass = "bl-negotiation";
                break;
            case "closed":
                stageClass = "bl-closed";
                break;
            default:
                stageClass = "";
                break;
        }
        return stageClass;
    }

    const sourcingSubMenu = () => {
        let pathArray = location.pathname.split('/');
        if (pathArray.length > 2 && pathArray[2] === 'company') {
            if (pathArray[3].includes('project')) {
                return (
                    <ul id="submenucontent" className="navbar-nav navbar-light sidebar accordion fixed-top sub-menu close" >
                        <li className={'nav-item mt-3'}>
                            <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav('/sourcing/overview'); setActiveSubTab('overview') }}>
                                <span><i className="fa fa-fw fa-arrow-left"></i> Main Menu</span></button>
                        </li>
                        <div className="sidebar-heading pb-2 mt-4">
                            GENERAL
                        </div>
                        <li className={activeSupSubMenu === `summary` ? 'nav-item selected' : 'nav-item'}>
                            <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav(`/sourcing/company/${sourceCompanySlug}/summary`); setActiveSupSubMenu(`summary`) }}>
                                <span><i className="fa fa-fw fa-user"></i> Summary</span></button>
                        </li>
                        <div className="sidebar-heading pb-2 mt-4">
                            Analysis
                        </div>
                        <li className={activeSupSubMenu === `ic-memo-analysis` ? 'nav-item selected' : 'nav-item'}>
                            <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav(`/sourcing/company/${sourceCompanySlug}/ic-memo-analysis`); setActiveSupSubMenu(`ic-memo-analysis`) }}>
                                <span><i className="fa fa-fw fa-book"></i> Investment Thesis</span></button>
                        </li>
                        <li className={activeSupSubMenu === `company-data-source` ? 'nav-item selected' : 'nav-item'}>
                            <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav(`/sourcing/company/${sourceCompanySlug}/company-data-source`); setActiveSupSubMenu(`company-data-source`) }}>
                                <span><i className="fa fa-fw fa-database"></i> View Data Repository</span></button>
                        </li>
                    </ul>
                )

            }
            return (
                <ul id="submenucontent" className="navbar-nav navbar-light sidebar accordion fixed-top sub-menu close" >

                    <li className={'nav-item mt-3'}>
                        <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav('/sourcing/overview'); setActiveSubTab('overview') }}>
                            <span><i className="fa fa-fw fa-arrow-left"></i> Main Menu</span></button>
                    </li>
                    <div className="sidebar-heading pb-2 mt-4">
                        GENERAL
                    </div>
                    <li className={activeSupSubMenu === `profile` ? 'nav-item selected' : 'nav-item'}>
                        <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav(`/sourcing/company/${sourceCompanySlug}/profile`); setActiveSupSubMenu(`profile`) }}>
                            <span><i className="fa fa-fw fa-user"></i> Profile</span></button>
                    </li>
                    <li className={activeSupSubMenu === `financial` ? 'nav-item selected' : 'nav-item'}>
                        <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav(`/sourcing/company/${sourceCompanySlug}/financial`); setActiveSupSubMenu(`financial`) }}>
                            <span><i className="fa fa-fw fa-usd"></i> Financial</span></button>
                    </li>
                    <li className={activeSupSubMenu === `risk` ? 'nav-item selected' : 'nav-item'}>
                        <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav(`/sourcing/company/${sourceCompanySlug}/risk`); setActiveSupSubMenu(`risk`) }}>
                            <span><i className="fa fa-fw fa-exclamation-triangle"></i> Risks</span></button>
                    </li>
                    <li className={activeSupSubMenu === `esg` ? 'nav-item selected' : 'nav-item'}>
                        <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav(`/sourcing/company/${sourceCompanySlug}/esg`); setActiveSupSubMenu(`esg`) }}>
                            <span><i className="fa fa-fw fa-etsy"></i> ESG</span></button>
                    </li>
                    <li className={activeSupSubMenu === `people` ? 'nav-item selected' : 'nav-item'}>
                        <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav(`/sourcing/company/${sourceCompanySlug}/people`); setActiveSupSubMenu(`people`) }}>
                            <span><i className="fa fa-fw fa-users"></i> People</span></button>
                    </li>
                    <li className={activeSupSubMenu === `all-news` ? 'nav-item selected' : 'nav-item'}>
                        <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav(`/sourcing/company/${sourceCompanySlug}/all-news`); setActiveSupSubMenu(`all-news`) }}>
                            <span><i className="fa fa-fw fa-newspaper-o"></i> News & Events</span></button>
                    </li>
                    <li className={activeSupSubMenu === `compare` ? 'nav-item selected' : 'nav-item'}>
                        <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav(`/sourcing/company/${sourceCompanySlug}/compare`); setActiveSupSubMenu(`compare`) }}>
                            <span><i className="fa fa-fw fa-sliders"></i> Comparable Companies</span></button>
                    </li>
                    <div className="sidebar-heading pb-2 mt-4">
                        Analysis
                    </div>
                    <li className={activeSupSubMenu === `ic-memo-analysis` ? 'nav-item selected' : 'nav-item'}>
                        <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav(`/sourcing/company/${sourceCompanySlug}/ic-memo-analysis`); setActiveSupSubMenu(`ic-memo-analysis`) }}>
                            <span><i className="fa fa-fw fa-book"></i> Investment Thesis</span></button>
                    </li>
                    {/* <div className="sidebar-heading pb-2 mt-4">
                        CONFIGURATION
                    </div> */}
                    <li className={activeSupSubMenu === `company-data-source` ? 'nav-item selected' : 'nav-item'}>
                        <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav(`/sourcing/company/${sourceCompanySlug}/company-data-source`); setActiveSupSubMenu(`company-data-source`) }}>
                            <span><i className="fa fa-fw fa-database"></i> View Data Repository</span></button>
                    </li>
                    {/* <div className="sidebar-heading pb-2 mt-4">
                        NEWS BY CATEGORY
                    </div>
                    <li className={activeSupSubMenu === `all-news` ? 'nav-item selected' : 'nav-item'}>
                        <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav(`/sourcing/company/${sourceCompanySlug}/all-news`); setActiveSupSubMenu(`all-news`) }}>
                            <span><i className="fa fa-fw fa-globe"></i> All</span></button>
                    </li>
                    <li className={activeSupSubMenu === `diligence` ? 'nav-item selected' : 'nav-item'}>
                        <button className="nav-link d-flex justify-content-between align-items-center disabled" onClick={() => { nav(`/sourcing/diligence`); setActiveSupSubMenu(`diligence`) }}>
                            <span><i className="fa fa-fw fa-bell"></i> Company Updates</span></button>
                    </li>
                    <li className={activeSupSubMenu === `negotiations` ? 'nav-item selected' : 'nav-item'}>
                        <button className="nav-link d-flex justify-content-between align-items-center disabled" onClick={() => { nav(`/sourcing/negotiations`); setActiveSupSubMenu(`negotiations`) }}>
                            <span><i className="fa fa-fw fa-exchange"></i> Transactions</span></button>
                    </li>
                    <li className={activeSupSubMenu === `negotiations` ? 'nav-item selected' : 'nav-item'}>
                        <button className="nav-link d-flex justify-content-between align-items-center disabled" onClick={() => { nav(`/sourcing/negotiations`); setActiveSupSubMenu(`negotiations`) }}>
                            <span><i className="fa fa-fw fa-bullhorn"></i> People Movement</span></button>
                    </li>
                    <li className={activeSupSubMenu === `negotiations` ? 'nav-item selected' : 'nav-item'}>
                        <button className="nav-link d-flex justify-content-between align-items-center disabled" onClick={() => { nav(`/sourcing/negotiations`); setActiveSupSubMenu(`negotiations`) }}>
                            <span><i className="fa fa-fw fa-handshake-o"></i> Partnerships</span></button>
                    </li>
                    <li className={activeSupSubMenu === `negotiations` ? 'nav-item selected' : 'nav-item'}>
                        <button className="nav-link d-flex justify-content-between align-items-center disabled" onClick={() => { nav(`/sourcing/negotiations`); setActiveSupSubMenu(`negotiations`) }}>
                            <span>More</span></button>
                    </li> */}
                </ul>
            )
        }
        else {
            return (
                <ul id="submenucontent" className="navbar-nav navbar-light sidebar accordion fixed-top sub-menu close" >
                    <button className="btn btn-danger bg-danger m-2 align-self-center text-nowrap bl-w-fill-available btn-sm" data-toggle="modal" data-target="#addCompanyModal">
                        <span><i className="fa fa-fw fa-plus-circle"></i> Analyze New</span></button>
                    <li className={activeSubTab === 'overview' ? 'nav-item selected mt-2' : 'nav-item mt-2'}>
                        <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav('/sourcing/overview'); setActiveSubTab('overview') }}>
                            <span><i className="fa fa-fw fa-tachometer"></i> Overview</span></button>
                    </li>
                    <li className={activeSubTab === 'all-company' ? 'nav-item selected' : 'nav-item'}>
                        <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav('/sourcing/all-company'); setActiveSubTab('all-company') }}>
                            <span><i className="fa fa-fw fa-th-large"></i> All Companies</span>
                            {
                                companyCount && (
                                    <a className="bg-white text-black px-1 rounded font-smaller">{companyCount}</a>
                                )
                            }
                        </button>
                    </li>
                    <div className="sidebar-heading pb-2 mt-4">
                        Pinned
                    </div>
                    {
                        sourcingPinnedCompanies.map((pinned, i) => {
                            return (
                                <li key={`pinned-companies-${i}-${pinned.slug}`} className={'nav-item'}>
                                    <button className={`nav-link d-flex justify-content-between align-items-center ${pinned.enable ? '' : 'disabled'}`} onClick={() => { nav(`/sourcing/company/${pinned.slug}/profile`); setActiveSupSubMenu(`profile`) }}>
                                        <span><i className="fa fa-fw fa-thumb-tack"></i> {pinned.companyName}</span></button>
                                </li>
                            )
                        })
                    }

                    <div className="sidebar-heading pb-2 mt-4">
                        BY STATUS
                    </div>
                    {
                        dealStages && dealStages.map((stage, i) => {
                            return <li key={`dealstage-${i}`} className={activeSupSubMenu === stage.stage ? 'nav-item selected' : 'nav-item'}>
                                <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav(`/sourcing/deal-stage/${stage.stage}`); setActiveSupSubMenu(stage.stage) }}>
                                    <span><i className={`fa fa-fw ${getDealStageIcon(stage.stage)}`}></i> {stage.statusName}</span><span className={`bl-report-count ${getDealStageColor(stage.stage)}`}>{stage.totalCount}</span></button>
                            </li>
                        })
                    }
                    {/* <div className="sidebar-heading pb-2 mt-4">
                        CONFIGURATION
                    </div>
                    <li className={activeSubTab === `data-source` ? 'nav-item selected' : 'nav-item'}>
                        <button className="nav-link d-flex justify-content-between align-items-center disabled" onClick={() => { nav(`/sourcing/prospect`); setActiveSupSubMenu(`prospect`) }}>
                            <span><i className="fa fa-fw fa-asterisk"></i> Data Sources</span></button>
                    </li>
                    <li className={activeSubTab === `ai-models` ? 'nav-item selected' : 'nav-item'}>
                        <button className="nav-link d-flex justify-content-between align-items-center disabled" onClick={() => { nav(`/sourcing/diligence`); setActiveSupSubMenu(`diligence`) }}>
                            <span><i className="fa fa-fw fa-microchip"></i> AI Models</span></button>
                    </li>
                    <li className={activeSubTab === `preference` ? 'nav-item selected' : 'nav-item'}>
                        <button className="nav-link d-flex justify-content-between align-items-center disabled" onClick={() => { nav(`/sourcing/negotiations`); setActiveSupSubMenu(`negotiations`) }}>
                            <span><i className="fa fa-fw fa-bell"></i> Alert Preferences</span></button>
                    </li> */}

                </ul>
            )
        }

    }

    const appsSubMenu = () => {
        return (
            <ul id="submenucontent" className="navbar-nav navbar-light sidebar accordion fixed-top sub-menu close" >

                <li className={activeSubTab === 'overview' ? 'nav-item selected mt-3' : 'nav-item mt-3'}>
                    <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav('/apps/overview'); setActiveSubTab('overview') }}>
                        <span><i className="fa fa-fw fa-tachometer"></i> Overview</span></button>
                </li>

                {
                    appSubMenu.map((items, i) => {
                        return (
                            <React.Fragment key={`apps-menu-items-${i + 1}`}>
                                <div className="sidebar-heading pb-2 mt-4">
                                    {items.menuType}
                                </div>
                                {
                                    items.list.map((item, j) => {
                                        return (
                                            <li key={`apps-menu-${j + 1}`} className={activeSubTab === item.slug ? 'nav-item selected' : 'nav-item'}>
                                                <button className={`nav-link d-flex justify-content-between align-items-center ${item.isDisabled ? 'disabled' : ''}`} onClick={() => { nav(`/apps/category/${item.slug}`); setActiveSubTab(item.slug) }}>
                                                    <span><i className={`fa fa-fw ${item.icon}`}></i> {item.name}</span></button>
                                            </li>
                                        )
                                    })
                                }
                            </React.Fragment>
                        )
                    })
                }

            </ul>
        )
    }

    const AIModelSubMenu = () => {
        return (
            <ul id="submenucontent" className="navbar-nav navbar-light sidebar accordion fixed-top sub-menu close" >

                <li className={activeSubTab === 'overview' ? 'nav-item selected mt-3' : 'nav-item mt-3'}>
                    <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav('/ai-model/overview'); setActiveSubTab('overview') }}>
                        <span><i className="fa fa-fw fa-tachometer"></i> Overview</span></button>
                </li>

                {
                    aiModelSubMenu.map((items, i) => {
                        return (
                            <React.Fragment key={`ai-model-menu-items-${i + 1}`}>
                                <div className="sidebar-heading pb-2 mt-4">
                                    {items.menuType}
                                </div>
                                {
                                    items.list.map((item, j) => {
                                        return (
                                            <li key={`ai-model-menu-${j + 1}`} className={activeSubTab === item.slug ? 'nav-item selected' : 'nav-item'}>
                                                <button className={`nav-link d-flex justify-content-between align-items-center ${item.isDisabled ? 'disabled' : ''}`} onClick={() => { nav(`/ai-model/category/${item.slug}`); setActiveSubTab(item.slug) }}>
                                                    <span><i className={`fa fa-fw ${item.icon}`}></i> {item.name}</span></button>
                                            </li>
                                        )
                                    })
                                }
                            </React.Fragment>
                        )
                    })
                }

            </ul>
        )
    }

    const ToolkitSubMenu = () => {
        return (
            <ul id="submenucontent" className="navbar-nav navbar-light sidebar accordion fixed-top sub-menu close" >

                <li className={activeSubTab === 'overview' ? 'nav-item selected mt-3' : 'nav-item mt-3'}>
                    <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav('/toolkit/overview'); setActiveSubTab('overview') }}>
                        <span><i className="fa fa-fw fa-tachometer"></i> Overview</span></button>
                </li>

                {
                    toolkitSubMenu.map((items, i) => {
                        return (
                            <React.Fragment key={`toolkit-menu-items-${i + 1}`}>
                                <div className="sidebar-heading pb-2 mt-4">
                                    {items.menuType}
                                </div>
                                {
                                    items.list.map((item, j) => {
                                        return (
                                            <li key={`toolkit-menu-${j + 1}`} className={activeSubTab === item.slug ? 'nav-item selected' : 'nav-item'}>
                                                <button className={`nav-link d-flex justify-content-between align-items-center ${item.isDisabled ? 'disabled' : ''}`} onClick={() => { nav(`/toolkit/category/${item.slug}`); setActiveSubTab(item.slug) }}>
                                                    <span className="bl-truncate-line-1" title={item.name}><i className={`fa fa-fw ${item.icon}`}></i> {item.name}</span></button>
                                            </li>
                                        )
                                    })
                                }
                            </React.Fragment>
                        )
                    })
                }

            </ul>
        )
    }

    const dataSphereSubMenu = () => {
        return (
            <ul id="submenucontent" className="navbar-nav navbar-light sidebar accordion fixed-top sub-menu close" >
                {
                    sphereSubMenu.map((items, i) => {
                        return (
                            <React.Fragment key={`data-sphere-menu-items-${i + 1}`}>
                                <div className="sidebar-heading pb-2 mt-4">
                                    {items.menuType}
                                </div>
                                {
                                    items.list.map((item, j) => {
                                        return (
                                            <li key={`data-sphere-menu-${j + 1}`} className={activeSubTab === item.slug ? 'nav-item selected' : 'nav-item'}>
                                                <button className={`nav-link d-flex justify-content-between align-items-center ${item.isDisabled ? 'disabled' : ''}`} onClick={() => { nav(`/data-sphere/${item.slug}`); setActiveSubTab(item.slug) }}>
                                                    <span><i className={`fa fa-fw ${item.icon}`}></i> {item.name}</span></button>
                                            </li>
                                        )
                                    })
                                }
                            </React.Fragment>
                        )
                    })
                }

            </ul>
        )
    }

    const getWorkflowsSubMenu = () => {
        return (
            <ul id="submenucontent" className="navbar-nav navbar-light sidebar accordion fixed-top sub-menu close" >

                <li className={activeSubTab === 'overview' ? 'nav-item selected mt-3' : 'nav-item mt-3'}>
                    <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav('/workflow/overview'); setActiveSubTab('overview') }}>
                        <span><i className="fa fa-fw fa-tachometer"></i> Overview</span></button>
                </li>

                {
                    workflowSubMenu.map((items, i) => {
                        return (
                            <React.Fragment key={`workflow-menu-items-${i + 1}`}>
                                <div className="sidebar-heading pb-2 mt-4">
                                    {items.menuType}
                                </div>
                                {
                                    items.list.map((item, j) => {
                                        return (
                                            <li key={`workflow-menu-${j + 1}`} className={activeSubTab === item.slug ? 'nav-item selected' : 'nav-item'}>
                                                <button className={`nav-link d-flex justify-content-between align-items-center ${item.isDisabled ? 'disabled' : ''}`} onClick={() => { nav(`/workflow/category/${item.slug}`); setActiveSubTab(item.slug) }}>
                                                    <span><i className={`fa fa-fw ${item.icon}`}></i> {item.name}</span></button>
                                            </li>
                                        )
                                    })
                                }
                            </React.Fragment>
                        )
                    })
                }

            </ul>
        )
    }

    const getRulesSubMenu = () => {
        return (
            <ul id="submenucontent" className="navbar-nav navbar-light sidebar accordion fixed-top sub-menu close" >

                <li className={activeSubTab === 'overview' ? 'nav-item selected mt-3' : 'nav-item mt-3'}>
                    <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav('/rules/overview'); setActiveSubTab('overview') }}>
                        <span><i className="fa fa-fw fa-tachometer"></i> Overview</span></button>
                </li>

                {
                    ruleSubMenu.map((items, i) => {
                        return (
                            <React.Fragment key={`rules-menu-items-${i + 1}`}>
                                <div className="sidebar-heading pb-2 mt-4">
                                    {items.menuType}
                                </div>
                                {
                                    items.list.map((item, j) => {
                                        return (
                                            <li key={`rules-menu-${j + 1}`} className={activeSubTab === item.slug ? 'nav-item selected' : 'nav-item'}>
                                                <button className={`nav-link d-flex justify-content-between align-items-center ${item.isDisabled ? 'disabled' : ''}`} onClick={() => { nav(`/rules/category/${item.slug}`); setActiveSubTab(item.slug) }}>
                                                    <span><i className={`fa fa-fw ${item.icon}`}></i> {item.name}</span></button>
                                            </li>
                                        )
                                    })
                                }
                            </React.Fragment>
                        )
                    })
                }

            </ul>
        )
    }

    const getTasksSubMenu = () => {
        return (
            <ul id="submenucontent" className="navbar-nav navbar-light sidebar accordion fixed-top sub-menu close" >

                <li className={activeSubTab === 'overview' ? 'nav-item selected mt-3' : 'nav-item mt-3'}>
                    <button className="nav-link d-flex justify-content-between align-items-center" onClick={() => { nav('/tasks/overview'); setActiveSubTab('overview') }}>
                        <span><i className="fa fa-fw fa-tachometer"></i> Overview</span></button>
                </li>

                {
                    tasksSubMenu.map((items, i) => {
                        return (
                            <React.Fragment key={`tasks-menu-items-${i + 1}`}>
                                <div className="sidebar-heading pb-2 mt-4">
                                    {items.menuType}
                                </div>
                                {
                                    items.list.map((item, j) => {
                                        return (
                                            <li key={`task-menu-${j + 1}`} className={activeSubTab === item.slug ? 'nav-item selected' : 'nav-item'}>
                                                <button className={`nav-link d-flex justify-content-between align-items-center ${item.isDisabled ? 'disabled' : ''}`} onClick={() => { nav(`/tasks/category/${item.slug}`); setActiveSubTab(item.slug) }}>
                                                    <span><i className={`fa fa-fw ${item.icon}`}></i> {item.name}</span></button>
                                            </li>
                                        )
                                    })
                                }
                            </React.Fragment>
                        )
                    })
                }

            </ul>
        )
    }

    const commonSubMenu = () => {
        return (
            <ul id="submenucontent" className="navbar-nav navbar-light sidebar accordion fixed-top sub-menu close" >
                <div className="sidebar-heading py-2">
                    General Settings
                </div>
            </ul>
        )
    }

    function containsProtocolOrWWW(url) {
        const pattern = /^(http:\/\/|https:\/\/|www\.)/i;
        return pattern.test(url);
    }

    const getSourceCompanyName = (data) => {
        if (data.company && !containsProtocolOrWWW(data.company)) {
            return data.company;
        }
        else {
            return data.external_data && data.external_data.companyName ? data.external_data.companyName : data.company ? data.company : ''
        }
    }

    const handleImageError = (e) => {
        e.target.src = missingImg;
    }

    const openSourceCompanyProfile = (item) => {
        window.$('#addCompanyModal').modal('toggle');
        const navPage = item.source.toLowerCase() === 'project' ? 'summary' : 'profile';
        nav(`/sourcing/company/${item.slug}/${navPage}`)
    }

    const addCompanyContent = () => {
        return (
            <div className="modal fade" id="addCompanyModal" tabIndex="-1" role="dialog" aria-labelledby="addCompanyModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <form onSubmit={(e) => addCompanyInfo(e)}>
                            <div className="modal-header">
                                <h5 className="modal-title" id="addCompanyModalLabel">Analyze New</h5>
                                <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body py-0">
                                <div className="row">
                                    <div className="col-2 p-0 bl-modal-nav-sec">
                                        <div className={`d-flex align-items-center py-3 pl-2 cp ${analyzeType === 'COMPANY' ? 'bl-modal-active-nav' : ''}`} onClick={() => setAnalyzeType('COMPANY')}>
                                            <i className="fa fa-building-o pr-2" aria-hidden="true"></i>
                                            <h6 className="mb-0">Company</h6>
                                        </div>
                                        <div className={`d-flex align-items-center py-3 pl-2 cp ${analyzeType === 'DEAL' ? 'bl-modal-active-nav' : ''}`} onClick={() => setAnalyzeType('DEAL')}>
                                            <i className="fa fa-delicious pr-2" aria-hidden="true"></i>
                                            <h6 className="mb-0">Project/Deal</h6>
                                        </div>
                                    </div>
                                    <div className="col-10 pt-3 pb-2">
                                        <div className="form-group">
                                            <div className="row col-12 p-0 m-0">
                                                <label htmlFor="companysite" className="col-3 col-form-label pl-0">{analyzeType === 'DEAL' ? 'Name' : 'URL'}</label>
                                                <div className="col-9 pr-0">
                                                    <input type="text" id="companysite" className="form-control" value={companySite} onChange={(e) => setCompanySite(e.target.value)} placeholder={analyzeType === 'DEAL' ? 'Please enter a name for your Project or Deal' : 'Enter a valid website url'} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bl-min-h-9rem">
                                        {
                                            analyzeType === 'DEAL' ?
                                                <div className="form-group mb-0">
                                                    <div className="row col-12 p-0 m-0">
                                                        <label htmlFor="companysite" className="col-3 col-form-label pl-0">Or Select from Library</label>
                                                        <div className="col-9 pr-0">
                                                            {
                                                                dealbookList.length && dealbookList.map((file, i) => {
                                                                    return (
                                                                        <div key={`data-source-${i}`} className="form-check form-check-inline pb-2">
                                                                            <input className="form-check-input" id={`filedeal${i}`} type="radio" name="dealbook" onChange={() => { setSelectedFileId(file.id); setCompanySite(file.companyName) }} />
                                                                            <img className="mr-2 rounded" src={pdfIcon} height="20px" alt="Generic image" />
                                                                            <label className="mb-0 form-check-label" htmlFor={`filedeal${i}`}>{file.fileName}</label>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                : (
                                                    <div className="form-group mb-0">
                                                        <div className="row col-12 p-0 m-0">
                                                            <label htmlFor="companysite" className="col-3 col-form-label pl-0">Recently Analyzed</label>
                                                            <div className="col-9 pr-0">
                                                                {
                                                                    topAnalysedCompany.length && topAnalysedCompany.map((comp, i) => {
                                                                        const companyLogo = comp.logo ? comp.logo : missingImg;
                                                                        return (
                                                                            <div key={`topanalysedcompany${i}`} className="d-flex align-items-center pb-2 cp" onClick={() => openSourceCompanyProfile(comp)}>
                                                                                <img src={companyLogo} onError={handleImageError} className="rounded mr-2" height="20px" alt="logo" />
                                                                                <p className="mr-2 mb-0 text-muted">{getSourceCompanyName(comp)}</p>
                                                                                <a className="text-muted">{comp.website}</a>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                        }
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer p-2">
                                <button className="btn btn-outline-primary secondary-link btn-sm" type="button" data-dismiss="modal">Cancel</button>
                                <button type="submit" className="btn btn-primary btn-sm">Proceed</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }


    return (
        <div className="sub-menu-content">
            {getSubMenuContent()}
            {addCompanyContent()}
        </div>
    )
}