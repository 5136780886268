import React, { useContext, useEffect, useState } from "react";
import { GridViewComponent } from "../gridView";
import { TableViewComponent } from "../tableView";
import reportsJson from "./../../../data/Analytics/reports.json";
import SearchContext from "../../../services/context/Search/searchContext";
import BreadcrumbContext from "../../../services/context/Breadcrumb/breadcrumbContext";



export const AnalyticsManageReportComponent = () => {
    window.scrollTo(0, 0);

    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)

    useEffect(() => {
        setBreadcrumbMenu(
            [
                { slug: '', menuName: 'Analytics', isActive: true, hasSubMenu: true },
                { slug: '/analytics/manage-report', menuName: 'Manage Reports', isActive: false, hasSubMenu: true }
            ]
        )
    }, [])

    const [viewType, setViewType] = useState('LIST');
    const [reportList, setReportList] = useState([]);
    const [reportColumn] = useState([
        'Category', 'Description', 'Report Type', 'User Groups'
    ])

    useEffect(() => {
        setViewType('LIST');
        setReportList(reportsJson)
    }, [])

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <section className="mb-3 d-flex justify-content-between">
                    <div>
                        <h6>Published Reports</h6>
                    </div>
                    <div>
                    <button className="btn btn-primary btn-sm mr-2"><i className="fa fa-fw fa-plus mr-1" aria-hidden="true"></i>{'Publish New Report'}</button>
                    <button className="btn btn-primary btn-sm mr-2" onClick={() => setViewType('GRID')}><i className="fa fa-th-large" aria-hidden="true"></i></button>
                    <button className="btn btn-primary btn-sm" onClick={() => setViewType('LIST')}><i className="fa fa-list" aria-hidden="true"></i></button>
                    </div>
                </section>
                <section className="mb-3">
                    {
                        viewType === 'GRID' ?
                            <GridViewComponent reports={reportList}></GridViewComponent> : <TableViewComponent data={reportList} reportColumn={reportColumn} pinRemove={true}></TableViewComponent>
                    }

                </section>
            </div>
        </div>
    )
}