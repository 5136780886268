import React from "react";


export const WorkflowCardComponent = ({ item }) => {


    return (
        <div className="card shadow-sm mb-3 bl-ai-model-card-deck">
            <div className="card-header">
                <div className="d-flex">
                    <div className="mr-2 ml-0 bl-recommended-app-icon">
                        <img className="img-fluid card-img-top bl-recommended-app-icon p-1" src={item.Icon} alt="Card image cap" />
                    </div>
                    <div className="align-self-center">
                        <h6 className="mb-0 text-nowrap">{item.title}</h6>
                        <p className="mb-0 font-small text-muted text-nowrap">{item.category}</p>
                    </div>
                </div>
                <p className="my-1 bl-desc-truncate-line-1 font-small">{item.description}</p>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-7">
                        <p className="text-muted font-small mb-1">Steps:</p>
                        <ol className="pl-4 mb-0">
                            {
                                item.Steps.map((step, j) => {
                                    return (
                                        <li key={`workflow-item-step-${j}`} className="font-small">{step}</li>
                                    )
                                })
                            }
                        </ol>
                    </div>
                    <div className="col-5 pr-0 py-0">
                    <p className="text-muted font-small mb-1">App Connections:</p>
                        {
                            item.Apps.map((f, j) => {
                                return (
                                    <span key={`workflow-apps-${j}`} className={`badge bl-filter-chip d-inline-block mb-2`}>{f}</span>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}