import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ColumnChart } from "../../Graph/columnChart";
import { SourceCommonCompanyListComponent } from "../Common/companyListView";
import { SourceCommonCompanyTitle } from "../Common/companyTitle";
import companyRiskProfile from "./../../../data/Risk/companyRiskProfile.json";
import SearchContext from "../../../services/context/Search/searchContext";
import BreadcrumbContext from "../../../services/context/Breadcrumb/breadcrumbContext";
import listViewLoader from "./../../../assets/img/loading-animation.gif";
import { SourcingContext } from "../../../services/context/sourcingContext";

export const SourceCompanyCompareComponent = () => {

    window.scrollTo(0, 0);
    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const { sourcingCompanyList } = useContext(SourcingContext)
    const location = useLocation()

    useEffect(() => {
        setBreadcrumbMenu(
            [
                { slug: '', menuName: 'Sourcing', isActive: true, hasSubMenu: true },
                { slug: '/sourcing/overview', menuName: 'Overview', isActive: false, hasSubMenu: true },
                { slug: '', menuName: 'Compare', isActive: false, hasSubMenu: true }
            ]
        )
    }, [location])

    const [companyInfo, setCompanyInfo] = useState({
        company: '', logo: '', website: '', description: '', address: '', employeeRange: '', fundingRound: '', valuation: '', fundingAmount: '',
        totalEmployee: '', acquisitions: '', investors: '', investments: '', exits: '', insights: ''
    });
    const [companyList, setCompanyList] = useState([]);
    const [columns] = useState([
        'Total Funding', 'Valuation', 'Headquarters'
    ])
    const [categoryField, setCategoryField] = useState("date");
    const [fundingSeries, setFundingSeries] = useState([]);
    const [fundingData, setFundingData] = useState([]);
    const [revenueSeries, setRevenueSeries] = useState([]);
    const [revenueData, setRevenueData] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        getFundingGraphData()
        getRevenueGraphData()
    }, [location, companyRiskProfile])

    useEffect(() => {
        setIsLoaded(sourcingCompanyList.length > 0)
        if (sourcingCompanyList && sourcingCompanyList.length) {
            setCompanyList([...sourcingCompanyList])
        }
    }, [sourcingCompanyList])

    const getFundingGraphData = () => {
        let items = companyRiskProfile.map(c => {
            return {
                company: c.company,
                funding: parseInt(c.valuation)
            }
        })
        setFundingData(items);
        setCategoryField('company');
        setFundingSeries([
            {
                name: "Total Funding", val: 'funding'
            }
        ])
    }

    const getRevenueGraphData = () => {
        let items = companyRiskProfile.map(c => {
            return {
                company: c.company,
                revenue: parseInt(c.revenue)
            }
        })
        setRevenueData(items);
        setCategoryField('company');
        setRevenueSeries([
            {
                name: "Total Revenue", val: 'revenue'
            }
        ])
    }

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <section className="mb-3">
                    <div className="row">
                        <div className="col-xl-6 col-md-6 col-12 align-self-center">
                            <h6>Comparable Companies</h6>
                        </div>
                        <div className="col-xl-6 col-md-6 col-12 text-right align-self-center">
                            <button className="btn btn-primary btn-sm mr-2 mb-0">
                                <i className="fa fa-fw fa-exchange" aria-hidden="true" title="Compare"></i>
                            </button>
                            <button className="btn btn-primary btn-sm mr-2 mb-0">
                                <i className="fa fa-fw fa-download" aria-hidden="true" title="Download"></i>
                            </button>
                            <button className="btn btn-primary btn-sm mr-2 mb-0">
                                <i className="fa fa-fw fa-thumb-tack" aria-hidden="true"></i>
                            </button>
                            <button className="btn btn-primary btn-sm mr-2 mb-0">
                                <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </section>
                <section className="mb-3 d-none">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-deck">
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="align-self-center">
                                            <h6 className="mb-0">{'Top 10 Comparable Companies by Funding'}</h6>
                                        </div>
                                        <div>
                                            <div className="form-group mb-0">
                                                <select className="form-control form-control-sm blc_button">
                                                    <option>This Week</option>
                                                    <option>This Month</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        {
                                            fundingData.length ? <ColumnChart id="fundingcompare" data={fundingData} categoryField={categoryField} seriesItem={fundingSeries} height="10rem"></ColumnChart> : null
                                        }
                                    </div>
                                </div>
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="align-self-center">
                                            <h6 className="mb-0">{'Top 10 Comparable Companies by Revenue'}</h6>
                                        </div>
                                        <div>
                                            <div className="form-group mb-0">
                                                <select className="form-control form-control-sm blc_button">
                                                    <option>This Week</option>
                                                    <option>This Month</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        {
                                            revenueData.length ? <ColumnChart id="revenuecompare" data={revenueData} categoryField={categoryField} seriesItem={revenueSeries} height="10rem"></ColumnChart> : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="mb-3">
                    {
                        !isLoaded ? (
                            <div className="col text-center">
                                <img className="bl-list-loader" src={listViewLoader} />
                            </div>
                        ) :
                            companyList.length ? <SourceCommonCompanyListComponent list={companyList} action={true}></SourceCommonCompanyListComponent> : null
                    }
                </section>
            </div>
        </div>
    )
}