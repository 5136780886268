import React, { useEffect, useState } from 'react';
import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5Percent from "@amcharts/amcharts5/percent";


export const PIEChartComponent = ({ data, height = '150px', hideCenterText=false }) => {

    const [chartRoot, setChartRoot] = useState(null);

    useEffect(() => {
        if (chartRoot) {
            chartRoot.dispose();
        }


        let root = am5.Root.new("piechartdiv");

        root.setThemes([
            am5themes_Animated.new(root)
        ]);
        root._logo.dispose();

        var chart = root.container.children.push(am5Percent.PieChart.new(root, {
            innerRadius: hideCenterText ? 0 : 50,
            layout: root.verticalLayout
        }));

        let series = chart.series.push(am5Percent.PieSeries.new(root, {
            valueField: "size",
            categoryField: "sector",
            // valueField: '70'
        }));

        series.data.setAll(data);

        series.appear(1000, 100);

        let label = root.tooltipContainer.children.push(am5.Label.new(root, {
            x: am5.p50,
            y: am5.p50,
            centerX: am5.p50,
            centerY: am5.p50,
            fill: am5.color('#5285d8'),
            fontSize: 14,
            text: hideCenterText ? '' : '70%'
        }));

        series.get("colors").set("colors", [
            // am5.color('#d6d8dd'),
            // am5.color('#3965e6'),

            am5.color('#6794dc'),
            am5.color('#67b7dc'),
            am5.color("#dc67ce"),
            am5.color("#8067dc"),
            // am5.color(0x5aaa95),
            // am5.color(0x86a873),
            // am5.color(0xbb9f06)
        ]);

        series.data.setAll(data);

        series.labels.template.set("visible", false);
        series.ticks.template.set("visible", false);
        setChartRoot(root);

        return () => {
            // Cleanup when unmounting the component
            if (chartRoot) {
                chartRoot.dispose();
            }

        };
    }, [data]);

    return <div id="piechartdiv" style={{ width: '100%', height: height }}></div>;
};
