import React, { useState } from 'react';
import BreadcrumbContext from './breadcrumbContext';

const BreadcrumbContextProvider = ({ children }) => {

    const [breadcrumbMenu, setBreadcrumbMenu] = useState([]);

    return (
        <BreadcrumbContext.Provider value={{ breadcrumbMenu, setBreadcrumbMenu }}>
            {children}
        </BreadcrumbContext.Provider>
    );
};

export default BreadcrumbContextProvider;