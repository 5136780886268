import React from "react";
import { useState } from "react";
import { PIEChartComponent } from "../Graph/piechart";


export const CompanyProfileSidebarComponent = () => {

    const [pieItem, setPieItem] = useState([
        { sector: "In Progress", size: 30 },
        { sector: "Completed", size: 70 },
    ])
    return (
        <div className="row">
            <div className="col-12 text-center mb-4">
                <PIEChartComponent data={pieItem}></PIEChartComponent>
                <h6>Overall Data Completion</h6>
            </div>
            <div className="col-12 mb-4">
                <h6>Individual Section Completion</h6>
                <div className="row bl-individual-section">
                    <div className="col-12 border-bottom">
                    <i className="fa fa-check-circle text-success" aria-hidden="true"></i>Company Information
                    </div>
                    <div className="col-12 border-bottom">
                    <i className="fa fa-check-circle text-success" aria-hidden="true"></i>Financial Information
                    </div>
                    <div className="col-12 border-bottom">
                    <i className="fa fa-check-circle text-success" aria-hidden="true"></i>Management and Leadership
                    </div>
                </div>
            </div>
            <div className="col-12 mb-4">
                <h6>Permissions</h6>
                <div className="bl-user-permission">
                    <img className="img-profile rounded-circle" alt="profile-icon" src="https://static.wixstatic.com/media/23081b_28d5f988ed4341d395e146b4b0590709~mv2.png/v1/crop/x_16,y_0,w_468,h_500/fill/w_538,h_600,al_c,lg_1,q_85,enc_auto/1679416083312-removebg-preview_edited_pn.png" />
                    <img className="img-profile rounded-circle" alt="profile-icon" src="https://cdn2.steamgriddb.com/file/sgdb-cdn/icon/ff51fb7a9bcb22c595616b4fa368880a/32/256x256.png" />
                    <img className="img-profile rounded-circle" alt="profile-icon" src="https://static.wixstatic.com/media/23081b_28d5f988ed4341d395e146b4b0590709~mv2.png/v1/crop/x_16,y_0,w_468,h_500/fill/w_538,h_600,al_c,lg_1,q_85,enc_auto/1679416083312-removebg-preview_edited_pn.png" />
                    <img className="img-profile rounded-circle" alt="profile-icon" src="https://static.wixstatic.com/media/23081b_28d5f988ed4341d395e146b4b0590709~mv2.png/v1/crop/x_16,y_0,w_468,h_500/fill/w_538,h_600,al_c,lg_1,q_85,enc_auto/1679416083312-removebg-preview_edited_pn.png" />
                    <img className="img-profile rounded-circle" alt="profile-icon" src="https://cdn2.steamgriddb.com/file/sgdb-cdn/icon/ff51fb7a9bcb22c595616b4fa368880a/32/256x256.png" />
                    <img className="img-profile rounded-circle" alt="profile-icon" src="https://static.wixstatic.com/media/23081b_28d5f988ed4341d395e146b4b0590709~mv2.png/v1/crop/x_16,y_0,w_468,h_500/fill/w_538,h_600,al_c,lg_1,q_85,enc_auto/1679416083312-removebg-preview_edited_pn.png" />
                </div>
            </div>
            <div className="col-12 mb-4">
                <h6>Activity Log</h6>
                <div className="activity">
                <div className="bl-activity-item d-flex">
                  <div className="bl-activity-badge align-self-start"></div>
                  <div className="activity-content">
                    <p className="mb-0">Approved by Patric S</p>
                    <p className="text-gray font-small">Just Now</p>
                  </div>
                </div>
                <div className="bl-activity-item d-flex">
                  <div className="bl-activity-badge align-self-start" style={{backgroundImage:'url(https://cdn2.steamgriddb.com/file/sgdb-cdn/icon/ff51fb7a9bcb22c595616b4fa368880a/32/256x256.png)'}}></div>
                  <div className="activity-content">
                    <p className="mb-0">Modified by John Deo</p>
                    <p className="text-gray font-small">59 minutes ago</p>
                  </div>
                </div>
                <div className="bl-activity-item d-flex">
                  <div className="bl-activity-badge align-self-start"></div>
                  <div className="activity-content">
                    <p className="mb-0">Created by Sarathi</p>
                    <p className="text-gray font-small">12 hours ago</p>
                  </div>
                </div>

              </div>
            </div>
            <div className="col-12 mb-4">
                <h6>Metadata</h6>
                <div className="d-flex justify-content-between">
                <p className="text-gray">Created On</p>
                <p>Aug 10, 2023</p>
                </div>
                <div className="d-flex justify-content-between">
                <p className="text-gray">Member Size</p>
                <p>100000</p>
                </div>
            </div>
        </div>
    )
}