import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ColumnChart } from "../../Graph/columnChart";
import { SourceCommonCompanyTitle } from "../Common/companyTitle";
import SearchContext from "../../../services/context/Search/searchContext";
import BreadcrumbContext from "../../../services/context/Breadcrumb/breadcrumbContext";
import { getCompanyInformationService, getFinanceInfoService } from "../../../services/companyService";
import { EmptyCardsContent } from "../Common/emptyCard";
import { convertCamelCaseToSeparateWords, truncateFileName } from "../../../services/commonService";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { CardDropdownContent } from "../../Common/cardDropdown";


export const SourceCompanyFinancialComponent = () => {

    window.scrollTo(0, 0);
    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const location = useLocation()

    useEffect(() => {
        setBreadcrumbMenu(
            [
                { slug: '', menuName: 'Sourcing', isActive: true, hasSubMenu: true },
                { slug: '/sourcing/overview', menuName: 'Overview', isActive: false, hasSubMenu: true },
                { slug: '', menuName: 'Financial', isActive: false, hasSubMenu: true }
            ]
        )
    }, [location])

    const [companyInfo, setCompanyInfo] = useState({
        company: '', logo: '', website: '', description: '', address: '', employeeRange: '', fundingRound: '', valuation: '', fundingAmount: '',
        totalEmployee: '', acquisitions: '', investors: '', investments: '', exits: '', insights: '', BlScore: '', slug: '', isNew: false, socialNetworkUrl: []
    });
    const [isLoaded, setIsLoaded] = useState(false)
    const [categoryField, setCategoryField] = useState("");
    const [incomeSeries, setIncomeSeries] = useState([]);
    const [incomeTableInfo, setIncomeTableInfo] = useState([]);
    const [incomeTableHeader, setIncomeTableHeader] = useState([]);
    const [incomePeriodData, setIncomePeriodData] = useState([]);
    const [balanceSeries, setBalanceSeries] = useState([]);
    const [balanceTableHeader, setBalanceTableHeader] = useState([]);
    const [balanceTableInfo, setBalanceTableInfo] = useState([]);
    const [balancePeriodData, setBalancePeriodData] = useState([]);
    const [cashSeries, setCashSeries] = useState([]);
    const [cashTableHeader, setCashTableHeader] = useState([]);
    const [cashTableInfo, setCashTableInfo] = useState([]);
    const [cashPeriodData, setCashPeriodData] = useState([]);
    const [isFinanceGraphDataLoaded, setIsFinanceGraphDataLoaded] = useState(false)
    const [uploadedFileName, setUploadedFileName] = useState('')

    useEffect(() => {
        setIsLoaded(false)
        setIsFinanceGraphDataLoaded(false)
        let pathArray = location.pathname.split('/');
        const companySlug = pathArray.length > 3 ? pathArray[3] : '';
        getCompanyInformation(companySlug)
    }, [location])

    const getCompanyInformation = async (slug) => {
        const resp = await getCompanyInformationService(slug);
        setIsLoaded(true)
        if (resp && resp.success) {
            setCompanyInfo(resp.data[0])
            getFinanceInfo(resp.data[0].companyId)
        }
    }

    const getFinanceInfo = async (companyId) => {
        setIsFinanceGraphDataLoaded(false)
        const resp = await getFinanceInfoService(companyId);
        setIsFinanceGraphDataLoaded(true)
        if (resp && resp.success && resp.data && resp.data.length) {
            setUploadedFileName(resp.fileName ? resp.fileName : '')
            if (resp.data[0].income_statement) {
                getIncomeStatementData(resp.data[0].income_statement)
            }
            if (resp.data[0].balance_sheet) {
                getBalanceSheetData(resp.data[0].balance_sheet)
            }
            if (resp.data[0].net_change || resp.data[0].cash_flow) {
                const cashFlowData = resp.data[0].net_change ? resp.data[0].net_change : resp.data[0].cash_flow ? resp.data[0].cash_flow : []
                getCashFlowData(cashFlowData)
            }
        }
    }

    const getIncomeStatementData = (statements) => {
        const data = statements.graph ? statements.graph : [];
        setIncomePeriodData(data);
        setCategoryField('date');
        let seriesItem = []
        for (let key in data[0]) {
            seriesItem.push({
                name: convertCamelCaseToSeparateWords(key),
                val: key
            })
        }
        const items = seriesItem.filter(item => item.val !== 'date');
        setIncomeSeries(items)

        const tableData = statements.table ? statements.table : [];
        let tableHeader = [];
        if(tableData.length){
            for (let key in tableData[0]) {
                const headerText = (key).toLowerCase() === 'text' ? 'USD (Millions)' : convertCamelCaseToSeparateWords(key);
                tableHeader.push({
                    header: headerText,
                    field: key
                })
            }
            setIncomeTableHeader(tableHeader)
            setIncomeTableInfo(tableData)
        }
    }

    const getBalanceSheetData = (statements) => {
        const data = statements.graph ? statements.graph : [];
        setBalancePeriodData(data);
        let seriesItem = []
        for (let key in data[0]) {
            seriesItem.push({
                name: convertCamelCaseToSeparateWords(key),
                val: key
            })
        }
        const items = seriesItem.filter(item => item.val !== 'date');
        setBalanceSeries(items)

        const tableData = statements.table ? statements.table : [];
        let tableHeader = [];
        if(tableData.length){
            for (let key in tableData[0]) {
                const headerText = (key).toLowerCase() === 'text' ? 'USD (Millions)' : convertCamelCaseToSeparateWords(key);
                tableHeader.push({
                    header: headerText,
                    field: key
                })
            }
            setBalanceTableHeader(tableHeader)
            setBalanceTableInfo(tableData)
        }
    }

    const getCashFlowData = (statements) => {
        const data = statements.graph ? statements.graph : [];
        setCashPeriodData(data);
        let seriesItem = []
        for (let key in data[0]) {
            seriesItem.push({
                name: convertCamelCaseToSeparateWords(key),
                val: key
            })
        }
        const items = seriesItem.filter(item => item.val !== 'date');
        setCashSeries(items)

        const tableData = statements.table ? statements.table : [];
        let tableHeader = [];
        if(tableData.length){
            for (let key in tableData[0]) {
                const headerText = (key).toLowerCase() === 'text' ? 'USD (Millions)' : convertCamelCaseToSeparateWords(key);
                tableHeader.push({
                    header: headerText,
                    field: key
                })
            }
            setCashTableHeader(tableHeader)
            setCashTableInfo(tableData)
        }
    }

    const arrangeColumnValue = (item, column) => {
        if(column !== 'USD (Millions)'){
            return formatCurrency(item[column]);

        }
        else return item.Text ? item.Text : item.text;
    }

    const graphTableInfoContent = (columns, data) => {
        return (
            <DataTable key={`items`} value={data} className="bl-finance-graph">
            {
                columns.map((col, i)=>{
                    const uniqueVal = Date.now() + Math.random();
                    return (
                         <Column key={`graph-table-List-view-${i}-${uniqueVal.toString()}`} header={col.header} field={col.field} body={(e)=>arrangeColumnValue(e, col.header)}></Column>
                    )
                })
            }
        </DataTable>
        )
    }

    const updateCompanyInfo = () => {
        getCompanyInformation(companyInfo.slug)
    }

    const formatCurrency = (number) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
            number
          ).replace(/(\.|,)00$/g, '')
    }

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <section className="mb-3">
                    {
                        isLoaded ? <SourceCommonCompanyTitle item={companyInfo} onChildInfoUpdate={updateCompanyInfo}></SourceCommonCompanyTitle> : null
                    }
                </section>
                <section className="mb-3">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-deck">
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">{'Income Statement'}</h6>
                                            {companyInfo.isDocumentsAdded && incomePeriodData.length && <span className="badge bl-source-badge ml-1"><i className="fa fa-file-excel-o" aria-hidden="true"></i>{truncateFileName(uploadedFileName, 20)}</span>}
                                        </div>
                                        <CardDropdownContent cardId="financeincomecard" />
                                    </div>
                                    <div className="card-body">
                                        {
                                            !isFinanceGraphDataLoaded ?
                                                (<div className="row align-items-center h-100">
                                                    <div className="col"> <span className="loader"></span></div>
                                                </div>) :
                                                companyInfo.isDocumentsAdded && incomePeriodData.length ?
                                                    <ColumnChart id="incomestatementfinance" data={incomePeriodData} categoryField={categoryField} seriesItem={incomeSeries} height="20rem"></ColumnChart> :
                                                    <EmptyCardsContent title="No Income Statement Summary data found" buttonView={false} />
                                        }
                                        {
                                            isFinanceGraphDataLoaded && incomeTableInfo.length ? graphTableInfoContent(incomeTableHeader, incomeTableInfo) : null
                                        }
                                    </div>
                                </div>
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">{'Balance Sheet'}</h6>
                                            {companyInfo.isDocumentsAdded && balancePeriodData.length && <span className="badge bl-source-badge ml-1"><i className="fa fa-file-excel-o" aria-hidden="true"></i>{truncateFileName(uploadedFileName, 20)}</span>}
                                        </div>
                                        <CardDropdownContent cardId="financebalancecard" />
                                    </div>
                                    <div className="card-body">
                                        {
                                            !isFinanceGraphDataLoaded ?
                                                (<div className="row align-items-center h-100">
                                                    <div className="col"> <span className="loader"></span></div>
                                                </div>) :
                                                companyInfo.isDocumentsAdded && balancePeriodData.length ?
                                                <ColumnChart id="balancesheetfinance" data={balancePeriodData} categoryField={categoryField} seriesItem={balanceSeries} height="20rem"></ColumnChart> :
                                                    <EmptyCardsContent title="No Balance Sheet Summary data found" buttonView={false} />
                                        }
                                        {
                                            isFinanceGraphDataLoaded && balanceTableInfo.length ? graphTableInfoContent(balanceTableHeader, balanceTableInfo) : null
                                        }
                                    </div>
                                </div>
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">{'Cash Flow'}</h6>
                                            {companyInfo.isDocumentsAdded && cashPeriodData.length && <span className="badge bl-source-badge ml-1"><i className="fa fa-file-excel-o" aria-hidden="true"></i>{truncateFileName(uploadedFileName, 20)}</span>}
                                        </div>
                                        <CardDropdownContent cardId="financecashcard" />
                                    </div>
                                    <div className="card-body">
                                        {
                                            !isFinanceGraphDataLoaded ?
                                                (<div className="row align-items-center h-100">
                                                    <div className="col"> <span className="loader"></span></div>
                                                </div>) :
                                                companyInfo.isDocumentsAdded && cashPeriodData.length ?
                                                    <ColumnChart id="cashflowfinance" data={cashPeriodData} categoryField={categoryField} seriesItem={cashSeries} height="20rem"></ColumnChart> :
                                                    <EmptyCardsContent title="No Cash Flow Summary data found" buttonView={false} />
                                        }
                                        {
                                            isFinanceGraphDataLoaded && cashTableInfo.length ? graphTableInfoContent(cashTableHeader, cashTableInfo) : null
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}