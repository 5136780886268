import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";


export const TaskListViewComponent = ({ list, all }) => {

    const arrangeColumnValue = (data, column) => {
        if (column === 'TASK') {
            return (
                <div className="d-flex">
                    <span className="d-flex align-self-center text-center rounded-circle bl-task-category bg-grey mr-2">
                        <i className={`fa ${data.icon} align-self-center w-100`} aria-hidden="true"></i>
                    </span>

                    <p className="mb-0 align-self-center">
                        <b>{data.title}</b> <br />
                        <span className="font-small text-muted">{data.description}</span>
                    </p>
                </div>
            )
        }
        else {
            return (
                <div className="form-group mb-0">
                    <select className="form-control form-control-sm blc_button">
                        <option>Approve</option>
                        <option>Reject</option>
                        <option>Re-Assign</option>
                        <option>More</option>
                    </select>
                </div>
            )
        }

    }

    return (
        <DataTable value={list}>
            <Column header="Task" field="title" body={(e) => arrangeColumnValue(e, 'TASK')} sortable></Column>
            {
                all ? <Column header="Category" field="category" sortable></Column> : null
            }
            <Column header="Requester" field="requestor" sortable></Column>
            <Column header="Created On" field="createdOn" sortable></Column>
            <Column header="Status" field="status" sortable></Column>
            <Column header="Due" field="dueDate" sortable></Column>
            <Column header="" field="" body={(e) => arrangeColumnValue(e, 'ACTION')}></Column>
        </DataTable>
    )
}