import React, { useState } from 'react';

export const SourcingContext = React.createContext();

export const SourcingContextProvider = ({ children }) => {

    const [sourcingCompanyList, setSourcingCompanyList] = useState([]);

    return (
        <SourcingContext.Provider value={{ sourcingCompanyList, setSourcingCompanyList }}>
            {children}
        </SourcingContext.Provider>
    );
};
