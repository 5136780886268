import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import blLogo from './../assets/icons/Logo_White.svg';
import { authenticateService, storeToken, storeUserInfo } from "../services/authService";
import { AuthContext } from "../services/context/Auth/authContext";


const LoginComponent = () => {

    window.scrollTo(0, 0)
    const { setLoggedUserName } = useContext(AuthContext)
    const { setIsCanabisApp } = useContext(AuthContext)
    const nav = useNavigate();

    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        setUserName('')
        setPassword('')
        document.getElementsByTagName('body')[0].classList.add('vista_login_bg');
        return () => {
            document.getElementsByTagName('body')[0].classList.remove('vista_login_bg')
        }
    }, [])

    const loginUser = async (e) => {
        e.preventDefault();
        let credential = {
            userName: userName,
            password: password
        }
        const resp = await authenticateService(credential);
        if(resp && resp.success){
            setLoggedUserName(userName === 'sd_002' ? 'Sarathi Dutta' : 'Apurva Pandey')
            setIsCanabisApp(userName === 'sd_002')
            const obj = {
                userName: userName === 'sd_002' ? 'Sarathi Dutta' : 'Apurva Pandey',
                isCanabisApp: userName === 'sd_002'
            }
            storeUserInfo(JSON.stringify(obj))
            storeToken(resp.data.sessionToken);
            nav('/dashboard')
        }
        else {
            alert('Invalid Credential!')
        }
    }

    return (
        <>
            <nav className="navbar navbar-expand-md navbar-dark fixed-top d-sm-flex justify-content-between">
                <img src={blLogo} height="22px" className="navbar-brand" alt="brand-logo" />
                <div className="text-white"><i className="fa fa-globe"></i> English</div>
            </nav>
            <div className="container">
                <br /><br /><br />
                {/* <!-- Outer Row --> */}
                <div className="row justify-content-center mt-4">
                    <div className="col-xl-10 col-lg-12 col-md-9">

                        <div className="card vista_login_card o-hidden border-0 shadow-lg mt-5rem">
                            <div className="card-body p-0">
                                {/* <!-- Nested Row within Card Body --> */}
                                <div className="row">
                                    <div className="col-lg-6 d-none d-lg-block bg-login-image">
                                        <div className="p-5">
                                            <h1 className="h2 text-white mb-4">Brownloop Cloud​ For <br /><b>Private Equity</b></h1>
                                            <div className="h4 text-white mb-5"><h4>Manage data, control flows and build apps – in one place.</h4>​</div>
                                            <button className="btn btn-outline-light btn-user btn-block secondary-link">
                                                <i className="fab fa-microsoft fa-fw"></i> Create a BL Cloud Account
                                            </button>
                                            <br /><br /><br /><br />
                                            <div className="small text-white mb-1">Learn More</div>
                                            <div className="small text-white mb-4">Pricing   |   Docs   |   Catalog</div>

                                        </div>

                                    </div>
                                    <div className="col-lg-6">
                                        <div className="p-5">
                                            <br /><br />
                                            <h1 className="h5 text-white mb-4">Log in to BL PE Cloud</h1>
                                            <form onSubmit={(e) => loginUser(e)} className="user">
                                                <div className="form-group">
                                                    <input type="text" onChange={(e) => setUserName(e.target.value)} value={userName} className="form-control form-control-user vista_login_formElements" id="exampleInputEmail" placeholder="Brownloop Id" />
                                                </div>
                                                <div className="form-group">
                                                    <input type="password" onChange={(e) => setPassword(e.target.value)} value={password} className="form-control form-control-user vista_login_formElements" id="exampleInputPassword" placeholder="Password" />
                                                </div>
                                                <div className="form-group">
                                                    <div className="custom-control custom-checkbox small">
                                                        <input type="checkbox" className="custom-control-input" id="customCheck" />
                                                        <label className="custom-control-label text-white" htmlFor="customCheck">Remember Me</label>
                                                    </div>
                                                </div>
                                                <button type="submit" className="btn btn-primary-login btn-user btn-block vista_login_formElements primary-link text-white">
                                                    Login
                                                </button>
                                            </form>
                                            <br />
                                            <div className="text-center">
                                                <a className="small text-white tertiary-link">Forgot ID?</a>
                                            </div>
                                            <div className="text-center">
                                                <a className="small text-white tertiary-link">Forgot Password?</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </>

    )
}

export default LoginComponent;