import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { RecentActivityPanel } from "../Common/recentActivitySidePanel";
import recentActivityJson from "./../../data/Apps/recentActivity.json";
import appListJson from "./../../data/Apps/appList.json";
import homeLabel from "./../../utils/property-file/componentOverview.json";
import { AppsCardComponent } from "./appsCard";
import SearchContext from "../../services/context/Search/searchContext";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";


export const AppsOverviewComponent = () => {

    window.scrollTo(0, 0);
    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const location = useLocation()

    useEffect(() => {
        setBreadcrumbMenu(
            [
                { slug: '', menuName: 'Apps', isActive: true, hasSubMenu: true },
                { slug: '/apps/overview', menuName: 'Overview', isActive: false, hasSubMenu: true }
            ]
        )
    }, [location])

    const [activity, setActivity] = useState([])
    const [pinnedApps, setPinnedApps] = useState([])
    const [relevantApps, setRelevantApps] = useState([])
    const [recommendedApp, setRecommendedApp] = useState([])
    const [labels, setLabels] = useState({
        WELCOME: "",
        WELCOME_DESC: "",
        LATEST_UPDATE: "",
        WELCOME_ICON: "",
        GUIDE: "",
        YOUR_ACTIVITY: "",
        ENTITY: {
            TITLE_1: "",
            DESC_1: "",
            TITLE_2: "",
            DESC_2: "",
            TITLE_3: "",
            DESC_3: ""
        },
        RECOMMEND: ""
    });

    useEffect(() => {
        let items = homeLabel.find(l => l.FEATURE === "APPS").items;
        setLabels(items)
    }, [homeLabel])

    useEffect(() => {
        setActivity(recentActivityJson)
    }, [recentActivityJson])

    useEffect(() => {
        let pinnedList = appListJson.filter(app => app.isPinned);
        let relevantList = appListJson.filter(app => {
            let pp = pinnedList.filter(p => {
                if (p.category === app.category && p.title !== app.title) {
                    return app
                }
            })
            if (pp && pp.length) {
                return pp
            }
        })
        setRelevantApps(relevantList);
        setPinnedApps(pinnedList)
        let recommended = appListJson.filter(app => {
            if (!app.isPinned) {
                let rlap = relevantList.findIndex(ra => ra.title === app.title);
                if (rlap === -1) {
                    return app;
                }
            }
        });
        setRecommendedApp(recommended)
    }, [appListJson])

    return (
        <div id="content">
            <div className="container-fluid pt-3">

                <div className="row">
                    <div className="col-xl-10 col-lg-10 col-md-9 col-12">
                        <section className="d-flex justify-content-between mb-3">
                            <div className="align-self-center mr-16">
                                <h4>{labels.WELCOME}</h4>
                                <p className="mb-0">{labels.WELCOME_DESC}</p>
                            </div>
                            <div>
                                <img className="bl-overview-img" src={labels.WELCOME_ICON} alt="overview-Img" />
                            </div>
                        </section>
                        <section className="mb-3">
                            <h6 className="mb-3">Navbar Pinned Apps</h6>
                            <div className="card-deck">
                                {
                                    pinnedApps.map((app, i) => {
                                        return (
                                            <div key={`pinned-app-${i}`} className="card shadow-sm bl-app-card mb-3">
                                                <div className="card-body text-center pb-2">
                                                    <img src={app.icon} alt="app-icon" className="img-fluid rounded-circle bl-h-3rem" />
                                                    <h6 className="mt-2">{app.title}</h6>
                                                    <i className="fa fa-thumb-tack bl-app-pinned-pos" aria-hidden="true"></i>
                                                </div>
                                                <div className="card-footer text-center bg-white p-2 font-small text-muted">
                                                    <i className={`fa fa-check-circle ${app.isEnabled ? 'text-success' : ''}`} aria-hidden="true"></i> {app.isEnabled ? 'Enabled' : 'Disabled'}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </section>
                        <section className="mb-3">
                            <h6 className="mb-3">Relevant New Apps</h6>
                            <div className="card-deck">
                                {
                                    relevantApps.map((app, i) => {
                                        return (
                                            <div key={`relevant-app-${i}`} className="card shadow-sm bl-app-card mb-3">
                                                <div className="card-body text-center pb-2">
                                                    <img src={app.icon} alt="app-icon" className="img-fluid rounded-circle bl-h-3rem" />
                                                    <h6 className="mt-2">{app.title}</h6>
                                                    <i className="fa fa-lock bl-app-pinned-pos" aria-hidden="true"></i>
                                                </div>
                                                <div className="card-footer text-center bg-white p-2 font-small">
                                                    <p className="text-muted mb-0 cp">Request Access</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </section>
                        <section className="mb-3">
                            <h6 className="mb-3">Recommended Apps for you</h6>
                            <div className="card-deck">
                                {
                                    recommendedApp.map((app, i) => {
                                        return (
                                            <AppsCardComponent key={`recommended-app-${i}`} app={app}></AppsCardComponent>
                                        )
                                    })
                                }
                            </div>
                        </section>
                    </div>

                    <div className="col-xl-2 col-lg-2 col-md-3 py-3 mt-n3 mob-hide border-left bg-gray">
                        {
                            activity.length ? <RecentActivityPanel title={labels.YOUR_ACTIVITY} data={activity}></RecentActivityPanel> : null
                        }

                    </div>

                </div>
            </div>

        </div>
    )
}