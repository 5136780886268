import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { RecentActivityPanel } from "../Common/recentActivitySidePanel";
import recentActivityJson from "./../../data/Tasks/recentActivity.json";
import itemListJson from "./../../data/Tasks/taskList.json";
import taskMenuJson from "./../../data/Tasks/subMenu.json";
import homeLabel from "./../../utils/property-file/componentOverview.json";
import { TaskListViewComponent } from "./taskListView";
import SearchContext from "../../services/context/Search/searchContext";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";
// import { WorkflowCardComponent } from "./workflowCard";


export const TasksOverviewComponent = () => {

    window.scrollTo(0, 0);
    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const location = useLocation()

    useEffect(() => {
        setBreadcrumbMenu(
            [
                { slug: '', menuName: 'Tasks', isActive: true, hasSubMenu: true },
                { slug: '/tasks/overview', menuName: 'Overview', isActive: false, hasSubMenu: true }
            ]
        )
    }, [location])

    const [activity, setActivity] = useState([])
    const [taskList, setTaskList] = useState([])
    const [categoryTaskList, setCategoryTaskList] = useState([])
    const [overDueTaskList, setOverDueTaskList] = useState([])
    const [overDueTaskCount, setOverDueTaskCount] = useState(0)
    const [labels, setLabels] = useState({
        WELCOME: "",
        WELCOME_DESC: "",
        WELCOME_ICON: "",
        YOUR_ACTIVITY: "",
        RECOMMEND: "",
        ENTITY: {
            TITLE_1: "",
            TITLE_2: ""
        }
    });
    const [filter, setFilter] = useState([])

    useEffect(() => {
        let items = homeLabel.find(l => l.FEATURE === "TASKS").items;
        setLabels(items)
    }, [homeLabel])

    useEffect(() => {
        setActivity(recentActivityJson.splice(0, 8))
    }, [recentActivityJson])

    useEffect(() => {
        let items = [];
        // const result = Object.groupBy(itemListJson, ({ category }) => category);
        const result = itemListJson.reduce((x, y) => {

            (x[y.category] = x[y.category] || []).push(y);

            return x;

        }, {});
        console.log("get result ===>", result);
        for (const item in result) {
            let inProgressTask = 0, overDueTask = 0;
            result[item].map((task) => {
                inProgressTask += task.status === 'In Progress' ? 1 : 0;
                overDueTask += new Date() > new Date(task.dueDate) ? 1 : 0;
            })
            items.push({
                category: item, totalTask: result[item].length, inProgressTask, overDueTask,
                description: result[item][0].categoryDescription
            })
        }
        let sortedItem = items.sort((a, b) => b.overDueTask - a.overDueTask || b.totalTask - a.totalTask)
        sortedItem = sortedItem.map((cat) => {
            let categoryItem = taskMenuJson[0].list.find((menu) => menu.name === cat.category)
            if (categoryItem) {
                cat.icon = categoryItem.icon
            }
            return cat;
        })
        let overDueItem = sortedItem.filter(it => it.overDueTask);
        let filItem = [];
        overDueItem.map((t, i) => {
            filItem.push({
                val: t.category, name: t.category, isActive: i === 0 ? true : false
            })
        })
        setFilter(filItem)
        let threeItem = overDueItem.splice(0, 5);
        let fourItem = [];
        if (overDueItem.length > 1) {
            let overCount = 0;
            overDueItem.map(o => {
                overCount += o.overDueTask
            })
            let forthItem = {
                category: 'Others',
                overDueTask: overCount
            }
            fourItem = [...threeItem, forthItem];
        }
        else {
            fourItem = [...threeItem, ...overDueItem]
        }
        let totalCount = 0;
        fourItem.map(count => {
            totalCount += count.overDueTask
        })
        setOverDueTaskCount(totalCount)
        setOverDueTaskList(fourItem)
        setTaskList(sortedItem.splice(0, 5))
    }, [itemListJson])

    useEffect(() => {
        let selectedFilter = filter.find(f => f.isActive);
        if (selectedFilter) {
            let items = itemListJson.filter(item => {
                if (item.category === selectedFilter.val) {
                    let categoryItem = taskMenuJson[0].list.find((menu) => menu.name === item.category)
                    if (categoryItem) {
                        item.icon = categoryItem.icon
                    }
                    return item;
                }

            });
            setCategoryTaskList(items)
        }

    }, [filter])

    const arrangeColumnValue = (data) => {
        return (
            <div className="d-flex">
                <span className="d-flex align-self-center text-center rounded-circle bl-task-category bg-grey mr-2">
                    <i className={`fa ${data.icon} align-self-center w-100`} aria-hidden="true"></i>
                </span>

                <p className="mb-0 align-self-center">
                    <b>{data.category}</b> <br />
                    <span className="font-small text-muted">{data.description}</span>
                </p>
            </div>
        )
    }

    const changeSelectedFilter = (indx) => {
        let filterList = filter;
        filterList = filterList.map(f => {
            f.isActive = false;
            return f;
        })
        filterList[indx].isActive = true;
        setFilter(filterList)
    }

    return (
        <div id="content">
            <div className="container-fluid pt-3">

                <div className="row">
                    <div className="col-xl-10 col-lg-10 col-md-9 col-12">
                        <section className="d-flex justify-content-between mb-3">
                            <div className="align-self-center mr-16">
                                <h4>{labels.WELCOME}</h4>
                                <p className="mb-0">{labels.WELCOME_DESC}</p>
                            </div>
                            <div>
                                <img className="bl-overview-img" src={labels.WELCOME_ICON} alt="overview-Img" />
                            </div>
                        </section>
                        <section className="mb-3">
                            <div className="row">
                                <div className="col-lg-7 col-xl-7 col-md-6 col-12">
                                    <div className="card shadow-sm">
                                        <div className="card-header">
                                            <h6 className="mb-0">{labels.ENTITY.TITLE_1}</h6>
                                        </div>
                                        <div className="card-body p-0 pl-2">
                                            <DataTable value={taskList}>
                                                <Column header="Category" field="category" body={(e) => arrangeColumnValue(e, 'CATEGORY')}></Column>
                                                <Column header="Total Tasks" field="totalTask"></Column>
                                                <Column header="In-Progress" field="inProgressTask"></Column>
                                                <Column header="Over Due" field="overDueTask"></Column>
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-xl-5 col-md-6 col-12">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card mb-3 shadow-sm">
                                                <div className="card-body d-flex justify-content-between">
                                                    <h6 className="mb-0">
                                                        <i className="fa fa-exclamation-triangle text-danger mr-2" aria-hidden="true"></i>
                                                        {labels.ENTITY.TITLE_2}
                                                    </h6>
                                                    <h6 className="mb-0">{overDueTaskCount}</h6>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            overDueTaskList.map((task, i) => {
                                                return (
                                                    <div key={`task-${i}-${task.category}`} className="col-6">
                                                        <div className="card mb-3 shadow-sm">
                                                            <div className="card-body">
                                                                <h6 className="mt-1">
                                                                    {task.category}
                                                                </h6>
                                                                <h6 className="d-flex justify-content-between mb-0">
                                                                    {task.overDueTask}
                                                                    {
                                                                        task.icon ? (<i className={`fa ${task.icon}`} aria-hidden="true"></i>) : null
                                                                    }
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="mb-3">
                            <h6 className="mb-3">{labels.RECOMMEND}</h6>
                            <div className="d-flex pb-2">
                                {
                                    filter.map((f, i) => {
                                        return (
                                            <span key={`analytics-overview-filter-${i + 1}`} className={`badge bl-filter-chip cp ${f.isActive ? 'active' : ''}`} onClick={() => changeSelectedFilter(i)}>{f.name}</span>
                                        )
                                    })
                                }
                            </div>
                            <div className="mt-3">
                                <TaskListViewComponent list={categoryTaskList}></TaskListViewComponent>
                            </div>
                        </section>
                    </div>

                    <div className="col-xl-2 col-lg-2 col-md-3 py-3 mt-n3 mob-hide border-left bg-gray">
                        {
                            activity.length ? <RecentActivityPanel title={labels.YOUR_ACTIVITY} data={activity}></RecentActivityPanel> : null
                        }

                    </div>

                </div>
            </div>

        </div>
    )
}