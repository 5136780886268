import React, { useEffect, useState } from 'react';
import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";

export const BarChart = ({ data, height = '10rem', id = 'barchartdiv' }) => {

    const [chartRoot, setChartRoot] = useState(null);

    useEffect(() => {
        if (chartRoot) {
            chartRoot.dispose();
        }

        var data = [];
        var value1 = 10;
        var value2 = 100;
        var value3 = 1000;

        var names = [
            "ACME Inc",
            "StrongBeat",
            "Decagon",
            "PreProd",
            "RichieRich",
            "Antony.AI",
            "Party.AI",
            "Idalia",
            "Jokez",
            "Marla",
            "Curtis",
            "Chronos.PE",
            "Alfred.xvz",
            "Blackjack.AI",
            "Couchgamer"
        ];

        for (var i = 0; i < names.length; i++) {
            value1 += Math.round(
                (Math.random() < 0.5 ? 1 : -1) * Math.random() * value1 * 0.2
            );
            value2 += Math.round(
                (Math.random() < 0.5 ? 1 : -1) * Math.random() * value2 * 0.2
            );
            value3 += Math.round(
                (Math.random() < 0.5 ? 1 : -1) * Math.random() * value3 * 0.2
            );
            data.push({
                category: names[i],
                value1: value1,
                value2: value2,
                value3: value3
            });
        }

        let root = am5.Root.new(id);

        root.setThemes([
            am5themes_Animated.new(root)
        ]);
        root._logo.dispose();

        var chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: false,
                panY: true,
                wheelX: "none",
                wheelY: "zoomY",
                arrangeTooltips: false,
                pinchZoomY: true,
                paddingLeft: 0,
                paddingBottom: 0,
                paddingTop: 0
            })
        );

        chart.bottomAxesContainer.set("layout", root.horizontalLayout);

        var yRenderer = am5xy.AxisRendererY.new(root, {
            minGridDistance: 25
        });

        yRenderer.labels.template.setAll({
            multiLocation: 0.5,
            location: 0.5,
            paddingRight: 15
        });

        yRenderer.grid.template.set("location", 0.5);

        var yAxis = chart.yAxes.push(
            am5xy.CategoryAxis.new(root, {
                categoryField: "category",
                tooltip: am5.Tooltip.new(root, {}),
                renderer: yRenderer
            })
        );

        yAxis.data.setAll(data);

        yAxis.get("renderer").labels.template.setAll({
            centerX: am5.p0,
            fontSize: 12
        });


        // Add series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
        function createSeries(field, margin, column) {
            var xRenderer = am5xy.AxisRendererX.new(root, {
                minGridDistance: 40
            });

            xRenderer.labels.template.setAll({
                rotation: -90,
                centerY: am5.p50
            });

            var xAxis = chart.xAxes.push(
                am5xy.ValueAxis.new(root, {
                    renderer: xRenderer,
                    tooltip: am5.Tooltip.new(root, {
                        animationDuration: 0
                    }),
                    marginLeft: margin // this makes gap between axes
                })
            );

            var series;
            if (column) {
                series = chart.series.push(
                    am5xy.ColumnSeries.new(root, {
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueXField: field,
                        categoryYField: "category",
                        sequencedInterpolation: true,
                        tooltip: am5.Tooltip.new(root, {
                            pointerOrientation: "horizontal",
                            labelText: "{valueX}"
                        })
                    })
                );
            } else {
                series = chart.series.push(
                    am5xy.LineSeries.new(root, {
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueXField: field,
                        categoryYField: "category",
                        sequencedInterpolation: true,
                        tooltip: am5.Tooltip.new(root, {
                            pointerOrientation: "horizontal",
                            labelText: "{valueX}"
                        })
                    })
                );
            }

            if (!column) {
                series.bullets.push(function () {
                    return am5.Bullet.new(root, {
                        locationX: 1,
                        locationY: 0.5,
                        sprite: am5.Circle.new(root, {
                            radius: 4,
                            fill: series.get("fill")
                        })
                    });
                });
            }

            series.data.setAll(data);
            series.appear();

            return series;
        }

        createSeries("value1", 10, true);
        createSeries("value2", 20, false);
        createSeries("value3", 30, false);
        
        var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            behavior: "none",
            yAxis: yAxis
        }));
        
        chart.appear(1000, 100);

        setChartRoot(root);

        return () => {
            if (chartRoot) {
                chartRoot.dispose();
            }
        };
    }, [data]);

    return <div id={id} style={{ width: '100%', height: height }}></div>;
};