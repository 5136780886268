import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import report1 from "./../../assets/img/report/Rep1_Main.png";
import report2 from "./../../assets/img/report/Rep2_Main.png";
import report3 from "./../../assets/img/report/Rep3_Main.png";
import report4 from "./../../assets/img/report/Rep4_Main.png";
import report5 from "./../../assets/img/report/Rep5_Main.png";
import report6 from "./../../assets/img/report/Rep6_Main.png";
import report7 from "./../../assets/img/report/Rep7_Main.png";
import reportJson from "./../../data/Analytics/reports.json";
import SearchContext from "../../services/context/Search/searchContext";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";
import { capitalizeFirstLetter } from "../../services/commonService";



export const AnalyticsViewReportComponent = () => {
    window.scrollTo(0, 0);

    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const location = useLocation();

    useEffect(() => {
        const reportCatName = getReportType();
        const modules = reportCatName.split(',');
        setBreadcrumbMenu(
            [
                { slug: '', menuName: 'Analytics', isActive: true, hasSubMenu: true },
                { slug: `/analytics/reports/${modules[0]}`, menuName: capitalizeFirstLetter(modules[0]), isActive: false, hasSubMenu: true },
                { slug: `/analytics/reports/${modules[0]}/${modules[1]}`, menuName: capitalizeFirstLetter(modules[1]), isActive: false, hasSubMenu: true }
            ]
        )
    }, [location])

    const [reports, setReports] = useState({
        reportName: ''
    });
    const [reportIcon, setReportIcon] = useState(report1);
    const [reportIcons] = useState([
        {
            id: 1,
            icon: report1
        },
        {
            id: 2,
            icon: report2
        }, {
            id: 3,
            icon: report3
        }, {
            id: 4,
            icon: report4
        }, {
            id: 5,
            icon: report5
        }, {
            id: 6,
            icon: report6
        }, {
            id: 7,
            icon: report7
        }, {
            id: 8,
            icon: report1
        },
        {
            id: 9,
            icon: report2
        }, {
            id: 10,
            icon: report3
        }, {
            id: 11,
            icon: report4
        }, {
            id: 12,
            icon: report5
        }, {
            id: 13,
            icon: report6
        }, {
            id: 14,
            icon: report7
        }
    ])

    const getReportType = () => {
        let pathArray = location.pathname.split('/');
        if (pathArray && pathArray.length > 2) {
            if (pathArray[2] === 'reports' && pathArray.length === 5) {
                let report = reportJson.find(r => r.reportId == pathArray[4]);
                let icon = reportIcons.find(r => r.id == pathArray[4]);
                if (icon) {
                    setReportIcon(icon.icon)
                }
                else { setReportIcon(report1) }
                if (report) {
                    setReports(report)
                    return `${report.catSlug}, ${report.reportName}`
                }
                return `${pathArray[3]}, ${pathArray[4]}`
            }
            return pathArray[2]
        }
        return ''
    }

    return (
        <div className="content">
            <div className="container-fluid pt-3">
                <section className="mb-3 d-flex justify-content-between">
                    <div>
                        <h6>{reports.reportName}</h6>
                    </div>
                    <div>
                        <button className="btn btn-primary btn-sm mr-2"><i className="fa fa-fw fa-area-chart" aria-hidden="true"></i>{'Edit Report'}</button>
                        <button className="btn btn-primary btn-sm mr-2"><i className="fa fa-fw fa-download" aria-hidden="true"></i>{'Download Report'}</button>
                        <button className="btn btn-primary btn-sm mr-2"><i className="fa fa-fw fa-info-circle" aria-hidden="true"></i></button>
                    </div>
                </section>
                <section className="mb-3">
                    <img className="img-fluid card-img-top" src={reportIcon} alt="reportIcon" />
                </section>
            </div>
        </div>
    )
}