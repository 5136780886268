import React, { useState } from 'react';
import SearchContext from './searchContext';

const SearchContextProvider = ({ children }) => {
  const [globalSearch, setGlobalSearch] = useState('');

  return (
    <SearchContext.Provider value={{ globalSearch, setGlobalSearch }}>
      {children}
    </SearchContext.Provider>
  );
};

export default SearchContextProvider;