import React, { useContext, useEffect } from "react";
import { TreeTableComponent } from "../treeTable";
import companyJson from "./../../data/companyMaster.json";
import SearchContext from "../../services/context/Search/searchContext";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";


export const MDMCompanyComponent = () => {
    window.scrollTo(0, 0);

    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)

    useEffect(() => {
        setBreadcrumbMenu(
            [
                { slug: '', menuName: 'Master Data', isActive: true, hasSubMenu: true },
                { slug: '/master-data/companies', menuName: 'Companies', isActive: false, hasSubMenu: true },
            ]
        )
    }, [])

    const companyGridCardContent = () => {
        return (
            <div className="companies-grid-container font-weight-600">
                <div className="grid-item">
                    <div className="card blc_card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center pb-1">
                                <div><p className="mb-0">Total Records</p></div>
                            </div>
                            <div>
                                <h6 className="m-0">55</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid-item">
                    <div className="card blc_card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center pb-1">
                                <div><p className="mb-0">Exception Detected</p></div>

                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <div><h6 className="m-0 text-warning">15</h6></div>
                                <div className="text-warning">
                                    <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid-item">
                    <div className="card blc_card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center pb-1">
                                <div><p className="mb-0">Resolved</p></div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <div><h6 className="m-0 text-primary">8</h6></div>
                                <div className="text-primary">
                                    <i className="fa fa-check-circle" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid-item">
                    <div className="card blc_card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center pb-1">
                                <div><p className="mb-0">Resolution In-progress</p></div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <div><h6 className="m-0 text-success">55</h6></div>
                                <div className="text-success">
                                    <i className="fa fa-spinner" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid-item">
                    <div className="card blc_card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center pb-1">
                                <div><p className="mb-0">Data Update Request</p></div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <h6 className="m-0">10</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="content">
            <div className="container-fluid pt-3">
                <section className="mb-3">
                    <div className="row">
                        <div className="col-xl-12 col-md-12">
                            <figure className="highlight p-3 bg-gray mb-0">
                                <pre className="m-0"><i className="fa fa-info-circle"></i> PreProd LLC: Legal Risks with High Impact identified  <a className="cp">click to view​</a></pre>
                            </figure>
                        </div>
                    </div>
                </section>
                <section className="mb-3">
                    {companyGridCardContent()}
                </section>
                <section className="mb-3">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    Companies
                                </div>
                                <div className="card-section">
                                    <nav className="nav">
                                        <a className="nav-link active" aria-current="page">All</a>
                                        <a className="nav-link">Active</a>
                                        <a className="nav-link">Private</a>
                                        <a className="nav-link">Exited</a>
                                        <a className="nav-link">Public</a>
                                        <a className="nav-link">Acquired</a>
                                    </nav>
                                </div>
                                <div className="card-actions p-2 d-flex justify-content-between">
                                    <div className="align-self-center">
                                        <button className="btn btn-primary btn-sm mr-2 mb-0">
                                            <i className="fa fa-file-code-o mr-2" aria-hidden="true"></i> Add New Entity
                                        </button>
                                        <button className="btn btn-primary btn-sm mr-2 mb-0">
                                            <i className="fa fa-file-code-o mr-2" aria-hidden="true"></i> Edit Entity Data
                                        </button>
                                        <button className="btn btn-primary btn-sm mr-2 mb-0">
                                            <i className="fa fa-file-code-o mr-2" aria-hidden="true"></i> Upload
                                        </button>
                                        {/* <button className="btn btn-primary btn-sm mr-2 mb-0">
                                        <i className="fa fa-search" aria-hidden="true"></i>
                                    </button> */}
                                        <button className="btn btn-primary btn-sm mr-2 mb-0">
                                            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                    <div className="form-group w-25 mb-0">
                                        <input type="text" className="form-control form-control-sm" id="blcomshst" placeholder="Search" />
                                    </div>
                                </div>
                                <div className="card-filter py-3">
                                    <div className="col-xl-12 col-md-12">
                                        <div className="form-row blc_button align-items-end">
                                            <div className="form-group mr-2 mb-0">
                                                <label htmlFor="inputState">Location</label>
                                                <select id="inputState" className="form-control form-control-sm blc_button">
                                                    <option>All...</option>
                                                    <option>...</option>
                                                </select>
                                            </div>
                                            <div className="form-group mr-2 mb-0">
                                                <label htmlFor="inputState">Status</label>
                                                <select id="inputState" className="form-control form-control-sm blc_button">
                                                    <option>All...</option>
                                                    <option>...</option>
                                                </select>
                                            </div>
                                            <div className="form-group mr-2 mb-0">
                                                <label htmlFor="inputState">Industry</label>
                                                <select id="inputState" className="form-control form-control-sm blc_button">
                                                    <option>All...</option>
                                                    <option>...</option>
                                                </select>
                                            </div>
                                            <div className="form-group mr-2 mb-0">
                                                <label htmlFor="inputState">Headquarters</label>
                                                <select id="inputState" className="form-control form-control-sm blc_button">
                                                    <option>All...</option>
                                                    <option>...</option>
                                                </select>
                                            </div>
                                            <div className="form-group mr-2 mb-0">
                                                <label htmlFor="inputState">Fund</label>
                                                <select id="inputState" className="form-control form-control-sm blc_button">
                                                    <option>All...</option>
                                                    <option>...</option>
                                                </select>
                                            </div>
                                            <button className="btn btn-filter btn-sm">Apply Filter</button>
                                            <button className="btn btn-link btn-sm bl-filter-clear">Clear All</button>
                                            {/* <div className="filter-btn">
                                                
                                            </div> */}
                                            {/* <div className="clear-btn mb-1">
                                                
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="card-table">
                                    <TreeTableComponent data={companyJson}></TreeTableComponent>
                                </div>
                                <div className="load-more">
                                    <h6>Load More</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}