import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";


export const AIListViewAnswerComponent = ({list, columnSet}) => {

    const arrangeColumnValue = (items) => {
        return (
            items.fundingRounds.map((item, i)=>{
                return (
                    <div key={`find-round-${i}`} className="row">
                        <div className="col">
                            <p className="mb-0 text-gray">
                                <span>{item.round} </span>
                            </p>
                        </div>
                        <div className="col">
                            <p className="mb-0">{item.amountRaised}</p>
                        </div>
                        <div className="col">
                            <p className="mb-0">{item.date}</p>
                        </div>
                    </div>
                )
            })
        )
    }

    return (
        <DataTable key={`items`} value={list}>
            {
                columnSet.map((col, i)=>{
                    let uniqueVal = Date.now() + Math.random();
                    return (
                        col.field === "fundingRounds" ? <Column key={`AI-List-view-${i}-${uniqueVal.toString()}`} header={col.header} body={(e) => arrangeColumnValue(e)}></Column>
                        : <Column key={`AI-List-view-${i}-${uniqueVal.toString()}`} header={col.header} field={col.field} sortable></Column>
                    )
                })
            }
        </DataTable>
    )
}