import React, { useContext, useEffect, useState } from "react";
import homeLabels from "./../../utils/property-file/componentOverview.json";
import modelListJson from "./../../data/AIModel/modelList.json";
import { RecentActivityPanel } from "../Common/recentActivitySidePanel";
import recentActivityJson from "./../../data/AIModel/recentActivity.json";
import { useLocation } from "react-router-dom";
import { AIModelCardComponent } from "./AIModelCard";
import SearchContext from "../../services/context/Search/searchContext";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";


export const AIModelOverviewComponent = () => {

    window.scrollTo(0, 0);
    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const location = useLocation()

    useEffect(() => {
        setBreadcrumbMenu(
            [
                { slug: '', menuName: 'AI Models', isActive: true, hasSubMenu: true },
                { slug: '/ai-model/overview', menuName: 'Overview', isActive: false, hasSubMenu: true }
            ]
        )
    }, [location])

    const [modellist, setModelList] = useState([])
    const [activity, setActivity] = useState([])
    const [labels, setLabels] = useState({
        WELCOME: "",
        WELCOME_DESC: "",
        WELCOME_ICON: "",
        LATEST_UPDATE: "",
        GUIDE: "",
        YOUR_ACTIVITY: "",
        ENTITY: {
            TITLE_1: "",
            DESC_1: "",
            TITLE_2: "",
            DESC_2: "",
            TITLE_3: "",
            DESC_3: ""
        },
        RECOMMEND: ""
    });

    useEffect(() => {
        let items = homeLabels.find(l => l.FEATURE === "AI_MODELS").items;
        setLabels(items)
    }, [homeLabels])

    useEffect(() => {
        setModelList(modelListJson)
    }, [modelListJson])

    useEffect(() => {
        setActivity(recentActivityJson)
    }, [recentActivityJson])


    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <div className="row">
                    <div className="col-xl-10 col-lg-10 col-md-9 col-12">
                        <section className="d-flex justify-content-between">
                            <div className="align-self-center mr-16">
                                <h4>{labels.WELCOME}</h4>
                                <p>{labels.WELCOME_DESC}</p>
                            </div>
                            <div>
                                <img className="bl-overview-img" src={labels.WELCOME_ICON} alt="overview-Img" />
                            </div>
                        </section>

                        <section className="mb-4">
                            <h6 className="mb-3">{labels.GUIDE}</h6>
                            <div className="row">
                                <div className="col-lg-3 col-xl-3 col-md-3 col-6">
                                    <div className="card">
                                        <img src={'https://www.gstatic.com/pantheon/images/aiplatform/notebooks-for-workbench-card-image-v2.png'} alt="explore icon" className="card-img-top img-fluid bl-w-9rem align-self-center" />
                                        <div className="card-body">
                                            <h6 className="align-self-center">{labels.ENTITY.TITLE_1}</h6>
                                            <p className="mb-0">{labels.ENTITY.DESC_1}.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-xl-3 col-md-3 col-6">
                                    <div className="card">
                                        <img src={'https://www.gstatic.com/pantheon/images/aiplatform/pipelines-for-mlops-card-image-v2.png'} alt="training icon" className="card-img-top img-fluid bl-w-9rem align-self-center" />
                                        <div className="card-body">
                                            <h6 className="align-self-center">{labels.ENTITY.TITLE_2}</h6>
                                            <p className="mb-0">{labels.ENTITY.DESC_2}.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-xl-3 col-md-3 col-6">
                                    <div className="card">
                                        <img src={'https://www.gstatic.com/pantheon/images/aiplatform/pretrained-model-apis-card-image-v2.png'} alt="deploy icon" className="card-img-top img-fluid bl-w-9rem align-self-center" />
                                        <div className="card-body">
                                            <h6 className="align-self-center">{labels.ENTITY.TITLE_3}</h6>
                                            <p className="mb-0">{labels.ENTITY.DESC_3}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="mb-3">
                            <h6 className="mb-3">{labels.RECOMMEND}</h6>
                            <div className="card-deck">
                                {
                                    modellist.map((app, i) => {
                                        return (
                                            <AIModelCardComponent key={`ai-model-card-app-${i}`} app={app}></AIModelCardComponent>
                                        )
                                    })
                                }
                            </div>
                        </section>

                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-3 py-3 mt-n3 mob-hide border-left bg-gray">
                        {
                            activity.length ? <RecentActivityPanel title={labels.YOUR_ACTIVITY} data={activity}></RecentActivityPanel> : null
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}