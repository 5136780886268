import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";


export const RulesListViewComponent = ({list}) => {

    const [nodes, setNodes] = useState([]);

    useEffect(()=>{
        setNodes(list)
    }, [list])

    return (
        <DataTable value={nodes}>
            <Column field="title" header="Rule Name" ></Column>
            <Column field="type" header="Type"></Column>
            <Column field="description" header="Description"></Column>
            <Column field="createdBy" header="Created By"></Column>
            <Column field="updatedOn" header="Last Updated"></Column>
        </DataTable>
    )
}