import React, { useEffect, useState } from "react";
import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5Percent from "@amcharts/amcharts5/percent";


export const BoldPieChart = ({ data, height = '150px', id = 'boldpiechartdiv', usedColor='#ab656e6b', remainingColor='#FDEBEE', score=85 }) => {

    const [chartRoot, setChartRoot] = useState(null);

    useEffect(() => {
        if (chartRoot) {
            chartRoot.dispose();
        }


        let root = am5.Root.new(id);

        root.setThemes([
            am5themes_Animated.new(root)
        ]);
        root._logo.dispose();

        var chart = root.container.children.push(am5Percent.PieChart.new(root, {
            innerRadius: 30,
            layout: root.verticalLayout,
            paddingBottom: 8,
            paddingLeft: 5,
            paddingRight: 5,
            paddingTop: 8
        }));

        var series0 = chart.series.push(
            am5Percent.PieSeries.new(root, {
                valueField: "value",
                categoryField: "category",
                alignLabels: false,
                radius: am5.percent(100),
                innerRadius: am5.percent(60)
            })
        );

        series0.states.create("hidden", {
            startAngle: 180,
            endAngle: 180
        });

        series0.slices.template.setAll({
            fillOpacity: 0.8,
            strokeOpacity: 0,
            templateField: "settings",
            
        });

        series0.slices.template.states.create("hover", { scale: 1 });
        series0.slices.template.states.create("active", { shiftRadius: 0 });

        series0.labels.template.setAll({
            templateField: "settings"
        });

        series0.ticks.template.setAll({
            templateField: "settings"
        });

        series0.labels.template.setAll({
            textType: "circular",
            radius: 30
        });

        series0.data.setAll([
            {
                category: "Used",
                value: parseInt(score),
                settings: { fill: am5.color(usedColor)}
            },
            {
                category: "Unused",
                value: 100-parseInt(score),
                settings: { forceHidden: true }
            }
        ]);

        var series1 = chart.series.push(
            am5Percent.PieSeries.new(root, {
                radius: am5.percent(95),
                innerRadius: am5.percent(75),
                valueField: "value",
                categoryField: "category",
                alignLabels: false
            })
        );

        series1.states.create("hidden", {
            startAngle: 180,
            endAngle: 180
        });

        series1.slices.template.setAll({
            templateField: "sliceSettings",
            strokeOpacity: 0,
            fillOpacity: 0.8
        });

        series1.labels.template.setAll({
            textType: "circular"
        });

        //   series1.labels.template.adapters.add("radius", function (radius, target) {
        //     var dataItem = target.dataItem;
        //     var slice = dataItem.get("slice");
        //     return -(slice.get("radius") - slice.get("innerRadius")) / 2 - 10;
        //   });

        series1.slices.template.states.create("hover", { scale: 1 });
        series1.slices.template.states.create("active", { shiftRadius: 0 });

        series1.ticks.template.setAll({
            forceHidden: true
        });

        series1.data.setAll([{
            category: "Used",
            value: parseInt(score),
            sliceSettings: { fill: am5.color(usedColor)}
        }, {
            category: "Remaining",
            value: 100-parseInt(score),
            sliceSettings: { fill: am5.color(remainingColor) }
        }]);


        let label = root.tooltipContainer.children.push(am5.Label.new(root, {
            x: am5.p50,
            y: am5.p50,
            centerX: am5.p50,
            centerY: am5.p50,
            fill: am5.color('#670411'),
            fontSize: 12,
            text: score
        }));

        series0.labels.template.set("visible", false);
        series0.ticks.template.set("visible", false);
        series1.labels.template.set("visible", false);
        series1.ticks.template.set("visible", false);
        setChartRoot(root);

        return () => {
            // Cleanup when unmounting the component
            if (chartRoot) {
                chartRoot.dispose();
            }

        };
    }, [data]);

    return <div id={id} style={{ width: '100%', height: height }}></div>;
}