import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import neo4j from 'neo4j-driver';

export const Neo4jGraphComponent = () => {

    const svgRef = useRef(null);
    const [nodesCount, setNodesCount] = useState(0)
    const [relationshipCount, setRelationshipCount] = useState(0)
    const [isClickedOnNode, setIsClickedOnNode] = useState(false)

    const color = d3.scaleOrdinal(d3.schemeCategory10);
    const width = '100%';
    const height = 600;
    useEffect(() => {
        const driver = neo4j.driver(
            'neo4j+s://04da78db.databases.neo4j.io',
            neo4j.auth.basic('neo4j', 'n-Thg1TFZfOH8gmd0y2wxykNFcBYph3fMB3f-aXY8HM')
        );
        const session = driver.session();
        session.run('MATCH (n)-[r]->(m) RETURN n, r, m LIMIT 1000')
            .then(result => {
                const graphData = { nodes: [], links: [] };

                result.records.forEach((record) => {
                    const node1 = record.get('n');
                    const node2 = record.get('m');
                    const relationship = record.get('r');

                    if (!graphData.nodes.some(node => node.id === node1.identity.toString())) {
                        graphData.nodes.push({ id: node1.identity.toString(), ...node1.properties, label: node1.labels[0], category: node1.labels[0] });
                    }

                    if (!graphData.nodes.some(node => node.id === node2.identity.toString())) {
                        graphData.nodes.push({ id: node2.identity.toString(), ...node2.properties, label: node2.labels[0], category: node2.labels[0] });
                    }

                    graphData.links.push({
                        source: node1.identity.toString(),
                        target: node2.identity.toString(),
                        type: relationship.type,
                        properties: relationship.properties
                    });
                });

                visualize(graphData);
                displayOverview(graphData)
            })
            .catch(error => console.error('Error loading data:', error))
            .finally(() => session.close());



    }, []);

    const visualize = (graphData) => {
        const svg = d3.select(svgRef.current)
            .attr('width', '100%')
            .attr('height', height)
            .attr('viewBox', `0 0 1200 ${height}`)
            .attr('preserveAspectRatio', 'xMidYMid meet');

        // Add zoom functionality
        const zoom = d3.zoom()
            .scaleExtent([0.5, 5]) // Zoom scale limits
            .on('zoom', (event) => {
                svgGroup.attr('transform', event.transform);
            });

        svg.call(zoom);

        // Create a group for all elements to apply zoom
        const svgGroup = svg.append('g');

        // Create links between nodes
        const link = svgGroup.append('g')
            .attr('stroke', '#999')
            .attr('stroke-opacity', 0.6)
            .selectAll('line')
            .data(graphData.links)
            .enter()
            .append('line')
            .attr('stroke-width', 1.5)
            .attr('marker-end', 'url(#arrowhead)');

        // Create nodes
        const node = svgGroup.append('g')
            .selectAll('circle')
            .data(graphData.nodes)
            .enter()
            .append('circle')
            .attr('r', 6)
            .attr('fill', d => color(d.category))
            .call(d3.drag()
                .on('start', (event, d) => {
                    if (!event.active) simulation.alphaTarget(0.3).restart();
                    d.fx = d.x;
                    d.fy = d.y;
                })
                .on('drag', (event, d) => {
                    d.fx = event.x;
                    d.fy = event.y;
                })
                .on('end', (event, d) => {
                    if (!event.active) simulation.alphaTarget(0);
                    d.fx = null;
                    d.fy = null;
                }))
            .on('click', (event, d) => {
                setIsClickedOnNode(true)
                // Show label in a badge with the particular color
                d3.select('#node-info').selectAll('*').remove();
                d3.select('#node-info')
                    .append('div')
                    .style('display', 'inline-block')
                    .style('margin', '5px')
                    .style('padding', '5px 10px')
                    .style('background-color', color(d.label))
                    .style('color', '#fff')
                    .style('border-radius', '12px')
                    .style('font-size', '14px')
                    .style('font-weight', 'bold')
                    .text(d.label);

                // Display properties in a table
                const filteredProperties = Object.entries(d).filter(([key]) => !['fx', 'fy', 'x', 'y', 'vx', 'vy', 'index'].includes(key));
                const table = d3.select('#node-info')
                    .append('table')
                    .style('width', '100%')
                    .style('margin-top', '10px')
                    .style('border-collapse', 'collapse')

                // Add table headers
                table.append('thead').append('tr')
                    .html('<th style="border: 1px solid #ddd; padding: 8px; font-weight: bold; background-color: #f2f2f2;">Key</th>' +
                        '<th style="border: 1px solid #ddd; padding: 8px; font-weight: bold; background-color: #f2f2f2;">Value</th>');

                // Add table rows
                const tbody = table.append('tbody');
                tbody.selectAll('tr')
                    .data(filteredProperties)
                    .enter()
                    .append('tr')
                    .html(([key, value]) => `<td style='border: 1px solid #ddd; padding: 8px; font-weight: bold;'>${key}</td><td style='border: 1px solid #ddd; padding: 8px;'>${value}</td>`);
            });

        // Create labels for nodes
        const labels = svgGroup.append('g')
            .selectAll('text')
            .data(graphData.nodes)
            .enter()
            .append('text')
            .text(d => d.name || d.id)
            .attr('font-size', 10)
            .attr('dx', 12)
            .attr('dy', '.35em');

        // Define arrow markers for links
        svg.append('defs').selectAll('marker')
            .data(['end'])
            .enter().append('marker')
            .attr('id', 'arrowhead')
            .attr('viewBox', '-0 -5 10 10')
            .attr('refX', 20)
            .attr('refY', 0)
            .attr('orient', 'auto')
            .attr('markerWidth', 6)
            .attr('markerHeight', 6)
            .attr('xoverflow', 'visible')
            .append('svg:path')
            .attr('d', 'M 0,-5 L 10 ,0 L 0,5')
            .attr('fill', '#999')
            .style('stroke', 'none');

        // Create force simulation
        const simulation = d3.forceSimulation(graphData.nodes)
            .force('link', d3.forceLink(graphData.links).id(d => d.id).distance(100))
            .force('charge', d3.forceManyBody().strength(-100))
            .force('center', d3.forceCenter(600, height / 2))
            .force('x', d3.forceX(600).strength(0.1)) // Keep nodes within width bounds
            .force('y', d3.forceY(height / 2).strength(0.1)); // Keep nodes within height bounds

        simulation.on('tick', () => {
            link
                .attr('x1', d => d.source.x)
                .attr('y1', d => d.source.y)
                .attr('x2', d => d.target.x)
                .attr('y2', d => d.target.y);

            node
                .attr('cx', d => d.x)
                .attr('cy', d => d.y);

            labels
                .attr('x', d => d.x)
                .attr('y', d => d.y);
        });
    };

    const displayOverview = (graphData) => {
        setNodesCount(graphData.nodes.length)
        setRelationshipCount(graphData.links.length)
        // Create overview arrays for customization
        const nodesOverview = d3.groups(graphData.nodes, d => d.label).map(([label, nodes]) => ({
            label,
            count: nodes.length,
            nodes: nodes.map(n => ({ id: n.id, label: n.label }))
        }));

        const relationshipsOverview = d3.groups(graphData.links, d => d.type).map(([type, links]) => ({
            type,
            count: links.length,
            links: links.map(l => ({ source: l.source, target: l.target }))
        }));

        // Display the overview using the arrays
        d3.select('#node-details').selectAll('*').remove();
        nodesOverview.forEach(({ label, count, nodes }) => {
            d3.select('#node-details')
                .append('div')
                .style('display', 'inline-block')
                .style('margin', '5px')
                .style('padding', '5px 10px')
                .style('background-color', () => color(label))
                .style('color', '#fff')
                .style('border-radius', '12px')
                .style('font-size', '12px')
                .style('font-weight', 'bold')
                .html(`<strong>${label} (${count})</strong>`);
        });


        d3.select('#relationship-details').selectAll('*').remove();
        relationshipsOverview.forEach(({ type, count, links }) => {
            d3.select('#relationship-details')
                .append('div')
                .style('display', 'inline-block')
                .style('margin', '5px')
                .style('padding', '5px 10px')
                .style('background-color', '#999')
                .style('color', '#fff')
                .style('border-radius', '12px')
                .style('font-size', '12px')
                .style('font-weight', 'bold')
                .html(`<strong>${type} (${count})</strong>`);
        });
    };

    return (
        <div>
            <svg ref={svgRef}></svg>
            <div className={isClickedOnNode ? 'card mb-3' : ''}>
                {
                    isClickedOnNode ? <div className='card-header'>Nodes details</div> : null
                }
                <div className={isClickedOnNode ? 'card-body' : ''}>
                    <div id="node-info"></div>
                </div>
            </div>
            <div className='row mb-3'>
                <div className='col'>
                    <h6 className='text-muted'>Nodes ({nodesCount})</h6>
                    <div id="node-details"></div>
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <h6 className='text-muted'>Relationships ({relationshipCount})</h6>
                    <div id="relationship-details"></div>
                </div>
            </div>
        </div>
    );



    // return <svg ref={svgRef} width={'100%'} height={400}></svg>;
};



