import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import homeLabel from "./../../utils/property-file/componentOverview.json";
import recentActivityJson from "./../../data/Rules/recentActivity.json"
import rulesListJson from "./../../data/Rules/rulesList.json"
import { RecentActivityPanel } from "../Common/recentActivitySidePanel";
import { RulesListViewComponent } from "./rulesListView";
import SearchContext from "../../services/context/Search/searchContext";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";


export const RulesOverviewComponent = () => {

    window.scrollTo(0, 0);
    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const location = useLocation()

    useEffect(() => {
        setBreadcrumbMenu(
            [
                { slug: '', menuName: 'Rules', isActive: true, hasSubMenu: true },
                { slug: '/rules/overview', menuName: 'Overview', isActive: false, hasSubMenu: true }
            ]
        )
    }, [location])

    const [activity, setActivity] = useState([])
    const [rules, setRules] = useState([])
    const [labels, setLabels] = useState({
        WELCOME: "",
        WELCOME_DESC: "",
        LATEST_UPDATE: "",
        WELCOME_ICON: "",
        GUIDE: "",
        YOUR_ACTIVITY: "",
        ENTITY: {
            TITLE_1: "",
            DESC_1: "",
            TITLE_2: "",
            DESC_2: "",
            TITLE_3: "",
            DESC_3: ""
        },
        RECOMMEND: ""
    });

    useEffect(() => {
        let items = homeLabel.find(l => l.FEATURE === "RULES").items;
        setLabels(items)
    }, [homeLabel])

    useEffect(() => {
        setActivity(recentActivityJson)
    }, [recentActivityJson])

    useEffect(() => {
        setRules(rulesListJson)
    }, [rulesListJson])

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <div className="row">
                    <div className="col-xl-10 col-lg-10 col-md-9 col-12">
                        <section className="d-flex justify-content-between mb-3">
                            <div className="align-self-center mr-16">
                                <h4>{labels.WELCOME}</h4>
                                <p className="mb-0">{labels.WELCOME_DESC}</p>
                            </div>
                            <div>
                                <img className="bl-overview-img" src={labels.WELCOME_ICON} alt="overview-Img" />
                            </div>
                        </section>
                        <section>
                            <div className="row">
                                <div className="col-lg-2 col-xl-2 col-md-3 col">
                                    <div className="card shadow-sm bl-rule-box1 mb-3">
                                        <div className="card-body text-center">
                                            <h6>23</h6>
                                            <h6 className="mb-0 text-nowrap">{labels.ENTITY.TITLE_1}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-xl-2 col-md-3 col">
                                    <div className="card shadow-sm bl-rule-box2 mb-3">
                                        <div className="card-body text-center">
                                            <h6>6</h6>
                                            <h6 className="mb-0 text-nowrap">{labels.ENTITY.TITLE_2}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-xl-2 col-md-3 col">
                                    <div className="card shadow-sm bl-rule-box3 mb-3">
                                        <div className="card-body text-center">
                                            <h6>4</h6>
                                            <h6 className="mb-0 text-nowrap">{labels.ENTITY.TITLE_3}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="mb-3">
                            <h6>{labels.GUIDE}</h6>
                            <RulesListViewComponent list={rules}></RulesListViewComponent>
                        </section>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-3 py-3 mt-n3 mob-hide border-left bg-gray">
                        {
                            activity.length ? <RecentActivityPanel title={labels.YOUR_ACTIVITY} data={activity}></RecentActivityPanel> : null
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}