import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


export const AImodalTableView = ({list, tableFor, TableContent}) => {

    const nav = useNavigate();

    const [nodes, setNodes] = useState([]);
    const [totalWeight, setTotalWeight] = useState(0)
    const [subtotal, setSubTotal] = useState(0)

    useEffect(() => {
        if(!TableContent || TableContent !=='ESG_TABLE'){
            let tw=0, st=0;
            list.map(i=>{
                tw += i.Weight;
                st += i.subTotal;
            })
            setSubTotal(st.toFixed(2))
            setTotalWeight(tw.toFixed(2))
        }
        setNodes(list)
    }, [list])
    
    
    const footerGroup = (
        <ColumnGroup>
            <Row>
                <Column footer={`Total (${tableFor})`} />
                <Column footer={''} />
                <Column footer={totalWeight} />
                <Column footer={''} />
                <Column footer={subtotal} />
            </Row>
        </ColumnGroup>
    );

    const redirectCompanyProfile = (e) => {
        let floatBtn = document.getElementById('aifloatbtn')
        floatBtn.classList.remove('d-none')
        nav(`/sourcing/company/${e.data.slug}/profile`)
    }

    const arrangeColumnFiled = () => {
        return <i className="fa fa-external-link" aria-hidden="true"></i>
    }

    const getESGTableView = () => {
        return (
            <DataTable className="cp" value={nodes} onRowClick={(e)=>redirectCompanyProfile(e)} data-dismiss="modal">
            <Column field="company" header="Company Name" ></Column>
            <Column field="esgScore.environmental" header="Environmental (E)"></Column>
            <Column field="esgScore.social" header="Social (S)"></Column>
            <Column field="esgScore.governance" header="Governance (G)"></Column>
            <Column field="esgScore.overAll" header="Overall ESG Rating"></Column>
            <Column field="" header="" body={()=>arrangeColumnFiled()}></Column>
        </DataTable>
        )
    }

    return (
        TableContent && TableContent === 'ESG_TABLE' ? getESGTableView() :
        <DataTable value={nodes} rowGroupMode="rowspan" groupRowsBy="category" footerColumnGroup={footerGroup}>
            <Column header="Category" field="category"></Column>
            <Column field="Criteria" header="Criteria"></Column>
            <Column field="Weight" header="Weight" style={{ width: '10%' }}></Column>
            <Column field="Score" header="Score" style={{ width: '10%' }}></Column>
            <Column field="subTotal" header="Subtotal" style={{ width: '10%' }}></Column>
        </DataTable>
    )
}