import { Column } from "primereact/column";
import { TreeTable } from "primereact/treetable";
import React, { useEffect, useState } from "react";
import { CarbonZeroChart } from "../../Graph/carbonzerochart";


export const TableCompanyViewComponent = ({ companyList, columns }) => {

    const [nodes, setNodes] = useState([]);
    const [tableColumn, setTableColumn] = useState([]);

    useEffect(() => {
        setNodes(loadNodes(0, companyList));
    }, [companyList])

    useEffect(()=>{
        setTableColumn(columns)
    }, [columns])

    const loadNodes = (first, rows) => {
        let nodes = [];
        for (let i = 0; i < rows.length; i++) {
            let node = {
                key: `table-company-view-key-${first + i}`,
                data: {
                    companyId: rows[i].companyId,
                    company: rows[i].company,
                    logo: rows[i].logo,
                    industry: rows[i].industry,
                    fundingAmount: rows[i].fundingAmount,
                    valuation: rows[i].valuation,
                    address: rows[i].address,
                    riskCategory: rows[i].riskCategory,
                    riskSubCategory: rows[i].riskSubCategory,
                    impact: rows[i].impact,
                    isPinned: rows[i].isPinned
                }
            }
            nodes.push(node);
        }
        setTimeout(() => {
            let leafItem = nodes.find(n => n.leaf !== undefined && n.leaf === false);
            if (leafItem) {
                let classList = document.getElementsByClassName('p-treetable-toggler');
                for (let i = 0; i < classList.length; i++) {
                    classList[i].classList.remove('d-none')
                }
            }
            else {
                let classList = document.getElementsByClassName('p-treetable-toggler');
                for (let i = 0; i < classList.length; i++) {
                    classList[i].classList.add('d-none')
                }
            }
        }, 500)
        return nodes;
    };

    const reportTypeBackground = (type) => {
        if(type){
            if(type === 'Immediate'){
                return 'badge-danger'
            }
            else if(type === 'Gradual'){
                return 'badge-warning'
            }
        }
        else {
            return 'badge-light'
        }
    }

    const arrangeColumnValue = (item, column) => {
        let data = item.data;
        if (column === 'ICON') {
            return (
                <div className="d-flex align-items-center">
                    {
                        data.isPinned ? (<i className="fa fa-fw fa-thumb-tack mr-2" aria-hidden="true"></i>) : (<i className="fa fa-fw fa-thumb-tack text-gray mr-2" aria-hidden="true"></i>)
                    }

                    <img src={data.logo} alt="logo" className="img-fluid rounded-circle bl-report-thumbnail" />
                    <div className="pl-2">
                        <p className="mb-0 text-nowrap"><b>{data.company}</b></p>
                    </div>
                </div>
            )
        }
        else if(column === 'Risk Category') {
            return (
                <span>{data.riskCategory}</span>
            )
        }
        else if(column === 'Risk Sub Category') {
            return (
                <span>{data.riskSubCategory}</span>
            )
        }
        else if(column === 'Impact') {
            return (
                <span className={`badge ${reportTypeBackground(data.impact)}`}>{data.impact}</span>
            )
        }
        else if(column === 'Total Funding'){
            return (
                <span>{data.fundingAmount}</span>
            )
        }
        else if(column === 'Headquarters'){
            return (
                <span>{data.address}</span>
            )
        }
        else if(column === 'Valuation'){
            return (
                <span>{data.valuation}</span>
            )
        }
        else {
            return (
                <CarbonZeroChart id={`rsikcomparecompanychart${item.data.companyId}`} height="15px"></CarbonZeroChart>
            )
        }

    }

    const addColumnWidth = (column) => {
        if(column === 'Headquarters'){
            return {width: '16rem'}
        }
        else if(column === 'Category'){
            return {width: '8rem'}
        }
        else return ''
    }

    return (
        <section className="mb-3">
            <div className="row">
                <div className="col-12">
                    <TreeTable value={nodes}>
                        <Column field="logo" header="Company Name" body={(e) => arrangeColumnValue(e, 'ICON')} ></Column>
                        <Column field="industry" header="Industry"></Column>
                        {
                            tableColumn.length ? tableColumn.map((col, i)=>{
                                return (
                                    <Column key={`com-view-column-${i}`} style={addColumnWidth(col)} header={col} body={(e) => arrangeColumnValue(e, col)} ></Column>
                                )
                            }) : null
                        }
                        <Column field="logo" header="Risk Score" body={(e) => arrangeColumnValue(e, 'CHART')}></Column>
                    </TreeTable>
                </div>
            </div>
        </section>
    )
}