import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import GuageChart from "../../Graph/guagechart";
import HeatmapChart from "../../Graph/heatmap";
import HierarchyChart from "../../Graph/hierarchychart";
import { TrendChartComponent } from "../../Graph/trendChart";
import { RiskEventViewComponent } from "../../Risk/common/eventsListView";
import { SourceCommonCompanyTitle } from "../Common/companyTitle";
import companyRiskProfile from "./../../../data/Risk/companyRiskProfile.json";
import eventCategoriesJson from "./../../../data/Risk/eventCategories.json";
import riskEventJson from "./../../../data/Risk/events.json";
import SearchContext from "../../../services/context/Search/searchContext";
import BreadcrumbContext from "../../../services/context/Breadcrumb/breadcrumbContext";
import { getCompanyInformationService, getCompanyNewsByCategoryService, getCompanyNewsService } from "../../../services/companyService";
import { CardDropdownContent } from "../../Common/cardDropdown";


export const SourceCompanyRiskComponent = () => {

    window.scrollTo(0, 0);

    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const location = useLocation()

    useEffect(() => {
        setBreadcrumbMenu(
            [
                { slug: '', menuName: 'Sourcing', isActive: true, hasSubMenu: true },
                { slug: '/sourcing/overview', menuName: 'Overview', isActive: false, hasSubMenu: true },
                { slug: '', menuName: 'Risk Monitor', isActive: false, hasSubMenu: true }
            ]
        )
    }, [location])

    const [companyInfo, setCompanyInfo] = useState({
        company: '', logo: '', website: '', description: '', address: '', employeeRange: '', fundingRound: '', valuation: '', fundingAmount: '',
        totalEmployee: '', acquisitions: '', investors: '', investments: '', exits: '', insights: '', BlScore: '', slug: '', isNew: false, socialNetworkUrl: []
    });
    const [loaded, setIsLoaded] = useState(false)
    const [riskScores, setRiskScores] = useState([]);
    const [heatYAxisData, setHeatYAxisData] = useState([]);
    const [heatXAxisData, setHeatXAxisData] = useState([]);
    const [filter] = useState([
        { val: 'ALL', name: 'All', isActive: true },
        { val: 'LEGAL', name: 'Legal', isActive: false },
        { val: 'HUMAN_CAPITAL', name: 'Human Capital', isActive: false },
        { val: 'Operational', name: 'Operational', isActive: false }
    ]);
    const [eventList] = useState(riskEventJson);
    const [newsCategory] = useState([
        {
            "eventCategoryId": 1,
            "eventCategory": "Crime and Justice Events",
            "eventCategoryIcon": "https://cdn.iconscout.com/icon/free/png-256/free-law-balance-scale-justice-judicial-system-legal-12-28938.png",
            "isActive": true,
            events: []
        },
        {
            "eventCategoryId": 2,
            "eventCategory": "Science and Technology Events",
            "eventCategoryIcon": "https://w7.pngwing.com/pngs/846/185/png-transparent-information-technology-computer-icons-high-tech-technology-electronics-photography-innovation.png",
            "isActive": true,
            events: []
        },
        {
            "eventCategoryId": 3,
            "eventCategory": "Business and Entrepreneurship Events",
            "eventCategoryIcon": "https://cdn-icons-png.flaticon.com/512/6553/6553332.png",
            "isActive": true,
            "events": []
        },
        {
            "eventCategoryId": 4,
            "eventCategory": "Social Issues and Civil Rights Events",
            "eventCategoryIcon": "https://cdn4.iconfinder.com/data/icons/charity-14/512/Charity-help-care-kind-04-512.png",
            "isActive": true,
            "events": []
        },
        {
            "eventCategoryId": 5,
            "eventCategory": "Politics and Government Events",
            "eventCategoryIcon": "https://www.vhv.rs/dpng/d/519-5193340_writing-to-your-mp-visiting-your-government-politics.png",
            "isActive": true,
            "events": []
        }
    ])
    const [riskNewsCategory] = useState([
        {
            "eventSubCategory": "Legal trials and court cases",
            "riskCategory": "Legal Risk",
        }, {
            "eventSubCategory": "Legal trials and court cases",
            "riskCategory": "Human Capital Risk"
        }, {
            "eventSubCategory": "Cybersecurity and Data Privacy",
            "riskCategory": "Operational Risk"
        }, {
            "eventSubCategory": "Corporate leadership changes",
            "riskCategory": "Reputation Risk"
        }, {
            "eventSubCategory": "Startups and entrepreneurship news",
            "riskCategory": "Financial Risk"
        }, {
            "eventSubCategory": "Corporate leadership changes",
            "riskCategory": "Human Capital Risk"
        }, {
            "eventSubCategory": "Social inequality and poverty",
            "riskCategory": "ESG Risk"
        }, {
            "eventSubCategory": "Gender equality and LGBTQ+ rights",
            "riskCategory": "ESG Risk"
        }, {
            "eventSubCategory": "Protests and political movements Operational Risk",
            "riskCategory": "Operational Risk"
        }
    ])
    const [isNewsLoaded, setIsNewsLoaded] = useState(false)
    const [newsList, setNewsList] = useState([])
    const [newsArticles, setNewsArticles] = useState([])

    useEffect(() => {
        setIsLoaded(false)
        setIsNewsLoaded(false)
        let pathArray = location.pathname.split('/');
        const companySlug = pathArray.length > 3 ? pathArray[3] : 'smartbeat';
        getCompanyInformation(companySlug)
    }, [location])

    const getCompanyInformation = async (slug) => {
        setIsLoaded(false)
        const resp = await getCompanyInformationService(slug);
        if (resp && resp.success) {
            getCompanyNews(resp.data[0].companyId)
            if (resp.data.length && resp.data[0].riskScores) {
                let riskScoresList = [];
                let periods = [];
                resp.data[0].riskScores.map((s, i) => {
                    if (i < 18) {
                        riskScoresList.push({
                            company: resp.data[0].company,
                            period: s.period,
                            value: s.value
                        });
                        periods.push({
                            period: s.period
                        })
                    }
                })
                setHeatYAxisData([{ company: resp.data[0].company }]);
                setRiskScores(riskScoresList);
                setHeatXAxisData(periods)
            }
            setCompanyInfo(resp.data[0])
        }
        setIsLoaded(true)
    }

    const getCompanyNews = async (companyId) => {
        setIsNewsLoaded(false)
        const resp = await getCompanyNewsByCategoryService(companyId);
        if (resp && resp.success) {
            const articles = resp.data;
            const categoryNews = [];
            for (let key in articles) {
                const categoryInfo = eventCategoriesJson.find(cat => cat.eventCategory === key);
                const icon = categoryInfo && categoryInfo.icon ? categoryInfo.icon : 'fa-calendar';
                categoryNews.push({
                    eventCategory: key,
                    icon: icon,
                    events: articles[key]
                })
            }
            setNewsArticles(categoryNews)
        }
        setTimeout(() => {
            setIsNewsLoaded(true)
        })
    }

    const dashHeat = (item) => {
        // console.log("get item from dash ==>", item);
    }

    const riskProfileContent = () => {
        return (
            <>
                <section className="py-3">
                    <div className="row">
                        <div className="col-xl-12 col-md-12 d-none">
                            <figure>
                                <pre className="m-0"><i className="fa fa-info-circle"></i> {companyInfo.insights}  <button className="btn btn-link btn-sm">click to view</button></pre>
                            </figure>
                        </div>
                        <div className="col-xl-4 col-md-4 col-12 mb-0">
                            <div className="card shadow-sm">
                                <div className="card-header d-flex justify-content-between">
                                    <div className="d-flex align-self-center">
                                        <h6 className="mb-0">Risk Score</h6>
                                        <span className="badge bl-source-badge ml-1"><i className="fa fa-database" aria-hidden="true"></i>{'PitchBook, S&P Capital IQ'}</span>
                                    </div>
                                    <CardDropdownContent cardId="riskscorecard" />
                                </div>
                                <div className="card-body p-3">
                                    <GuageChart height={'15rem'}></GuageChart>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-4 col-md-4 col-12 mb-0">
                            <div className="card shadow-sm">
                                <div className="card-header d-flex justify-content-between">
                                    <div className="d-flex align-self-center">
                                        <h6 className="mb-0">Distribution of Companies by Risk Factor Range</h6>
                                        <span className="badge bl-source-badge ml-1"><i className="fa fa-database" aria-hidden="true"></i>{'PitchBook, S&P Capital IQ'}</span>
                                    </div>
                                    <CardDropdownContent cardId="riskfactorcard" />
                                </div>
                                <div className="card-body">
                                    <HierarchyChart height="15rem"></HierarchyChart>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-4 col-12 mb-0">
                            <div className="card shadow-sm">
                                <div className="card-header d-flex justify-content-between">
                                    <div className="d-flex align-self-center">
                                        <h6 className="mb-0">Risk Trend Over Months</h6>
                                        <span className="badge bl-source-badge ml-1"><i className="fa fa-database" aria-hidden="true"></i>{'PitchBook, S&P Capital IQ'}</span>
                                    </div>
                                    <CardDropdownContent cardId="risktrendcard" />
                                </div>
                                <div className="card-body">
                                    <TrendChartComponent id="sourcecompanyriskchart" height="15rem"></TrendChartComponent>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {
                    isNewsLoaded && newsArticles.length ? <section className="mb-3">
                        <div className="row">
                            <div className="col-12">
                                <h6 className="mb-3">{'Detected Events by Risks Category'}</h6>
                                <div className="d-flex pb-2">
                                    {
                                        filter.map((f, i) => {
                                            return (
                                                <span key={`risk-cate-filter-${i + 1}`} className={`badge bl-filter-chip ${f.isActive ? 'active' : ''}`}>{f.name}</span>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="mt-3">
                            <RiskEventViewComponent eventList={newsArticles} companyInfo={companyInfo}></RiskEventViewComponent>
                        </div>
                    </section> : null
                }
            </>
        )
    }

    return (
        <div id="content">

            <div className="container-fluid pt-3">
                <section className="mb-3">
                    {
                        loaded ? <SourceCommonCompanyTitle item={companyInfo}></SourceCommonCompanyTitle> : null
                    }
                </section>
                <section className="py-3">
                    <div className="row">
                        <div className="col-12">
                            <h6>Risk Score Breakdown (Last 30 Days)</h6>
                            {
                                loaded ? <HeatmapChart id="sourcecompanyriskscorechart" data={riskScores} yaxisData={heatYAxisData} xAxisData={heatXAxisData} categoryYField="company" categoryXField="period" yAxisVisiblity={false} xAxisVisiblity={true} height={'60px'} dashHeat={(item) => dashHeat(item)}></HeatmapChart> : null
                            }
                        </div>
                    </div>
                </section>
                {
                    riskProfileContent()
                }
            </div>
        </div>
    )

}