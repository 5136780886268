import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { SourceCommonCompanyListComponent } from "./Common/companyListView";
import SearchContext from "../../services/context/Search/searchContext";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";
import { getCompanyByDealStageService, getDealStagesService } from "../../services/companyService";
import { AnalyzeCompanyContext } from "../../services/context/analyzeCompanyContext";

export const SourceDealStagesComponent = () => {

    window.scrollTo(0, 0);
    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const { analyzedCompanyInfo } = useContext(AnalyzeCompanyContext)
    const location = useLocation()

    const [dealStageInfo, setDealStageInfo] = useState({
        "id": null,
        "stage": "",
        "statusName": ""
    })

    useEffect(() => {
        setCompanyList([])
        const stage = getSourceStage();
        getCompanyDealStages(stage);
    }, [location])

    const [companyList, setCompanyList] = useState([])

    useEffect(() => {
        if (dealStageInfo.id) {
            setCompanyList([])
            getDealStageCompanyList(1);
            setBreadcrumbMenu(
                [
                    { slug: '', menuName: 'Sourcing', isActive: true, hasSubMenu: true },
                    { slug: `/sourcing/deal-stage/${dealStageInfo.stage}`, menuName: dealStageInfo.statusName, isActive: false, hasSubMenu: true }
                ]
            )
        }

    }, [dealStageInfo, analyzedCompanyInfo])

    const getCompanyDealStages = async (stage) => {
        const resp = await getDealStagesService();
        if (resp && resp.success) {
            const stageInfo = resp.data.find(s => s.stage === stage);
            if (stageInfo !== -1) {
                setDealStageInfo(stageInfo)
            }
        }
    }

    const getSourceStage = () => {
        let pathArray = location.pathname.split('/');
        if (pathArray && pathArray.length > 2) {
            if (pathArray[1] === 'sourcing' && pathArray.length === 4) {
                return pathArray[3]
            }
            return pathArray[1]
        }
        return ''
    }

    const getDealStageCompanyList = async (page) => {
        const resp = await getCompanyByDealStageService(dealStageInfo.id, page, 5);
        getCompanyListResponse(page, resp)
    }

    const getCompanyListResponse = (page, resp) => {
        if (resp && resp.success) {
            const newCompanyList = resp.data;
            setCompanyList(companies => [...companies, ...newCompanyList])
            if (resp.data.length >= 5) {
                getDealStageCompanyList(page + 1);
            }
        }
    }

    const getDealStageName = (name) => {
        let stageName = "Prospect";
        switch (name) {
            case 'diligence':
                stageName = "Due Diligence"
                break;
            case 'prospects':
                stageName = "Prospect"
                break;
            case 'negotiations':
                stageName = "Negotiations"
                break;
            case 'closed':
                stageName = "Closed"
                break;
            default:
                stageName = "All"
                break;
        }
        return stageName;
    }

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <section className="mb-3">
                    <div className="row">
                        <div className="col-xl-6 col-md-6 col-12">
                            <h6>{dealStageInfo.statusName}</h6>
                        </div>
                        <div className="col-xl-6 col-md-6 col-12 text-right align-self-center">
                            <button className="btn btn-primary btn-sm mr-2 mb-0">
                                <i className="fa fa-fw fa-exchange" aria-hidden="true" title="Compare"></i>
                            </button>
                            <button className="btn btn-primary btn-sm mr-2 mb-0">
                                <i className="fa fa-fw fa-download" aria-hidden="true" title="Download"></i>
                            </button>
                            <button className="btn btn-primary btn-sm mr-2 mb-0">
                                <i className="fa fa-fw fa-thumb-tack" aria-hidden="true"></i>
                            </button>
                            <button className="btn btn-primary btn-sm mr-2 mb-0">
                                <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </section>
                <section className="mb-3">
                    <div className="row">
                        <div className="col-12">
                            {
                                companyList.length ? <SourceCommonCompanyListComponent list={companyList}></SourceCommonCompanyListComponent> : null
                            }
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}