import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";

export const KairosModalComponent = () => {

  const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
  const location = useLocation()
  const iframeRef = useRef(null);
  const [kairosAppDomain] = useState('https://blplatformapimanagerdev02.azure-api.net')
  // const [kairosAppDomain] = useState('http://localhost:3000')

  useEffect(() => {
    setBreadcrumbMenu(
      [
        { slug: '/kairos', menuName: 'Kairos', isActive: true, hasSubMenu: false }
      ]
    )
  }, [location])

  useEffect(() => {
    // Handle message when iframe is ready
    const handleIframeReady = (event) => {
      console.log('Parent app: Received event from origin:', event.origin, event.data);

      if (event.origin === kairosAppDomain && event.data === 'iframe-ready') {
        console.log('Parent app: Iframe is ready, sending token');
        if (iframeRef.current) {
          const authToken = true;
          iframeRef.current.contentWindow.postMessage({ token: authToken }, kairosAppDomain);
        }
      }
    };

    // Attach event listener to handle messages from iframe
    window.addEventListener('message', handleIframeReady);

    return () => {
      // Clean up the event listener when component unmounts
      window.removeEventListener('message', handleIframeReady);
    };
  }, []);

  return (
    <div id="content">
      <div className="container-fluid p-0">
        <iframe ref={iframeRef} id="kairosiframe" className="bl-border-none bl-iframe-h overflow-hidden" src={`${kairosAppDomain}/#/kairos`} width={'100%'}></iframe>
      </div>
    </div>
  )
}