import React from "react";
import emptyIcon from "./../../../assets/img/empty-widget.png";


export const EmptyCardsContent = ({ title = 'No Social Media data found', desc = "Unable to build widget. Additional data sources required", buttonView = true }) => {

    return (
        <div className="row h-100">
            <div className="col text-center align-self-center">
                <img src={emptyIcon} />
                <h6>{title}</h6>
                <p className="text-gray font-small">{desc}</p>
                {
                    buttonView ? <div className="dropdown">
                    <button className="btn btn-sm secondary-link btn-outline-primary dropdown-toggle bl-configure-btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Configure Now
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a className="dropdown-item cp" data-toggle="modal" data-target="#datasourceModal"><i className="fa fa-fw fa-plus-circle" aria-hidden="true"></i> Add Data Source</a>
                        <a className="dropdown-item cp" data-toggle="modal" data-target="#uploaddocumentModal"><i className="fa fa-fw fa-cloud-upload" aria-hidden="true"></i> Upload Document</a>
                        <a className="dropdown-item cp"><i className="fa fa-fw fa-link" aria-hidden="true"></i> Add Link</a>
                    </div>
                </div> : null
                }
            </div>
        </div>
    )
}