import React, { useContext, useState } from "react";
import aiIcon from "./../../assets/icons/floatbtn.png";
import AIContext from "../../services/context/AIAssistance/aiContext";


export const FloatButton = ({ modalMode }) => {

    const { setAIModalType } = useContext(AIContext)

    const [modalType, setModalType] = useState('DEFAULT');

    // const modalView = () => {
    //     setAIModalType('DEFAULT')
    //     // modalMode(modalType)
    //     // setModalType(modalType === 'DEFAULT' ? 'OPEN_DEFAULT' : 'DEFAULT')
    // }

    return (
        <div id="aifloatbtn" className="fab-container" onClick={() => setAIModalType('DEFAULT')} data-toggle="modal" data-target="#aimodalbox">
            <div className="fab shadow d-none">
                <div className="fab-content">
                    <img src={aiIcon} height="40px" title="Ask AI" />
                </div>
            </div>
        </div>
    )
}