import React from "react";


export const AllDataSourcesComponent = ({ dataSources }) => {

    return (
        <div className="row">
            <div className="col-md-12 col-lg-12 col-12">
                <div className="card-deck bl-card-deck">
                    {
                        dataSources.map((src, i) => {
                            return (
                                <div key={`risk-data-src-${i}`} className="card">
                                    <div className="card-header"><h6 className={`mb-0`}>{src.name}</h6></div>
                                    <div className="card-body p-0">
                                        <ul className="list-group list-group-flush">
                                            {
                                                src.items.map((item, j) => {
                                                    return (
                                                        <li key={`risk-cat-item-${i}${j}`} className="list-group-item d-flex justify-content-between align-items-center font-small">
                                                            {item.name}
                                                            <i className={`fa fa-check-circle text-${item.status_color}`} aria-hidden="true"></i>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}