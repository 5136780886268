import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { TreeTable } from "primereact/treetable";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const TableViewComponent = ({ data, reportColumn, catalog, pinRemove }) => {

    const nav = useNavigate();
    const [nodes, setNodes] = useState([]);

    useEffect(() => {
        setNodes(data);
        // setNodes(loadNodes(0, data));
    }, [data]);

    const loadNodes = (first, rows) => {
        let nodes = [];

        for (let i = 0; i < rows.length; i++) {
            let node = {
                key: `report-key-${first + i}`,
                data: {
                    "reportName": rows[i].reportName,
                    "reportCategory": rows[i].reportCategory,
                    "thumbnailUrl": rows[i].thumbnailUrl,
                    "createdBy": rows[i].createdBy,
                    "createdOn": rows[i].createdOn,
                    "updatedAt": rows[i].updatedAt,
                    "pinned": rows[i].pinned,
                    "userGroups": rows[i].userGroups,
                    "sharedWith": rows[i].sharedWith,
                    "hasAccess": rows[i].hasAccess,
                    reportDescription: rows[i].reportDescription,
                    reportType: rows[i].reportType
                },
                // leaf: false
            };

            nodes.push(node);
        }
        setTimeout(() => {
            let leafItem = nodes.find(n => n.leaf !== undefined && n.leaf === false);
            if (leafItem) {
                let classList = document.getElementsByClassName('p-treetable-toggler');
                for (let i = 0; i < classList.length; i++) {
                    classList[i].classList.remove('d-none')
                }
            }
            else {
                let classList = document.getElementsByClassName('p-treetable-toggler');
                for (let i = 0; i < classList.length; i++) {
                    classList[i].classList.add('d-none')
                }
            }
        }, 500)
        return nodes;
    };

    const reportTypeBackground = (type) => {
        if(type){
            let reportType = type.toLowerCase();
            if(reportType === 'powerbi'){
                return 'badge-warning'
            }
            else if(reportType === 'tableau'){
                return 'badge-primary'
            }
            else if(reportType === 'domo'){
                return 'badge-info'
            }
            else {
                return 'badge-secondary'
            }
        }
        else {
            return 'badge-light'
        }
    }

    const arrangeColumnValue = (val, name) => {
        let report = val;
        if (name === 'REPORT') {
            return (
                <div className="d-flex align-items-center">
                    {
                       pinRemove ? '' : report.pinned ? (<i className={`fa ${catalog ? 'fa-unlock' : 'fa-thumb-tack'} mr-2`} aria-hidden="true"></i>) : (<i className={`fa ${catalog ? 'fa-lock' : 'fa-thumb-tack text-gray'} mr-2`} aria-hidden="true"></i>)
                    }

                    <img src={report.thumbnailUrl} alt="" className="bl-report-thumbnail" />
                    <div className="pl-2">
                        <p className="mb-0 text-nowrap"><b>{report.reportName}</b></p>
                        <p className="text-muted font-small mb-0"><i>Updated {report.updatedAt}</i></p>
                    </div>
                </div>
            )
        }
        else if (name === 'Category') {
            return (
                <span>{report.reportCategory}</span>
            )
        }
        else if (name === 'Shared with') {
            return (
                <div className="d-flex">
                    {
                        report.sharedWith.map((u, i) => {
                            return (
                                <span key={`chip-rep-${i}`} className="badge bl-filter-chip bg-white bl-limited-text d-block px-2" title={u}><i className="fa fa-fw fa-user-o" aria-hidden="true"></i>{u}</span>
                            )
                        })
                    }
                </div>
            )
        }
        else if (name === 'Description') {
            return (
                <span>{report.reportDescription}</span>
            )
        }
        else if (name === 'Report Type') {
            return (
                <span className={`badge badge-pill ${reportTypeBackground(report.reportType)}`}>{report.reportType}</span>
            )
        }
        else if (name === 'Created By') {
            return (
                <span>{report.createdBy}</span>
            )
        }
        else if (name === 'Action') {
            return (
                <div>
                    {
                        !report.hasAccess ? <button className="btn btn-primary btn-sm">Request Access</button> : null
                }
                </div>

            )
        }
        else if (name === 'User Groups') {
            return (
                <div className="d-flex">
                    {
                        report.userGroups.map((u, i) => {
                            return (
                                <span key={`chip-rep-${i}`} className="badge bl-chip">{u}</span>
                            )
                        })
                    }
                </div>
            )
        }
    }

    const addColumnWidth = (column) => {
        if(column === 'Report Type'){
            return {width: '7.3rem'}
        }
        else if(column === 'Category'){
            return {width: '8rem'}
        }
        else if(column === 'Action' && catalog) {
            return {width: '15rem'}
        }
        else return ''
    }

    const viewReport = (data) => {
        const item = data.data;
        nav(`/analytics/reports/${item.catSlug}/${item.reportId}`)
    }

    return (
        <DataTable value={nodes} onRowClick={viewReport} className="cp">
            <Column style={{ width: '13rem' }} field="companyName" header="Report Name" body={(e) => arrangeColumnValue(e, 'REPORT')} sortable={!catalog}></Column>
            {/* <Column style={{ width: '8rem' }} field="reportCategory" className="bl-width-8rem1" header="Category" sortable></Column> */}
            <Column style={{ width: '7rem' }} field="createdBy" className="bl-width-7rem1" header="Created By"sortable={!catalog}></Column>

            {
                reportColumn && reportColumn.map((column, i) => {
                    return (
                        <Column key={`table-column-${i}`} style={addColumnWidth(column)} field="sharedWith" header={column} body={(e) => arrangeColumnValue(e, column)} sortable={!catalog}></Column>
                    )
                })
            }

        </DataTable>
    )
}