import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import GuageChart from "../Graph/guagechart";
import HeatmapChart from "../Graph/heatmap";
import HierarchyChart from "../Graph/hierarchychart";
import companyRiskProfile from "./../../data/Risk/companyRiskProfile.json";
import riskEventJson from "./../../data/Risk/events.json";
import appUpdatesJson from "./../../data/Risk/appUpdates.json";
import moment from "moment";
import { TableCompanyViewComponent } from "./common/tableCompanyView";
import { RiskEventViewComponent } from "./common/eventsListView";
import { TrendChartComponent } from "../Graph/trendChart";
import SearchContext from "../../services/context/Search/searchContext";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";
import { capitalizeFirstLetter } from "../../services/commonService";


export const RiskCompanyProfileComponent = () => {

    window.scrollTo(0, 0);

    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const location = useLocation()

    useEffect(() => {
        const companySlug = getCompanyInfo();
        let crumbElement = [];
        if (typeof companySlug != 'string') {
            crumbElement = [
                { slug: '', menuName: 'Risk Monitor', isActive: true, hasSubMenu: true },
                { slug: '/risk/overview', menuName: 'Overview', isActive: false, hasSubMenu: true },
                { slug: `/risk/company/${companySlug[0]}`, menuName: capitalizeFirstLetter(companySlug[0]), isActive: false, hasSubMenu: true },
                { slug: ``, menuName: companySlug[1], isActive: false, hasSubMenu: true }
            ];
        }
        else if (companySlug) {
            crumbElement = [
                { slug: '', menuName: 'Risk Monitor', isActive: true, hasSubMenu: true },
                { slug: '/risk/overview', menuName: 'Overview', isActive: false, hasSubMenu: true },
                { slug: `/risk/company/${companySlug}`, menuName: capitalizeFirstLetter(companySlug), isActive: false, hasSubMenu: true }
            ];
        }
        setBreadcrumbMenu(crumbElement)
    }, [location])

    const [companyList] = useState(companyRiskProfile);
    const [eventList] = useState(riskEventJson);
    const [viewType, setViewType] = useState('OVERVIEW')
    const [loaded, setIsLoaded] = useState(false)
    const [riskScores, setRiskScores] = useState([]);
    const [heatYAxisData, setHeatYAxisData] = useState([]);
    const [heatXAxisData, setHeatXAxisData] = useState([]);
    const [companyInfo, setCompanyInfo] = useState({
        company: '', logo: '', website: '', description: '', address: '', employeeRange: '', fundingRound: '', valuation: '', fundingAmount: '',
        totalEmployee: '', acquisitions: '', investors: '', investments: '', exits: '', insights: ''
    });
    const [userActivity, setUserActivity] = useState([]);
    const [filter] = useState([
        { val: 'ALL', name: 'All', isActive: true },
        { val: 'LEGAL', name: 'Legal', isActive: false },
        { val: 'HUMAN_CAPITAL', name: 'Human Capital', isActive: false },
        { val: 'Operational', name: 'Operational', isActive: false }
    ]);
    const [columns] = useState([
        'Total Funding', 'Valuation', 'Headquarters'
    ])

    const getCompanyInfo = () => {
        let pathArray = location.pathname.split('/');
        if (pathArray && pathArray.length > 2) {
            if (pathArray[2] === 'company' && pathArray.length === 4) {
                return pathArray[3]
            }
            return pathArray[2]
        }
        return ''
    }

    useEffect(() => {
        let companySlug = getCompanyInfo();
        let company = companyRiskProfile.find(c => c.slug === companySlug);
        if (company) {
            setCompanyInfo(company)
            let riskScoresList = [];
            let periods = [];
            company.riskScores.map((s, i) => {
                if (i < 18) {
                    riskScoresList.push({
                        company: company.company,
                        period: s.period,
                        value: s.value
                    });
                    periods.push({
                        period: s.period
                    })
                }
            })
            setHeatYAxisData([{ company: company.company }]);
            setRiskScores(riskScoresList);
            setHeatXAxisData(periods)
            setIsLoaded(true)
        }
    }, [location])

    useEffect(() => {
        let items = [];
        for (let i = 0; i < 4; i++) {
            items.push(appUpdatesJson[i])
        }
        setUserActivity(items)
    }, [appUpdatesJson])

    const getUpdatedTime = (time) => {

        if (time) {
            let date = moment(new Date(time));
            return date.calendar({
                sameDay: '[Today at] hh:mm A',
                nextDay: '[Tomorrow]',
                nextWeek: 'dddd',
                lastDay: '[Yesterday at] hh:mm A',
                lastWeek: '[Last] dddd',
                sameElse: 'MMM DD, YYYY hh:mm A'
            })
        }
        else {
            return ''
        }

    }

    const dashHeat = (item) => {
        // console.log("get item from dash ==>", item);
    }


    const overViewContent = () => {
        return (
            <>
                <section className="mb-3">
                    <div className="row">
                        <div className="col-xl-12 col-md-12">
                            <figure>
                                <pre className="m-0"><i className="fa fa-info-circle"></i> {companyInfo.insights}  <button className="btn btn-link btn-sm">click to view</button></pre>
                            </figure>
                        </div>
                        <div className="col-xl-4 col-md-4 col-12 mb-0">
                            <div className="card shadow-sm">
                                <div className="card-header d-flex justify-content-between">
                                    <div className="align-self-center">
                                        <h6 className="mb-0">Risk Score</h6>
                                    </div>
                                </div>
                                <div className="card-body p-3">
                                    <GuageChart height={'15rem'}></GuageChart>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-4 col-md-4 col-12 mb-0">
                            <div className="card shadow-sm">
                                <div className="card-header d-flex justify-content-between">
                                    <div className="align-self-center">
                                        <h6 className="mb-0">Distribution of Companies by Risk Factor Range</h6>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <HierarchyChart height="15rem"></HierarchyChart>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-4 col-12 mb-0">
                            <div className="card shadow-sm">
                                <div className="card-header d-flex justify-content-between">
                                    <div className="align-self-center">
                                        <h6 className="mb-0">Risk Trend Over Months</h6>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <TrendChartComponent height="15rem"></TrendChartComponent>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="mb-3">
                    <div className="row">
                        <div className="col-12">
                            <h6 className="mb-3">{'Detected Events by Risks Category'}</h6>
                            <div className="d-flex pb-2">
                                {
                                    filter.map((f, i) => {
                                        return (
                                            <span key={`risk-cate-filter-${i + 1}`} className={`badge bl-filter-chip ${f.isActive ? 'active' : ''}`}>{f.name}</span>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </section>
                <RiskEventViewComponent eventList={eventList} companyInfo={companyInfo}></RiskEventViewComponent>
                {/* <div className="col-xl-12 col-md-12 mb-4 text-center"><button type="button" className="btn btn-sm btn-outline-secondary">Load More</button></div> */}
            </>
        )
    }

    return (
        <div id="content">

            <div className="container-fluid pt-3">
                <section className="mb-3">
                    <div className="row">
                        <div className="col-xl-6 col-md-6 col-12">
                            <div className="bl-company-profile d-flex">
                                <div className="mr-2"><img className="img-fluid img-profile rounded-circle" src={companyInfo.logo} alt="company-logo" /></div>
                                <div>
                                    <h5 className="mb-0">{companyInfo.company}</h5>
                                    <p className="text-gray mb-0"><a href={companyInfo.website} target="_blank" className="font-small">{companyInfo.website}</a></p>
                                    <div className="bl-social-links">
                                        <a className="twitter"><i className="fa fa-twitter"></i></a>
                                        <a className="facebook"><i className="fa fa-facebook"></i></a>
                                        <a className="instagram"><i className="fa fa-instagram"></i></a>
                                        <a className="linkedin"><i className="fa fa-linkedin"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-6 col-12 text-right align-self-center">
                            <button className="btn btn-primary btn-sm mr-2 mb-0">
                                <i className="fa fa-fw fa-share" aria-hidden="true"></i>Share
                            </button>
                            <button className="btn btn-primary btn-sm mr-2 mb-0">
                                <i className="fa fa-fw fa-exchange" aria-hidden="true"></i>Compare
                            </button>
                            <button className="btn btn-primary btn-sm mr-2 mb-0">
                                <i className="fa fa-fw fa-download" aria-hidden="true"></i>
                            </button>
                            <button className="btn btn-primary btn-sm mr-2 mb-0">
                                <i className="fa fa-thumb-tack" aria-hidden="true"></i>
                            </button>
                            <button className="btn btn-primary btn-sm mr-2 mb-0">
                                <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </section>
                <section className="mb-2">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                            <h6><i className="fa fa-fw fa-info-circle" aria-hidden="true"></i> About</h6>
                            <p>{companyInfo.description}</p>
                            <p><i className="fa fa-fw fa-home" aria-hidden="true"></i> {companyInfo.address}</p>
                            <p><i className="fa fa-fw fa-users" aria-hidden="true"></i> {companyInfo.employeeRange}</p>
                            <p><i className="fa fa-fw fa-usd" aria-hidden="true"></i> {companyInfo.fundingRound}</p>
                            <p><i className="fa fa-fw fa-university" aria-hidden="true"></i> {companyInfo.valuation}</p>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                            <h6><i className="fa fa-fw fa-bolt" aria-hidden="true"></i> Highlights</h6>
                            <div className="row">
                                <div className="col-xl-6 col-md-6 col-6">
                                    <p>Total Funding Amount <br /><b>{companyInfo.fundingAmount}</b></p>
                                    <p>Current Team Member <br /><b>{companyInfo.totalEmployee}</b></p>
                                    <p>Acquisitions <br /><b>{companyInfo.acquisitions}</b></p>
                                </div>
                                <div className="col-xl-6 col-md-6 col-6">
                                    <p>No of Investors <br /><b>{companyInfo.investors}</b></p>
                                    <p>Investments <br /><b>{companyInfo.investments}</b></p>
                                    <p>Exits <br /><b>{companyInfo.exits}</b></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                            <h6><i className="fa fa-fw fa-rss-square" aria-hidden="true"></i> Recent News & Activity</h6>
                            {
                                userActivity.map((activity, i) => {
                                    return (
                                        <div key={`risk-activity-k-${i + 1}`} >
                                            <div className={`bl-activity py-2`}>
                                                <div className="">
                                                    <i className={`fa fa-user-o rounded-circle bl-user-activity-icon`} aria-hidden="true"></i>
                                                </div>
                                                <div>
                                                    <p className="mb-0">{activity.message}</p>
                                                    <p className="text-muted font-small mb-0">{getUpdatedTime(activity.updated_at ? activity.updated_at : activity.created_at ? activity.created_at : null)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </section>
                <section className="mb-3">
                    <div className="row">
                        <div className="col-12">
                            {
                                loaded ? <HeatmapChart id="companyriskscorechart" data={riskScores} yaxisData={heatYAxisData} xAxisData={heatXAxisData} categoryYField="company" categoryXField="period" yAxisVisiblity={false} xAxisVisiblity={true} height={'60px'} dashHeat={(item) => dashHeat(item)}></HeatmapChart> : null
                            }
                        </div>
                    </div>
                </section>
                <section className="mb-3">
                    <div className="row">
                        <div className="col-xl-12 col-md-12 col-12">
                            <ul className="nav nav-pills border-bottom border-light">
                                <li className="nav-item">
                                    <a className={viewType === 'OVERVIEW' ? 'nav-link active blc_nav_active' : 'nav-link'} onClick={() => setViewType('OVERVIEW')}>Summary</a>
                                </li>
                                <li className="nav-item">
                                    <a className={viewType === 'ALL' ? 'nav-link active blc_nav_active' : 'nav-link'} onClick={() => setViewType('ALL')}>Comparable Risk-Related Companies</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
                {
                    viewType === 'OVERVIEW' ? overViewContent() : <TableCompanyViewComponent companyList={companyList} columns={columns}></TableCompanyViewComponent>
                }




            </div>
        </div>
    )
}