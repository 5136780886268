import React, { useState } from 'react';
import AIContext from './aiContext';

const AIAssistanceContextProvider = ({ children }) => {
  const [aiModalType, setAIModalType] = useState('');
  const [companyProfileWidget, setCompanyProfileWidget] = useState(null);

  return (
    <AIContext.Provider value={{ aiModalType, setAIModalType, companyProfileWidget, setCompanyProfileWidget }}>
      {children}
    </AIContext.Provider>
  );
};

export default AIAssistanceContextProvider;