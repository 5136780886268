import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import appListJson from "./../../data/AIModel/modelList.json";
import appCategoryJson from "./../../data/AIModel/subMenu.json";
import { AIModelCardComponent } from "./AIModelCard";
import SearchContext from "../../services/context/Search/searchContext";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";


export const AIModelCategoriesComponent = () => {

    window.scrollTo(0, 0);
    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const location = useLocation()

    useEffect(() => {
        const slug = getLocationSlug();
        const category = appCategoryJson[0].list.find(c => c.slug === slug);
        if (slug === 'all') {
            setAppCategory('All AI Models')
            setAppList(appListJson)
        }
        else {
            if (category) {
                setAppCategory(category.name)
                let applist = appListJson.filter(app => app.category === category.name);
                setAppList(applist)
            }
        }
        setBreadcrumbMenu(
            [
                { slug: '', menuName: 'AI Model', isActive: true, hasSubMenu: true },
                { slug: `/ai-model/category/${slug}`, menuName: category.name, isActive: false, hasSubMenu: true }
            ]
        )
    }, [location])

    const [applist, setAppList] = useState([])
    const [appCategory, setAppCategory] = useState('')

    const getLocationSlug = () => {
        let pathArray = location.pathname.split('/');
        if (pathArray && pathArray.length > 3) {
            return pathArray[3];
        }
    }

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <div className="row">
                    <div className="col">
                        <section className="mb-3">
                            <h6>{appCategory}</h6>
                        </section>
                        <section className="mb-3">
                            <div className="card-deck">
                                {
                                    applist.map((app, i) => {
                                        return (
                                            <AIModelCardComponent key={`category-app-${i}`} app={app}></AIModelCardComponent>
                                        )
                                    })
                                }
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}