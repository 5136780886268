import React, { useContext, useEffect, useState } from "react";
import company_logo_placeholder from "./../../../assets/img/company_logo_placeholder.svg"
import { SourceCommonCompanyTitle } from "../Common/companyTitle";
import { getCompanyInformationService } from "../../../services/companyService";
import { useLocation } from "react-router-dom";
import BreadcrumbContext from "../../../services/context/Breadcrumb/breadcrumbContext";


export const DealProfileComponent = () => {

    const location = useLocation()
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const [isLoaded, setIsLoaded] = useState(false)
    const [source, setSource] = useState('')
    const [profileInfo, setProfileInfo] = useState(null)
    const [financialTableInfo, setFinancialTableInfo] = useState([])
    const [financialTableHeader, setFinancialTableHeader] = useState([])
    const [companyInfo, setCompanyInfo] = useState({
        companyId: '', company: '', logo: '', website: '', description: '', address: '', employeeRange: '', fundingRound: '', valuation: '', fundingAmount: '',
        totalEmployee: '', acquisitions: '', investors: '', investments: '', exits: '', insights: '', BlScore: '', slug: '', isNew: false,
        socialNetworkUrl: [], isDataSourceAdded: false, isDocumentsAdded: false
    });

    useEffect(() => {
        setBreadcrumbMenu(
            [
                { slug: '', menuName: 'Sourcing', isActive: true, hasSubMenu: true },
                { slug: '/sourcing/overview', menuName: 'Overview', isActive: false, hasSubMenu: true },
                { slug: '', menuName: 'Summary', isActive: false, hasSubMenu: true }
            ]
        )
        setIsLoaded(false)
        const pathArray = location.pathname.split('/');
        const companySlug = pathArray.length > 3 ? pathArray[3] : 'smartbeat';
        getCompanyInformation(companySlug)
    }, [location.pathname])

    useEffect(() => {
        if (source) {
            generateValuationFinancialTableData()
        }
    }, [source])

    const generateValuationFinancialTableData = () => {
        const financialData = profileInfo.valuationSummary.FinancialHighlights;
        const metricElements = financialData.Metric;
        const values = financialData.Value;
        const mergedElement = metricElements.map((item, index) => [item, ...values[index]]);
        console.log("mergedElement ==>", mergedElement);
        setFinancialTableInfo(mergedElement)
        setFinancialTableHeader(['Metric', ...financialData.Time])
    }

    const getCompanyInformation = async (slug) => {
        const resp = await getCompanyInformationService(slug);
        if (resp && resp.data && resp.data.length) {
            setProfileInfo(resp.data[0].projectInfo && resp.data[0].projectInfo.data ? resp.data[0].projectInfo.data : null)
            setSource(resp.data[0].source)
            setCompanyInfo(resp.data[0])
        }
        setIsLoaded(true)
    }

    const updateCompanyInfo = () => {
        getCompanyInformation(companyInfo.slug)
    }

    return (
        <div id="content">
            {
                isLoaded ? (
                    <div className="container-fluid pt-3">
                        <section className="mb-3">
                            {
                                isLoaded ? <SourceCommonCompanyTitle item={companyInfo} onChildInfoUpdate={updateCompanyInfo} isDealProject={true}></SourceCommonCompanyTitle> : null
                            }
                        </section>
                        <section className="mb-3">
                            <div className="row bl-profile-heighlights">
                                <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                                    <h6><i className="fa fa-fw fa-building" aria-hidden="true"></i> Company Overview </h6>
                                    <p>{profileInfo.companyOverview.description}
                                        <span className="ml-1 text-primary">[Pg {profileInfo.companyOverview.pageCitation.join(', ')}]</span>
                                    </p>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                                    <h6>Deal Overview</h6>
                                    <div className="row">
                                        <div className="col-xl-6 col-md-6 col-6">
                                            <p>Deal Source <br /><b>{profileInfo.dealOverview.dealSource ? profileInfo.dealOverview.dealSource : 'NA'}</b></p>
                                            <p>Source Type <br /><b>{profileInfo.dealOverview.sourceType ? profileInfo.dealOverview.sourceType : 'NA'}</b></p>
                                            <p>Deal Type <br /><b>{profileInfo.dealOverview.dealType ? profileInfo.dealOverview.dealType : 'NA'}</b></p>
                                        </div>
                                        <div className="col-xl-6 col-md-6 col-6">
                                            <p>Industry <br /><b>{profileInfo.dealOverview.industry ? profileInfo.dealOverview.industry : 'NA'}</b></p>
                                            <p>Geography <br /><b>{profileInfo.dealOverview.geography ? profileInfo.dealOverview.geography : 'NA'}</b></p>
                                            <p>Investment Size <br />
                                                <b>{profileInfo.dealOverview.investmentSize ? profileInfo.dealOverview.investmentSize : 'NA'}</b>
                                                {/* <span className="ml-1 text-primary">[Pg {profileInfo.dealOverview.pageCitation.join(', ')}]</span> */}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                                    <h6>Comparable Companies</h6>
                                    <div className="row">
                                        {
                                            profileInfo.ComparableCompanies.map((activity, i) => {
                                                // const newsImage = activity && activity.urlToImage ? activity.urlToImage : missingImg;
                                                return (
                                                    <div key={`risk-activity-k-${i + 1}`} className="col-6" >
                                                        <div className={`bl-activity py-2 align-items-center`}>
                                                            <img className="img-fluid border rounded" src={company_logo_placeholder} width={'20px'} alt="news logo" />
                                                            <div>
                                                                <p className="mb-0">{activity.companyName}</p>
                                                                <a className="bl-desc-truncate-line-2 p-0 text-left" href={activity.companyUrl} target="_blank">{activity.companyUrl}</a>
                                                                {/* <p className="text-muted font-smaller bl-desc-truncate-line-1 mb-0">{moment(new Date(activity.publishedAt)).format('MMM DD, YYYY')} | {activity.source}</p> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="mb-3 card shadow-sm">
                            <div className="card-header">
                                <div className="d-flex align-self-center">
                                    <h6 className="mb-0">Journey So Far</h6>
                                </div>
                            </div>
                            <div className="card-body timeline p-0">
                                <ol>
                                    {
                                        profileInfo.timelineChart && profileInfo.timelineChart.length ? profileInfo.timelineChart.map((timeline, i) => {
                                            return (
                                                <li key={`timeline-${i}`}>
                                                    <div>
                                                        <time>{timeline.date}</time>
                                                        <b>{timeline.title}</b>
                                                        <p className="text-muted">{timeline.description}</p>
                                                    </div>
                                                </li>
                                            )
                                        }) : null
                                    }
                                    <li></li>
                                </ol>
                            </div>
                        </section>
                        <section className="mb-3">
                            <div className="card shadow-sm">
                                <div className="card-header d-flex justify-content-between">
                                    <div className="d-flex align-self-center">
                                        <h6 className="mb-0">Executive Summary</h6>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <p>{profileInfo.executiveSummary.description}
                                        <span className="ml-1 text-primary">[Pg {profileInfo.executiveSummary.pageCitation.join(', ')}]</span>
                                    </p>
                                </div>
                            </div>
                        </section>
                        <section className="mb-3">
                            <div className="card-deck">
                                <div className="card shadow-sm">
                                    {/* <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">Assesment Title A</h6>
                                        </div>
                                    </div> */}
                                    <div className="card-body">
                                        <h6 className="text-muted mb-3">Considerations</h6>
                                        <table width={'100%'}>
                                            <tbody>
                                                <tr>
                                                    <td width={'25%'}>Strategic Fit</td>
                                                    <td className="bl-truncate-line-1 text-muted mr-2">{profileInfo.considerations.strategicFit.explanation}</td>
                                                    <td width={'10%'}>
                                                        {
                                                            profileInfo.considerations.strategicFit.rating.toLowerCase() === 'high' ? (
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-success" role="progressbar" style={{ width: '100%' }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                            ) : profileInfo.considerations.strategicFit.rating.toLowerCase() === 'medium' ? (
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-warning" role="progressbar" style={{ width: '70%' }} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                            ) : (
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-danger" role="progressbar" style={{ width: '35%' }} aria-valuenow="35" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                            )
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Synergy Potential</td>
                                                    <td className="bl-truncate-line-1 text-muted mr-2">{profileInfo.considerations.synergyPotential.explanation}</td>
                                                    <td>
                                                        {
                                                            profileInfo.considerations.synergyPotential.rating.toLowerCase() === 'high' ? (
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-success" role="progressbar" style={{ width: '100%' }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                            ) : profileInfo.considerations.synergyPotential.rating.toLowerCase() === 'medium' ? (
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-warning" role="progressbar" style={{ width: '70%' }} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                            ) : (
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-danger" role="progressbar" style={{ width: '35%' }} aria-valuenow="35" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                            )
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Exit Strategy Viability</td>
                                                    <td className="bl-truncate-line-1 text-muted mr-2">{profileInfo.considerations.synergyPotential.explanation}</td>
                                                    <td>
                                                        {
                                                            profileInfo.considerations.synergyPotential.rating.toLowerCase() === 'high' ? (
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-success" role="progressbar" style={{ width: '100%' }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                            ) : profileInfo.considerations.synergyPotential.rating.toLowerCase() === 'medium' ? (
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-warning" role="progressbar" style={{ width: '70%' }} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                            ) : (
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-danger" role="progressbar" style={{ width: '35%' }} aria-valuenow="35" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                            )
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <h6 className="text-muted my-3">Market & Competitive Position</h6>
                                        <table width={'100%'}>
                                            <tbody>
                                                <tr>
                                                    <td width={'25%'}>Market Growth Potential</td>
                                                    <td className="bl-truncate-line-1 text-muted mr-2">{profileInfo.marketAndCompetitivePosition.marketGrowthPotential.explanation}</td>
                                                    <td width={'10%'}>
                                                        {
                                                            profileInfo.marketAndCompetitivePosition.marketGrowthPotential.rating.toLowerCase() === 'high' ? (
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-success" role="progressbar" style={{ width: '100%' }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                            ) : profileInfo.marketAndCompetitivePosition.marketGrowthPotential.rating.toLowerCase() === 'medium' ? (
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-warning" role="progressbar" style={{ width: '70%' }} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                            ) : (
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-danger" role="progressbar" style={{ width: '35%' }} aria-valuenow="35" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                            )
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Competitive Landscape</td>
                                                    <td className="bl-truncate-line-1 text-muted mr-2">{profileInfo.marketAndCompetitivePosition.competitiveLandscape.explanation}</td>
                                                    <td>
                                                        {
                                                            profileInfo.marketAndCompetitivePosition.competitiveLandscape.rating.toLowerCase() === 'high' ? (
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-success" role="progressbar" style={{ width: '100%' }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                            ) : profileInfo.marketAndCompetitivePosition.competitiveLandscape.rating.toLowerCase() === 'medium' ? (
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-warning" role="progressbar" style={{ width: '70%' }} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                            ) : (
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-danger" role="progressbar" style={{ width: '35%' }} aria-valuenow="35" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                            )
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Value Proposition</td>
                                                    <td className="bl-truncate-line-1 text-muted mr-2">{profileInfo.marketAndCompetitivePosition.differentiationAndValueProposition.explanation}</td>
                                                    <td>
                                                        {
                                                            profileInfo.marketAndCompetitivePosition.differentiationAndValueProposition.rating.toLowerCase() === 'high' ? (
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-success" role="progressbar" style={{ width: '100%' }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                            ) : profileInfo.marketAndCompetitivePosition.differentiationAndValueProposition.rating.toLowerCase() === 'medium' ? (
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-warning" role="progressbar" style={{ width: '70%' }} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                            ) : (
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-danger" role="progressbar" style={{ width: '35%' }} aria-valuenow="35" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                            )
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <h6 className="text-muted my-3">Source/Referral Record</h6>
                                        <table width={'100%'}>
                                            <tbody>
                                                <tr>
                                                    <td width={'25%'}>Engagement Period</td>
                                                    <td className="bl-truncate-line-1 text-muted mr-2">{profileInfo.sourceReferralRecord.engagementPeriod.explanation}</td>
                                                    <td width={'10%'}>
                                                        {
                                                            profileInfo.sourceReferralRecord.engagementPeriod.rating.toLowerCase() === 'high' ? (
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-success" role="progressbar" style={{ width: '100%' }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                            ) : profileInfo.sourceReferralRecord.engagementPeriod.rating.toLowerCase() === 'medium' ? (
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-warning" role="progressbar" style={{ width: '70%' }} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                            ) : (
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-danger" role="progressbar" style={{ width: '35%' }} aria-valuenow="35" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                            )
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Success Rate</td>
                                                    <td className="bl-truncate-line-1 text-muted mr-2">{profileInfo.sourceReferralRecord.successRate.explanation}</td>
                                                    <td>
                                                        {
                                                            profileInfo.sourceReferralRecord.successRate.rating.toLowerCase() === 'high' ? (
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-success" role="progressbar" style={{ width: '100%' }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                            ) : profileInfo.sourceReferralRecord.successRate.rating.toLowerCase() === 'medium' ? (
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-warning" role="progressbar" style={{ width: '70%' }} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                            ) : (
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-danger" role="progressbar" style={{ width: '35%' }} aria-valuenow="35" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                            )
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Overall Rating Outcome</td>
                                                    <td className="bl-truncate-line-1 text-muted mr-2">{profileInfo.sourceReferralRecord.overallRatingOutcome.explanation}</td>
                                                    <td>
                                                        {
                                                            profileInfo.sourceReferralRecord.overallRatingOutcome.rating.toLowerCase() === 'high' ? (
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-success" role="progressbar" style={{ width: '100%' }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                            ) : profileInfo.sourceReferralRecord.overallRatingOutcome.rating.toLowerCase() === 'medium' ? (
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-warning" role="progressbar" style={{ width: '70%' }} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                            ) : (
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-danger" role="progressbar" style={{ width: '35%' }} aria-valuenow="35" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                            )
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="card shadow-sm">
                                    {/* <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">Assesment Title B</h6>
                                        </div>
                                    </div> */}
                                    <div className="card-body">
                                        <h6 className="text-muted mb-3">Financial Metrics</h6>
                                        <table width={'100%'}>
                                            <tbody>
                                                {
                                                    profileInfo.financialMetrics && profileInfo.financialMetrics.map((m, i) => {
                                                        return (
                                                            <tr key={`financial-metric-${i}`}>
                                                                <td width={'25%'}>{m.metric}</td>
                                                                <td className="bl-truncate-line-1 text-muted mr-2">{m.explanation}</td>
                                                                <td width={'10%'}>
                                                                    {
                                                                        m.rating.toLowerCase() === 'high' ? (
                                                                            <div className="progress">
                                                                                <div className="progress-bar bg-success" role="progressbar" style={{ width: '100%' }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                                            </div>
                                                                        ) : m.rating.toLowerCase() === 'medium' ? (
                                                                            <div className="progress">
                                                                                <div className="progress-bar bg-warning" role="progressbar" style={{ width: '70%' }} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                                                                            </div>
                                                                        ) : (
                                                                            <div className="progress">
                                                                                <div className="progress-bar bg-danger" role="progressbar" style={{ width: '35%' }} aria-valuenow="35" aria-valuemin="0" aria-valuemax="100"></div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        <h6 className="text-muted my-3">Risk Assessment</h6>
                                        <table width={'100%'}>
                                            <tbody>
                                                <tr>
                                                    <td width={'25%'}>Market Risk</td>
                                                    <td className="bl-truncate-line-1 text-muted mr-2">{profileInfo.riskAssessment.marketRisk.explanation}</td>
                                                    <td width={'10%'}>
                                                        {
                                                            profileInfo.riskAssessment.marketRisk.rating.toLowerCase() === 'high' ? (
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-success" role="progressbar" style={{ width: '100%' }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                            ) : profileInfo.riskAssessment.marketRisk.rating.toLowerCase() === 'medium' ? (
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-warning" role="progressbar" style={{ width: '70%' }} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                            ) : (
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-danger" role="progressbar" style={{ width: '35%' }} aria-valuenow="35" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                            )
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Financial Risk</td>
                                                    <td className="bl-truncate-line-1 text-muted mr-2">{profileInfo.riskAssessment.financialRisk.explanation}</td>
                                                    <td>
                                                        {
                                                            profileInfo.riskAssessment.financialRisk.rating.toLowerCase() === 'high' ? (
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-success" role="progressbar" style={{ width: '100%' }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                            ) : profileInfo.riskAssessment.financialRisk.rating.toLowerCase() === 'medium' ? (
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-warning" role="progressbar" style={{ width: '70%' }} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                            ) : (
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-danger" role="progressbar" style={{ width: '35%' }} aria-valuenow="35" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                            )
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Operational Risk</td>
                                                    <td className="bl-truncate-line-1 text-muted mr-2">{profileInfo.riskAssessment.operationalRisk.explanation}</td>
                                                    <td>
                                                        {
                                                            profileInfo.riskAssessment.operationalRisk.rating.toLowerCase() === 'high' ? (
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-success" role="progressbar" style={{ width: '100%' }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                            ) : profileInfo.riskAssessment.operationalRisk.rating.toLowerCase() === 'medium' ? (
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-warning" role="progressbar" style={{ width: '70%' }} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                            ) : (
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-danger" role="progressbar" style={{ width: '35%' }} aria-valuenow="35" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                            )
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Execution Risk</td>
                                                    <td className="bl-truncate-line-1 text-muted mr-2">{profileInfo.riskAssessment.executionRisk.explanation}</td>
                                                    <td>
                                                        {
                                                            profileInfo.riskAssessment.executionRisk.rating.toLowerCase() === 'high' ? (
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-success" role="progressbar" style={{ width: '100%' }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                            ) : profileInfo.riskAssessment.executionRisk.rating.toLowerCase() === 'medium' ? (
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-warning" role="progressbar" style={{ width: '70%' }} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                            ) : (
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-danger" role="progressbar" style={{ width: '35%' }} aria-valuenow="35" aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                            )
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </section>
                        <section className="mb-3">
                            <div className="card shadow-sm">
                                <div className="card-header d-flex justify-content-between">
                                    <div className="d-flex align-self-center">
                                        <h6 className="mb-0">Valuation Summary</h6>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <h6>Analysis</h6>
                                    <div className="row">
                                        <div className="col-6">
                                            <p>{profileInfo.valuationSummary.valuationAnalysis.keyAreasOfMatchMismatch.description}
                                                <span className="ml-1 text-primary">[Pg {profileInfo.valuationSummary.valuationAnalysis.keyAreasOfMatchMismatch.pageCitation.join(', ')}]</span>
                                            </p>
                                            <p>{profileInfo.valuationSummary.valuationAnalysis.potentialFit.description}
                                                <span className="ml-1 text-primary">[Pg {profileInfo.valuationSummary.valuationAnalysis.potentialFit.pageCitation.join(', ')}]</span>
                                            </p>
                                        </div>
                                        <div className="col-6">
                                            <div className="card-deck">
                                                {
                                                    profileInfo.valuationSummary.valuationAnalysis.table.map((s, i) => {
                                                        return (
                                                            <div key={`valuation-summary-analysis-${i}`} className="card bg-gray">
                                                                <div className="card-body">
                                                                    <p>{s.method}</p>
                                                                    <h6 className="mb-0">{s.rangeOfValue}</h6>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 p-0">
                                        <div className="table table-responsive">
                                            <table width="100%" cellPadding="0" cellSpacing="0" border="0" className="table table-sm mb-0">
                                                <thead>
                                                    <tr>
                                                        {
                                                            financialTableHeader.map((t, i) => {
                                                                return (
                                                                    <th key={`financial-table-header-${i}`}>{t}</th>
                                                                )
                                                            })
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        financialTableInfo.map((r, i) => {
                                                            return (
                                                                <tr key={`fin-tab-row-${i}`}>
                                                                    {
                                                                        r.map((item, j) => {
                                                                            return <td key={`fin-tab-row-data-${i}-${j}`}>{item}</td>
                                                                        })
                                                                    }
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="col-12 p-0">
                                        <h6>Valuation Comparison</h6>
                                        <p>{profileInfo.valuationSummary.ValuationComparison.description}
                                            <span className="ml-1 text-primary">[Pg {profileInfo.valuationSummary.ValuationComparison.pageCitation.join(', ')}]</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                ) : null
            }

        </div>
    )
}