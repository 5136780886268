import React, { useState } from 'react';

export const AnalyzeCompanyContext = React.createContext();

export const AnalyzeCompanyContextProvider = ({ children }) => {

    const [analyzedCompanyInfo, setAnalyzedCompanyInfo] = useState(null);

    return (
        <AnalyzeCompanyContext.Provider value={{ analyzedCompanyInfo, setAnalyzedCompanyInfo }}>
            {children}
        </AnalyzeCompanyContext.Provider>
    );
};
