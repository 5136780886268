import React, { useContext, useEffect, useState } from "react";
import companyRiskProfile from "./../../../data/Risk/companyRiskProfile.json";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { TrendChartComponent } from "../../Graph/trendChart";
import { SourceCommonCompanyTitle } from "../Common/companyTitle";
import { PeopleListComponent } from "../Common/peopleListView";
import { ColumnChart } from "../../Graph/columnChart";
import sourceprofile2Pic from "./../../../assets/img/sourceprofile2.png";
import eventNews from "./../../../data/Risk/eventsExplorer.json";
import ask_question from "./../../../assets/icons/app/AI_Icon.png";
import { ESGScoreCardComponent } from "../../Common/esgScoreCard";
import keyPeopleJson from "./../../../data/peopleData.json";
import AIContext from "../../../services/context/AIAssistance/aiContext";
import SearchContext from "../../../services/context/Search/searchContext";
import BreadcrumbContext from "../../../services/context/Breadcrumb/breadcrumbContext";
import { getAllDatasourceService, getCompanyInformationService, getCompanyNewsByCategoryService, getCompanyNewsService, getCompanyWidgetInformationService, getFinanceInfoService } from "../../../services/companyService";
import { EmptyCardsContent } from "../Common/emptyCard";
import { convertCamelCaseToSeparateWords, getUploadedFilesService, truncateFileName } from "../../../services/commonService";
import emptyIcon from "./../../../assets/img/empty-widget.png";
import missingImg from "./../../../assets/img/imagemissing.png";
import { CardDropdownContent } from "../../Common/cardDropdown";
import { DealProfileComponent } from "./dealProfile";
import { MapChartComponent } from "../../Graph/mapchart";
import { WorldMapChartComponent } from "../../Graph/worldMapChart";


export const SourceCompanyProfileComponent = () => {

    window.scrollTo(0, 0);
    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const { setAIModalType } = useContext(AIContext);
    const { companyProfileWidget, setCompanyProfileWidget } = useContext(AIContext);
    const nav = useNavigate()
    const location = useLocation()

    useEffect(() => {
        setBreadcrumbMenu(
            [
                { slug: '', menuName: 'Sourcing', isActive: true, hasSubMenu: true },
                { slug: '/sourcing/overview', menuName: 'Overview', isActive: false, hasSubMenu: true },
                { slug: '', menuName: 'Profile', isActive: false, hasSubMenu: true }
            ]
        )
    }, [location])

    const [source, setSource] = useState('')
    const [companyProfileInfo, setCompanyProfileInfo] = useState(null)
    const [companyInfo, setCompanyInfo] = useState({
        companyId: '', company: '', logo: '', website: '', description: '', address: '', employeeRange: '', fundingRound: '', valuation: '', fundingAmount: '',
        totalEmployee: '', acquisitions: '', investors: '', investments: '', exits: '', insights: '', BlScore: '', slug: '', isNew: false,
        socialNetworkUrl: [], isDataSourceAdded: false, isDocumentsAdded: false
    });
    const [userActivity, setUserActivity] = useState([]);
    const [keyPeople, setKeyPeople] = useState([
        {
            name: "Satya Nadella",
            designation: "CEO",
            about: "Satya Narayana Nadella is an Indian-American business executive. He is the executive chairman and CEO of Microsoft, succeeding Steve Ballmer in 2014 as CEO and John W.",
            isBoardMember: true,
            country: 'in'
        }
    ])
    const [categoryField, setCategoryField] = useState("");
    const [incomeSeries, setIncomeSeries] = useState([]);
    const [incomePeriod, setIncomePeriod] = useState("Quarterly");
    const [incomePeriodData, setIncomePeriodData] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false)
    const [companyNews, setCompanyNews] = useState([])
    const [socialMediaFollowers, setSocialMediaFollowers] = useState([])
    const [valuationChartData, setValuationChartData] = useState([])
    const [employeeChartData, setEmployeeChartData] = useState([])
    const [isGraphDataLoaded, setIsGraphDataLoaded] = useState(false)
    const [isFinanceGraphDataLoaded, setIsFinanceGraphDataLoaded] = useState(false)
    const [ESGScores, setESGScores] = useState({
        "esg_score": 77,
        "community": 87,
        "environment": 83,
        "employees": 65,
        "governance": 81
    })
    const [isESGDataFetched, setIsESGDataFetched] = useState(false)
    const [isNewsFetched, setIsNewsFetched] = useState(false)
    const [isTwitterEnabled, setIsTwitterEnabled] = useState(false)
    const [isPitchbookEnabled, setIsPitchbookEnabled] = useState(false)
    const [uploadedFileName, setUploadedFileName] = useState('')
    const [companyLocations, setCompanyLocations] = useState([])
    const [companyProducts, setCompanyProducts] = useState([])
    const [companyServices, setCompanyServices] = useState([])
    const [companyFirstHalfClients, setCompanyFirstHalfClients] = useState([])
    const [companySecondHalfClients, setCompanySecondHalfClients] = useState([])


    useEffect(() => {
        setIsLoaded(false)
        setIsGraphDataLoaded(false)
        setIsFinanceGraphDataLoaded(false)
        setIsTwitterEnabled(false)
        setIsPitchbookEnabled(false)
        let pathArray = location.pathname.split('/');
        const companySlug = pathArray.length > 3 ? pathArray[3] : 'smartbeat';
        getCompanyInformation(companySlug, 1)
        // getIncomeStatementData()
    }, [location])

    useEffect(() => {
        let items = [];
        for (let i = 0; i < 3; i++) {
            items.push(eventNews[0].categories[i].list[0])
        }
        setUserActivity(items)
    }, [eventNews])

    const getCompanyWidgetInfo = async (slug) => {
        setIsESGDataFetched(false)
        const resp = await getCompanyWidgetInformationService(slug);
        getCompanyResponse(resp)
        setIsGraphDataLoaded(true)
    }

    function getRandomElements(arr, count) {
        return arr
            .sort(() => Math.random() - 0.5)
            .slice(0, count);
    }

    const getCompanyInformation = async (slug, retry) => {
        setIsESGDataFetched(false)
        const resp = await getCompanyInformationService(slug);
        if (retry === 1) {
            getCompanyResponse(resp)
        }
        if (resp && resp.data && resp.data.length) {
            const companyInfoResp = resp.data[0];
            setSource(resp.data[0].source)
            setCompanyProfileInfo(resp.data[0].projectInfo && resp.data[0].projectInfo.data ? resp.data[0].projectInfo.data : null)
            if (resp.data.length && resp.data[0].keyexecutives) {
                let peoples = resp.data[0].keyexecutives.map(p => {
                    return {
                        name: p.Name,
                        about: p.Title,
                        country: resp.data[0].country ? (resp.data[0].country).toLowerCase() : 'us',
                        phone: 'NA',
                        email: 'NA',
                        url: p.employee_link
                    }
                })
                setKeyPeople(peoples)
            }
            setCompanyInfo(resp.data[0])
            if (companyInfoResp.external_data && companyInfoResp.external_data.location) {
                const compLocations = companyInfoResp.external_data.location.map(loc => {
                    return {
                        title: loc.city,
                        latitude: loc.latitude, longitude: loc.longitude
                    }
                })
                setCompanyLocations(compLocations)
            }
            if (companyInfoResp.external_data && companyInfoResp.external_data.products) {
                if (Array.isArray(companyInfoResp.external_data.products)) {
                    const compPrds = getRandomElements(companyInfoResp.external_data.products, 10);
                    setCompanyProducts(compPrds)
                }
                else {
                    const compPrds = getRandomElements(companyInfoResp.external_data.products.split(','), 10);
                    setCompanyProducts(compPrds)
                }
            }
            if (companyInfoResp.external_data && companyInfoResp.external_data.services) {
                if (Array.isArray(companyInfoResp.external_data.services)) {
                    const compPrds = getRandomElements(companyInfoResp.external_data.services, 10);
                    setCompanyServices(compPrds)
                }
                else {
                    const compPrds = getRandomElements(companyInfoResp.external_data.services.split(','), 10);
                    setCompanyServices(compPrds)
                }
            }
            if (companyInfoResp.external_data && companyInfoResp.external_data.clients && companyInfoResp.external_data.clients.length) {
                const clients = companyInfoResp.external_data.clients;
                const middleIndex = Math.ceil(clients.length / 2);
                const firstHalf = clients.slice(0, middleIndex);
                const secondHalf = clients.slice(middleIndex);
                setCompanyFirstHalfClients(firstHalf)
                setCompanySecondHalfClients(secondHalf)
            }
            if (retry === 1) {
                getAllDatasource(resp.data[0].companyId)
                getFinanceInfo(resp.data[0].companyId)
                getCompanyNews(resp.data[0].companyId)
            }
            const compDate = companyInfoResp.updated_at ? companyInfoResp.updated_at : moment();
            const diffInHours = moment().diff(moment(compDate), 'hours', true)
            if (Math.abs(diffInHours) < 1 && companyInfoResp.source === 'company' && !companyInfoResp.external_data && retry <= 5) {
                setTimeout(() => {
                    getCompanyInformation(slug, retry + 1)
                }, 7000)
            }
        }
    }

    const getCompanyResponse = (resp) => {
        setIsLoaded(true)
        if (resp && resp.success && resp.data) {
            if (!resp.data[0].social_media_followers_over_time || !resp.data[0].valuation_over_time || !resp.data[0].employee_count_over_time) {
                getCompanyWidgetInfo(resp.data[0].slug)
            }

            if (resp.data.length && resp.data[0].ESGScore) {
                setESGScores(resp.data[0].ESGScore)
                setIsESGDataFetched(true)
            }
            if (resp.data.length && resp.data[0].social_media_followers_over_time && resp.data[0].social_media_followers_over_time.length) {
                setSocialMediaFollowers(resp.data[0].social_media_followers_over_time)
                setIsGraphDataLoaded(true)
            }
            if (resp.data.length && resp.data[0].valuation_over_time && resp.data[0].valuation_over_time.length) {
                setValuationChartData(resp.data[0].valuation_over_time)
                setIsGraphDataLoaded(true)
            }
            if (resp.data.length && resp.data[0].employee_count_over_time && resp.data[0].employee_count_over_time.length) {
                setEmployeeChartData(resp.data[0].employee_count_over_time)
                setIsGraphDataLoaded(true)
            }
        }
    }

    const getAllDatasource = async (companyId) => {
        setIsPitchbookEnabled(false)
        setIsTwitterEnabled(false)
        const resp = await getAllDatasourceService(companyId);
        if (resp && resp.success) {
            resp.data.forEach(s => {
                if (s.name === 'Pitchbook' && s.mappingId) {
                    setIsPitchbookEnabled(true);
                }
                if (s.name === 'X' && s.mappingId) {
                    setIsTwitterEnabled(true);
                }
            })
        }
    }

    const getCompanyNews = async (companyId) => {
        setIsNewsFetched(false)
        const resp = await getCompanyNewsByCategoryService(companyId);
        if (resp && resp.success) {
            const articles = resp.data;
            let categoryNews = [];
            for (let key in articles) {
                categoryNews.push({
                    eventCategory: key,
                    list: articles[key]
                })
            }
            const allObjects = categoryNews.flatMap(category => category.list);
            const sortedObjects = allObjects.sort((a, b) => new Date(b.publishedAt) - new Date(a.publishedAt));
            const list = sortedObjects.slice(0, 3)
            setCompanyNews(list)
        }
        setIsNewsFetched(true)
    }

    useEffect(() => {
        if (isNewsFetched && companyInfo.external_data && companyNews.length === 0) {
            const articles = companyInfo.external_data.companyNews;
            let categoryNews = [];
            articles.forEach(n => {
                categoryNews.push({
                    eventCategory: 'Others',
                    source: 'Company Website',
                    title: n.headline,
                    url: n.url,
                    publishedAt: new Date(n.date)
                })
            })
            const sortedObjects = categoryNews.sort((a, b) => new Date(b.publishedAt) - new Date(a.publishedAt));
            const list = sortedObjects.slice(0, 3)
            setCompanyNews(list)
        }
    }, [isNewsFetched, companyInfo.external_data])

    const getFinanceInfo = async (companyId) => {
        setIsFinanceGraphDataLoaded(false)
        const resp = await getFinanceInfoService(companyId);
        setIsFinanceGraphDataLoaded(true)
        if (resp && resp.success && resp.data && resp.data.length && resp.data[0].income_statement) {
            getIncomeStatementData(resp.data[0].income_statement)
            setUploadedFileName(resp.fileName ? resp.fileName : '')
        }
    }

    const getIncomeStatementData = (statements) => {
        const data = statements.graph ? statements.graph : [];
        setIncomePeriodData(data);
        setCategoryField('date');
        let seriesItem = []
        for (let key in data[0]) {
            seriesItem.push({
                name: convertCamelCaseToSeparateWords(key),
                val: key
            })
        }
        const items = seriesItem.filter(item => item.val !== 'date');
        setIncomeSeries(items)
    }

    const getUpdatedTime = (time) => {

        if (time) {
            let date = moment(new Date(time));
            return date.calendar({
                sameDay: '[Today at] hh:mm A',
                nextDay: '[Tomorrow]',
                nextWeek: 'dddd',
                lastDay: '[Yesterday at] hh:mm A',
                lastWeek: '[Last] dddd',
                sameElse: 'MMM DD, YYYY hh:mm A'
            })
        }
        else {
            return ''
        }

    }

    const onChildCardEvent = (item) => {
        if (item) {
            onModalLaunch(item)
        }
    }

    const onModalLaunch = (modalMode) => {
        let companyProfileInfoObj = {
            company: companyInfo.company, website: companyInfo.website, description: companyInfo.description, address: companyInfo.address,
            employeeRange: companyInfo.employeeRange, fundingRound: companyInfo.fundingRound, valuation: companyInfo.valuation,
            fundingAmount: companyInfo.fundingAmount, totalEmployee: companyInfo.totalEmployee
        }
        if (modalMode === 'RISK') {
            companyProfileInfoObj.riskScores = companyInfo.riskScores
        }
        else if (modalMode === 'ESG') {
            companyProfileInfoObj.ESGScore = companyInfo.ESGScore
        }
        else if (modalMode === 'FINANCIAL') {
            companyProfileInfoObj.incomeStatement = companyInfo.incomeStatement
            companyProfileInfoObj.incomePeriodData = incomePeriodData;
            companyProfileInfoObj.categoryField = categoryField;
            companyProfileInfoObj.incomeSeries = incomeSeries;
        }
        else if (modalMode === 'VALUATION') {
            companyProfileInfoObj.valuationChartData = valuationChartData;
        }
        else if (modalMode === 'EMPLOYEE_COUNT') {
            companyProfileInfoObj.employeeChartData = employeeChartData;
        }
        else if (modalMode === 'SOCIAL_MEDIA') {
            companyProfileInfoObj.socialMediaFollowers = socialMediaFollowers;
        }
        else if (modalMode === 'KEY_PEOPLE') {
            companyProfileInfoObj.keyPeople = keyPeople;
        }
        setCompanyProfileWidget(companyProfileInfoObj)
        setAIModalType(modalMode)
    }

    const switchModule = (moduleName) => {
        let path = location.pathname.replace('profile', moduleName);
        nav(path)
    }

    const getCompanySectors = () => {
        if (companyInfo.specialty && companyInfo.specialty.length) {
            let specialty = [];
            const specialtyList = companyInfo.specialty.slice(0, 3);
            for (let i = 0; i < specialtyList.length; i++) {
                specialty.push(companyInfo.specialty[i].specialty)
            }
            return specialty.join(', ');
        }
        else return 'NA'
    }

    const updateCompanyInfo = () => {
        getCompanyInformation(companyInfo.slug, 1)
    }

    const handleImageError = (e) => {
        e.target.src = missingImg;
        e.target.alt = 'Alternative Image';
    }

    const profileSection = () => {
        return (
            <div>
                <section className="mb-3">
                    <div className="row bl-profile-heighlights">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                            <h6><i className="fa fa-fw fa-info-circle" aria-hidden="true"></i> About <span className="badge bl-source-badge"><i className="fa fa-database" aria-hidden="true"></i>{'LinkedIn, Crunchbase'}</span></h6>
                            <p className="bl-truncate-line-5">{companyInfo.description ? companyInfo.description : companyInfo.external_data && companyInfo.external_data.aboutUs ? companyInfo.external_data.aboutUs : ''}</p>
                            <p><i className="fa fa-fw fa-location-arrow" aria-hidden="true"></i> {companyInfo.address ? companyInfo.address : companyInfo.external_data && companyInfo.external_data.headquarters ? companyInfo.external_data.headquarters : 'NA'}</p>
                            <p><i className="fa fa-fw fa-users" aria-hidden="true"></i> {companyInfo.employeeRange ? companyInfo.employeeRange : 'NA'}</p>
                            <p><i className="fa fa-fw fa-stack-exchange" aria-hidden="true"></i> {companyInfo.type ? companyInfo.type : 'NA'}</p>
                            {/* <div className="d-flex font-small">
                                <i className="fa fa-fw fa-tags align-self-center mr-1" aria-hidden="true"></i>
                                <span className="badge bl-filter-chip bg-transparent py-1 px-2">
                                    <i className="fa fa-users" aria-hidden="true"></i>Consumer Digital
                                </span>
                                <span className="badge bl-filter-chip bg-transparent py-1 px-2">
                                    <i className="fa fa-cc-mastercard" aria-hidden="true"></i>Online Payment
                                </span>
                            </div> */}
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                            <h6><i className="fa fa-fw fa-bolt" aria-hidden="true"></i> Highlights</h6>
                            <div className="row">
                                <div className="col-xl-6 col-md-6 col-6">
                                    <p>Total Funding Amount <br /><b>{companyInfo.fundingAmount ? companyInfo.fundingAmount : 'NA'}</b></p>
                                    <p>Latest Valuation <br /><b>{companyInfo.valuation ? companyInfo.valuation : 'NA'}</b></p>
                                    <p>Industry <br /><b>{companyInfo.industry ? companyInfo.industry : 'NA'}</b></p>
                                    {
                                        companyInfo.external_data ? <p>Geographic Reach <br /><b>{companyInfo.external_data.listOfContinents && companyInfo.external_data.listOfContinents.length ? companyInfo.external_data.listOfContinents.join(', ') : 'NA'}</b></p> : null
                                    }
                                </div>
                                <div className="col-xl-6 col-md-6 col-6">
                                    <p>Funding Rounds <br /><b>{companyInfo.rounds ? companyInfo.rounds : 'NA'}</b></p>
                                    <p>No of Investors <br /><b>{companyInfo.investments ? companyInfo.investments : 'NA'}</b></p>
                                    <p className="bl-truncate-line-4">Sector <br /><b>{companyInfo.specialty && companyInfo.specialty.length ? getCompanySectors() : 'NA'}</b></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                            <h6><i className="fa fa-fw fa-rss-square" aria-hidden="true"></i> Recent News <span className="badge bl-source-badge"><i className="fa fa-database" aria-hidden="true"></i>{'Google News, Reddit, Business Wire'}</span></h6>
                            {
                                companyNews.map((activity, i) => {
                                    const newsImage = activity && activity.urlToImage ? activity.urlToImage : missingImg;
                                    return (
                                        <div key={`risk-activity-k-${i + 1}`} >
                                            <div className={`bl-activity py-2`}>
                                                <img className="img-fluid align-self-center bl-w-4rem" src={newsImage} onError={handleImageError} alt="news logo" />
                                                <div>
                                                    <p className="mb-0"><small className="text-muted">{activity.eventCategory}</small></p>
                                                    <a className="bl-desc-truncate-line-2 p-0 text-left" href={activity.url} target="_blank">{activity.title}</a>
                                                    <p className="text-muted font-smaller bl-desc-truncate-line-1 mb-0">{moment(new Date(activity.publishedAt)).format('MMM DD, YYYY')} {activity.source ? `| ${activity.source}` : ''}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {
                                isNewsFetched && companyNews.length === 0 ?
                                    <div className="d-flex align-self-center mt-3">
                                        <img className="mr-3 rounded align-self-center" src={emptyIcon} height="60px" alt="Generic placeholder image" />
                                        <div>
                                            <h6 className="mb-1 align-self-center">{'No News Found'}</h6>
                                            <p className="mb-2 align-self-center font-small text-muted bl-truncate-line-2">{'Unable to build widget. Additional data sources required.'}</p>
                                            <button className="btn btn-sm secondary-link btn-outline-primary" data-toggle="modal" data-target="#datasourceModal">View Data Sources</button>
                                        </div>
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                </section>
                <section className="mb-3">
                    <div className="row">
                        <div className="col-12 mb-3">
                            <div className="card-deck">
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">Locations</h6>
                                            <span className="badge bl-source-badge ml-1"><i className="fa fa-globe" aria-hidden="true"></i>{'Website'}</span>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        {
                                            !isLoaded ?
                                                (<div className="row align-items-center h-100">
                                                    <div className="col"> <span className="loader"></span></div>
                                                </div>) :
                                                companyLocations && companyLocations.length ? <WorldMapChartComponent height="12rem" data={companyLocations} /> : <EmptyCardsContent title="Location not found" buttonView={false} />
                                        }

                                    </div>
                                </div>
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">Product & Services</h6>
                                            <span className="badge bl-source-badge ml-1"><i className="fa fa-globe" aria-hidden="true"></i>{'Website'}</span>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        {
                                            !isLoaded ?
                                                (<div className="row align-items-center h-100">
                                                    <div className="col"> <span className="loader"></span></div>
                                                </div>) :
                                                companyProducts && companyProducts.length || companyServices && companyServices.length ?
                                                    <div className="row">
                                                        {
                                                            companyProducts && companyProducts.length ?
                                                                <div className="col-12 mb-4">
                                                                    <div className="row">
                                                                        <div className="col-3">
                                                                            <h6>Products</h6>
                                                                        </div>
                                                                        <div className="row col-9">
                                                                            {
                                                                                companyProducts.map((prd, i) => {
                                                                                    return (
                                                                                        <span key={`prodkey${i}`} className="badge bl-filter-chip mb-2">{prd}</span>
                                                                                    )
                                                                                })
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                        {
                                                            companyServices && companyServices.length ?
                                                                <div className="col-12">
                                                                    <div className="row">
                                                                        <div className="col-3">
                                                                            <h6>Services</h6>
                                                                        </div>
                                                                        <div className="row col-9">
                                                                            {
                                                                                companyServices.map((prd, i) => {
                                                                                    return (
                                                                                        <span key={`serviceskey${i}`} className="badge bl-filter-chip mb-2">{prd}</span>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> : null
                                                        }

                                                    </div> : <EmptyCardsContent title="No products and services found" buttonView={false} />
                                        }

                                    </div>
                                </div>
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center">
                                            <h6 className="mb-0">Key Clients</h6>
                                            <span className="badge bl-source-badge ml-1"><i className="fa fa-globe" aria-hidden="true"></i>{'Website'}</span>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        {
                                            !isLoaded ?
                                                (<div className="row align-items-center h-100">
                                                    <div className="col"> <span className="loader"></span></div>
                                                </div>) :
                                                companyFirstHalfClients && companyFirstHalfClients.length ?
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <ul>
                                                                {
                                                                    companyFirstHalfClients.map((c, i) => {
                                                                        return <li key={`firsthalfclients${i}`}>{c}</li>
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                        <div className="col-6">
                                                            <ul>
                                                                {
                                                                    companySecondHalfClients.map((c, i) => {
                                                                        return <li key={`secondhalfclients${i}`}>{c}</li>
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div> : <EmptyCardsContent title="No key clients found" buttonView={false} />
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-12 mb-3">
                            <div className="card-deck">
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center cp" onClick={() => switchModule('risk')}>
                                            <h6 className="mb-0">Risk Intensity over Time</h6>
                                            <span className="badge bl-source-badge ml-1"><i className="fa fa-database" aria-hidden="true"></i>{'PitchBook, S&P Capital IQ'}</span>
                                        </div>
                                        <CardDropdownContent cardId="profileriskcard" cardType={'RISK'} onChildCardEvent={(item) => onChildCardEvent(item)} />
                                    </div>
                                    <div className="card-body">
                                        {/* {
                                            !isGraphDataLoaded ?
                                            (<div className="row align-items-center h-100">
                                                <div className="col"> <span className="loader"></span></div>
                                            </div>) : <img className="img-fluid" src={sourceprofile2Pic} />
                                        } */}
                                        <img className="img-fluid" src={sourceprofile2Pic} />
                                    </div>
                                </div>
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center cp" onClick={() => switchModule('esg')}>
                                            <h6 className="mb-0">ESG Scorecard</h6>
                                            <span className="badge bl-source-badge ml-1"><i className="fa fa-database" aria-hidden="true"></i>{'Bloomberg ESG Data'}</span>
                                        </div>
                                        <CardDropdownContent cardId="profileesgcard" cardType={'ESG'} onChildCardEvent={(item) => onChildCardEvent(item)} />
                                    </div>
                                    <div className="card-body">
                                        {
                                            !isESGDataFetched ?
                                                (<div className="row align-items-center h-100">
                                                    <div className="col"> <span className="loader"></span></div>
                                                </div>) : <ESGScoreCardComponent id="companyprofileinfo" scores={ESGScores}></ESGScoreCardComponent>
                                        }
                                    </div>
                                </div>
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center cp" onClick={() => switchModule('financial')}>
                                            <h6 className="mb-0">Financial Summary Over Time</h6>
                                            {companyInfo.isDocumentsAdded && incomePeriodData.length && <span className="badge bl-source-badge ml-1"><i className="fa fa-file-excel-o" aria-hidden="true"></i>{truncateFileName(uploadedFileName, 20)}</span>}
                                        </div>
                                        {companyInfo.isDocumentsAdded && incomePeriodData.length && <CardDropdownContent cardId="profilefinancialcard" cardType={'FINANCIAL'} onChildCardEvent={(item) => onChildCardEvent(item)} />}
                                    </div>
                                    <div className="card-body p-3">
                                        {
                                            !isFinanceGraphDataLoaded ?
                                                (<div className="row align-items-center h-100">
                                                    <div className="col"> <span className="loader"></span></div>
                                                </div>) :
                                                companyInfo.isDocumentsAdded && incomePeriodData.length ?
                                                    <ColumnChart id="profileincomestatementfinance" data={incomePeriodData} categoryField={categoryField} seriesItem={incomeSeries} height="8rem"></ColumnChart> :
                                                    <EmptyCardsContent title="No Financial Summary data found" />
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-12 mb-3">
                            <div className="card-deck">
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center" onClick={() => switchModule('financial')}>
                                            <h6 className="mb-0">Valuation Over Time</h6>
                                            <span className="badge bl-source-badge ml-1"><i className="fa fa-database" aria-hidden="true"></i>{'PitchBook'}</span>
                                        </div>
                                        <CardDropdownContent cardId="profilevaluationcard" cardType={'VALUATION'} onChildCardEvent={(item) => onChildCardEvent(item)} />
                                    </div>
                                    <div className="card-body p-3">
                                        {
                                            !isGraphDataLoaded ?
                                                (<div className="row align-items-center h-100">
                                                    <div className="col"> <span className="loader"></span></div>
                                                </div>) :
                                                valuationChartData.length ?
                                                    <TrendChartComponent id="sourceprofilevaluation" height="15rem" chartInfo={valuationChartData}></TrendChartComponent> :
                                                    <EmptyCardsContent title="No Valuation data found" />
                                        }

                                    </div>
                                </div>
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center cp" onClick={() => switchModule('people')}>
                                            <h6 className="mb-0">Employee Count Over Time</h6>
                                            {isPitchbookEnabled && employeeChartData.length && <span className="badge bl-source-badge ml-1"><i className="fa fa-database" aria-hidden="true"></i>{'LinkedIn, Crunchbase, Glassdoor'}</span>}
                                        </div>
                                        {isPitchbookEnabled && employeeChartData.length && <CardDropdownContent cardId="profileemployeecard" cardType={'EMPLOYEE_COUNT'} onChildCardEvent={(item) => onChildCardEvent(item)} />}
                                    </div>
                                    <div className="card-body p-3">
                                        {
                                            !isGraphDataLoaded ?
                                                (<div className="row align-items-center h-100">
                                                    <div className="col"> <span className="loader"></span></div>
                                                </div>) :
                                                isPitchbookEnabled && employeeChartData.length ? <TrendChartComponent id="sourceprofileemployee" height="15rem" chartInfo={employeeChartData}></TrendChartComponent> :
                                                    <EmptyCardsContent title="No Employee data found" />
                                        }
                                    </div>
                                </div>
                                <div className="card shadow-sm">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="d-flex align-self-center cp" onClick={() => switchModule('people')}>
                                            <h6 className="mb-0">Social Media Followers Over Time</h6>
                                            {isTwitterEnabled && socialMediaFollowers.length && <span className="badge bl-source-badge ml-1"><i className="fa fa-database" aria-hidden="true"></i>{'X, LinkedIn'}</span>}
                                        </div>
                                        {isTwitterEnabled && socialMediaFollowers.length && <CardDropdownContent cardId="profilesocialmediacard" cardType={'SOCIAL_MEDIA'} onChildCardEvent={(item) => onChildCardEvent(item)} />}
                                    </div>
                                    <div className="card-body p-3 cp">
                                        {
                                            !isGraphDataLoaded ?
                                                (<div className="row align-items-center h-100">
                                                    <div className="col"> <span className="loader"></span></div>
                                                </div>) :
                                                isTwitterEnabled && socialMediaFollowers.length ?
                                                    <TrendChartComponent id="sourceprofilesocialmedia" height="15rem" chartInfo={socialMediaFollowers}></TrendChartComponent> :
                                                    <EmptyCardsContent title="No Social Media data found" />
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
                <section className="mb-3">
                    <div className="row">
                        <div className="col-12">
                            <div className="card shadow-sm">
                                <div className="card-header d-flex justify-content-between">
                                    <div className="d-flex align-self-center cp" onClick={() => switchModule('people')}>
                                        <h6 className="mb-0 align-self-center">Key People</h6>
                                        <span className="badge bl-source-badge ml-1"><i className="fa fa-database" aria-hidden="true"></i>{'LinkedIn'}</span>
                                    </div>
                                    <CardDropdownContent cardId="profilekeypeoplecard" cardType={'KEY_PEOPLE'} onChildCardEvent={(item) => onChildCardEvent(item)} />
                                </div>
                                <div className="card-body p-0">
                                    <PeopleListComponent list={keyPeople}></PeopleListComponent>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }

    return (
        <div id="content">

            <div className="container-fluid pt-3">
                <section className="mb-3">
                    {
                        isLoaded ? <SourceCommonCompanyTitle item={companyInfo} onChildInfoUpdate={updateCompanyInfo}></SourceCommonCompanyTitle> : null
                    }
                </section>
                {
                    source === 'project' ? <DealProfileComponent profileInfo={companyProfileInfo} /> : profileSection()
                }

            </div>
        </div>
    )
}