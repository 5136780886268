import React, { useContext, useEffect, useState } from "react";
import recomendedDataSourceJson from "./../../../data/Risk/dataSources_recommended.json";
import allDataSourceJson from "./../../../data/Risk/dataSources_all.json";
import { RecommendedSourceComponent } from "../../Common/recommendedSource";
import { AllDataSourcesComponent } from "../../Common/allDataSources";
import SearchContext from "../../../services/context/Search/searchContext";
import BreadcrumbContext from "../../../services/context/Breadcrumb/breadcrumbContext";


export const RiskDataSourceComponent = () => {
    window.scrollTo(0, 0);

    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)

    useEffect(() => {
        setBreadcrumbMenu(
            [
                { slug: '', menuName: 'Risk Monitor', isActive: true, hasSubMenu: true },
                { slug: '/risk/data-sources', menuName: 'Data Source', isActive: false, hasSubMenu: true }
            ]
        )
    }, [])

    const [viewType, setViewType] = useState('RECOMENDED')

    const recomendedSourcesContent = () => {
        return (
            <section className="mb-3">
                <RecommendedSourceComponent recommendedDataSource={recomendedDataSourceJson}></RecommendedSourceComponent>
            </section>
        )
    }

    const viewAllSourcesContent = () => {
        return (
            <section className="mb-3">
                <AllDataSourcesComponent dataSources={allDataSourceJson}></AllDataSourcesComponent>
            </section>
        )
    }

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <section className="mb-3 d-flex justify-content-between">
                    <div>
                        <h6>Data Sources</h6>
                    </div>
                    <div>
                        <button className="btn btn-primary btn-sm mr-2"><i className="fa fa-fw fa-refresh" aria-hidden="true"></i>Refresh</button>
                        <button className="btn btn-primary btn-sm"><i className="fa fa-fw fa-plus" aria-hidden="true"></i>{'Add New Source'}</button>
                    </div>
                </section>
                <section className="mb-3">
                    <div className="row">

                        <div className="col-xl-12 col-md-12 mb-3">
                            <ul className="nav nav-pills border-bottom border-light">
                                <li className="nav-item">
                                    <a className={viewType === 'RECOMENDED' ? 'nav-link active blc_nav_active' : 'nav-link'} onClick={() => setViewType('RECOMENDED')}>Recomemded for You</a>
                                </li>
                                <li className="nav-item">
                                    <a className={viewType === 'ALL' ? 'nav-link active blc_nav_active' : 'nav-link'} onClick={() => setViewType('ALL')}>View All Sources</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
                {
                    viewType === 'RECOMENDED' ? recomendedSourcesContent() : viewAllSourcesContent()
                }
            </div>
        </div>
    )
}