import axios from "axios";
const apiKey = process.env.REACT_APP_BASE_URL;

const instance = axios.create({
    baseURL: apiKey
})

const cannabisInstance = axios.create({
    baseURL: "https://file-process-ai.azurewebsites.net/api/ai_file_processor?code=_CqhaBln1DgYHLrL3vOjj1O2gPmJ-5zmvaQ899D8-wj7AzFuN-hJnQ%3D%3D"
})

instance.interceptors.request.use((config) => {
    // config.headers['Authorization'] = `${getToken()}`;
    // config.headers['Content-Type'] = `multipart/form-data`;
    return config;
}, (err) => {
    return Promise.reject(err);
})

instance.interceptors.response.use((res) => {
    return res.data;
}, (err) => {
    if (!err || !err.response) {
        window.location.href = '/#/system-down'
    }
    else if (err.response.status === 401) {
        // removeToken()
        window.location.href = "/";
    }
    else if (err.response.status >= 500) {
        window.location.href = "/#/error";
    }

    return Promise.reject(err.response);
})

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const convertCamelCaseToSeparateWords = (camelCaseWord) => {
    const separateWords = camelCaseWord.replace(/([a-z])([A-Z])/g, '$1 $2');
    let words = separateWords.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    return words.replace(/_/g, ' ')
}

export const truncateFileName = (fileName, maxLength) => {
    if (fileName.length <= maxLength) {
      return fileName;
    } else {
      const extensionIndex = fileName.lastIndexOf('.');
      if (extensionIndex !== -1) {
        const extension = fileName.slice(extensionIndex);        
        const truncatedName = fileName.slice(0, maxLength - extension.length);        
        return truncatedName + '...' + extension;
      } else {
        return fileName.slice(0, maxLength);
      }
    }
  }

const fileUpload = 'ai/data/upload';
const text = 'ai/question';
const uploadedFilesUrl = 'ai/file/audit';
const canabisRadditUrl = 'reddit/question';
const AIFileURL = "ai/file";

export const fileUploadService = async (file) => {
    try {
        return instance.post(fileUpload, file)
    }
    catch (e) {
        return e;
    }
}

export const cannabisFileUploadService = async (file) => {
    try {
        return instance.post(`${AIFileURL}/upload`, file)
    }
    catch (e) {
        return e;
    }
}

export const questionInputForServerService = async (question) => {
    try {
        return instance.post(text, question)
    }
    catch (e) {
        return e;
    }
}

export const canabisQuestionInputForServerService = async (question) => {
    try {
        return instance.post(canabisRadditUrl, question)
    }
    catch (e) {
        return e;
    }
}

export const getUploadedFilesService = async (obj) => {
    try {
        let queryParams = "?";
        let itemArray = Object.keys(obj);
        for (let i = 0; i < itemArray.length; i++) {
            if (obj[itemArray[i]]) {
                queryParams += `${itemArray[i]}=${obj[itemArray[i]]}&`
            }
        }
        queryParams = queryParams.slice(0, -1)
        return instance.get(`${uploadedFilesUrl}${queryParams}`)
    }
    catch (e) {
        return e;
    }
}

export const cannabisQueryService = async(fileName, query) => {
    try {
        return instance.get(`${AIFileURL}/question/${fileName}/${query}`)
    }
    catch(e){
        return e;
    }
}