import { Column } from "primereact/column";
import { TreeTable } from "primereact/treetable";
import React, { useEffect, useState } from "react";


export const PeopleListComponent = ({ list, itemLenght=10, boardMemberColumn=true }) => {

    const [nodes, setNodes] = useState([]);

    useEffect(() => {
        setNodes(loadNodes(0, list))
    }, [list])

    const loadNodes = (first, rows) => {
        let nodes = [];
        for (let i = 0; i < rows.length; i++) {
            let node = {
                key: `table-company-profile-key-${first + i}`,
                data: {
                    name:  rows[i].name,
                    designation: rows[i].designation,
                    about: rows[i].about,
                    isBoardMember: rows[i].isBoardMember,
                    country: rows[i].country,
                    phone: rows[i].phone,
                    email: rows[i].email,
                    url: rows[i].url
                }
            }
            nodes.push(node);
        }
        return nodes;
    };

    const arrangeColumnValue = (item, column) => {
        let data = item.data;
        if (column === 'BOARD_MEMBER') {
            return (
                <span>
                    {
                        data.isBoardMember ? <span className="badge badge-pill badge-success">Board Member</span> : null
                    }
                </span>
            )
        }
        else if (column === 'CONTACT') {
            return (
                <div>
                    <p className="mb-0"><i className="fa fa-fw fa-phone" aria-hidden="true"></i> {data.phone}</p>
                    <a href={data.url} target="_blank" className="font-small text-gray">{data.url}</a>
                    {/* <p className="mb-0 d-flex font-small"><i className="fa fa-fw fa-envelope-o mr-1 align-self-center" aria-hidden="true"></i> {data.url}</p> */}
                </div>
            )
        }
        else if (column === 'ABOUT') {
            return (
                <span className="bl-desc-truncate-line-2">{data.about}</span>
            )
        }
        else if (column === 'NAME') {
            return (
                <div className="d-flex">
                    <i className="fa fa-user-o rounded-circle bl-user-activity-icon mr-2 align-self-center" aria-hidden="true"></i>
                    <p className="mb-0">
                        <b>{data.name}</b>
                        {/* <span className="font-small">{data.designation}</span> */}
                    </p>
                </div>
            )
        }
        else if (column === 'COUNTRY') {
            return (
                <span className={`flag-icon flag-icon-${data.country}`}></span>
            )
        }
        else if (column === 'SOCIAL') {
            return (
                <div className="d-flex">
                    <a><i className="fa fa-fw fa-twitter mr-2 text-gray" aria-hidden="true"></i></a>
                    <a href={data.url} target="_blank" className="cp"><i className="fa fa-fw fa-linkedin" aria-hidden="true"></i></a>
                </div>
            )
        }
    }

    return (
        <TreeTable value={nodes}>
            <Column field="name" header="Name" body={(e) => arrangeColumnValue(e, 'NAME')} style={{ width: '20%' }} sortable={boardMemberColumn}></Column>
            <Column field="" header="Contact Info" body={(e) => arrangeColumnValue(e, 'CONTACT')} style={{ width: '20%' }} sortable={boardMemberColumn}></Column>
            <Column field="about" header="About" body={(e) => arrangeColumnValue(e, 'ABOUT')} sortable={boardMemberColumn}></Column>
            {
                // boardMemberColumn ? <Column field="isBoardMember" header="Board Member" body={(e) => arrangeColumnValue(e, 'BOARD_MEMBER')} style={{ width: '12%' }} sortable></Column> : null
            }
            
            <Column field="country" header="Country" className="text-center" body={(e) => arrangeColumnValue(e, 'COUNTRY')} style={{ width: '9%' }} sortable={boardMemberColumn}></Column>
            <Column field="social" header="Social" body={(e) => arrangeColumnValue(e, 'SOCIAL')} style={{ width: '10%' }} sortable={boardMemberColumn}></Column>
        </TreeTable>

    )
}