import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { RulesListViewComponent } from "./rulesListView";
import rulesListJson from "./../../data/Rules/rulesList.json";
import rulesMenuJson from "./../../data/Rules/subMenu.json";
import SearchContext from "../../services/context/Search/searchContext";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";


export const RulesCategoryComponent = () => {

    window.scrollTo(0, 0);
    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const location = useLocation()

    useEffect(() => {
        const slug = getLocationSlug();
        const category = rulesMenuJson[0].list.find(c => c.slug === slug);
        if (category) {
            setCategoryTitle(category.name)
            let ruleList = rulesListJson.filter(rule => rule.type === category.name);
            setRules(ruleList)
        }
        setBreadcrumbMenu(
            [
                { slug: '', menuName: 'Rules', isActive: true, hasSubMenu: true },
                { slug: `/rules/category/${slug}`, menuName: category.name, isActive: false, hasSubMenu: true }
            ]
        )
    }, [location])

    const [rules, setRules] = useState([])
    const [categoryTitle, setCategoryTitle] = useState('')

    const getLocationSlug = () => {
        let pathArray = location.pathname.split('/');
        if (pathArray && pathArray.length > 3) {
            return pathArray[3];
        }
    }

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <div className="row">
                    <div className="col">
                        <section className="mb-3">
                            <h6>{categoryTitle}</h6>
                        </section>
                        <section className="mb-3">
                            <RulesListViewComponent list={rules}></RulesListViewComponent>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}