import React, { useEffect, useState } from "react";
import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";

export const ComparableChartComponent = ({ id = 'comparablechart', height = '100px' }) => {

    const [chartRoot, setChartRoot] = useState(null);

    useEffect(() => {
        if (chartRoot) {
            chartRoot.dispose();
        }

        let data = [{
            date: new Date(2019, 5, 12).getTime(),
            value1: 50,
            value2: 48,
            previousDate: new Date(2019, 5, 5)
        }, {
            date: new Date(2019, 5, 13).getTime(),
            value1: 53,
            value2: 51,
            previousDate: "2019-05-06"
        }, {
            date: new Date(2019, 5, 14).getTime(),
            value1: 56,
            value2: 58,
            previousDate: "2019-05-07"
        }, {
            date: new Date(2019, 5, 15).getTime(),
            value1: 52,
            value2: 53,
            previousDate: "2019-05-08"
        }, {
            date: new Date(2019, 5, 16).getTime(),
            value1: 48,
            value2: 44,
            previousDate: "2019-05-09"
        }, {
            date: new Date(2019, 5, 17).getTime(),
            value1: 47,
            value2: 42,
            previousDate: "2019-05-10"
        }, {
            date: new Date(2019, 5, 18).getTime(),
            value1: 59,
            value2: 55,
            previousDate: "2019-05-11"
        }]


        let root = am5.Root.new(id);

        root.dateFormatter.setAll({
            dateFormat: "yyyy",
            dateFields: ["valueX"]
        });

        root.setThemes([
            am5themes_Animated.new(root)
        ]);
        root._logo.dispose();

        var chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: true,
                panY: true,
                wheelX: "panX",
                wheelY: "zoomX",
                paddingLeft: 0,
                paddingBottom: 10,
                paddingTop: 10,
                paddingRight: 0
            })
        );

        chart.get("colors").set("step", 3);

        let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
        cursor.lineY.set("visible", false);

        var easing = am5.ease.linear;

        var xAxis = chart.xAxes.push(
            am5xy.DateAxis.new(root, {
                maxDeviation: 0.3,
                baseInterval: {
                    timeUnit: "day",
                    count: 1
                },
                renderer: am5xy.AxisRendererX.new(root, {}),
                tooltip: am5.Tooltip.new(root, {})
            })
        );

        var yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                maxDeviation: 0.3,
                renderer: am5xy.AxisRendererY.new(root, {})
            })
        );

        yAxis.get("renderer").labels.template.setAll({
            fontSize: 11
        });

        xAxis.get("renderer").labels.template.setAll({
            fontSize: 11
        });

        let series = chart.series.push(am5xy.LineSeries.new(root, {
            name: "Series 1",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value1",
            valueXField: "date",
            tooltip: am5.Tooltip.new(root, {
                labelText: "{valueX}: {valueY}\n{previousDate}: {value2}"
            })
        }));

        series.strokes.template.setAll({
            strokeWidth: 2
        });

        series.get("tooltip").get("background").set("fillOpacity", 0.5);

        let series2 = chart.series.push(am5xy.LineSeries.new(root, {
            name: "Series 2",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value2",
            valueXField: "date"
        }));
        series2.strokes.template.setAll({
            strokeDasharray: [2, 2],
            strokeWidth: 2
        });

        root.dateFormatter.setAll({
            dateFormat: "yyyy-MM-dd",
            dateFields: ["valueX"]
        });

        series.data.processor = am5.DataProcessor.new(root, {
            dateFormat: "yyyy-MM-dd",
            dateFields: ["date"]
        });

        series.data.setAll(data);
        series2.data.setAll(data);

        series.appear(1000);
        series2.appear(1000);

        chart.appear(1000, 100);

        setChartRoot(root);

        return () => {
            // Cleanup when unmounting the component
            if (chartRoot) {
                chartRoot.dispose();
            }

        };
    }, []);

    return <div id={id} style={{ width: '100%', height: height }}></div>;
}