import moment from "moment";
import React, { useState } from "react";


export const RecentActivityPanel = ({ title = 'Recent News & Activity', data }) => {

    const [listItems] = useState(data);

    const getUpdatedTime = (time) => {
        if (time) {
            let date = moment(new Date(time));
            return date.calendar({
                sameDay: '[Today at] hh:mm A',
                nextDay: '[Tomorrow]',
                nextWeek: 'dddd',
                lastDay: '[Yesterday at] hh:mm A',
                lastWeek: '[Last] dddd',
                sameElse: 'MMM DD, YYYY hh:mm A'
            })
        }
        else {
            return ''
        }
    }

    return (
        <div className="row">
            <div className="col-12 mb-3">
                <h6>{title}</h6>
                {
                    listItems.map((activity, i) => {
                        let icon = activity.icon ? activity.icon : 'fa-user-o';
                        return (
                            <div key={`recent-activity-k-${i + 1}`} >
                                <div className={`bl-activity py-2`}>
                                <i className={`fa ${icon} rounded-circle bl-user-activity-icon`} aria-hidden="true"></i>
                                    <div>
                                        {
                                            activity.title ? <p className="mb-0"><b>{activity.title}</b></p> : null
                                        }
                                        <p className="mb-0">{activity.message}</p>
                                        <p className="text-muted font-small mb-0">{activity.date ? activity.date : getUpdatedTime(activity.updated_at ? activity.updated_at : activity.created_at ? activity.created_at : null)}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}