import React, { useEffect, useState } from 'react';
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";

export const HierarchyChart = ({ height = '500px' }) => {

  const [chartRoot, setChartRoot] = useState(null);
  useEffect(() => {

    if (chartRoot) {
      chartRoot.dispose();
    }

    let hierarchyRoot = am5.Root.new("hierarchychartdiv");

    hierarchyRoot.setThemes([
      am5themes_Animated.new(hierarchyRoot)
    ]);

    hierarchyRoot._logo.dispose();

    var container = hierarchyRoot.container.children.push(am5.Container.new(hierarchyRoot, {
      width: am5.percent(100),
      height: am5.percent(100),
      layout: hierarchyRoot.verticalLayout,
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 0,
      paddingTop: 0
    }));


    var series = container.children.push(am5hierarchy.ForceDirected.new(hierarchyRoot, {
      downDepth: 1,
      initialDepth: 2,
      topDepth: 1,
      valueField: "value",
      categoryField: "name",
      childDataField: "children",
      xField: "x",
      yField: "y"
    }));

    var data = {
      name: "Firm",
      value: 1,
      children: [
        {
          name: "PreProd LLC",
          value: 50,
          linkWith: ["ESG", "Financial", "Legal", "Operational", "Strategic"]

        },


        {
          name: "ESG",
          children: [
            {
              name: "Governance Risk", value: 50
            },
            {
              name: "Environmental Risk", value: 30
            }
          ]
        },
        {
          name: "Financial",
          children: [
            {
              name: "Liquidity Risk", value: 50
            },
            {
              name: "Credit Risk", value: 10
            }
          ]
        },
        {
          name: "Legal",
          children: [
            {
              name: "Intellectual Property", value: 100
            }
          ]
        },
        {
          name: "Operational",
          children: [
            {
              name: "Data Breach", value: 100,
              linkWith: ["**Legal Trials", "**Data Privacy"]
            },
            {
              name: "GeoPolitical Risk", value: 30
            }
          ]
        },
        {
          name: "Strategic",
          children: [
            {
              name: "Product Risk", value: 50
            },
            {
              name: "Customer Risk", value: 100
            }
          ]
        },
        {
          name: "**Legal Trials",
          value: 10

        },
        {
          name: "**Data Privacy",
          value: 10

        }
      ]
    };

    series.data.setAll([data]);
    series.set("selectedDataItem", series.dataItems[0]);

    var legend = container.children.push(am5.Legend.new(hierarchyRoot, {
      centerX: am5.percent(50),
      x: am5.percent(50),
      layout: hierarchyRoot.horizontalLayout,
      visible: false
    }));

    legend.data.setAll(series.dataItems[0].get("children"));

    setChartRoot(hierarchyRoot)

    return () => {
      // Cleanup when unmounting the component
      if (chartRoot) {
        chartRoot.dispose();
      }

    };
  }, [])

  return (
    <div id="hierarchychartdiv" style={{ width: "100%", height: height }}></div>
  )
}

export default HierarchyChart;
