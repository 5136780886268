import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import riskEventJson from "./../../data/Risk/events.json";
import riskEventExplorerJson from "./../../data/Risk/eventsExplorer.json";
import { RiskEventViewComponent } from "./common/eventsListView";
import { TableCompanyViewComponent } from "./common/tableCompanyView";
import companyRiskProfile from "./../../data/Risk/companyRiskProfile.json";
import SearchContext from "../../services/context/Search/searchContext";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";
import { capitalizeFirstLetter } from "../../services/commonService";


export const RiskCompanyEventsComponent = () => {
    window.scrollTo(0, 0);

    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const location = useLocation()
    const [companyInfo, setCompanyInfo] = useState();

    useEffect(() => {
        if(companyInfo){
            const companySlug = [companyInfo.slug, 'Event Details'];
            let crumbElement = [];
            if (typeof companySlug != 'string') {
                crumbElement = [
                    { slug: '', menuName: 'Risk Monitor', isActive: true, hasSubMenu: true },
                    { slug: '/risk/overview', menuName: 'Overview', isActive: false, hasSubMenu: true },
                    { slug: `/risk/company/${companySlug[0]}`, menuName: capitalizeFirstLetter(companySlug[0]), isActive: false, hasSubMenu: true },
                    { slug: ``, menuName: companySlug[1], isActive: false, hasSubMenu: true }
                ];
            }
            else if (companySlug) {
                crumbElement = [
                    { slug: '', menuName: 'Risk Monitor', isActive: true, hasSubMenu: true },
                    { slug: '/risk/overview', menuName: 'Overview', isActive: false, hasSubMenu: true },
                    { slug: `/risk/company/${companySlug}`, menuName: capitalizeFirstLetter(companySlug), isActive: false, hasSubMenu: true }
                ];
            }
            setBreadcrumbMenu(crumbElement)
        }
    }, [companyInfo])

    const [companyList] = useState(companyRiskProfile);
    const [eventList] = useState(riskEventJson);
    const [viewType, setViewType] = useState('IMPECTED_COMPANY')
    const [eventInfo, setEventInfo] = useState({
        newsTitle:'', newsDescription:'', imgUrl:'', newsSource:'', publishedOn:'', eventCategory:'', eventSubCategory:'', riskCategory:'', riskSubCategory:''
    });
    const [columns] = useState([
        'Risk Category', 'Risk Sub Category', 'Impact'
    ])

    useEffect(() => {
        getCompanyInfo()
    }, [location])

    const getCompanyInfo = () => {
        let pathArray = location.pathname.split('/');
        if (pathArray && pathArray.length > 2) {
            if (pathArray[2] === 'company' && pathArray.length === 5) {
                let company = companyRiskProfile.find(c=> c.slug === pathArray[3]);
                if(company){
                    setCompanyInfo(company)
                }
                riskEventJson.map(er => {
                    let eveInfo = er.events.find(item => item.newsId == pathArray[4]);
                    if (eveInfo) {
                        setEventInfo(eveInfo)
                    }
                })
            }
            else if (pathArray[2] === 'event-explorer' && pathArray.length === 4) {
                riskEventExplorerJson.map(rexp => {
                    rexp.categories.map(cat => {
                        let eventExp = cat.list.find(item => item.newsExpId == pathArray[3]);
                        if (eventExp) {
                            setEventInfo(eventExp)
                        }
                    })
                })
            }
        }
    }

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <div className="row">
                    <div className="col-12 d-flex justify-content-between mb-3">
                    <div className="align-self-center mr-16">
                                <h6>{eventInfo.newsTitle}</h6>
                                <p>{eventInfo.newsDescription}</p>
                            </div>
                            <div>
                                <img className="bl-overview-img rounded" src={eventInfo.imgUrl} alt="overview-Img" />
                            </div>
                    </div>
                    <div className="col-xl-4 col-md-4 mb-3">
                        <div className="d-flex justify-content-between border-bottom pb-2">
                            <p className="m-0">Source</p>
                            <h6 className="m-0">{eventInfo.newsSource}</h6>
                        </div>
                        <div className="d-flex justify-content-between pt-2">
                            <p className="m-0">Published On</p>
                            <h6 className="m-0">{eventInfo.publishedOn}</h6>
                        </div>
                    </div>

                    <div className="col-xl-4 col-md-4 mb-3">
                    <div className="d-flex justify-content-between border-bottom pb-2">
                            <p className="m-0">Event Category</p>
                            <h6 className="m-0">{eventInfo.eventCategory}</h6>
                        </div>
                        <div className="d-flex justify-content-between pt-2">
                            <p className="m-0">Event Sub Category</p>
                            <h6 className="m-0">{eventInfo.eventSubCategory}</h6>
                        </div>
                    </div>

                    <div className="col-xl-4 col-md-4 mb-3">
                    <div className="d-flex justify-content-between border-bottom pb-2">
                            <p className="m-0">Risk Category</p>
                            <h6 className="m-0">{eventInfo.riskCategory}</h6>
                        </div>
                        <div className="d-flex justify-content-between pt-2">
                            <p className="m-0">Risk Sub Category</p>
                            <h6 className="m-0">{eventInfo.riskSubCategory}</h6>
                        </div>
                    </div>
                </div>
                <section className="mb-3">
                    <div className="row">
                        <div className="col-xl-12 col-md-12 col-12">
                            <ul className="nav nav-pills border-bottom border-light">
                                <li className="nav-item">
                                    <a className={viewType === 'IMPECTED_COMPANY' ? 'nav-link active blc_nav_active' : 'nav-link'} onClick={() => setViewType('IMPECTED_COMPANY')}>Impacted Companies</a>
                                </li>
                                <li className="nav-item">
                                    <a className={viewType === 'SIMILAR' ? 'nav-link active blc_nav_active' : 'nav-link'} onClick={() => setViewType('SIMILAR')}>Similar Events</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
                {
                    viewType === 'SIMILAR' 
                    ? <RiskEventViewComponent eventList={eventList} companyInfo={companyInfo}></RiskEventViewComponent>
                    : <TableCompanyViewComponent companyList={companyList} columns={columns}></TableCompanyViewComponent>
                }
            </div>
        </div>
    )
}