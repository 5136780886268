import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { AIAssistantModalComponent } from "./AIAssistant/AIModal";
import { FloatButton } from "./Common/floatButton";
import { HeaderComponent } from "./Common/header";
import { MenuComponent } from "./Common/menu";
import { SubMenuComponent } from "./Common/subMenu";
import { validateSessionService } from "../services/authService";



export const PageLayout = () => {

    const location = useLocation();
    const nav = useNavigate();

    useEffect(() => {
        validateSession()
    }, [location])

    const validateSession = async () => {
        const resp = await validateSessionService();
        if (!resp || !resp.success) {
            nav('/')
        }
    }

    return (
        <>
            {/* <!-- Header Topbar (SD) --> */}
            <HeaderComponent />
            {/* <!-- End of Header Topbar (SD)--> */}

            {/* <!-- Page Wrapper --> */}
            <div id="wrapper">

                {/* <!-- Sidebar --> */}
                <MenuComponent />
                {/* <!-- End of Sidebar --> */}
                <SubMenuComponent />
                {/* <!-- Content Wrapper --> */}
                <div id="content-wrapper" className="d-flex flex-column vistaContentMargin vistaCollapsedContentMargin">
                    <div className="page-loader-container" id="pageLoaderContainer">
                        <div className="page-loader"></div>
                    </div>
                    {/* <!-- Main Content --> */}
                    <Outlet />
                    {/* <!-- End of Main Content --> */}
                    <FloatButton />
                </div>
                {/* <!-- End of Content Wrapper --> */}
                <AIAssistantModalComponent />
                {/* <div className="minmaxCon"></div>   */}
            </div>
            {/* <!-- End of Page Wrapper --> */}
        </>
    )
}