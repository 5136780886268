import axios from "axios";
const apiKey = process.env.REACT_APP_BASE_URL;
const kairosAPPAPIKey = "428b5e8aefb41503b5977802be310f878c329cdc25";

const instance = axios.create({
    baseURL: apiKey
})

instance.interceptors.request.use((config)=>{
    if(getToken()){
        config.headers['Authorization'] = getToken();
    }
    return config;
}, (err)=>{
    return Promise.reject(err);
})

instance.interceptors.response.use((res)=>{
    return res.data;
}, (err)=>{
    if(!err || !err.response){
        window.location.href='/#/system-down'
    }
    else if(err.response.status >= 500){
        window.location.href = "/#/error";
    }
    return Promise.reject(err);
})

const authenticationUrl = "/auth/authenticate";
const sessionUrl = "/auth/validate/session";

export async function authenticateService(credential){
    try{
        return await instance.post(authenticationUrl, credential)
    }
    catch(e){
        return e;
    }
}

export async function validateSessionService(){
    try{
        return await instance.get(sessionUrl)
    }
    catch(e){
        return e;
    }
}

export async function storeToken(token){
    localStorage.setItem("token", token)
}

export function getToken(){
    return localStorage.getItem("token")
}

export function removeToken(){
    localStorage.clear()
}

export async function storeUserInfo(info){
    localStorage.setItem("user", info)
}

export function getUserInfo(){
    return localStorage.getItem("user")
}

export const authenticateKairosAppService = async() => {
    try{
        // const kairosApiUrl = "http://localhost:8001/";
        const kairosApiUrl = "https://blplatformapimanagerdev02.azure-api.net/bl-pt-api-gateway/";
        const kairosResp = await axios({
            method: 'get',
            url: `${kairosApiUrl}auth/token?userName=xyz@brownloop.com`,
            headers: { 'api-key': kairosAPPAPIKey },
        });
        return kairosResp.data;
    }
    catch(e){
        return e;
    }
}