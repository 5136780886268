import React, { useContext, useEffect } from "react";
import eventCategoryJson from "./../../../data/Risk/eventCategories.json";
import SearchContext from "../../../services/context/Search/searchContext";
import BreadcrumbContext from "../../../services/context/Breadcrumb/breadcrumbContext";

export const RiskEventCategoryComponent = () => {
    window.scrollTo(0, 0);

    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)

    useEffect(() => {
        setBreadcrumbMenu(
            [
                { slug: '', menuName: 'Risk Monitor', isActive: true, hasSubMenu: true },
                { slug: '/risk/event-categories', menuName: 'Event Category', isActive: false, hasSubMenu: true }
            ]
        )
    }, [])


    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <section className="mb-3 d-flex justify-content-between">
                    <div>
                        <h6>Event Categories</h6>
                    </div>
                    <div>
                        <button className="btn btn-primary btn-sm mr-2"><i className="fa fa-fw fa-download" aria-hidden="true"></i>Export</button>
                        <button className="btn btn-primary btn-sm mr-2"><i className="fa fa-fw fa-plus" aria-hidden="true"></i>{'Add Custom Category'}</button>
                        <button className="btn btn-primary btn-sm mr-2"><i className="fa fa-fw fa-edit" aria-hidden="true"></i></button>
                        <button className="btn btn-primary btn-sm mr-2"><i className="fa fa-fw fa-trash" aria-hidden="true"></i></button>
                        <button className="btn btn-primary btn-sm"><i className="fa fa-fw fa-filter" aria-hidden="true"></i></button>
                    </div>
                </section>
                <section className="mb-3">
                    <div className="row">
                        <div className="col-md-12 col-lg-12 mb-4">
                            <div className="card-deck bl-card-deck">
                                {
                                    eventCategoryJson.map((cat, i) => {
                                        return (
                                            <div key={`risk-event-cat-${i}`} className="card">
                                                <div className="card-header">
                                                    <h6 className={`mb-0`}><i className={`fa ${cat.icon} mr-3`} aria-hidden="true"></i>{cat.eventCategory}</h6>
                                                </div>
                                                <div className="card-body p-0">
                                                    <ul className="list-group list-group-flush">
                                                        {
                                                            cat.subCategory.map((item, j) => {
                                                                return (
                                                                    <li key={`risk-event-cat-item-${i}${j}`} className="list-group-item d-flex justify-content-between align-items-center font-small">
                                                                        {item}
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )

}