import React, { useContext, useEffect, useState } from "react";
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { InputText } from "primereact/inputtext";
import { useNavigate } from "react-router-dom";
import SearchContext from "../services/context/Search/searchContext";
import BreadcrumbContext from "../services/context/Breadcrumb/breadcrumbContext";
// import { Toast } from 'primereact/toast';


export const TreeTableComponent = ({ data }) => {

    // const toast = useRef(null);
    const nav = useNavigate();

    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)

    useEffect(() => {
        setBreadcrumbMenu([{ slug: '/feed', menuName: 'Feed', isActive: true, hasSubMenu: true }])
    }, [])

    const [nodes, setNodes] = useState([]);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [totalRecords, setTotalRecords] = useState(100);
    const [loading, setLoading] = useState(true);
    const [sortField, setSortField] = useState(null); // Column to sort by
    const [sortOrder, setSortOrder] = useState(null);
    const [selectedNodeKeys, setSelectedNodeKeys] = useState(null);

    useEffect(() => {
        setNodes(loadNodes(first, rows));
        setLoading(false)
    }, []);

    const onEditorValueChange = (options, value) => {
        let newNodes = JSON.parse(JSON.stringify(nodes));
        let editedNode = findNodeByKey(newNodes, options.node.key);

        editedNode.data[options.field] = value;

        setNodes(newNodes);
    };

    const findNodeByKey = (nodes, key) => {
        let path = key.split('-');
        let node;

        while (path.length) {
            let list = node ? node.children : nodes;

            node = list[parseInt(path[0], 10)];
            path.shift();
        }

        return node;
    };

    const inputTextEditor = (options) => {
        return <InputText type="text" value={options.rowData[options.field]} onChange={(e) => onEditorValueChange(options, e.target.value)} />;
    };

    const sizeEditor = (options) => {
        return inputTextEditor(options);
    };

    const typeEditor = (options) => {
        return inputTextEditor(options);
    };

    const onSelect = (event) => {
        nav('/master-data/companies/profile')
    };

    const loadNodes = (first, rows) => {
        let nodes = [];

        for (let i = 0; i < rows; i++) {
            let node = {
                key: `company-key-${first + i}`,
                data: {
                    "companyName": data[0].companyName,
                    "ownership": data[0].ownership,
                    "EBITDA": data[0].EBITDA,
                    "revenueGrowth": data[0].revenueGrowth,
                    "investment": data[0].investment,
                    "valuation": data[0].valuation,
                    "personnel": data[0].personnel,
                    "location": data[0].location
                },
                // leaf: false
            };

            nodes.push(node);
        }
        setTimeout(()=>{
            let leafItem = nodes.find(n=> n.leaf !== undefined && n.leaf === false);
        if(leafItem){
            let classList = document.getElementsByClassName('p-treetable-toggler');
            for(let i=0; i<classList.length; i++){
                classList[i].classList.remove('d-none')
            }
        }
        else {
            let classList = document.getElementsByClassName('p-treetable-toggler');
            for(let i=0; i<classList.length; i++){
                classList[i].classList.add('d-none')
            }
        }
        }, 500)

        return nodes;
    };

    const onPage = (event) => {
        setLoading(true);
        //imitate delay of a backend call
        setTimeout(() => {
            setFirst(event.first);
            setRows(event.rows);
            setNodes(loadNodes(event.first, event.rows));
            setLoading(false);
        }, 500);
    };

    const onExpand = (event) => {
        if (!event.node.children) {
            setLoading(true);
            setTimeout(() => {
                setLoading(false);
                let lazyNode = { ...event.node };

                lazyNode.children = [
                    {
                        data: {
                            name: lazyNode.data.name + ' - 0',
                            size: Math.floor(Math.random() * 1000) + 1 + 'kb',
                            type: 'File'
                        },
                        leaf: true
                    },
                    {
                        data: {
                            name: lazyNode.data.name + ' - 1',
                            size: Math.floor(Math.random() * 1000) + 1 + 'kb',
                            type: 'File'
                        }
                    }
                ];

                let _nodes = nodes.map((node) => {
                    if (node.key === event.node.key) {
                        node = lazyNode;
                    }

                    return node;
                });

                setLoading(false);
                setNodes(_nodes);
            }, 250);
        }
    };

    // const customCellRenderer = (rowData) => {
    //     let cellClass = rowData.key % 2 === 1 ? 'cell-chip odd-cell' : 'cell-chip';
    //     return (
    //         <div>
    //             <span className="mr-2">{rowData.data.size}</span>
    //             <span className={cellClass}>{rowData.data.performance} <i className="fa fa-line-chart" aria-hidden="true"></i></span>
    //         </div>
    //     );
    // };

    const onSort = (event) => {
        setSortField(event.sortField);
        setSortOrder(event.sortOrder);
        // fetchData();
    };

    const investmentArrange = (investmentRow) => {
        return (
            <div>
                {
                    investmentRow.data.investment.map((r,i)=>{
                        return (
                            <span key={`invest-${investmentRow.key}-${i+1}`}>{r} <br /></span>
                        )
                    })
                }
            </div>
        )
    }

    const personnelArrange = (personnelRow) => {
        return (
            <div>
                {
                    personnelRow.data.personnel.map((p, i)=>{
                        return (
                            <span key={`personnel-${personnelRow.key}-${i+1}`}>{p} <br /></span>
                        )
                    })
                }
            </div>
        )
    }

    const locationArrange = (item) => {
        return (
            <div>
                {
                    item.data.location.map((p, i)=>{
                        return (
                            <span key={`loc-${item.key}-${i+1}`}>{p} <br /></span>
                        )
                    })
                }
            </div>
        )
    }

    return (
        <TreeTable value={nodes} key="key" selectionMode="single" selectionKeys={selectedNodeKeys} onSelectionChange={(e) => setSelectedNodeKeys(e.value)} onSelect={onSelect} reorderableColumns
            lazy totalRecords={totalRecords} first={first} rows={rows} onPage={onPage} onExpand={onExpand} loading={loading} onSort={onSort}
            sortField={sortField} sortOrder={sortOrder}>
            <Column field="companyName" header="Company Name" expander sortable></Column>
            <Column field="ownership" className="bl-width-16rem" header="Ownership Hierarchy" editor={sizeEditor} sortable></Column>
            <Column field="EBITDA" className="bl-width-7rem" header="EBITDA" editor={typeEditor} sortable></Column>
            <Column field="revenueGrowth" className="bl-width-6rem" header="Growth" editor={typeEditor} sortable></Column>
            <Column field="investment" header="Investment" body={investmentArrange} sortable></Column>
            <Column field="valuation" header="Valuation History" sortable></Column>
            <Column field="personnel" header="Key Personnel" body={personnelArrange} sortable></Column>
            <Column field="location" header="Locations" body={locationArrange} sortable></Column>
        </TreeTable>
    )
}