import React from "react";


export const AppsCardComponent = ({ app }) => {

    return (
        <div className="card shadow-sm mb-3 bl-recommended">
            <div className="card-body pb-1 d-flex justify-content-between">
                <div>
                    <div className="d-flex">
                        <div className="mr-2 ml-0 bl-recommended-app-icon">
                            <img className="img-fluid card-img-top bl-recommended-app-icon p-1" src={app.icon} alt="Card image cap" />
                        </div>
                        <div className="align-self-center">
                            <h6 className="mb-0 text-nowrap">{app.title}</h6>
                            <p className="mb-0 font-small text-muted text-nowrap">{app.category}</p>
                        </div>
                    </div>
                    <p className="my-1 font-small">{app.description}</p>
                </div>
                {
                    app.isEnabled ? (<i className={`fa fa-thumb-tack bl-app-pinned-pos ${app.isPinned ? 'text-black' : 'text-gray'}`} aria-hidden="true"></i>) : null
                }
            </div>
            <div className="card-footer text-center bg-white p-2">
                <p className="mb-0 cp font-small text-muted">
                    {
                        app.isEnabled ? (
                            <span><i className={`fa fa-check-circle text-success`} aria-hidden="true"></i> Enabled</span>
                        ) : 'Enable Now'
                    }

                </p>
            </div>
        </div>
    )
}