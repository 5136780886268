import { Column } from "primereact/column";
import { TreeTable } from "primereact/treetable";
import React, { useContext, useEffect, useState } from "react";
import { BarChart } from "../../Graph/barchart";
import { BubbleChart } from "../../Graph/bubblechart";
import { CarbonZeroChart } from "../../Graph/carbonzerochart";
import homeLabel from "./../../../utils/property-file/componentOverview.json";
import companyRiskProfile from "./../../../data/Risk/companyRiskProfile.json";
import riskTrendIcon from "./../../../assets/img/risk-trend.svg";
import SearchContext from "../../../services/context/Search/searchContext";
import BreadcrumbContext from "../../../services/context/Breadcrumb/breadcrumbContext";


export const RiskPortfolioCompanyComponent = () => {

    window.scrollTo(0, 0);

    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)

    useEffect(() => {
        setBreadcrumbMenu(
            [
                { slug: '', menuName: 'Risk Monitor', isActive: true, hasSubMenu: true },
                { slug: '/risk/data-sources', menuName: 'Portfolio Company', isActive: false, hasSubMenu: true }
            ]
        )
    }, [])

    const [nodes, setNodes] = useState([]);
    const [labels, setLabels] = useState({
        WELCOME: "",
        WELCOME_DESC: "",
        LATEST_UPDATE: "",
        WELCOME_ICON:"",
        GUIDE: "",
        YOUR_ACTIVITY: "",
        ENTITY: {
            TITLE_1: "",
            DESC_1: "",
            TITLE_2: "",
            DESC_2: "",
            TITLE_3: "",
            DESC_3: ""
        },
        RECOMMEND: ""
    });

    useEffect(()=>{
        let items = homeLabel.find(l=> l.FEATURE === "RISK").items;
        setLabels(items)
    }, [homeLabel])

    useEffect(()=>{
        setNodes(loadNodes(0, companyRiskProfile));
    }, [companyRiskProfile])

    const loadNodes = (first, rows) => {
        let nodes = [];
        for (let i = 0; i < rows.length; i++) {
            let node = {
                key: `risk-comp-port-key-${first + i}`,
                data: {
                    companyId: rows[i].companyId,
                    company: rows[i].company,
                    logo: rows[i].logo,
                    industry: rows[i].industry,
                    fundingAmount: rows[i].fundingAmount,
                    valuation: rows[i].valuation,
                    address: rows[i].address,
                    eventCount: rows[i].eventCount,
                    isPinned: rows[i].isPinned,
                    description: rows[i].description
                }
            };
            nodes.push(node);
        }
        setTimeout(() => {
            let leafItem = nodes.find(n => n.leaf !== undefined && n.leaf === false);
            if (leafItem) {
                let classList = document.getElementsByClassName('p-treetable-toggler');
                for (let i = 0; i < classList.length; i++) {
                    classList[i].classList.remove('d-none')
                }
            }
            else {
                let classList = document.getElementsByClassName('p-treetable-toggler');
                for (let i = 0; i < classList.length; i++) {
                    classList[i].classList.add('d-none')
                }
            }
        }, 500)
        return nodes;
    };

    const arrangeColumnValue = (item, column) => {
        let data = item.data;
        if (column === 'ICON') {
            return (
                <div className="d-flex align-items-center">
                    {
                       data.isPinned ? (<i className="fa fa-fw fa-thumb-tack mr-2" aria-hidden="true"></i>) : (<i className="fa fa-fw fa-thumb-tack text-gray mr-2" aria-hidden="true"></i>)
                    }

                    <img src={data.logo} alt="logo" className="img-fluid rounded-circle bl-report-thumbnail" />
                    <div className="pl-2">
                        <p className="mb-0 text-nowrap"><b>{data.company}</b></p>
                    </div>
                </div>
            )
        }
        else if(column === 'RISK_SCORE') {
            return (
                <CarbonZeroChart id={item.key} height="15px"></CarbonZeroChart>
            )
        }
        else if(column === 'EVENTS'){
            return (
                <span className="bl-rounded-info">{data.eventCount}</span>
            )
        }
        else if(column === 'RISK_TREND'){
            return (
                <img className="img-fluid" alt="risk-trend" src={riskTrendIcon}/>
            )
        }
    }

    return (
        <div id="content">
            <div className="container-fluid pt-3">
                <section className="mb-3 d-flex justify-content-between">
                    <div>
                        <h6>My Portfolio Companies</h6>
                    </div>
                    <div>
                        <button className="btn btn-primary btn-sm mr-2"><i className="fa fa-fw fa-share" aria-hidden="true"></i>Share</button>
                        <button className="btn btn-primary btn-sm mr-2"><i className="fa fa-fw fa-exchange" aria-hidden="true"></i>Compare With</button>
                        <button className="btn btn-primary btn-sm mr-2"><i className="fa fa-fw fa-download" aria-hidden="true"></i>Download</button>
                        <button className="btn btn-primary btn-sm mr-2"><i className="fa fa-fw fa-filter" aria-hidden="true"></i></button>
                        <button className="btn btn-primary btn-sm"><i className="fa fa-fw fa-ellipsis-v" aria-hidden="true"></i></button>
                    </div>
                </section>
                <section className="mb-3">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="card shadow-sm">
                                <div className="card-header d-flex justify-content-between">
                                    <div className="align-self-center">
                                        <h6 className="mb-0">{labels.ENTITY.TITLE_1}</h6>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <BubbleChart height="13rem" id="riskportbubble"></BubbleChart>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="card shadow-sm">
                                <div className="card-header d-flex justify-content-between">
                                    <div className="align-self-center">
                                        <h6 className="mb-0">{labels.ENTITY.TITLE_2}</h6>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <BarChart height="13rem" id="riskportbarchart"></BarChart>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="mb-3">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-12 mt-3">
                            <div className="d-flex justify-content-between mb-3">
                                <div className="align-self-center">
                                    <h6 className="mb-0">{labels.ENTITY.TITLE_3}</h6>
                                </div>
                                <div>
                                    <div className="form-group mb-0">
                                        <select className="form-control form-control-sm blc_button">
                                            <option>Table view</option>
                                            <option>....</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <TreeTable value={nodes}>
                                <Column field="company" header="Company Name" style={{ width: '13rem' }} body={(e) => arrangeColumnValue(e, 'ICON')} sortable></Column>
                                <Column field="description" header="About the Company" sortable></Column>
                                <Column field="industry" header="Industry" style={{ width: '6rem' }} sortable></Column>
                                <Column field="fundingAmount" header="Risk Trend" body={(e) => arrangeColumnValue(e, 'RISK_TREND')} style={{ width: '7rem' }} sortable></Column>
                                <Column header="Current Risk Score" style={{ width: '10rem' }} body={(e) => arrangeColumnValue(e, 'RISK_SCORE')}></Column>
                                <Column field="eventsCount" header="Events Identified" className="text-center" body={(e) => arrangeColumnValue(e, 'EVENTS')} style={{ width: '8rem' }}></Column>
                            </TreeTable>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}