import React from "react";


export const RecommendedSourceComponent = ({ recommendedDataSource }) => {

    return (
        <>
            {
                recommendedDataSource.map((rd, i) => {
                    return (
                        <div key={`risk-data-recom-cat-${i}`} className="row">
                            <div className="col-xl-12 col-md-12 col-12 mb-3">
                                <h6>{rd.category}</h6>
                            </div>
                            <div className="col-md-12 col-lg-12 col-12">
                                <div className="card-deck bl-card-deck">
                                    {
                                        rd.list.map((item, j) => {
                                            return (
                                                <div key={`risk-data-recom-cat-item-${i}${j}`} className="card shadow-sm mb-4">
                                                    <div className="card-body">
                                                        <div className="d-flex justify-content-between mb-2">
                                                            <div className="d-flex align-self-center">
                                                                <img className="mr-2 rounded" src={item.imgUrl} height="40px" alt="Generic placeholder image" />
                                                                <h6 className="mb-0 align-self-center">{item.name}</h6>
                                                            </div>
                                                            <div className="align-self-center">
                                                                <label className="bl-switch mb-0">
                                                                    <input type="checkbox" defaultChecked={item.enabled} />
                                                                    <span className="bl-slider round"></span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <p className="mb-0">{item.desc}</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                            </div>
                        </div>
                    )
                })
            }
        </>
    )
}