import React, { useEffect, useState } from 'react';
import { getUserInfo } from '../../authService';

export const AuthContext = React.createContext();

export const AuthContextProvider = ({ children }) => {

    const [loggedUserName, setLoggedUserName] = useState('');
    const [isCanabisApp, setIsCanabisApp] = useState(false);

    useEffect(() => {
        const userInfo = JSON.parse(getUserInfo());
        if(userInfo){
            setLoggedUserName(userInfo.userName)
            setIsCanabisApp(userInfo.isCanabisApp)
        }
    }, [])

    return (
        <AuthContext.Provider value={{ loggedUserName, setLoggedUserName, isCanabisApp, setIsCanabisApp }}>
            {children}
        </AuthContext.Provider>
    );
};
