import React, { useEffect } from "react";
import systemdown from "./../../assets/img/systemdown.png";
import commonLabel from "../../utils/property-file/common.json";
import { validateSessionService } from "../../services/authService";
import { useNavigate } from "react-router-dom";


const SystemDownCompoent = () =>{

    const nav = useNavigate();

    useEffect(()=>{
        validateSession()
    }, [])

    const validateSession = async () => {
        const resp = await validateSessionService();
        if(resp && resp.success){
            nav('/')
        }
    }

    return (
        <div className="row">
            <div className="col text-center mt-5">
            <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
              <div className="sidebar-brand-icon mb-2">
                <img src={systemdown} height="80px" alt="system-down" />
              </div>
            </div>
            <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
              <h1 className="h3 text-gray-800">{commonLabel.SYSTEM_DOWN}</h1>
            </div>
            <div className="col-xl-12 col-md-12 d-flex align-items-center justify-content-center">
              <p>{commonLabel.DOWN_DESC}</p>
            </div>
            </div>
        </div>
    )
}

export default SystemDownCompoent;