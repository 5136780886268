import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import itemListJson from "./../../data/Workflows/workflowItems.json";
import workflowMenuJson from "./../../data/Workflows/subMenu.json";
import { WorkflowCardComponent } from "./workflowCard";
import SearchContext from "../../services/context/Search/searchContext";
import BreadcrumbContext from "../../services/context/Breadcrumb/breadcrumbContext";


export const WorkflowCategoryComponent = () => {

    window.scrollTo(0, 0);
    const { globalSearch } = useContext(SearchContext)
    const { setBreadcrumbMenu } = useContext(BreadcrumbContext)
    const location = useLocation()

    useEffect(() => {
        const slug = getLocationSlug();
        const category = workflowMenuJson[0].list.find(c=> c.slug === slug);
        if(category){
            setAppCategory(category.name)
            let items = itemListJson.filter(app=>app.category === category.name);
            setWorkflowItems(items)
        }
        setBreadcrumbMenu(
            [
                { slug: '', menuName: 'Workflows', isActive: true, hasSubMenu: true },
                { slug: `/workflow/category/${slug}`, menuName: category.name, isActive: false, hasSubMenu: true }
            ]
        )
    }, [location])

    const [workflowItems, setWorkflowItems] = useState([])
    const [appCategory, setAppCategory] = useState('')

    const getLocationSlug = () => {
        let pathArray = location.pathname.split('/');
        if (pathArray && pathArray.length > 3) {
            return pathArray[3];
        }
    }

    return (
        <div id="content">
            <div className="container-fluid pt-3">

                <div className="row">
                    <div className="col-12">
                        <section className="d-flex justify-content-between mb-3">
                            <div className="align-self-center mr-16">
                                <h6>{appCategory}</h6>
                            </div>
                        </section>
                        <section className="mb-3">
                            <div className="card-deck">
                                {
                                    workflowItems.map((item, i) => {
                                        return (
                                            <WorkflowCardComponent key={`workflow-items-app-${i}`} item={item}></WorkflowCardComponent>
                                        )
                                    })
                                }
                            </div>
                        </section>
                    </div>

                </div>
            </div>

        </div>
    )
}